import usePortalData from "../hooks/usePortalData";

const tr = (data, lang) => {
    if (data) {
        if (!lang || lang === "any") {
            lang = "en";
        }
        try {
            data = JSON.parse(data);
            if (data[lang]) {
                return data[lang];
            }
            if (data["en"]) {
                return data["en"];
            }
        } catch (error) {
            console.error(error);
        }
    }
    return "";
};

export const arePublicConditions = () => {
    return window.location.pathname.startsWith("/conditions");
};

export const isPortal = () => {
    return window.location.pathname.startsWith("/portal");
};

export const isMikrotikSimulator = () => {
    return window.location.pathname.startsWith("/mikrotik-simulator");
};

export const isWifi = () => {
    return isPortal() || arePublicConditions() || isMikrotikSimulator();
};

export const useConfiguredTerms = (lang) => {
    const { session: portalSession } = usePortalData();
    const config = window.appConfig;
    const data = config && config.accessConditions ? tr(config.accessConditions, lang) : null;
    if (data) {
        return data;
    } else {
        const isConditions = window.location.pathname.startsWith("/conditions");
        if (isConditions) {
            return portalSession && portalSession.config ? portalSession.config.accessConditions : null;
        }
    }
    return null;
};

export const useConfiguredPrivacy = (lang) => {
    const { session: portalSession } = usePortalData();
    const config = window.appConfig;
    const data = config && config.privacyPolicy ? tr(config.privacyPolicy, lang) : null;
    if (data) {
        return data;
    } else {
        const isConditions = window.location.pathname.startsWith("/conditions");
        if (isConditions) {
            return portalSession && portalSession.config ? portalSession.config.privacyPolicy : null;
        }
    }
    return null;
};

export const useAskMarketing = () => {
    const config = window.appConfig;
    return config ? config.askMarketing : false;
};
