import React from "react";

import { size as renderSize } from "zrender/sizes";
import { getDeviceWidth } from "./Design/DesignUtils";

const Tag = (props) => {
    const size = (v) => renderSize(v, "mobile", getDeviceWidth());
    return (
        <div
            {...props}
            className={`inline-block rounded bg-primary-contrast text-primary ${props?.className}`}
            style={{
                fontWeight: 400,
                fontSize: size(1.1),
                paddingLeft: size(0.75),
                paddingRight: size(0.75),
                paddingTop: size(0.3),
                paddingBottom: size(0.3),
                marginRight: size(0.5),
                marginBottom: size(0.5),
                ...props?.style,
            }}
        >
            {props?.children}
        </div>
    );
};

export default Tag;
