import React from "react";

import { size as renderSize } from "zrender/sizes";
import { getDeviceWidth } from "./Design/DesignUtils";
import ProductItem from "./ProductItem";

const ProductsList = ({ style, items, isCartSection = false }) => {
    const size = (v) => renderSize(v, "mobile", getDeviceWidth());
    return (
        <div style={{ fontSize: size(1.4), overflow: "overlay", ...style }}>
            {items?.map((item, index) => (
                <ProductItem key={`product-list-product-item-${index}`} {...item} isCartSection={isCartSection} />
            ))}
        </div>
    );
};

export default ProductsList;
