export const SET_GOOGLE_TOKEN = "SET_GOOGLE_TOKEN";
export const SET_VERIFIED_TOKEN = "SET_VERIFIED_TOKEN";

export function setGoogleToken(value) {
    return setDispatch(SET_GOOGLE_TOKEN, value);
}

export function setVerifiedToken(value) {
    return setDispatch(SET_VERIFIED_TOKEN, value);
}

function setDispatch(type, value) {
    return (dispatch) => {
        dispatch({
            type: type,
            payload: value,
        });
    };
}
