import React from "react";
import { PRECHECKIN_STATUS } from "./utils";
import { useTranslation } from "react-i18next";

const SendInformation = ({ dataStayBooking }) => {
    const { t } = useTranslation();
    const statusBooking = dataStayBooking?.state;
    const isPrecheckinOrValidated = [
        PRECHECKIN_STATUS.PENDING_COMPLETION.value,
        PRECHECKIN_STATUS.VALIDATED.value,
        PRECHECKIN_STATUS.PENDING_VALIDATION.value,
    ].includes(statusBooking);

    return (
        <div className=" py-6">
            {isPrecheckinOrValidated && (
                <>
                    <p className=" block mb-2">{t("all-the-information-is-already-filled-in")}</p>
                    <p className=" block mb-2">{t("send-the-form-so-that-hotel-can-validate-your-data")}</p>
                </>
            )}
            {statusBooking === PRECHECKIN_STATUS.PENDING_MODIFICATION.value && (
                <p>{t("send-the-form-so-that-hotel-can-validate-your-data")}</p>
            )}
            {isPrecheckinOrValidated || statusBooking === PRECHECKIN_STATUS.PENDING_MODIFICATION.value ? (
                <p>{t("you-will-be-able-to-modify-later")}</p>
            ) : null}
        </div>
    );
};

export default SendInformation;
