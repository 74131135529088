import React from "react";
import { useTranslation } from "react-i18next";

const InputDate = ({ placeholder = "", value, onChange, name = "name", error, isRequired, errorMsg, disabled }) => {
    const { t } = useTranslation();

    return (
        <div className=" w-full">
            <label
                className={`block text-sm mb-1 ${disabled ? " text-neutral-contrast-40" : ""} `}
                htmlFor={`text-input-${name}-input`}
            >
                {t(name)} {isRequired && !disabled ? " *" : ""}
            </label>
            <input
                // ref={register()}
                className={` ${disabled ? "disabled" : ""} ${error ? "error" : ""}`}
                type="date"
                onChange={(e) => {
                    if (onChange) {
                        onChange(e.target.value);
                    }
                }}
                disabled={disabled}
                name="birth"
                id={`date-input-${name}-input`}
                value={value}
                placeholder="DD/MM/YYYY"
                max={new Date().toISOString().split("T")[0]}
                // onChange={() => triggerValidation("birth")}
                // className={errors.birth ? "error" : ""}
            />
            {error && errorMsg ? <div className=" text-error float-right text-sm">{errorMsg}</div> : null}
        </div>
    );
};

export default InputDate;
