import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";

import { configToCSS } from "../../DesignUtils";
import useInterval from "../../../../hooks/useInterval";

const UseVerify = ({ code: initialCode, cfg, deviceType, submitCode, submitVerify }) => {
    const textInput = useRef(null);
    const { t } = useTranslation();

    let initialCount = 60;
    const lastVerification = null;
    if (lastVerification && lastVerification.sent) {
        const verificationAgo = Math.round((new Date().getTime() - lastVerification.sent) / 1000);
        initialCount = 60 - verificationAgo;
        if (initialCount < 0) {
            initialCount = 0;
        }
    }
    const [count, setCount] = useState(initialCount);
    const [code, setCode] = useState("");

    useInterval(() => {
        if (count > 0) {
            setCount(count - 1);
        }
    }, 1000);

    useEffect(() => {
        if (initialCode) {
            if (initialCode.length >= 6) {
                verifyCode(initialCode);
            } else {
                setCode(initialCode);
            }
        }
    }, [initialCode]);

    const sendCode = () => {
        if (count === 0) {
            setCount(60);
            setCode("");
            textInput.current.value = "";
            textInput.current.focus();
            if (submitCode) {
                submitCode().catch((err) => {
                    setCount(0);
                    swal({
                        title: t("error.title"),
                        text: t("error.unknown") + "\n" + t("error.unknownAction"),
                        button: t("alert ok"),
                    }).then(() => {
                        textInput.current.focus();
                    });
                });
            }
        }
    };

    const verifyCode = (code) => {
        setCode(code);
        submitVerify(code).catch((err) => {
            swal({
                title: t("error.title"),
                text: err || t("error.unknown") + "\n" + t("error.unknownAction"),
                button: t("alert ok"),
            }).then(() => {
                setCount(60);
                setCode("");
                if (textInput && textInput.current) {
                    textInput.current.value = "";
                    textInput.current.focus();
                }
            });
        });
    };

    return (
        <>
            <div className="opacity-0 h-0 relative overflow-hidden">
                <input
                    ref={textInput}
                    type="number"
                    max="999999"
                    onChange={(e) => {
                        const value = e.target.value.trim().substring(0, 6);
                        if (value.length === 6) {
                            verifyCode(value);
                        } else {
                            setCode(value);
                        }
                    }}
                    autoFocus={true}
                    className="absolute"
                />
            </div>
            <p
                className="flex text-center mb-6"
                onClick={(e) => {
                    textInput.current.focus();
                }}
                onDoubleClick={(e) => {
                    textInput.current.value = "";
                    setCode("");
                }}
            >
                {[0, 1, 2, 3, 4, 5].map((index) => (
                    <span
                        className="font-mono h-12 text-xl inline-block border-b border-neutral-contrast flex-1 mx-1 py-2 "
                        key={index}
                    >
                        {code[index]}
                    </span>
                ))}
            </p>
            {submitCode ? (
                <div className="text-left my-5 pt-5" style={{ fontSize: ".8em" }}>
                    <button
                        className={count > 0 ? "opacity-50" : ""}
                        disabled={count > 0 ? true : false}
                        onClick={() => sendCode()}
                        style={count === 0 ? configToCSS(cfg.link, deviceType) : null}
                    >
                        {t("send code again")}
                    </button>
                    <span className="inline-block ml-4">{count > 0 ? t("x seconds", { count: count }) : ""}</span>
                </div>
            ) : null}
        </>
    );
};

export default UseVerify;
