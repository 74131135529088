import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import { useConfiguredTerms, useConfiguredPrivacy } from "../utils/config";
import { useUpdateProfile } from "../graphql/useUser";
import Loading from "./Loading";
import { handleShowTerms, handleShowPrivacy } from "./DialogHandlers";

const NewTerms = ({ token, onSuccess, onError }) => {
    const { t, i18n } = useTranslation();
    const [accepted, setAccepted] = useState(false);
    const i18nLang = (i18n.language || "").match(/^[^-]+/)[0];

    const accessConditions = useConfiguredTerms(i18nLang);
    const privacyPolicy = useConfiguredPrivacy(i18nLang);

    const { acceptTerms, loading } = useUpdateProfile({
        token,
        onCompleted: (data) => {
            const user = data && data.updateUserProfile ? data.updateUserProfile.user : null;
            if (user) {
                if (onSuccess) onSuccess();
            } else {
                if (onError) onError();
            }
        },
        onError: (error) => {
            console.error(error);
            if (onError) onError(error);
        },
    });

    return (
        <div className="text-center px-4">
            {loading && <Loading />}
            <div className="font-bold text-lg my-2">{t("update")}</div>
            <div className=" my-5  text-sm">{t("terms updated")}</div>
            <div className=" my-10 text-sm">
                <input
                    id="checkbox-terms"
                    type="checkbox"
                    name="terms"
                    disabled={loading}
                    checked={accepted}
                    onChange={() => {
                        setAccepted(!accepted);
                    }}
                />
                <label htmlFor="checkbox-terms" className="pl-2">
                    {t("accept terms", { terms: " " })}
                </label>
                <span
                    href="#"
                    onClick={() => {
                        handleShowTerms(accessConditions, swal);
                    }}
                    className="text-accent cursor-pointer"
                >
                    {t("terms & conditions")}
                </span>
                {t("terms & policy", { terms: " ", privacy: " " })}
                <span
                    href="#"
                    onClick={() => {
                        handleShowPrivacy(privacyPolicy, swal);
                    }}
                    className="text-accent cursor-pointer"
                >
                    {t("privacy policy")}
                </span>
            </div>
            <button
                type="button"
                onClick={() => {
                    if (accepted && !loading) {
                        acceptTerms();
                    }
                }}
                disabled={!accepted || loading}
                className={`btn btn-thin btn-accent ${
                    accepted && !loading ? "" : "opacity-50"
                } inline-block mx-auto mt-2 mb-2 px-10`}
            >
                {t("continue")}
            </button>
        </div>
    );
};

export default NewTerms;
