import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import swal from "@sweetalert/with-react";
import _ from "lodash";

import { calculateAmounts, createOrder, updateAmounts, validateAviability } from "../../../integrations/useCuiner";
import { useSendCuinerOrderMail } from "../../../graphql/useUser";
import { calculateDecimals } from "../../../utils/others";
import {
    setCuinerData,
    getCuinerData,
    getCuinerRestaurant,
    getCuinerMenu,
    getCuinerCart,
} from "../../../actions/cuiner";
import { getUser, getUserData } from "../../../actions/user";
import { markdown } from "../../../utils/others";

import useForceUpdate from "../../../hooks/useForceUpdate";
import Tabs from "../../../components/Tabs";
import Header from "../../../components/Header";
import Loading from "../../../components/Loading";

const Cart = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const forceUpdate = useForceUpdate();
    const i18nLang = (i18n.language || "").match(/^[^-]+/)[0];
    const currentUser = useSelector(getUser);
    const user = useSelector(getUserData);
    const cuinerData = useSelector(getCuinerData);
    const selectedRestaurant = useSelector(getCuinerRestaurant);
    const selectedMenu = useSelector(getCuinerMenu);
    const cart = useSelector(getCuinerCart);
    const selectedCategoy = selectedMenu && selectedMenu.currentCategory ? selectedMenu.currentCategory : null;
    const selectedLanguage =
        selectedCategoy && selectedCategoy.selectedLanguage ? selectedCategoy.selectedLanguage : i18nLang;

    const { sendCuinerOrderMail } = useSendCuinerOrderMail();
    const [redirectTo, setRedirectTo] = useState("");
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState(null);
    const [comments, setComments] = useState(
        selectedRestaurant && selectedRestaurant.comments ? selectedRestaurant.comments : ""
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (cart && cart.length > 0) {
            let showModal = false;
            for (const item of cart) {
                if (item.canPurchase && item.canPurchase.available) {
                    const available = validateAviability(
                        item.cuinerData.available,
                        item.cuinerData.availabilities,
                        {
                            all: item.cuinerData.modifiersGroups,
                            selecteds: item.modifierGroups,
                        },
                        t,
                        selectedLanguage
                    );

                    if (!available || !available.available) {
                        updateItemQuantity(item, item.quantity);
                        showModal = true;
                    }
                } else {
                    updateItemQuantity(item, 0);
                }
            }
            if (showModal) {
                swal({
                    title: t("unavailable products"),
                    text: t("unavailable products details"),
                    buttons: {
                        cancel: t("close"),
                    },
                });
            }
        } else {
            setRedirectTo("/site");
        }
    }, []);

    useEffect(() => {
        if (cart && cart.length > 0) {
            let summaryData = { subTotal: 0.0, taxes: 0.0, total: 0.0 };
            for (const item of cart) {
                if (item.canPurchase && item.canPurchase.available) {
                    summaryData.subTotal += item.subTotal;
                    summaryData.taxes += item.taxes;
                    summaryData.total += item.total;
                }
            }
            summaryData.subTotal = calculateDecimals(summaryData.subTotal, 2);
            summaryData.taxes = calculateDecimals(summaryData.taxes, 2);
            summaryData.total = calculateDecimals(summaryData.total, 2);
            setSummary(summaryData);
        }
    }, [cart]);

    const updateItemQuantity = (item, quantity) => {
        if (item) {
            const cuinerDataClone = { ...cuinerData };
            const selectedRestaurantClone = { ...selectedRestaurant };
            const cartClone = [...cart];
            let itemClone = { ...item };
            itemClone.quantity = quantity;
            itemClone = updateAmounts(updateAviabilityItem(itemClone));

            const existingCartItem = cartClone.find((cartItem) => cartItem.id === itemClone.id);

            if (itemClone.quantity && itemClone.quantity > 0) {
                if (existingCartItem) {
                    const index = cartClone.indexOf(existingCartItem);
                    cartClone.splice(index, 1, itemClone);
                } else {
                    cartClone.push(itemClone);
                }
            } else {
                if (existingCartItem) {
                    _.remove(cartClone, { id: itemClone.id });
                }
            }

            selectedRestaurantClone.cart = cartClone;
            const index = _.findIndex(cuinerDataClone.restaurants, { id: selectedRestaurantClone.id });
            cuinerDataClone.restaurants.splice(index, 1, selectedRestaurantClone);

            dispatch(setCuinerData(cuinerDataClone));
            forceUpdate();
        }
    };

    const updateComments = (comments) => {
        let cuinerDataClone = { ...cuinerData };
        let selectedRestaurantClone = { ...selectedRestaurant };
        selectedRestaurantClone.comments = comments;
        let index = _.findIndex(cuinerDataClone.restaurants, { id: selectedRestaurantClone.id });
        cuinerDataClone.restaurants.splice(index, 1, selectedRestaurantClone);
        dispatch(setCuinerData(cuinerDataClone));
        setComments(comments);
        forceUpdate();
    };

    const updateAviabilityItem = (item) => {
        if (item && selectedLanguage) {
            item.canPurchase = validateAviability(
                item.cuinerData.available,
                item.cuinerData.availabilities,
                {
                    all: item.cuinerData.modifiersGroups,
                    selecteds: item.modifierGroups,
                },
                t,
                selectedLanguage
            );
        }
        return item;
    };

    const sendOrder = async () => {
        if (cart && cart.length > 0) {
            var cartClone = [...cart];
            var showModal = false;
            cartClone.forEach(async (item) => {
                var available = validateAviability(
                    item.cuinerData.available,
                    item.cuinerData.availabilities,
                    {
                        all: item.cuinerData.modifiersGroups,
                        selecteds: item.modifierGroups,
                    },
                    t,
                    selectedLanguage
                );
                if (!available || !available.available) {
                    updateItemQuantity(item, item.quantity);
                    showModal = true;
                }
            });
            if (!showModal) {
                if (currentUser && user) {
                    var cartItems = [];
                    var sendMailItems = [];
                    var send = true;
                    for (const element of cart) {
                        var cartItem = element;
                        if (cartItem.canPurchase && cartItem.canPurchase.available) {
                            var totalData = calculateAmounts({
                                quantity: cartItem.quantity,
                                unitPrice: cartItem.unitPrice,
                                taxPercent: cartItem.taxPercent,
                            });
                            if (cartItem.extraAmounts && cartItem.extraAmounts.length > 0) {
                                for (var j = 0; j < cartItem.extraAmounts.length; j++) {
                                    var extraAmount = cartItem.modifierGroups[j];
                                    if (extraAmount.operator === "-") {
                                        totalData.total = calculateDecimals(totalData.total - extraAmount.total, 2);
                                    }
                                }
                            }
                            var cartItemOrder = {
                                id: cartItem.id,
                                pending: true,
                                quantity: cartItem.quantity,
                                name:
                                    selectedLanguage &&
                                    cartItem.cuinerData.titles &&
                                    cartItem.cuinerData.titles[selectedLanguage]
                                        ? cartItem.cuinerData.titles[selectedLanguage]
                                        : "",
                                price: totalData.total,
                                taxPercent: totalData.taxPercent,
                                modifiers: [],
                            };
                            var sendMailItem = {
                                title: cartItemOrder.name,
                                quantity: cartItemOrder.quantity,
                                unitPrice: cartItem.unitPrice,
                                total: totalData.total,
                                extras: [],
                            };
                            if (cartItem.modifierGroups && cartItem.modifierGroups.length > 0) {
                                for (var j = 0; j < cartItem.modifierGroups.length; j++) {
                                    var modifieGroup = cartItem.modifierGroups[j];
                                    if (modifieGroup && modifieGroup.items && modifieGroup.items.length > 0) {
                                        var modifierItem = null;
                                        var sendMailItemExtra = {
                                            title:
                                                selectedLanguage &&
                                                modifieGroup.title &&
                                                modifieGroup.title[selectedLanguage]
                                                    ? modifieGroup.title[selectedLanguage]
                                                    : "",
                                            items: [],
                                        };
                                        for (var k = 0; k < modifieGroup.items.length; k++) {
                                            modifierItem = modifieGroup.items[k];
                                            if (modifierItem && modifierItem.cuinerData) {
                                                send = validateAviability(
                                                    modifierItem.cuinerData.available,
                                                    modifierItem.cuinerData.availabilities,
                                                    null,
                                                    t,
                                                    selectedLanguage
                                                );
                                                if (send) {
                                                    cartItemOrder.modifiers.push({
                                                        id: modifierItem.id,
                                                        pending: true,
                                                        quantity: modifierItem.quantity,
                                                        name:
                                                            selectedLanguage &&
                                                            modifierItem.cuinerData.titles &&
                                                            modifierItem.cuinerData.titles[selectedLanguage]
                                                                ? modifierItem.cuinerData.titles[selectedLanguage]
                                                                : "",
                                                        price: modifierItem.total,
                                                        taxPercent: modifierItem.taxPercent,
                                                        modifiers_group_id: modifieGroup.id,
                                                    });
                                                    var extraAmountAdded =
                                                        cartItem.extraAmounts &&
                                                        cartItem.extraAmounts.filter(
                                                            (em) => em.itemModifierGroup.id === modifierItem.id
                                                        ).length > 0
                                                            ? cartItem.extraAmounts.filter(
                                                                  (em) => em.itemModifierGroup.id === modifierItem.id
                                                              )
                                                            : null;
                                                    extraAmountAdded =
                                                        extraAmountAdded && extraAmountAdded.length > 0
                                                            ? _.sumBy(
                                                                  extraAmountAdded.filter((ea) => ea.operator === "+"),
                                                                  "total"
                                                              )
                                                            : 0.0;

                                                    sendMailItemExtra.items.push({
                                                        title:
                                                            selectedLanguage &&
                                                            modifierItem.cuinerData.titles &&
                                                            modifierItem.cuinerData.titles[selectedLanguage]
                                                                ? modifierItem.cuinerData.titles[selectedLanguage]
                                                                : "",
                                                        quantity: modifierItem.quantity,
                                                        unitPrice: `${
                                                            extraAmountAdded && extraAmountAdded > 0.0
                                                                ? `${calculateDecimals(extraAmountAdded, 2)}\u20AC`
                                                                : ""
                                                        }`,
                                                    });
                                                    if (cartItem.extraAmounts && cartItem.extraAmounts.length > 0) {
                                                        for (var j = 0; j < cartItem.extraAmounts.length; j++) {
                                                            var extraAmount = cartItem.modifierGroups[j];
                                                        }
                                                    }
                                                } else {
                                                    toast(t("error.unknown"));
                                                }
                                            } else {
                                                send = false;
                                                toast(t("error.unknown"));
                                            }
                                            if (!send) break;
                                        }
                                        if (sendMailItemExtra.items.length > 0) {
                                            sendMailItem.extras.push(sendMailItemExtra);
                                        }
                                    }
                                    if (!send) break;
                                }
                            }
                            if (send) {
                                if (cartItemOrder.modifiers.length <= 0) delete cartItemOrder.modifiers;
                                cartItems.push(cartItemOrder);

                                if (sendMailItem.extras.length <= 0) delete sendMailItem.extras;
                                sendMailItems.push(sendMailItem);
                            }
                        }
                        if (!send) break;
                    }
                    if (send) {
                        const orderRef = Math.random().toString(36).slice(2);
                        const bodyData = {
                            order_status: "new",
                            channel_name: window.appConfig.name,
                            channel_id: `${cuinerData.selectedRoom}_-${user.email}`,
                            customer_comment: comments,
                            delivery: {
                                delivery_type: "delivery_restaurant",
                                is_asap: true,
                                client: {
                                    name: user && user.name ? user.name : "",
                                    phone: user && user.phone ? user.phone : "",
                                    email: user && user.email ? user.email : "",
                                },
                            },
                            cart: cartItems,
                        };
                        setLoading(true);
                        const orderResponse = await createOrder(cuinerData.api, {
                            restaurantId: selectedRestaurant.id,
                            orderRef: orderRef,
                            bodyData: bodyData,
                        });
                        setLoading(false);
                        if (orderResponse) {
                            if (orderResponse.data && orderResponse.data.queued) {
                                let sendCuinerOrderMailRequest = {
                                    roomNumber: cuinerData.selectedRoom,
                                    comments: comments,
                                    restaurant: selectedRestaurant.name,
                                    summary: [
                                        {
                                            key: "Subtotal",
                                            value: `${calculateDecimals(summary ? summary.subTotal : 0, 2)}\u20AC`,
                                        },
                                        {
                                            key: "Taxes",
                                            value: `${calculateDecimals(summary ? summary.taxes : 0, 2)}\u20AC`,
                                        },
                                        {
                                            key: "Total",
                                            value: `${calculateDecimals(summary ? summary.total : 0, 2)}\u20AC`,
                                        },
                                    ],
                                    items: sendMailItems,
                                };
                                goToOrder(orderRef, bodyData);
                                sendCuinerOrderMail({ variables: sendCuinerOrderMailRequest });
                            } else {
                                if (orderResponse.data && orderResponse.data.message) {
                                    toast(orderResponse.data.message);
                                } else {
                                    toast(t("error.unknown"));
                                }
                            }
                        } else {
                            toast(t("error.unknown"));
                        }
                    }
                } else {
                    setRedirectTo("/pos/cart/signin");
                }
            } else {
                swal({
                    title: t("unavailable products"),
                    text: t("unavailable products details"),
                    buttons: {
                        cancel: t("close"),
                    },
                });
            }
        }
    };

    const goToOrder = (orderRef, orderData) => {
        const selectedRestaurantClone = { ...selectedRestaurant };

        selectedRestaurantClone.cart = [];
        selectedRestaurantClone.comments = "";

        cuinerData.orders = cuinerData.orders || [];
        cuinerData.orders.push({
            ref: orderRef,
            room: cuinerData.selectedRoom,
            restaurant: {
                id: selectedRestaurant.id,
                name: selectedRestaurant.name,
            },
            items: cart,
            comments: comments,
            orderData: orderData,
            summary: summary,
            selectedLanguage: selectedLanguage,
        });

        const index = cuinerData.restaurants.findIndex((r) => r.id === selectedRestaurant.id);
        if (index !== -1) {
            cuinerData.restaurants.splice(index, 1, selectedRestaurantClone);
        }

        dispatch(setCuinerData(cuinerData));
        setRedirectTo(`/pos/order/${orderRef}`);
    };

    const goToItem = (item) => {
        var index = null;
        let cuinerDataClone = { ...cuinerData };
        let selectedRestaurantClone = { ...selectedRestaurant };
        let selectedMenuClone = { ...selectedMenu };
        selectedMenuClone.currentItem = item;
        index = _.findIndex(selectedRestaurantClone.menus, { id: selectedMenu.id });
        selectedRestaurantClone.menus.splice(index, 1, selectedMenuClone);
        index = _.findIndex(cuinerDataClone.restaurants, { id: selectedRestaurant.id });
        cuinerDataClone.restaurants.splice(index, 1, selectedRestaurantClone);
        dispatch(setCuinerData(cuinerDataClone));
        setRedirectTo("/pos/item/fromCart");
    };

    const _renderItemOptions = (item) => {
        const response = [];
        if (item) {
            if (item.canPurchase && item.canPurchase.available) {
                if (item.quantity && item.quantity > 0) {
                    response.push(
                        <>
                            <button
                                type="submit"
                                className="btn border block p-0 h-8 w-8 mb-2"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    updateItemQuantity(item, item.quantity + 1);
                                }}
                            >
                                <i className="icon icon-add"></i>
                            </button>
                            <button
                                type="submit"
                                className="btn border block p-0 h-8 w-8"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    updateItemQuantity(item, item.quantity - 1);
                                }}
                            >
                                <i className="icon icon-less"></i>
                            </button>
                        </>
                    );
                }
            } else {
                response.push(
                    <button
                        type="submit"
                        className="btn block p-0 h-8 w-8 mb-2"
                        onClick={(e) => {
                            e.stopPropagation();
                            updateItemQuantity(item, 0);
                        }}
                    >
                        <i className="icon icon-close"></i>
                    </button>
                );
            }
        }
        return response;
    };

    const _renderItems = () => {
        var response = [];
        if (cart && cart.length > 0) {
            cart.map(async (item, index) => {
                var selectedTitles = [];
                if (selectedLanguage && item.modifierGroups && item.modifierGroups.length > 0) {
                    item.modifierGroups.map(async (modifieGroup) => {
                        if (modifieGroup.items && modifieGroup.items.length > 0) {
                            modifieGroup.items.map(async (modifieGroupItem) => {
                                if (
                                    modifieGroupItem.cuinerData &&
                                    modifieGroupItem.cuinerData.titles &&
                                    modifieGroupItem.cuinerData.titles[selectedLanguage]
                                )
                                    selectedTitles.push(modifieGroupItem.cuinerData.titles[selectedLanguage]);
                            });
                        }
                    });
                }
                if (item.canPurchase && item.canPurchase.available) {
                    response.push(
                        <li
                            key={index}
                            className={`w-full py-3 cursor-pointer border-${index + 1 >= cart.length ? "none" : "b"}`}
                            onClick={(e) => {
                                goToItem(item);
                            }}
                        >
                            <div className="flex items-start align-start">
                                <div className="items-start align-start">
                                    <span className="bg-neutral-contrast items-center rounded text-sm font-normal p-1 text-neutral mr-2">
                                        x{item.quantity && item.quantity > 0 ? item.quantity : 0}
                                    </span>
                                </div>
                                <div className="w-full">
                                    <div className="flex justify-between items-start">
                                        <div className="">
                                            <span className="block mb-1">
                                                {selectedLanguage &&
                                                item.cuinerData &&
                                                item.cuinerData.titles &&
                                                item.cuinerData.titles[selectedLanguage]
                                                    ? item.cuinerData.titles[selectedLanguage]
                                                    : ""}
                                            </span>
                                            {selectedTitles && selectedTitles.length ? (
                                                <span className="block text-neutral-contrast-50 mb-1">
                                                    {selectedTitles.join(" - ")}
                                                </span>
                                            ) : selectedLanguage &&
                                              item.cuinerData &&
                                              item.cuinerData.descriptions &&
                                              item.cuinerData.descriptions[selectedLanguage] ? (
                                                <span className="block text-neutral-contrast-50 mb-1">
                                                    {item.cuinerData.descriptions[selectedLanguage]}
                                                </span>
                                            ) : null}
                                            <span className="block font-medium">{`${item.total}\u20AC`}</span>
                                        </div>
                                        <div className="">{_renderItemOptions(item)}</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    );
                } else {
                    response.push(
                        <li key={index} className={`w-full py-3 border-${index + 1 >= cart.length ? "none" : "b"}`}>
                            <div className="w-full flex justify-between items-start pl-5">
                                <div className="">
                                    <span className="block mb-1 text-sm text-neutral-contrast-70">
                                        {selectedLanguage &&
                                        item.cuinerData &&
                                        item.cuinerData.titles &&
                                        item.cuinerData.titles[selectedLanguage]
                                            ? item.cuinerData.titles[selectedLanguage]
                                            : ""}
                                    </span>
                                    {selectedTitles && selectedTitles.length ? (
                                        <span className="block text-sm text-neutral-contrast-50 mb-1">
                                            {selectedTitles.join(" - ")}
                                        </span>
                                    ) : selectedLanguage &&
                                      item.cuinerData &&
                                      item.cuinerData.descriptions &&
                                      item.cuinerData.descriptions[selectedLanguage] ? (
                                        <span className="block text-sm text-neutral-contrast-50 mb-1">
                                            {item.cuinerData.descriptions[selectedLanguage]}
                                        </span>
                                    ) : null}
                                    <span className="block text-sm text-red-100">{t("currently unavailable")}</span>
                                </div>
                                <div className="">{_renderItemOptions(item)}</div>
                            </div>
                        </li>
                    );
                }
            });
        }
        return <ul>{response}</ul>;
    };

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    return (
        <section className="basic has-tabs has-top">
            <Header title={t("basket")}></Header>
            <section style={{ paddingLeft: 0, paddingRight: 0 }}>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="w-full p-4 bg-white mb-3">
                            <h3 className="block text-lg font-bold border-b mb-2">{t("delivery and payment")}</h3>
                            <span className="block mb-2">
                                {markdown(
                                    t("products will be delivered to x", {
                                        location: t("room x", { room: cuinerData ? cuinerData.selectedRoom : "" }),
                                    })
                                )}
                            </span>
                            <span className="mb-2">{t("order will be charged to the room")}</span>
                        </div>
                        <div className="w-full p-4 bg-white mb-3">
                            <h3 className="block text-lg font-bold border-b mb-2">{t("comments")}</h3>
                            <span className="block mb-2">{t("description comments order")}</span>
                            <textarea
                                className="w-full"
                                placeholder={t("write your comments")}
                                rows="10"
                                onChange={(e) => {
                                    updateComments(e.target.value);
                                }}
                                value={comments}
                            ></textarea>
                        </div>
                        <div className="w-full p-4 bg-white mb-3">
                            <h3 className="block text-lg font-bold border-b mb-2">{t("your order")}</h3>
                            <span className="block mb-2">
                                {t("x products in your order", { count: cart ? cart.length : 0 })}
                            </span>
                            {_renderItems()}
                        </div>
                        <div className="w-full p-4 bg-white mb-3">
                            <h3 className="block text-lg font-bold border-b mb-2">{t("summary")}</h3>
                            <div className="w-full px-2">
                                <div className="flex justify-between items-center mb-2">
                                    <span className="">{t("products")}</span>
                                    <span className="font-bold">{`${summary ? summary.subTotal : 0}\u20AC`}</span>
                                </div>
                                <div className="flex justify-between items-center mb-2 border-b">
                                    <span className="">
                                        {t("vat")}{" "}
                                        <span className="text-neutral-contrast-50 text-sm">
                                            ({t("included in the price")})
                                        </span>{" "}
                                    </span>
                                    <span className="font-bold">{`${summary ? summary.taxes : 0}\u20AC`}</span>
                                </div>
                                <div className="flex justify-between items-center mb-2">
                                    <span className="font-bold">{t("total")}</span>
                                    <span className="font-bold">{`${summary ? summary.total : 0}\u20AC`}</span>
                                </div>
                            </div>
                        </div>
                        {cart && cart.length > 0 ? (
                            <div className="w-full p-4 bg-white mb-3">
                                <div
                                    className="bg-neutral-contrast-90 rounded-sm font-medium text-neutral w-full text-center py-2 text-xl px-4 cursor-pointer"
                                    onClick={(e) => {
                                        sendOrder();
                                    }}
                                >
                                    <span>{t("order now")}</span>
                                </div>
                            </div>
                        ) : null}
                    </>
                )}
            </section>
            <Tabs hideKey={true} />
        </section>
    );
};

export default Cart;
