import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";

const isUnitTest = process.env.NODE_ENV === "test";

export const getAnalytics = () => {
    return firebase?.apps?.length > 0 && !isUnitTest ? firebase.analytics() : null;
};

export const FirebaseInit = (firebaseConfig) => {
    console.log("fire", firebase?.apps);
    if (!firebase?.apps?.length > 0 && !isUnitTest) {
        firebase.initializeApp(firebaseConfig);
    }
    getAnalytics();
};

export const FirebaseLogoutPush = () => {
    if (isUnitTest) {
        return;
    }
    try {
        const messaging = firebase?.messaging();
        messaging?.deleteToken();
    } catch (error) {
        console.error(error);
    }
};

export const FirebaseOnTokenRefresh = async () => {
    if (isUnitTest) {
        return;
    }
    try {
        const messaging = firebase?.messaging();
        const t = await messaging?.onTokenRefresh(async () => {
            const token = await messaging?.getToken();
            console.info("NEW TOKEN: " + token);
            return token;
        });
        return t();
    } catch (error) {
        console.error(error);
    }
    return null;
};

export const FirebaseGetNewToken = async () => {
    if (isUnitTest) {
        return;
    }
    const messaging = firebase?.messaging();
    const token = await messaging?.getToken();
    console.info("NEW TOKEN: " + token);
    return token;
};

export const FirebaseAskPermissions = async (pushNotificationsHandle) => {
    if (isUnitTest) {
        return;
    }
    try {
        const firebasePushCertWeb = window?.appConfig?.firebasePushCertWeb;
        const messaging = firebase?.messaging();
        messaging?.onMessage((payload) => {
            pushNotificationsHandle(payload?.data);
        });
        const t = await Notification?.requestPermission().then(async (permission) => {
            if (permission === "denied") {
                console.info("Permission wasn't granted. Allow a retry.");
                return;
            } else if (permission === "default") {
                console.warn("The permission request was dismissed.");
                return;
            }
            if (firebasePushCertWeb) {
                messaging.getToken({ vapidKey: firebasePushCertWeb });
            } else {
                console.error("Firebase push key not found");
            }
            const token = await messaging?.getToken();
            console.info("TOKEN: " + token);
            return token;
        });
        return t;
    } catch (error) {
        console.error(error);
    }
};
