import React from "react";
import { useTranslation } from "react-i18next";

const SaveButton = ({ onClick, disabled }) => {
    const { t } = useTranslation();

    return (
        <div className=" tab-bar flex justify-center h-20" style={{ zIndex: "20" }}>
            <div className=" w-full flex items-center px-4 space-x-2 h-full justify-center">
                <button
                    onClick={() => {
                        if (typeof onClick === "function") {
                            onClick();
                        }
                    }}
                    disabled={disabled}
                    id="save-tab-button"
                    className={` w-full text-accent-contrast ${
                        disabled ? " bg-neutral-contrast-40" : "bg-accent"
                    }  font-bold rounded py-2 text-lg  `}
                >
                    {t("save")}
                </button>
            </div>
        </div>
    );
};

export default SaveButton;
