const transformToAssocArray = (prmstr, url) => {
    var params = {};
    var prmarr = prmstr.split("&");
    for (var i = 0; i < prmarr.length; i++) {
        var tmparr = prmarr[i].split("=");
        var value = tmparr[1];
        if (url === true) {
            value = decodeURIComponent(value);
        }
        params[tmparr[0]] = value;
    }
    return params;
};

export function GET(location) {
    if (typeof location === "string" && location.match(/\?/)) {
        location = {
            search: "?" + location.split("?")[1],
        };
    }
    if (typeof location === "object") {
        var prmstr = location.search ? location.search.substr(1) : null;
        if (prmstr !== null && prmstr !== "") {
            return transformToAssocArray(prmstr, true);
        }
    }
    return {};
}
