import React from "react";
import TextLabel from "./TextLabel";

const TextLabelGrid = ({ grid }) => {
    const numberOfColumns = grid?.length || 0;

    const columns = Array.from({ length: numberOfColumns }, (_, index) => index);
    return (
        <div className=" w-full flex">
            {columns.map((c, index) => {
                return (
                    <div className=" flex flex-col space-y-4" style={{ width: 100 / numberOfColumns + "%" }}>
                        {grid?.[index]?.map((item) => {
                            return <TextLabel label={item?.label} value={item?.value} />;
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default TextLabelGrid;
