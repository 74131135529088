import React from "react";

import { configToCSS, translateWeatherLang } from "../DesignUtils";
import useWidget from "../../../hooks/useWidget";

const UseWidgetWeather = ({ config, id, deviceType }) => {
    const { cfg } = useWidget({
        data: config.weather,
        styles: config.styles,
        parentCfg: config ? config.parentCfg : null,
        parseConfig,
    });
    return (
        <div id={id} className={`overflow-hidden w-full h-full`} style={configToCSS(cfg, deviceType)}>
            {cfg.image.src ? (
                <img className="w-full h-full object-contain mx-auto" alt="" title="preview" src={cfg.image.src}></img>
            ) : null}
        </div>
    );
};

const parseConfig = ({ data, styles, theme, parentCfg, lang }) => {
    const location = data.location ? data.location.id : null;
    const unit = data.unit || "celsius";
    const design = data.design || 3;
    const src =
        `https://w.bookcdn.com/weather/picture/DESIGN_CITY_ID_FORMAT_LANG_137AE9_580_ffffff_333333_08488D_1_ffffff_333333_0_6.png?scode=124&domid=582&anc_id=77506`
            .replace("FORMAT", unit === "celsius" ? "1" : "0")
            .replace("DESIGN", design)
            .replace("LANG", translateWeatherLang(lang))
            .replace("CITY_ID", location);
    let bgColor = styles.bgColor || theme.colors.defaultBackground;
    if(bgColor && (parentCfg && parentCfg.bgColor === bgColor))bgColor = null;
    return {
        image: { src },
        bgColor: bgColor,
        border: {
            color: styles.borderColor,
            size: styles.borderWidth,
            radius: styles.radius,
        },
    };
};

export default UseWidgetWeather;
