import gql from "graphql-tag";

export const GET_PROFILE = {
    query: gql`
        query getProfile {
            getProfile {
                ref
                email
                name
                surname
                auth {
                    google
                    apple
                    password
                }
                birthDate
                country
                language
                gender
                phone
                emailVerified
                termsAccepted
                marketingAccepted
            }
        }
    `,
    defaultVars: {},
};


export const GET_INVOICE = {
  query: gql`
        query getInvoice($hotelRef: ID!, $roomNumber: Int, $password: String){
          getInvoice(hotelRef: $hotelRef, roomNumber: $roomNumber, password: $password){
            currency{
              position
              symbol
            }
            items{
              date
              description
              price
              quantity
            }
            paid
            totalAmount
          }
        }
    `
};