import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import pkg from "../../package.json";
import i18n from "i18n";

for (let i = 0; i < pkg.languages.length; i++) {
    require("dayjs/locale/" + pkg.languages[i] + ".js");
}
dayjs.extend(localizedFormat);

export default dayjs;

export function convertDateBasedOnLocale(isoDate) {
    if (!isoDate || typeof isoDate !== "string") {
        return null;
    }

    const lang = (i18n.language || "").match(/^[^-]+/)[0];

    const parts = isoDate.split("-");

    if (parts.length !== 3 || parts[0].length !== 4 || parts[1].length !== 2 || parts[2].length !== 2) {
        console.error("The date format must be YYYY-MM-DD.");
        return null;
    }

    let formattedDate;
    switch (lang) {
        case "es":
            formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
            break;
        case "en":
        default:
            formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
            break;
    }

    // console.log(formattedDate);

    return formattedDate;
}
