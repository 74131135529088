import React, { useRef, useContext, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { size as renderSize } from "zrender/sizes";
import { getDeviceWidth } from "./Design/DesignUtils";
import { ProjectContext } from "../contexts/project";
import { useShops, useOrders, useCancelOrder, useShopCategories } from "../graphql/shop";
import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";
import {
    printPrice,
    displayTime,
    displayTimeAndDate,
    SHIPPING_METHOD_PICKUP,
    ORDER_STATE_PENDING,
    ORDER_STATE_DELIVERED,
    ORDER_STATE_CANCELLED,
    ORDER_STATE_ACCEPTED,
    ORDER_STATE_IN_PROGRESS,
    getName,
} from "utils/shop";
import { markdownClean } from "utils/others";
import Tag from "./Tag";
import { ShopProvider } from "contexts/shop";
import ShopOrder from "./ShopOrder";
import Footer from "./Footer";
import { toast } from "react-toastify";
import swal from "@sweetalert/with-react";
import { SHIPPING_METHOD_GEOLOCATION } from "utils/shop";

const ShopsOrders = ({ lang, topBarRef, theme }) => {
    const { project, room } = useContext(ProjectContext);
    const ordersManagementEnabled = project?.permissionsConfig?.shopsOrders;

    const orderComponentRef = useRef(null);

    const { t: tr } = useTranslation();
    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const size = (v) => renderSize(v, "mobile", getDeviceWidth());

    const details = theme?.style?.details?.colors;
    const footer = theme?.style?.section2?.status?.default?.sections?.general?.colors;
    const footerButton = theme?.style?.section2?.status?.default?.sections?.button;

    const [activeOrder, setActiveOrder] = useState(null);
    const [displayCancelOrder, setDisplayCancelOrder] = useState(null);

    const activeShop = activeOrder?.shop;
    const allowCancelOrder = ordersManagementEnabled && activeShop?.isOrderCancellationAllowed;

    const {
        query: loadShops,
        called: loadShopsCalled,
        loading: loadingShops,
        error: errorShops,
        data: shops,
    } = useShops({ projectRef: project?.ref, lang });

    const shopsToken = shops?.map((shop) => shop.token).join(",");

    const cancelOrder = useCancelOrder({ shopToken: activeShop?.token });

    const {
        query: loadOrders,
        refetch: reloadOrders,
        called: loadOrdersCalled,
        loading: loadingOrders,
        error: errorOrders,
        data: orders,
    } = useOrders({ shopToken: shopsToken, guestId: room?.guestID, roomNumber: room?.number });

    const {
        query: loadCategories,
        loading: loadingCategories,
        data: categories,
    } = useShopCategories({ shopToken: shopsToken, level: 1 });

    const activeBack = useCallback(() => {
        setActiveOrder(null);
        reloadOrders();
    }, [reloadOrders]);

    useEffect(() => {
        if (activeOrder) {
            setDisplayCancelOrder(activeOrder?.order?.state === ORDER_STATE_PENDING);
            topBarRef?.current?.setTitle(activeOrder.shop?.name);
            topBarRef?.current?.setBack(activeBack, { icon: "icon-close" });
        } else {
            setDisplayCancelOrder(false);
            topBarRef?.current?.setTitle(t("my orders"));
            topBarRef?.current?.setBack(null);
        }
        topBarRef?.current?.setSearch(false);
    }, [topBarRef, activeOrder]);

    useEffect(() => {
        if (!loadShopsCalled) {
            loadShops();
            loadCategories();
        }
    }, []);

    useEffect(() => {
        if (shopsToken && !loadOrdersCalled) {
            loadOrders();
        }
    }, [shopsToken]);

    const loading = loadingShops || loadingOrders || loadingCategories;
    const error = errorShops || errorOrders;

    return (
        <>
            {loading ? <Loading /> : null}
            {error ? <ErrorMessage type="connection" error={error} /> : null}
            {activeOrder ? (
                <ShopProvider
                    value={{
                        shopToken: activeShop?.token,
                        pricesIncludeTax: activeShop?.pricesIncludeTax,
                        deliverySchedule: activeShop?.deliverySchedule,
                        autoAcceptOrders: activeShop?.autoAcceptOrders,
                        theme,
                    }}
                >
                    <ShopOrder
                        ref={orderComponentRef}
                        lang={lang}
                        orderID={activeOrder?.order?.id}
                        style={{ zoom: "0.9" }}
                    />
                    {allowCancelOrder && displayCancelOrder ? (
                        <>
                            <div style={{ height: size(7) }}></div>
                            <Footer
                                theme={{
                                    fgColor: footer?.fgColor,
                                    bgColor: footer?.bgColor,
                                }}
                            >
                                <div
                                    style={{
                                        textAlign: "center",
                                        fontSize: size(1.5),
                                        fontWeight: "bold",
                                        padding: size(0.75) + " " + size(1.5),
                                        color: footerButton?.colors?.fgColor,
                                        background: footerButton?.colors?.bgColor,
                                        borderWidth: footerButton?.border?.width + "px",
                                        borderRadius: footerButton?.border?.radius + "rem",
                                        borderColor: footerButton?.border?.color,
                                        cursor: "pointer",
                                        marginTop: size(0.5),
                                        marginLeft: size(2.5),
                                        marginRight: size(2.5),
                                    }}
                                    onClick={() => {
                                        swal({
                                            title: t("confirm cancel order"),
                                            text: t("cancel order info"),
                                            buttons: {
                                                cancel: t("close"),
                                                confirm: t("confirm"),
                                            },
                                        }).then((confirm) => {
                                            if (confirm) {
                                                cancelOrder(activeOrder?.order?.id)
                                                    .then(() => {
                                                        orderComponentRef?.current?.reload();
                                                        setDisplayCancelOrder(false);
                                                        reloadOrders();
                                                    })
                                                    .catch((e) => {
                                                        toast.error(e?.message || e?.errorCode);
                                                    });
                                            }
                                            swal.close();
                                        });
                                    }}
                                >
                                    {t("cancel order")}
                                </div>
                            </Footer>
                        </>
                    ) : null}
                </ShopProvider>
            ) : null}
            {!activeOrder ? (
                <div>
                    {loadOrdersCalled && !loading && !error && !orders?.length ? (
                        <div style={{ marginTop: size(2), textAlign: "center", opacity: 0.5 }}>
                            {t("no orders yet")}
                        </div>
                    ) : null}
                    {orders
                        ? orders?.map((order) => {
                              const shop = shops.find((shop) => shop.token === order.shopToken);
                              const price = shop.pricesIncludeTax ? order.totalWithTax : order.total;
                              const state =
                                  shop.autoAcceptOrders &&
                                  (order.state === ORDER_STATE_PENDING || order.state === ORDER_STATE_IN_PROGRESS)
                                      ? ORDER_STATE_ACCEPTED
                                      : order.state;

                              const deliveryTime = (() => {
                                  if (state === ORDER_STATE_DELIVERED) {
                                      return "";
                                  } else if (state === ORDER_STATE_CANCELLED) {
                                      return displayTimeAndDate(new Date(order.updatedAt), { lang });
                                  } else {
                                      const scheduleStart = order?.scheduleStart;
                                      const scheduleEnd = order?.scheduleEnd;
                                      if (scheduleStart && scheduleEnd) {
                                          const start = new Date(scheduleStart);
                                          const end = new Date(scheduleEnd);
                                          return (
                                              displayTime(start.getHours(), start.getMinutes(), { lang }) +
                                              " - " +
                                              displayTimeAndDate(end, { lang })
                                          );
                                      }
                                      return t("as soon as possible");
                                  }
                              })();
                              return (
                                  <div
                                      key={order.id}
                                      onClick={() => setActiveOrder({ order: { ...order }, shop: { ...shop } })}
                                      style={{
                                          fontSize: size(1.2),
                                          color: "black",
                                          backgroundColor: "white",
                                          padding: size(1),
                                          paddingBottom: 0,
                                          marginTop: size(1),
                                          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.05)",
                                          cursor: "pointer",
                                      }}
                                  >
                                      <div
                                          style={{
                                              fontSize: size(1.4),
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                          }}
                                      >
                                          {shop.name}
                                          {state !== ORDER_STATE_CANCELLED ? (
                                              <div
                                                  style={{
                                                      display: "flex",
                                                      justifyContent: "space-between",
                                                      alignItems: "center",
                                                      paddingTop: size(0.5),
                                                      paddingBottom: size(0.5),
                                                  }}
                                              >
                                                  <div
                                                      style={{
                                                          fontSize: size(1.4),
                                                          fontWeight: "bold",
                                                      }}
                                                  >
                                                      {printPrice(price, order.currencyCode, lang)}
                                                  </div>
                                              </div>
                                          ) : null}
                                      </div>
                                      {state !== ORDER_STATE_CANCELLED && order.orderPlacedAt && (
                                          <div
                                              style={{
                                                  display: "flex",
                                                  justifyContent: "space-between",
                                                  alignItems: "center",
                                                  paddingTop: size(0.5),
                                                  paddingBottom: size(0.5),
                                              }}
                                          >
                                              <div style={{ color: "rgba(50, 50, 50, 0.6)" }}>
                                                  {`${t("order-date")} - ${displayTimeAndDate(
                                                      new Date(order.orderPlacedAt),
                                                      { lang }
                                                  )}`}
                                              </div>
                                          </div>
                                      )}
                                      <hr />
                                      <div
                                          style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "flex-end",
                                          }}
                                      >
                                          <div
                                              style={{
                                                  textAlign: "right",
                                                  paddingTop: size(0.5),
                                                  paddingBottom: size(0.5),
                                                  display: "flex",
                                                  flexDirection: "column",
                                              }}
                                          >
                                              {state != ORDER_STATE_CANCELLED && (
                                                  <span>
                                                      {order.shippingMethod === SHIPPING_METHOD_PICKUP.code
                                                          ? markdownClean(
                                                                t("pick up at x", {
                                                                    location: getName(
                                                                        order?.deliveryLocation,
                                                                        order?.pickupLocations,
                                                                        lang
                                                                    ),
                                                                })
                                                            )
                                                          : order.shippingMethod === SHIPPING_METHOD_GEOLOCATION.code
                                                          ? t("your-confirmed-location")
                                                          : t("room service")}
                                                  </span>
                                              )}
                                              <span style={{ color: "rgba(50, 50, 50, 0.6)" }}>{deliveryTime}</span>
                                          </div>
                                          {state != ORDER_STATE_PENDING ? (
                                              <div style={{ paddingTop: size(0.5), paddingBottom: size(0.5) }}>
                                                  <Tag
                                                      className="rounded-lg"
                                                      style={{
                                                          background: details?.bgColor,
                                                          color: details?.fgColor,
                                                          marginLeft: size(1.5),
                                                          marginRight: 0,
                                                      }}
                                                  >
                                                      {t(`order_state_${state}`)}
                                                  </Tag>
                                              </div>
                                          ) : null}
                                      </div>
                                  </div>
                              );
                          })
                        : null}
                </div>
            ) : null}
        </>
    );
};

export default ShopsOrders;
