import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAuthorizePaymentInvoice, useExpressCheckout, useInvoice, useRooms } from "../../graphql/useUser";
import { calculateDecimals, markdown } from "../../utils/others";
import { getDasUrl } from "../../actions/config";
import useWindowDimensions, { arrangeStyles } from "../Design/DesignUtils";
import ErrorMessage from "../ErrorMessage";
import Header from "../Header";
import Loading from "../Loading";
import Tabs from "../Tabs";
import { getProjectRooms, getRoomNumber } from "../../modules/rooms/store";
import { getHotels, getHotel } from "../../actions/hotel";
import NoRoom from "./NoRoom";
import { fetchInvoiceData, arrangeItems } from "./utils";

const CheckOut = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const { height: pageHeight } = useWindowDimensions();
    const lang = (i18n.language || "").match(/^[^-]+/)[0];
    const dasURL = getDasUrl();
    const selectedHotelRef = useSelector(getHotel);
    const roomNumber = useSelector(getRoomNumber);
    const { update: updateRooms } = useRooms();
    const selectedLanding = useSelector((state) =>
        selectedHotelRef && state.session.selectedLandings ? state.session.selectedLandings[selectedHotelRef] : null
    );
    const rooms = useSelector(getProjectRooms);
    const hotels = useSelector(getHotels);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [currencyIcon, setCurrencyIcon] = useState(null);
    const [totalInvoice, setTotalInvoice] = useState(0);
    const [checkoutSuccess, setCheckoutSuccess] = useState(false);
    const [parentBackground, setParentBackground] = useState(null);
    const { getInvoice, loading: loadingInvoice, error: errorInvoice, data: invoiceData } = useInvoice();
    const {
        authorizePaymentInvoice,
        loading: loadingAuthorizePaymentInvoice,
        error: errorAuthorizePaymentInvoice,
        data: authorizePaymentInvoiceData,
    } = useAuthorizePaymentInvoice();
    const {
        expressCheckout,
        loading: loadingExpressCheckout,
        error: errorExpressCheckout,
        data: expressCheckoutData,
    } = useExpressCheckout();

    useEffect(() => {
        setLoading(true);
        setError(null);
        setItems([]);
        updateRooms();
        if (selectedLanding && selectedLanding.landingStyle) {
            let styles = arrangeStyles({
                style: selectedLanding.landingStyle,
                dasURL: dasURL,
                lang: lang,
                useParentBackground: true,
            });
            setParentBackground(styles && styles.parentBackground ? { ...styles.parentBackground, zIndex: 1 } : null);
        }
        fetchInvoiceData(selectedHotelRef, roomNumber, rooms, hotels, setLoading, getInvoice);
    }, []);

    useEffect(() => {
        if (!loadingInvoice && !errorInvoice && invoiceData) {
            if (invoiceData && invoiceData.getInvoice && invoiceData.getInvoice.items) {
                setCurrencyIcon(invoiceData.getInvoice.currency ? invoiceData.getInvoice.currency.symbol : null);
                setItems(arrangeItems(invoiceData.getInvoice.items));
                setTotalInvoice(invoiceData.getInvoice.totalAmount ? invoiceData.getInvoice.totalAmount : 0);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } else if (errorInvoice) {
            console.log(errorInvoice);
            setLoading(false);
            setError(errorInvoice);
        }
    }, [loadingInvoice, errorInvoice, invoiceData]);

    useEffect(() => {
        if (!loadingAuthorizePaymentInvoice && !errorAuthorizePaymentInvoice && authorizePaymentInvoiceData) {
            if (authorizePaymentInvoiceData) {
                setLoading(true);
                setError(null);
                setItems([]);
                fetchInvoiceData(selectedHotelRef, roomNumber, rooms, hotels, setLoading, getInvoice);
            } else {
                setLoading(false);
            }
        } else if (errorAuthorizePaymentInvoice) {
            setLoading(false);
            setError(errorAuthorizePaymentInvoice);
        }
    }, [loadingAuthorizePaymentInvoice, errorAuthorizePaymentInvoice, authorizePaymentInvoiceData]);

    useEffect(() => {
        if (!loadingExpressCheckout && !errorExpressCheckout && expressCheckoutData) {
            if (expressCheckoutData && expressCheckoutData.expressCheckout) {
                setLoading(false);
                setCheckoutSuccess(true);
            } else {
                setLoading(false);
            }
        } else if (errorExpressCheckout) {
            console.log(errorExpressCheckout);
            setLoading(false);
            setError(errorExpressCheckout);
        }
    }, [loadingExpressCheckout, errorExpressCheckout, expressCheckoutData]);

    const authorisePayment = () => {
        setLoading(true);
        let roomInHotel = rooms[selectedHotelRef][roomNumber];
        authorizePaymentInvoice({
            variables: {
                hotelRef: selectedHotelRef,
                roomNumber: roomNumber,
                password: roomInHotel.code,
            },
        });
    };

    const checkout = () => {
        setLoading(true);
        let roomInHotel = rooms[roomNumber];
        expressCheckout({
            variables: {
                hotelRef: selectedHotelRef,
                roomNumber: roomNumber,
                password: roomInHotel.code,
                totalAmount: totalInvoice,
            },
        });
    };

    const renderContent = () => {
        if (loading) {
            return <Loading />;
        }
        if (error) {
            return <ErrorMessage type="server">{error}</ErrorMessage>;
        }
        if (!roomNumber) {
            return (
                <div className="w-full p-5">
                    <NoRoom description={t("bill you need add room")} />
                </div>
            );
        }
        if (checkoutSuccess) {
            return (
                <>
                    {parentBackground ? <div id="img-background" style={parentBackground}></div> : null}
                    <div className="flex h-full w-full align-middle items-center text-center">
                        <div className="w-full pb-5 mb-5 text-center z-10">
                            <span className="text-xl">{markdown(t("checkout-success"))}</span>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <div className="w-full px-5 relative">
                <div className="w-full mb-5">
                    <span className="block mb-3">
                        {markdown(t("express checkout text", { roomNumber: roomNumber }))}
                    </span>
                    <span className="block text-sm">{t("express checkout subText1")}</span>
                    {items && items.length > 0 && (
                        <span className="block text-sm">{t("express checkout subText2")}</span>
                    )}
                </div>
                {items && items.length > 0 ? (
                    <div className="w-full">
                        <div className="w-full text-right pr-4 pb-2">
                            <span className="text-xl text-neutral-contrast font-medium capitalize">{`${t(
                                "total"
                            )}: ${calculateDecimals(totalInvoice, 2)}${currencyIcon}`}</span>
                        </div>
                        <div className="w-full">
                            <ul
                                className="border-solid border-2 rounded-lg px-2 pb-4 overflow-y-scroll"
                                style={{ height: pageHeight - 417 }}
                            >
                                {items.map((item) => (
                                    <li className="border-b py-2" key={item.name}>
                                        <span className="">
                                            x{item.quantity} {item.name}
                                        </span>
                                        <div className="w-full flex justify-between items-center mt-2 pl-5">
                                            <span className="">{item.date}</span>
                                            <span className="font-medium">{`${calculateDecimals(
                                                item.price,
                                                2
                                            )}${currencyIcon}`}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ) : null}
                <div className="w-full absolute bottom-0 pr-10" style={{ bottom: "-50px" }}>
                    <button onClick={checkout} className="btn btn-accent w-full text-lg">
                        {t("checkout")}
                    </button>
                </div>
            </div>
        );
    };

    return (
        <section className={`${!checkoutSuccess ? "hotels has-tabs has-top" : "w-full has-tabs"}`}>
            {!checkoutSuccess && <Header title={t("express checkout")}></Header>}
            <section className="h-full">{renderContent()}</section>
            <Tabs hideKey={true} />
        </section>
    );
};

export default CheckOut;
