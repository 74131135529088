import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useInvoice } from "../../graphql/useUser";
import { calculateDecimals } from "../../utils/others";
import ErrorMessage from "../ErrorMessage";
import Header from "../Header";
import Loading from "../Loading";
import Tabs from "../Tabs";
import { getRoomNumber, getProjectRooms } from "../../modules/rooms/store";
import { getHotels, getHotel } from "../../actions/hotel";
import NoRoom from "./NoRoom";
import { fetchInvoiceData, arrangeItems } from "./utils";

const Bills = () => {
    const { t } = useTranslation();
    const selectedHotelRef = useSelector(getHotel);
    const roomNumber = useSelector(getRoomNumber);
    const rooms = useSelector(getProjectRooms);
    const hotels = useSelector(getHotels);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [currencyIcon, setCurrencyIcon] = useState(null);
    const [totalInvoice, setTotalInvoice] = useState(0);
    const { getInvoice, loading: loadingInvoice, error: errorInvoice, data: invoiceData } = useInvoice();

    useEffect(() => {
        setLoading(true);
        setError(null);
        setItems([]);
        fetchInvoiceData(selectedHotelRef, roomNumber, rooms, hotels, setLoading, getInvoice);
    }, []); // eslint-disable-line

    useEffect(() => {
        if (!loadingInvoice && !errorInvoice && invoiceData) {
            if (invoiceData && invoiceData.getInvoice && invoiceData.getInvoice.items) {
                setCurrencyIcon(invoiceData.getInvoice.currency ? invoiceData.getInvoice.currency.symbol : null);
                setItems(arrangeItems(invoiceData.getInvoice.items));
                setTotalInvoice(invoiceData.getInvoice.totalAmount ? invoiceData.getInvoice.totalAmount : 0);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } else if (errorInvoice) {
            setLoading(false);
            setError(errorInvoice);
        }
    }, [loadingInvoice, errorInvoice, invoiceData]);

    return (
        <section className="hotels has-tabs has-top">
            <Header title={t("my bills")}></Header>
            <section
                style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                }}
            >
                <LoadingOrError loading={loading} error={error} />
                <RoomOrItems
                    roomNumber={roomNumber}
                    items={items}
                    t={t}
                    calculateDecimals={calculateDecimals}
                    totalInvoice={totalInvoice}
                    currencyIcon={currencyIcon}
                />
            </section>
            <Tabs hideKey={true} />
        </section>
    );
};

export default Bills;

const LoadingOrError = ({ loading, error }) => {
    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <ErrorMessage type="server">{error}</ErrorMessage>;
    }
    return null;
};

const RoomOrItems = ({ roomNumber, items, t, calculateDecimals, totalInvoice, currencyIcon }) => {
    if (!roomNumber) {
        return <NoRoom description={t("bill you need add room")} />;
    }
    if (!items || items.length <= 0) {
        return (
            <div className="w-full text-center">
                <span className="text-lg">{t("there are no pending expenses")}</span>
            </div>
        );
    }
    return (
        <>
            <div className="w-full text-right pr-4 pb-2">
                <span className="text-xl text-neutral-contrast font-medium capitalize">{`${t(
                    "total"
                )}: ${calculateDecimals(totalInvoice, 2)}${currencyIcon}`}</span>
            </div>
            <div className="w-full">
                <ul className="border-solid border-2 rounded-lg px-2 pb-4">
                    {items.map((item) => (
                        <li className="border-b py-2" key={item.name}>
                            <span className="">
                                x{item.quantity} {item.name}
                            </span>
                            <div className="w-full flex justify-between items-center mt-2 pl-5">
                                <span className="">{item.date}</span>
                                <span className="font-medium">{`${calculateDecimals(
                                    item.price,
                                    2
                                )}${currencyIcon}`}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};
