import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducers";
import { loadState, saveState } from "./localStorage";

const store = createStore(
  reducer,
  loadState(),
  compose(
    applyMiddleware(thunk),
    process.env.NODE_ENV === "development" &&
      typeof window === "object" &&
      typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== "undefined"
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
