import gql from "graphql-tag";

export const UPDATE_NOTIFICATION = {
  query: gql`
    mutation updateNotification($ref: ID!, $isRead: Boolean, $isCompleted: Boolean) {
      updateNotification(
        ref: $ref
        isRead: $isRead
        isCompleted: $isCompleted
      ) {
        dateTime
        description
        isCompleted
        isRead
        kind
        operation
        projectRef
        ref
        title
      }
    }
  `,
};
