import Bills from "../components/CheckOut/Bills";
import CheckOut from "../components/CheckOut/CheckOut";

export const checkOutRoutes = (prefix = "") => {
	return [
		{
			path: prefix,
			exact: true,
			section: CheckOut
		},
		{
            path: prefix + "/bills",
            exact: true,
            section: Bills,
        },
	];
};
