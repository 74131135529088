import React, { useState } from "react";

const DropDown = (props) => {
    const placeholder = props?.placeholder;
    const disabled = props?.disabled || false;
    const options = props?.options;
    const [selected, setSelected] = useState(props?.selected);

    const transferProps = { ...props };
    delete transferProps.onChange;
    delete transferProps.onClick;
    delete transferProps.style;
    delete transferProps.selected;
    delete transferProps.placeholder;

    return (
        <select
            {...transferProps}
            className="border rounded border-gray inline-flex"
            style={{
                padding: ".25em",
                fontSize: "1.1em",
                verticalAlign: "middle",
                cursor: "pointer",
                color: selected ? "#323232" : "#D3DAE1",
                borderColor: "#D3DAE1",
            }}
            onClick={(e) => {
                if (!disabled) {
                    if (props?.onClick) {
                        props?.onClick(e);
                    }
                }
            }}
            onChange={(e) => {
                const selected = e && e.target ? e.target.value : null;
                setSelected(selected);
                if (props?.onChange) {
                    props.onChange(selected);
                }
            }}
        >
            {placeholder ? <option value="">{placeholder}</option> : null}
            {options?.map((data) => {
                return (
                    <>
                        <option key={data?.key || data?.value} value={data?.value} selected={data?.value === selected}>
                            {data?.name || data?.value || data} {data?.subtext ?? ""}
                        </option>
                    </>
                );
            })}
        </select>
    );
};

export default DropDown;
