import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ShopContext } from "../contexts/shop";

import ShopItem from "../components/widgets/ShopItem";

const ShopCategories = ({ lang, topBarRef }) => {
    const { shop, theme, categories, setCategoryID } = useContext(ShopContext);
    const { t: tr } = useTranslation();
    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const delivery = shop?.deliverySchedule
        ? shop.deliverySchedule.map((s) => s.startTime + " - " + s.endTime)
        : [t("delivery 24 hours")];

    useEffect(() => {
        topBarRef?.current?.setTitle(shop?.name);
        topBarRef?.current?.setBack(null);
    }, []);

    if (categories?.length > 0) {
        return (
            <div className="px-4 py-5">
                <div
                    className={`${
                        delivery.length > 2 ? "flex-col" : "flex"
                    } items-center whitespace-nowrap justify-between px-1 pb-2 mb-2`}
                >
                    <div className={`${delivery.length > 2 ? "pb-2" : ""} font-bold`}>{t("delivery time")}</div>
                    <div
                        className={`${delivery.length > 2 ? "" : "ml-3"} flex-grow align-middle`}
                        style={{ overflow: "overlay" }}
                    >
                        {delivery.map((value, index) => (
                            <div
                                key={`delivery_time_${index + 1}`}
                                className="text-sm inline-block px-2 py-1 rounded mr-2  bg-primary-contrast text-primary"
                                style={{
                                    background: theme?.style?.details?.colors?.bgColor,
                                    color: theme?.style?.details?.colors?.fgColor,
                                }}
                            >
                                {value}
                            </div>
                        ))}
                    </div>
                </div>

                {categories.map((category) => (
                    <div className="mx-1 mb-2" key={`shop_cat_${category.id}`}>
                        <ShopItem
                            {...{
                                config: {
                                    shopToken: shop?.token,
                                    categoryID: category.id,
                                    name: category.name,
                                    coverURL: category.coverURL,
                                    coverColor: category.coverColor,
                                    events: {
                                        click: () => {
                                            setCategoryID(category.id);
                                        },
                                    },
                                },
                                style: {
                                    fgColor: "rgb(255, 255, 255)",
                                    bgColor: "rgb(245, 246, 248)",
                                    alignment: "center",
                                },
                            }}
                        />
                    </div>
                ))}
            </div>
        );
    }
    return null;
};

export default ShopCategories;
