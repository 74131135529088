import React from "react";

const BoxIconValue = ({ icon, value }) => {
    return (
        <div className=" bg-neutral h-16 w-20 flex items-center justify-center p-6 rounded">
            <div style={{ marginTop: "0.1rem" }} className="  mr-3 text-neutral-contrast">
                {value}
            </div>
            <i className={`text-2xl icon ${icon} `}></i>
        </div>
    );
};

export default BoxIconValue;
