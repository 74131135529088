import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import _ from "lodash";
import swal from "@sweetalert/with-react";

import Tabs from "../../../components/Tabs";
import Header from "../../../components/Header";
import Loading from "../../../components/Loading";
import ErrorMessage from "../../../components/ErrorMessage";

import { calculateDecimals } from "../../../utils/others";

import {
    calculateAmounts,
    updateAmounts,
    validateAviability,
    validateAviabilityRestaurant,
    checkRestaurantSchedule,
} from "../../../integrations/useCuiner";

import {
    setCuinerData,
    getCuinerData,
    getCuinerRestaurant,
    getCuinerMenu,
    getCuinerCart,
} from "../../../actions/cuiner";

const Item = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { prevLocation } = useParams();

    const lang = (i18n.language || "").match(/^[^-]+/)[0];

    const cuinerData = useSelector(getCuinerData);
    const selectedRestaurant = useSelector(getCuinerRestaurant);
    const cart = useSelector(getCuinerCart);
    const selectedMenu = useSelector(getCuinerMenu);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [item, setItem] = useState(null);
    const [requireModifier, setRequireModifier] = useState(false);
    const [canPurchase, setCanPurchase] = useState(null);
    const [periodRestaurantInfo, setPeriodRestaurantInfo] = useState("");
    const [heightItemSection, setHeightItemSection] = useState("auto");
    const [restaurantAvailability, setRestaurantAvailability] = useState(false);

    const title = (itemLang && itemData && itemData.titles ? itemData.titles[itemLang] : null) || "";

    const itemLang = item?.selectedLanguage;
    const itemData = item?.cuinerData;
    const priceList = item?.selectedPriceList;
    const itemTitle = (itemLang && item.titles ? item.titles[itemLang] : null) || "";

    const available = canPurchase?.available;
    const availableDate = canPurchase?.availableAt;
    const availabilityText = available ? t("product available") : t("available at x", { datetime: availableDate });
    const hasSchedule = canPurchase?.currentAvailability;

    useEffect(() => {
        setError(null);
        setItem(null);
        setLoading(true);
        if (selectedMenu && selectedMenu.currentItem) {
            const currentItemClone = { ...selectedMenu.currentItem };
            if (!currentItemClone.quantity || currentItemClone.quantity <= 0) currentItemClone.quantity = 1;
            setItem(updateAmounts(currentItemClone));
        } else {
            setError(t("error.unknown"));
        }
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    useEffect(() => {
        setRestaurantAvailability(
            validateAviabilityRestaurant(
                selectedRestaurant && selectedRestaurant.calendar ? selectedRestaurant.calendar : []
            )
        );
        setPeriodRestaurantInfo(
            checkRestaurantSchedule(
                selectedRestaurant && selectedRestaurant.calendar ? selectedRestaurant.calendar : [],
                t,
                lang
            )
        );
        const timer = setTimeout(() => {
            setHeightItemSection(heightSectionAccordingToModifiersGroups());
        }, 1000);
        return () => {
            clearTimeout(timer);
        };
    }, [selectedRestaurant]);

    useEffect(() => {
        if (item) {
            setRequireModifier(
                validateAviability(
                    true,
                    null,
                    {
                        all: itemData.modifiersGroups,
                        selecteds: item.modifierGroups,
                    },
                    t,
                    itemLang
                ).requireModifier
            );
            setCanPurchase(validateAviability(itemData.available, itemData.availabilities, null, t, itemLang));
        }
    }, [item]);

    const updateItemQuantity = (quantity) => {
        if (item) {
            let itemClone = { ...item };
            itemClone.quantity = quantity;
            itemClone = updateAmounts(itemClone);
            setItem(itemClone);
        }
    };
    const updateModifierItemQuantity = (quantity, modifierGroupData, modifierItemData) => {
        if (!item) {
            return;
        }

        const itemClone = { ...item };
        let modifierGroups = [...itemClone.modifierGroups];

        let modifierGroup = modifierGroups.find((m) => m.id === modifierGroupData.id) || null;
        let modifierItem = null;
        let modifierAddedPreviously = false;
        let modifierGroupAddedPreviously = false;
        let pricesWithModifiers = null;
        let index = null;

        if (modifierGroupData && modifierGroupData.id > 0 && modifierItemData && modifierItemData.id > 0) {
            if (!modifierGroup) {
                modifierGroup = { ...modifierGroupData };
                modifierGroup.items = [];
                delete modifierGroup.items_ids;
            } else {
                modifierGroupAddedPreviously = true;
            }

            modifierItem = modifierGroup.items.find((mi) => mi.id === modifierItemData.id) || null;

            if (!modifierItem) {
                pricesWithModifiers =
                    priceList?.pricesWithModifiers?.find(
                        (u) =>
                            u.modifiers_groups_id === modifierGroupData.id &&
                            u.price_info?.price_list_id === priceList.id
                    ) || null;

                modifierItem = {
                    id: modifierItemData.id,
                    cuinerData: { ...modifierItemData },
                    quantity: 0,
                    unitPrice: pricesWithModifiers?.price_info?.price || 0.0,
                    taxPercent: pricesWithModifiers?.price_info?.tax_percent || 0.0,
                    unitTax: 0.0,
                    subTotal: 0.0,
                    taxes: 0.0,
                    total: 0.0,
                };
            } else {
                modifierAddedPreviously = true;
            }

            if (quantity >= 1) {
                modifierItem.quantity = quantity;
                modifierItem = calculateAmounts(modifierItem);

                if (modifierAddedPreviously) {
                    index = modifierGroup.items.findIndex((mi) => mi.id === modifierItem.id);
                    modifierGroup.items[index] = modifierItem;
                } else {
                    modifierGroup.items.push(modifierItem);
                }
            } else {
                if (modifierAddedPreviously) {
                    modifierGroup.items = modifierGroup.items.filter((mi) => mi.id !== modifierItem.id);
                }
            }
            if (modifierGroup.items.length > 0) {
                if (modifierGroupAddedPreviously) {
                    index = modifierGroups.findIndex((m) => m.id === modifierGroup.id);
                    modifierGroups[index] = modifierGroup;
                } else {
                    modifierGroups.push(modifierGroup);
                }
            } else {
                if (modifierGroupAddedPreviously) {
                    modifierGroups = modifierGroups.filter((m) => m.id !== modifierGroup.id);
                }
            }
            itemClone.modifierGroups = modifierGroups;
            setItem(updateAmounts(itemClone));
        }
    };

    const showModifierDetails = (modifierGroup) => {
        const itemClone = { ...item };
        if (
            itemClone &&
            itemClone.cuinerData &&
            itemClone.cuinerData.modifiersGroups &&
            itemClone.cuinerData.modifiersGroups.length > 0
        ) {
            let modifierClone = itemClone.cuinerData.modifiersGroups.filter((m) => m.id === modifierGroup.id);
            if (modifierClone && modifierClone.length > 0) {
                modifierClone = modifierClone[0];
            } else {
                modifierClone = null;
            }
            if (modifierClone) {
                modifierClone.showingItems = !modifierClone.showingItems ? true : false;
                const index = _.findIndex(itemClone.cuinerData.modifiersGroups, { id: modifierGroup.id });
                itemClone.cuinerData.modifiersGroups.splice(index, 1, modifierClone);
                setItem(itemClone);
            }
        }
    };
    const showAvailabilitySchedule = (currentAvailability) => {
        const content = [];
        if (currentAvailability) {
            let periods = [];
            if (currentAvailability.schedule && currentAvailability.schedule.length > 0) {
                for (const element of currentAvailability.schedule) {
                    let scheduleDay = element;
                    if (scheduleDay.week_day) {
                        if (scheduleDay.time_periods && scheduleDay.time_periods.length > 0) {
                            for (const element of scheduleDay.time_periods) {
                                let timePeriod = element;
                                if (timePeriod) {
                                    let periodTitle = "start_time - end_time";
                                    periodTitle = periodTitle.replace(
                                        "start_time",
                                        timePeriod.start_time && timePeriod.start_time !== ""
                                            ? timePeriod.start_time
                                            : ""
                                    );
                                    periodTitle = periodTitle.replace(
                                        "end_time",
                                        timePeriod.end_time && timePeriod.end_time !== "" ? timePeriod.end_time : ""
                                    );
                                    let index = _.findIndex(periods, { title: periodTitle });
                                    let period = null;
                                    if (index > -1) {
                                        period = periods[index];
                                        period.days.push(t(scheduleDay.week_day));
                                        periods[index] = period;
                                    } else {
                                        period = {
                                            title: periodTitle,
                                            days: [t(scheduleDay.week_day)],
                                        };
                                        periods.push(period);
                                    }
                                }
                            }
                        } else {
                            let periodTitle = t("all day");
                            let index = _.findIndex(periods, { title: periodTitle });
                            let period = null;
                            if (index > -1) {
                                period = periods[index];
                                period.days.push(t(scheduleDay.week_day));
                                periods[index] = period;
                            } else {
                                period = {
                                    title: periodTitle,
                                    days: [],
                                };
                                period.days.push(t(scheduleDay.week_day));
                                periods.push(period);
                            }
                        }
                    }
                }
            }
            if (periods && periods.length > 0) {
                periods.forEach((period, index) => {
                    content.push(
                        <div className="w-full my-3">
                            <span className="block font-bold text-left">{period.days.join(" - ")}</span>
                            <span className="block text-left">{period.title}</span>
                        </div>
                    );
                });
            }
        }
        swal({
            title: t("availability schedule"),
            buttons: {
                confirm: t("close"),
            },
            content: <div className="w-full">{content}</div>,
        });
    };
    const addToCart = () => {
        if (item) {
            let cuinerDataClone = { ...cuinerData };
            let selectedRestaurantClone = { ...selectedRestaurant };
            let itemClone = { ...item };
            let cartClone = [...cart];
            let index = null;
            itemClone.canPurchase = canPurchase;
            if (itemClone.quantity && itemClone.quantity > 0) {
                if (cartClone && cartClone.filter((cart) => cart.id === itemClone.id).length > 0) {
                    index = _.findIndex(cartClone, { id: itemClone.id });
                    cartClone.splice(index, 1, itemClone);
                } else {
                    cartClone.push(itemClone);
                }
            } else {
                if (cartClone && cartClone.filter((cart) => cart.id === itemClone.id).length > 0) {
                    _.remove(cartClone, { id: itemClone.id });
                }
            }
            selectedRestaurantClone.cart = cartClone;
            index = _.findIndex(cuinerDataClone.restaurants, { id: selectedRestaurantClone.id });
            cuinerDataClone.restaurants.splice(index, 1, selectedRestaurantClone);
            dispatch(setCuinerData(cuinerDataClone));
            history.goBack();
        }
    };

    const heightSectionAccordingToModifiersGroups = () => {
        const modifiersList = document.querySelector("#modifiers-list");
        const itemSection = document.querySelector("#item-section");
        if (modifiersList && itemSection) {
            const itemSectionHeight = window.getComputedStyle(itemSection).getPropertyValue("height").match(/\d+/)[0];
            const modifierListHeight = window
                .getComputedStyle(modifiersList)
                .getPropertyValue("height")
                .match(/\d+/)[0];
            const newHeightToItemSection =
                String(Number(itemSectionHeight) + Number(modifierListHeight) / 2 + 100) + "px";
            return newHeightToItemSection;
        }
        return "auto";
    };

    const handlerTextOpens = (text) => {
        if (text.includes(t("tomorrow"))) {
            const textSplit = String(text.split(t("tomorrow")));
            return `${t("opens-tomorrow-at")} ${textSplit.replace(",", "").split("-")[0]}`;
        } else if (text.includes(t("Opens"))) {
            return text.trim().charAt(0).toUpperCase() + text.trim().slice(1);
        } else {
            return `${t("Opens at")} ${text.split("-")[0]}`;
        }
    };

    //Render functions
    const _renderModifierGroups = () => {
        const response = [];
        let nestedItems = null;
        if (itemData && itemData.modifiersGroups && itemData.modifiersGroups.length > 0) {
            itemData.modifiersGroups.map((modifierGroup) => {
                const unitsMin = modifierGroup.units_min && modifierGroup.units_min > 0 ? modifierGroup.units_min : 0;
                const unitsMax = modifierGroup.units_max && modifierGroup.units_max > 0 ? modifierGroup.units_max : 0;
                const modifierGroupItem = item.modifierGroups.filter((m) => m.id === modifierGroup.id);
                const unitsAdded =
                    modifierGroupItem && modifierGroupItem.length > 0
                        ? _.sumBy(modifierGroupItem[0].items, "quantity")
                        : 0;
                const extrasTotalAdded =
                    item.extraAmounts && item.extraAmounts.length > 0
                        ? _.sumBy(
                              item.extraAmounts.filter((ea) => ea.operator === "+"),
                              "total"
                          )
                        : 0.0;
                nestedItems = [];
                if (modifierGroup.items && modifierGroup.items.length > 0) {
                    modifierGroup.items.map(async (nestedItem) => {
                        let dynamicObject = null;
                        let itemPricesWithModifiers =
                            priceList &&
                            nestedItem.pricesWithModifiers &&
                            nestedItem.pricesWithModifiers.filter((u) => {
                                return (
                                    u.modifiers_groups_id === modifierGroup.id &&
                                    u.price_info &&
                                    u.price_info.price_list_id &&
                                    u.price_info.price_list_id === priceList.id
                                );
                            }).length > 0
                                ? nestedItem.pricesWithModifiers.filter((u) => {
                                      return (
                                          u.modifiers_groups_id === modifierGroup.id &&
                                          u.price_info &&
                                          u.price_info.price_list_id &&
                                          u.price_info.price_list_id === priceList.id
                                      );
                                  })[0]
                                : null;

                        let disabled = !validateAviability(
                            nestedItem.available,
                            nestedItem.availabilities,
                            null,
                            t,
                            itemLang
                        );
                        let extraAmountAdded =
                            item.extraAmounts &&
                            item.extraAmounts.filter((em) => em.itemModifierGroup.id === nestedItem.id).length > 0
                                ? item.extraAmounts.filter((em) => em.itemModifierGroup.id === nestedItem.id)
                                : null;
                        extraAmountAdded =
                            extraAmountAdded && extraAmountAdded.length > 0
                                ? _.sumBy(
                                      extraAmountAdded.filter((ea) => ea.operator === "+"),
                                      "total"
                                  )
                                : 0.0;
                        if (modifierGroupItem && modifierGroupItem.length > 0)
                            dynamicObject = modifierGroupItem[0].items.filter((im) => im.id === nestedItem.id);
                        dynamicObject = dynamicObject && dynamicObject.length > 0 ? dynamicObject[0] : null;
                        if (!disabled && unitsMax > 0 && unitsAdded >= unitsMax && !dynamicObject) disabled = true;
                        if (!canPurchase || !available) disabled = true;
                        if (!selectedRestaurant || !selectedRestaurant.availabilities) disabled = true;
                        nestedItems.push(
                            <li className="w-full my-3">
                                <div className="flex justify-between w-full">
                                    <span className="text-center">
                                        <input
                                            id={`modifierGroup-activate-${modifierGroup.id}-${nestedItem.id}`}
                                            type={
                                                modifierGroup.units_max && modifierGroup.units_max === 1
                                                    ? "radio"
                                                    : "checkbox"
                                            }
                                            checked={dynamicObject ? true : false}
                                            disabled={disabled}
                                            onChange={() =>
                                                updateModifierItemQuantity(
                                                    dynamicObject ? 0 : 1,
                                                    modifierGroup,
                                                    nestedItem
                                                )
                                            }
                                        />
                                        <label
                                            htmlFor={`modifierGroup-activate-${modifierGroup.id}-${nestedItem.id}`}
                                            className={`pl-2 ${
                                                !selectedRestaurant || !selectedRestaurant.availabilities || !available
                                                    ? "text-neutral-contrast-50"
                                                    : ""
                                            }`}
                                        >
                                            {itemLang && nestedItem.titles && nestedItem.titles[itemLang]
                                                ? nestedItem.titles[itemLang]
                                                : ""}
                                        </label>
                                    </span>
                                    <span className="text-neutral-contrast-50">
                                        {extraAmountAdded && extraAmountAdded > 0.0
                                            ? `+ ${extraAmountAdded}\u20AC`
                                            : null}
                                    </span>
                                </div>
                            </li>
                        );
                    });
                }
                response.push(
                    <div
                        id="modifiers-list"
                        className="w-full my-2 py-2 bg-white"
                        style={
                            modifierGroup && modifierGroup.color
                                ? {
                                      borderLeftColor:
                                          modifierGroup.color === "#783F04" ? modifierGroup.color : "#ffffff",
                                      borderLeftWidth: 10,
                                  }
                                : {}
                        }
                    >
                        <div className="flex justify-between w-full px-4 py-2">
                            <div className="flex items-center justify-center">
                                <i
                                    className={`icon icon-right mr-2 cursor-pointer transform ${
                                        modifierGroup && modifierGroup.showingItems ? `-` : ``
                                    }rotate-90`}
                                    onClick={() => {
                                        showModifierDetails(modifierGroup);
                                    }}
                                ></i>
                                <span className="mr-2">
                                    {itemLang && modifierGroup.title && modifierGroup.title[itemLang]
                                        ? modifierGroup.title[itemLang]
                                        : ""}
                                </span>
                                {unitsAdded > 0 ? (
                                    <span className="text-sm text-neutral-contrast-50">
                                        ({unitsAdded} {t("selected")})
                                    </span>
                                ) : null}
                            </div>
                            <span className="">{extrasTotalAdded > 0.0 ? `+ ${extrasTotalAdded}\u20AC` : ""}</span>
                        </div>
                        {modifierGroup && modifierGroup.showingItems ? (
                            <div className="w-full pl-10 pr-10">
                                {unitsMin > 0 ? (
                                    <div className="w-full mb-2">
                                        <span className="text-sm text-neutral-contrast-50">
                                            {"* " + t("x options required", { count: unitsMin })}
                                        </span>
                                    </div>
                                ) : null}
                                {modifierGroup.image_url && modifierGroup.image_url !== "" ? (
                                    <div className="w-full mb-2">
                                        <img
                                            className="object-cover h-20 w-full m-auto rounded-md"
                                            src={modifierGroup.image_url}
                                        />
                                    </div>
                                ) : null}
                                {unitsMin - unitsAdded > 0 ? (
                                    <div className="w-full mb-2">
                                        <span className="text-neutral-contrast-50">
                                            {t("at least x extra toppings", {
                                                quantity: unitsMin - unitsAdded,
                                                name: itemTitle,
                                            })}
                                        </span>
                                    </div>
                                ) : null}
                                <ul className="w-full mb-2">{nestedItems}</ul>
                            </div>
                        ) : null}
                    </div>
                );
            });
        }
        return response;
    };

    const _renderProductAvailability = () => {
        const response = [];
        if (item && canPurchase) {
            response.push(
                <div className="w-full my-2 py-2 bg-white">
                    <div className="flex justify-between w-full px-4 py-2">
                        <span className={"text-" + (available ? "green" : "red") + "-100"}>{availabilityText}</span>
                        {hasSchedule ? (
                            <span
                                className="text-accent align-baseline font-bold cursor-pointer"
                                onClick={(e) => {
                                    showAvailabilitySchedule(hasSchedule);
                                }}
                            >
                                {t("see availability schedule")}
                            </span>
                        ) : null}
                    </div>
                </div>
            );
        }
        return response;
    };

    const _renderProductOptions = () => {
        const response = [];
        if (item && canPurchase) {
            if (selectedRestaurant && restaurantAvailability) {
                if (available) {
                    response.push(
                        <>
                            <div className="flex items-center justify-center pb-3">
                                <button
                                    type="submit"
                                    className="btn border w-10 font-normal"
                                    disabled={item.quantity <= 1}
                                    onClick={(e) => {
                                        updateItemQuantity(item.quantity - 1);
                                    }}
                                >
                                    <i className="icon icon-less text-xl"></i>
                                </button>
                                <span className="text-xl px-6">{item.quantity}</span>
                                <button
                                    type="submit"
                                    className="btn border w-10 font-normal"
                                    onClick={(e) => {
                                        updateItemQuantity(item.quantity + 1);
                                    }}
                                >
                                    <i className="icon icon-add text-xl"></i>
                                </button>
                            </div>
                            {requireModifier ? (
                                <div className="w-full text-center pb-3">
                                    <span className="text-sm text-neutral-contrast-50">
                                        {`* ${t("select the required options")}`}
                                    </span>
                                </div>
                            ) : null}
                            <div className="w-full flex items-center justify-center">
                                <button
                                    type="submit"
                                    disabled={requireModifier}
                                    className={`w-full mx-10 bg-neutral-contrast-${
                                        !requireModifier ? `90` : `40`
                                    } cursor-pointer rounded-sm py-2 text-center`}
                                    onClick={(e) => {
                                        addToCart();
                                    }}
                                >
                                    <span className="font-medium text-neutral w-full text-xl px-4">
                                        {prevLocation === "fromCart"
                                            ? t("update")
                                            : t("add x for y", {
                                                  quantity: item.quantity,
                                                  price: `${item.total}\u20AC`,
                                              })}
                                    </span>
                                </button>
                            </div>
                        </>
                    );
                } else {
                    response.push(
                        <>
                            <div className="w-full text-center">
                                <span className="block font-bold">
                                    {t("available at x", { datetime: availableDate })}
                                </span>
                                <span className="block text-neutral-contrast-60 mt-5">
                                    {t("product will only be accepted during the hours")}
                                </span>
                            </div>
                        </>
                    );
                }
            } else {
                response.push(
                    <>
                        <div className="w-full text-center">
                            <span className=" font-medium text-base text-primary-contrast ">
                                {handlerTextOpens(periodRestaurantInfo.period)}
                            </span>
                            <span className="block text-neutral-contrast-60 mt-5">
                                {t("product only be accepted during the store opening")}
                            </span>
                        </div>
                    </>
                );
            }
        }
        return response;
    };

    const attributes =
        itemData && itemData.attributes && itemData.attributes.length > 0 ? (
            <div className="w-full pb-3 overflow-x-auto">
                {itemData.attributes.map((a) =>
                    a ? (
                        <div className="bg-neutral-contrast items-center rounded-md mr-2 inline-block py-1">
                            <span className="text-sm font-normal text-neutral px-4">{a}</span>
                        </div>
                    ) : null
                )}
            </div>
        ) : null;

    const allergens =
        itemData && itemData.allergens && itemData.allergens.length > 0 ? (
            <div className="w-full pb-3 overflow-x-auto">
                <h3 className="inline-block">
                    <span className="mr-1">{t("allergens")}:</span>
                    {itemData.allergens.map((a, index) => (
                        <i className={`icon ${a.icon} text-2xl mr-1`}></i>
                    ))}
                </h3>
            </div>
        ) : null;

    let sectionContent = null;
    if (loading) {
        sectionContent = <Loading />;
    } else if (error) {
        sectionContent = <ErrorMessage type="server" error={error} />;
    } else {
        sectionContent = (
            <>
                {item && itemData && itemData.imageUrl !== null && itemData.imageUrl !== "" ? (
                    <div className="w-full mb-2">
                        <img className="object-cover h-48 w-full m-auto" src={itemData.imageUrl} />
                    </div>
                ) : null}
                <div
                    className="w-full p-3 bg-white"
                    style={itemData && itemData.color ? { borderTopColor: itemData.color, borderTopWidth: 10 } : {}}
                >
                    <h2 className="block text-lg pb-2">{title}</h2>
                    <h2 className="block text-lg pb-2 font-bold">{`${calculateDecimals(
                        item ? item.unitPrice + item.unitTax : 0.0,
                        2
                    )}\u20AC`}</h2>
                    <h3 className="block text-lg pb-4 text-neutral-contrast-50">
                        {item && itemLang && itemData && itemData.descriptions && itemData.descriptions[itemLang]
                            ? itemData.descriptions[itemLang]
                            : ""}
                    </h3>
                    {attributes}
                    {allergens}
                </div>

                <div className="w-full">{_renderModifierGroups()}</div>

                <div className="w-full">{_renderProductAvailability()}</div>

                <div style={{ bottom: "64px", left: "0" }} className=" w-full fixed z-40 bg-neutral border-t py-4">
                    {_renderProductOptions()}
                </div>
            </>
        );
    }

    return (
        <section id="item-section" style={{ minHeight: `${heightItemSection}` }} className="basic has-tabs has-top">
            <Header title={title} />
            <section style={{ paddingLeft: 0, paddingRight: 0 }}>{sectionContent}</section>
            <Tabs hideKey={true} />
        </section>
    );
};

export default Item;
