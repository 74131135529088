import React, { useContext, useState, useEffect } from "react";
import { matchPath } from "react-router";
import { useLocation, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import KeyButton from "./KeyButton";
import { setSelectedRoom, setSelectedLanding } from "../actions/hotel";
import { useTranslation } from "react-i18next";
import pkg from "../../package.json";
import { postMessageToParent } from "./Design/DesignUtils";
import { getUser } from "../actions/user";
import { getIsEmbedded } from "../actions/config";
import { MessagesContext } from "contexts/messages";
import { ProjectContext } from "contexts/project";
import { NotificationContext, NOTIF_KIND_CHECKIN } from "contexts/notifications";

const Tabs = (props) => {
    const { t } = useTranslation();
    const location = useLocation();

    const { room, project } = useContext(ProjectContext);
    const { notifications } = useContext(NotificationContext);
    const { hasUnread } = useContext(MessagesContext);

    const selectedLanding = useSelector((state) => state.session?.selectedLandings?.[project?.ref]);
    const userLogged = useSelector(getUser);
    const isEmbedded = useSelector(getIsEmbedded);
    const preCheckInEnabled = project?.permissionsConfig?.preCheckIn;

    const hasUnreadMessage = hasUnread();
    const hasUnreadNotif = notifications?.filter((n) => !n.isRead).length > 0;
    const hasCheckInPending = notifications?.filter((n) => !n.isRead && n.kind === NOTIF_KIND_CHECKIN).length > 0;

    const isPath = (path, exact) => {
        return matchPath(location.pathname, {
            path: path,
            exact: exact,
        });
    };

    const dispatch = useDispatch();
    const [redirectTo, setRedirectTo] = useState("");

    const hotelsScreen = "/site/list";
    const hotelScreen = "/site";
    const roomScreen = "/site/room";

    const goToHotel = () => {
        if (isPath(roomScreen, false) && room?.number !== null) {
            dispatch(setSelectedRoom(null));
        }
        if (!isPath(hotelScreen, true) && project?.name) {
            setRedirectTo(hotelScreen);
        } else {
            if (room?.number) {
                let selectedLandingClone = { ...selectedLanding };
                let goToHotelList = true;
                let selectedRoom = room?.number;
                if (selectedLandingClone && selectedLandingClone.history && selectedLandingClone.history.length > 0) {
                    goToHotelList = false;
                    selectedLandingClone.landingRef = selectedLandingClone.history[0];
                    selectedLandingClone.topBar = null;
                    selectedLandingClone.history = null;
                    dispatch(setSelectedLanding(selectedLandingClone));
                    dispatch(setSelectedRoom(null));
                    setTimeout(() => {
                        dispatch(setSelectedRoom(selectedRoom));
                    }, 500);
                }
                if (goToHotelList) {
                    if (isEmbedded) {
                        postMessageToParent({ action: "exit" });
                        return;
                    } else {
                        setRedirectTo(hotelsScreen);
                    }
                }
            } else {
                setRedirectTo(hotelsScreen);
            }
        }
    };

    const goToProfile = () => {
        if (isEmbedded) {
            postMessageToParent({
                action: "ensureUser",
                params: {
                    page: "/profile",
                },
            });
        } else {
            if (!isPath("/profile", true)) {
                setRedirectTo("/profile");
            }
        }
    };

    const goToStays = () => {
        if (!isPath("/stays", true)) {
            setRedirectTo("/stays");
        }
    };

    const goToNotifications = () => {
        if (!isPath("/notifications", true)) {
            setRedirectTo("/notifications");
        }
    };

    if (redirectTo && !isPath(redirectTo, true)) {
        return <Redirect push to={redirectTo} />;
    }

    const buttons = [
        {
            id: "tab-property",
            name: t("hotel"),
            icon: "hotel",
            action: () => goToHotel(),
            active: isPath(hotelScreen, false),
            notif: false,
        },
        pkg.features.stays &&
            preCheckInEnabled && {
                id: "tab-stay",
                name: t("stays"),
                icon: "stay",
                action: () => goToStays(),
                active: isPath("/stays", false),
                notif: hasCheckInPending,
            },
        pkg.features.notifications
            ? {
                  id: "tab-notif",
                  name: t("notifications"),
                  icon: "notif",
                  action: () => goToNotifications(),
                  active: isPath("/notifications", false),
                  notif: hasUnreadNotif || hasUnreadMessage,
              }
            : null,
        {
            id: "tab-user",
            name: userLogged ? t("profile") : t("signin"),
            icon: "user",
            action: () => goToProfile(),
            active: isPath("/profile", false),
            notif: false,
        },
    ];

    return (
        <>
            <div className="tab-bar">
                {props.children ? (
                    props.children
                ) : (
                    <nav>
                        {buttons.map((b, i) =>
                            b ? (
                                <button
                                    id={b.id}
                                    key={"tab-k-" + i}
                                    onClick={b.action}
                                    className={b.active ? "active" : ""}
                                >
                                    <div className="notif-icon">
                                        {b.notif ? <div className="circle"></div> : ""}
                                        <i className={"icon icon-" + b.icon + (b.active ? "-solid" : "")}></i>
                                    </div>
                                    <div>{b.name}</div>
                                </button>
                            ) : null
                        )}
                    </nav>
                )}
            </div>
            {props.children ? null : props.hideKey === true ? null : <KeyButton></KeyButton>}
        </>
    );
};

export default Tabs;
