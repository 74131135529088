import React from "react";
import { useTranslation } from "react-i18next";
import { displayTime, getMinutes } from "../../utils/shop";
import Widget from "../Widget";

const ShopItem = ({ config, style, texts }) => {
    const { i18n, t } = useTranslation();
    const lang = (i18n.language || "").match(/^[^-]+/)[0];

    return (
        <Widget
            {...{
                type: "SHOPITEM",
                noUseFullHeight: true,
                config: {
                    ...config,
                    ...(config?.coverURL ? { src: { externalUrl: config.coverURL } } : null),
                    ...(config && Object(config).hasOwnProperty("deliverySchedule")
                        ? {
                              delivery: config.deliverySchedule
                                  ? config.deliverySchedule.map((s) => {
                                        const start = getMinutes(s.startTime);
                                        const end = getMinutes(s.endTime);
                                        return (
                                            displayTime(start / 60, start % 60, { lang }) +
                                            " - " +
                                            displayTime(end / 60, end % 60, { lang })
                                        );
                                    })
                                  : [t("delivery 24 hours")],
                          }
                        : null),
                },
                style: {
                    ...style,
                    ...(config?.coverColor ? { bgColor: config.coverColor } : null),
                },
                texts: {
                    delivery: t("delivery time"),
                    ...texts,
                },
            }}
        />
    );
};

export default ShopItem;
