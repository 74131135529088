import React, { useEffect, useState } from "react";

const CheckBox = (props) => {
    const text = props?.text;
    const radioButton = props?.radioButton || false;
    const disabled = props?.disabled || false;

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if ((props?.checked !== null && props?.checked) !== undefined) {
            setChecked(props?.checked);
        }
    }, [props?.checked]);

    const transferProps = { ...props };
    delete transferProps.onChange;
    delete transferProps.onClick;
    delete transferProps.style;
    delete transferProps.text;
    delete transferProps.radioButton;

    return (
        <div
            {...transferProps}
            style={{
                display: "inline-block",
                ...(disabled ? null : { cursor: "pointer" }),
                verticalAlign: "middle",
                ...props?.style,
            }}
            onClick={(e) => {
                e.stopPropagation();
                if (!disabled) {
                    setChecked(!checked);
                    if (props?.onChange) {
                        props.onChange(!checked);
                    }
                    if (props?.onClick) {
                        props?.onClick(e);
                    }
                }
            }}
        >
            <div
                className="border rounded border-gray inline-flex"
                style={{
                    padding: ".1em",
                    fontSize: "1.2em",
                    verticalAlign: "middle",
                    color: "#ffffff",
                    ...(disabled
                        ? {
                              background: checked ? "#D3DAE1" : "transparent",
                              borderColor: "#D3DAE1",
                          }
                        : {
                              background: checked && !radioButton ? "#323232" : "transparent",
                              borderColor: checked ? "#323232" : "rgba(50,50,50,.5)",
                          }),
                    ...(radioButton
                        ? {
                              width: "1em",
                              height: "1em",
                              lineHeight: "1em",
                              borderRadius: "50%",
                              borderWidth: checked ? ".35em" : ".05em",
                          }
                        : null),
                }}
            >
                {radioButton ? " " : <i className={`icon icon-checkmark ${checked ? null : "invisible"}`}></i>}
            </div>
            {text ? (
                <span
                    style={{
                        paddingLeft: ".5em",
                        verticalAlign: "middle",
                        ...(disabled ? { color: "#D3DAE1" } : null),
                    }}
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                ></span>
            ) : null}
        </div>
    );
};

export default CheckBox;
