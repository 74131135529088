import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { ProjectContext } from "contexts/project";
import { ShopContext } from "contexts/shop";
import { size as renderSize } from "zrender/sizes";
import { getDeviceWidth } from "components/Design/DesignUtils";
import Loading from "./Loading";
import { ErrorMessage } from "react-hook-form";
import { useShopCategories } from "graphql/shop";

const ShopSearch = ({ lang, search, topBarRef }) => {
    const { setCustomBackground } = useContext(ProjectContext);
    const { shop, setProductID, setCategoryID, setSubCategoryID, setLatestScrollY, searchHistory, setSearchHistory } =
        useContext(ShopContext);

    const {
        query: loadCats,
        called: loadCatsCalled,
        loading: loadingCats,
        error: loadCatsError,
        data: categories,
    } = useShopCategories({ shopToken: shop?.token });

    const { t: tr } = useTranslation();
    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const size = (v) => renderSize(v, "mobile", getDeviceWidth());

    const allProducts = categories?.categories?.reduce((acc, cat) => {
        const products = cat?.products
            ? cat?.products.map((product) => ({
                  id: `${cat.id}.${product?.id}`,
                  productID: product?.id,
                  categoryID: cat?.breadcrumbs?.length > 1 ? cat.breadcrumbs[1]?.id : null,
                  subCategoryID: cat?.breadcrumbs?.length > 2 ? cat.breadcrumbs[2]?.id : null,
                  name: product?.name,
                  category: cat?.name,
              }))
            : null;
        return products ? [...acc, ...products] : acc;
    }, []);

    const results = allProducts
        ? allProducts.filter((result) => result?.name?.toLowerCase().includes(search?.toLowerCase()))
        : null;

    const deleteResult = (id) => {
        setSearchHistory(searchHistory ? searchHistory.filter((result) => result.id !== id) : []);
    };

    useEffect(() => {
        if (!loadCatsCalled) {
            loadCats();
        }
        setCustomBackground("white");
        return () => {
            setCustomBackground(null);
        };
    }, []);

    const openResult = (result) => {
        setSearchHistory([result, ...(searchHistory || [])]);
        setCategoryID(result.categoryID);
        setSubCategoryID(result.subCategoryID);
        setProductID(result.productID, result.subCategoryID);
        setLatestScrollY(null);
        topBarRef?.current?.closeSearch();
    };

    if (loadingCats) {
        return <Loading />;
    }
    if (loadCatsError) {
        return <ErrorMessage type="connection" error={loadCatsError} />;
    }

    return (
        <div className="px-6 py-5">
            <div style={{ fontSize: size(1.4), overflow: "overlay" }}>
                {search?.length > 0 && results?.length === 0 ? <span>{t("no results found")}</span> : null}
                {search?.length > 0
                    ? (results || [])
                          .filter((r) => r)
                          .map((result) => (
                              <SearchResult
                                  key={`search_results_${result.id}`}
                                  name={result.name}
                                  search={search}
                                  description={result.category}
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      openResult(result);
                                  }}
                              />
                          ))
                    : (searchHistory || [])
                          .filter((r) => r)
                          .map((result) => (
                              <SearchResult
                                  key={`search_history_${result.id}`}
                                  name={result.name}
                                  description={result.category}
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      openResult(result);
                                  }}
                                  onRemove={() => deleteResult(result.id)}
                              />
                          ))}
            </div>
        </div>
    );
};

const SearchResult = ({ name, search, description, onClick, onRemove }) => {
    const highlightMatches = (text, search) => {
        if (!search) {
            return <strong>{text}</strong>;
        }
        const parts = text?.split(new RegExp(`(${_.escape(search)})`, "gi"));
        return (
            <span>
                {parts?.map((part, i) =>
                    part?.toLowerCase() === search?.toLowerCase() ? <strong key={"hl_" + i}>{part}</strong> : part
                )}
            </span>
        );
    };
    return (
        <div
            className={`flex justify-between items-center py-2 px-2 mb-4 ${onClick ? " cursor-pointer" : ""}`}
            style={{ borderBottom: "1px solid rgba(50,50,50,.1)" }}
            onClick={onClick}
        >
            <div>
                {highlightMatches(name, search)}
                <br />
                <span style={{ fontSize: ".9em" }}>{description}</span>
            </div>
            {onRemove ? (
                <div
                    className="icon icon-close text-xl p-3 cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (onRemove) {
                            onRemove();
                        }
                    }}
                ></div>
            ) : null}
        </div>
    );
};

export default ShopSearch;
