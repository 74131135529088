import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "@sweetalert/with-react";
import { useTranslation } from "react-i18next";
import { getSelectedHotel, setSelectedRoom } from "../../../actions/hotel";
import { getProjectRooms, getRoom, deleteRoom, updateRoom } from "../../../modules/rooms/store";
import { configToCSS } from "../DesignUtils";
import { getIsEmbedded } from "../../../actions/config";

import useForceUpdate from "../../../hooks/useForceUpdate";
import useWidget from "../../../hooks/useWidget";

const UseWidgetRooms = ({ config, id, deviceType }) => {
    const { cfg } = useWidget({
        data: config ? config.rooms : null,
        styles: config ? config.styles : null,
        parentCfg: config ? config.parentCfg : null,
        parseConfig,
    });
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const forceUpdate = useForceUpdate();

    const isEmbedded = useSelector(getIsEmbedded);
    const project = useSelector(getSelectedHotel);
    const room = useSelector(getRoom);
    const rooms = useSelector(getProjectRooms);

    useEffect(() => {
        if (!room && rooms && Object.keys(rooms).length > 0) {
            const firstRoomNumber = Object.keys(rooms)[0];
            dispatch(setSelectedRoom(firstRoomNumber));
        }
    }, [room, rooms]);

    const addRoom = () => {
        window.location.href = `/#/site/addroom`;
    };

    const openMenu = (menu) => {
        swal({
            className: "menu-list",
            button: false,
            content: (
                <div>
                    <ul className="px-5 mb-5">
                        {menu.map((item, index) =>
                            item ? (
                                <li key={"room-menu-" + item.title} id={item.id} onClick={item.onClick}>
                                    <span>{item.title}</span>
                                </li>
                            ) : null
                        )}
                    </ul>
                </div>
            ),
        });
    };
    const closeMenu = () => {
        swal.close();
    };

    const showPassword = () => {
        swal({
            title: t("room code"),
            text: room.code,
            buttons: {
                cancel: t("close"),
            },
        });
    };

    const promptDeleteRoom = () => {
        swal({
            title: t("remove room"),
            text: t("remove room info"),
            buttons: {
                cancel: t("alert cancel"),
                confirm: t("remove"),
            },
        }).then((confirm) => {
            if (confirm) {
                dispatch(
                    deleteRoom({
                        projectRef: project?.ref,
                        number: room.number,
                        code: room.code,
                        embedded: isEmbedded,
                    })
                );
                dispatch(setSelectedRoom(null));
                forceUpdate();
                toast(t("room deleted"));
            }
            swal.close();
        });
    };

    const handleClickRoomOptions = () => {
        openMenu([
            {
                title: t("add another room"),
                id: "action-add-another-room",
                onClick: () => {
                    closeMenu();
                    addRoom();
                },
            },
            {
                title: t("show password"),
                id: "action-show-password",
                onClick: () => {
                    closeMenu();
                    showPassword();
                },
            },
            room.automatic
                ? null
                : {
                      title: t("remove"),
                      id: "action-remove-room",
                      onClick: () => {
                          closeMenu();
                          promptDeleteRoom();
                      },
                  },
        ]);
    };

    const showOptions = () => {
        const menu = [
            {
                title: t("select room"),
                disabled: true,
                selected: false,
            },
        ];
        if (rooms && Object.keys(rooms).length > 0) {
            Object.entries(rooms).forEach(([, r]) => {
                const roomNumber = parseInt(r.number);
                const selected = roomNumber === parseInt(room.number);
                menu.push({
                    title: roomNumber,
                    disabled: selected,
                    selected: selected,
                    id: "action-select-room-" + roomNumber,
                    onClick: () => {
                        closeMenu();
                        dispatch(setSelectedRoom(roomNumber));
                    },
                });
            });
        }
        swal({
            className: "menu-list",
            button: false,
            content: (
                <div>
                    <ul className="px-5 mb-5">
                        {menu.map((item, index) => (
                            <li
                                key={"room-select-menu-" + item.title}
                                id={item.id}
                                onClick={item.onClick}
                                className={`${item.disabled ? "font-medium" : ""}`}
                                disabled={item.disabled}
                            >
                                <span>{item.title}</span>
                                {item.selected ? (
                                    <i className="absolute text-accent right-0 px-2 text-xl icon icon-checkmark"></i>
                                ) : null}
                            </li>
                        ))}
                    </ul>
                </div>
            ),
        });
    };

    return (
        <>
            <div className="flex w-full h-full" style={configToCSS(cfg, deviceType)} id={id}>
                {room ? (
                    <span className="w-full m-auto text-center">
                        {room?.hasChromecast ? (
                            <span className="absolute left-0 px-2 text-2xl">
                                <i
                                    className="icon icon-chromecast cursor-pointer"
                                    id="action-chromecast-message"
                                    onClick={() => {
                                        dispatch(
                                            updateRoom({
                                                projectRef: project.ref,
                                                number: room.number,
                                                data: {
                                                    showPairingMessage: !room.showPairingMessage,
                                                },
                                            })
                                        );
                                    }}
                                ></i>
                            </span>
                        ) : null}
                        <Link
                            id="action-room-selector"
                            onClick={(e) => {
                                e.preventDefault();
                                showOptions();
                            }}
                            to="#"
                            style={configToCSS(cfg.room, deviceType)}
                            className="font-medium"
                        >
                            {t("room")} {room.number}
                        </Link>
                        {rooms && Object.keys(rooms).length > 0 ? (
                            <button
                                id="action-icon-room-selector"
                                onClick={showOptions}
                                className="px-2 text-xl  transform rotate-90"
                            >
                                <i className="icon icon-right"></i>
                            </button>
                        ) : null}
                        <button
                            id="action-room-options"
                            onClick={handleClickRoomOptions}
                            className="absolute right-0 px-2 text-xl"
                        >
                            <i className="icon icon-ellipsis-v"></i>
                        </button>
                    </span>
                ) : (
                    <span className="w-full m-auto text-center">
                        <Link
                            to="#"
                            id="action-add-room"
                            onClick={(e) => {
                                e.preventDefault();
                                addRoom();
                            }}
                            style={configToCSS(cfg.room, deviceType)}
                            className="font-medium"
                        >
                            {t("add your room")}
                        </Link>
                        <br />
                        {t("to enjoy all our services")}
                    </span>
                )}
            </div>
        </>
    );
};

const parseConfig = ({ styles, parentCfg, theme }) => {
    let bgColor = styles.bgColor || theme.colors.defaultBackground;
    if (bgColor && parentCfg && parentCfg.bgColor === bgColor) {
        bgColor = null;
    }
    return {
        fgColor: styles.fgColor || theme.colors.defaultForeground,
        bgColor: bgColor,
        room: {
            fgColor: styles.fgActiveColor || theme.colors.activeForeground,
        },
    };
};

export default UseWidgetRooms;
