import React, { useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import Loading from "../components/Loading";
import { useDispatch } from "react-redux";
import { useSendResetCode, useVerifyResetCode, useResetPassword } from "../graphql/useUser";
import useInterval from "../hooks/useInterval";
import swal from "@sweetalert/with-react";
import { loginUser, updateUserData } from "../actions/user";
import { toast } from "react-toastify";

const Forgot = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [lastPass, setLastPass] = useState("");
    const { register, handleSubmit, watch, errors, triggerValidation } = useForm();
    const [redirectTo, setRedirectTo] = useState("");
    const [count, setCount] = useState(60);
    const [code, setCode] = useState("");

    const [recovery, setRecovery] = useState({
        email: null,
        hash: null,
        code: null,
        valid: null,
    });

    const [passVisible, setPassVisible] = useState(false);

    const togglePassVisibility = () => {
        setPassVisible(!passVisible);
    };

    const textInput = useRef(null);

    useInterval(() => {
        if (count > 0) {
            setCount(count - 1);
        }
    }, 1000);

    const recoveryError = () => {
        swal({
            title: t("error.recoveryTitle"),
            text: t("error.recovery") + "\n" + t("error.try again"),
            button: t("alert ok"),
        });
    };

    const verifyCodeError = () => {
        resetError();
    };

    const resetError = () => {
        swal({
            title: t("error.title"),
            text: t("error.unknown") + "\n" + t("error.unknownAction"),
            button: t("alert ok"),
        });
    };

    const { sendResetCode, loading: sending } = useSendResetCode({
        onCompleted: (data) => {
            const email = data.sendResetCode.email;
            const hash = data.sendResetCode.hash;
            if (email && hash) {
                setRecovery({
                    email: email,
                    hash: hash,
                });
            } else {
                recoveryError();
            }
        },
        onError: (error) => {
            recoveryError();
        },
    });

    const { verifyResetCode, loading: verifying } = useVerifyResetCode({
        onCompleted: (data) => {
            if (data.verifyResetCode) {
                setRecovery({
                    ...recovery,
                    valid: true,
                });
            } else {
                verifyCodeError();
            }
        },
        onError: (error) => {
            verifyCodeError();
        },
    });

    const { resetPassword, loading } = useResetPassword({
        onCompleted: (data) => {
            const user = data.resetPassword.user;
            const token = data.resetPassword.auth.token;
            if (user && token) {
                dispatch(loginUser(user.ref, token, lastPass));
                dispatch(updateUserData(user, "password"));
                toast(t("hello user", { name: user.name }) + "\n" + t("password changed"));
                setRedirectTo("/site/list");
            } else {
                resetError();
            }
        },
        onError: (error) => {
            resetError();
        },
    });

    const onSubmitEmail = (data, e) => {
        sendResetCode({
            variables: {
                email: data.email,
            },
        });
    };

    const onSubmitNewpass = (data, e) => {
        setLastPass(data.password);
        resetPassword({
            variables: {
                email: recovery.email,
                hash: recovery.hash,
                code: code,
                password: data.password,
            },
        });
    };

    const sendCode = () => {
        if (count === 0) {
            sendResetCode({
                variables: {
                    email: recovery.email,
                },
            });
            setCount(60);
            setCode("");
            textInput.current.value = "";
            textInput.current.focus();
        }
    };

    const verifyCode = (code) => {
        setRecovery({
            ...recovery,
            code: code,
        });
        verifyResetCode({
            variables: {
                email: recovery.email,
                hash: recovery.hash,
                code: code,
            },
        });
    };

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    const classNumber = "font-mono h-12 text-xl inline-block border-b border-neutral-contrast flex-1 mx-1 py-2 ";

    const checkPass = (exp) => {
        return (watch("password") || "").match(exp) ? "line-through" : errors.password ? " text-error" : "";
    };

    return (
        <section className="basic has-top">
            <Header
                title={recovery.valid ? t("change your pass") : recovery.hash ? t("enter code") : t("recovery pass")}
            />
            <section>
                {loading || sending || verifying ? (
                    <Loading />
                ) : recovery.valid ? (
                    <form onSubmit={handleSubmit(onSubmitNewpass)}>
                        <p className="text-center">{t("write new pass")}</p>

                        <div className="mt-3 mb-1">
                            <div
                                className={
                                    "float-left font-medium text-sm pl-1 pb-1 " + (!watch("password") && "invisible")
                                }
                            >
                                {t("password")}
                            </div>
                            <div className="relative">
                                <input
                                    ref={register({
                                        required: true,
                                        pattern: /^(?=.{6,50}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).*$/gm,
                                    })}
                                    type={passVisible ? "text" : "password"}
                                    name="password"
                                    id="input-pass"
                                    placeholder={t("password")}
                                    onChange={() => triggerValidation("password")}
                                    className={errors.password ? "error" : ""}
                                />
                                <i
                                    className={
                                        "absolute right-0 m-3 text-neutral-contrast-50 text-xl cursor-pointer icon icon-eye-" +
                                        (passVisible ? "on" : "off")
                                    }
                                    id="toggle-pass-visibility"
                                    onClick={() => togglePassVisibility()}
                                ></i>
                            </div>
                        </div>
                        {errors.password ? errors.password.message : ""}
                        <p className={"text-sm whitespace-pre-line"}>
                            <span className={checkPass(/(?=.{6,50}$)/gm)}>{t("passrules.chars")}</span>
                            <br />
                            <span className={checkPass(/(?=.*[a-z])(?=.*[A-Z])/gm)}>{t("passrules.case")}</span>
                            <br />
                            <span className={checkPass(/(?=.*[0-9])/gm)}>{t("passrules.num")}</span>
                        </p>
                        <footer>
                            <div className="text-right">
                                <button type="submit" id="action-save" className="btn btn-accent w-full my-5 text-lg">
                                    {t("save password")}
                                </button>
                            </div>
                        </footer>
                    </form>
                ) : recovery.hash !== null ? (
                    <>
                        <p className="text-center mb-10">{t("code sent to email", { email: recovery.email })}</p>

                        <div className="opacity-0 h-0 relative overflow-hidden">
                            <input
                                ref={textInput}
                                type="number"
                                max="999999"
                                id="input-code"
                                onChange={(e) => {
                                    const value = e.target.value.trim().substring(0, 6);
                                    if (value.length === 6) {
                                        verifyCode(value);
                                    }
                                    setCode(value);
                                }}
                                autoFocus={true}
                                className="absolute"
                            />
                        </div>
                        <p
                            className="flex text-center mb-6"
                            id="input-code-mask"
                            onClick={(e) => {
                                textInput.current.focus();
                            }}
                            onDoubleClick={(e) => {
                                textInput.current.value = "";
                                setCode("");
                            }}
                        >
                            {[0, 1, 2, 3, 4, 5].map((index) => (
                                <span className={classNumber} key={index}>
                                    {code[index]}
                                </span>
                            ))}
                        </p>

                        <p className="text-neutral-contrast my-3">
                            <button
                                className={count > 0 ? "text-neutral-contrast-50" : "text-neutral-contrast"}
                                disabled={count > 0 ? true : false}
                                id="action-send-code"
                                onClick={() => sendCode()}
                            >
                                {t("send code again")}
                            </button>
                            <span className="inline-block ml-4">
                                {count > 0 ? t("x seconds", { count: count }) : ""}
                            </span>
                        </p>
                    </>
                ) : (
                    <form onSubmit={handleSubmit(onSubmitEmail)}>
                        <p>{t("enter email to recovery pass")}</p>

                        <div className="mb-10">
                            <input
                                ref={register({
                                    required: t("required input"),
                                    validate: (value) => isEmail(value) || t("invalid email"),
                                })}
                                type="email"
                                id="input-email"
                                name="email"
                                placeholder={t("email")}
                                onChange={() => triggerValidation("email")}
                                autoFocus={true}
                                className={errors.email ? "error" : ""}
                            />
                            {errors.email && (
                                <div className="text-error float-right text-sm">{errors.email.message}</div>
                            )}
                        </div>

                        <footer>
                            <button
                                id="action-next"
                                type="submit"
                                className="btn btn-accent block mx-auto my-5 w-full text-lg"
                            >
                                {t("next")}
                            </button>
                        </footer>
                    </form>
                )}
            </section>
        </section>
    );
};

export default Forgot;
