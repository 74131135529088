import useLandingSession from "./useLandingSession";
import { useTranslation } from "react-i18next";
import WidgetStyle from "zrender/widgetStyle";

const useWidget = ({ type, data, styles, parentCfg, parseConfig, deviceType }) => {
    const { i18n } = useTranslation();
    const lang = (i18n.language || "").match(/^[^-]+/)[0];
    const { landing, theme, project, locationVOD } = useLandingSession();

    let th = theme || {};
    if (!th.colors) th.colors = {};
    if (!th.fonts) th.fonts = { paragraph: { font: {} }, heading: { font: {} } };

    const themeTranslate = (input) => {
        if (!input) input = {};
        if (!input.colors) input.colors = {};
        if (!input.fonts) input.fonts = {};
        if (!input.fonts.paragraph) input.fonts.paragraph = {};
        if (!input.fonts.heading) input.fonts.heading = {};

        let theme = {
            name: null,
            default: {
                bg: null,
                fg: null,
                bgAlpha: null,
                fgAlpha: null,
                rgbaBG: input.colors.defaultBackground,
                rgbaFG: input.colors.defaultForeground,
            },
            active: {
                bg: null,
                fg: null,
                bgAlpha: null,
                fgAlpha: null,
                rgbaBG: input.colors.activeBackground,
                rgbaFG: input.colors.activeForeground,
            },
            focus: {
                bg: null,
                fg: null,
                bgAlpha: null,
                fgAlpha: null,
                rgbaBG: input.colors.focusBackground,
                rgbaFG: input.colors.focusForeground,
            },
            focusBorderSize: 0,
            paragraphStyle: {
                font: input.fonts.paragraph.font,
                size: input.fonts.paragraph.size,
                textAlign: input.fonts.paragraph.alignment,
                bold: input.fonts.paragraph.bold,
                italic: input.fonts.paragraph.italic,
                underline: input.fonts.paragraph.underline,
            },
            headerStyle: {
                font: input.fonts.heading.font,
                size: input.fonts.heading.size,
                textAlign: input.fonts.heading.alignment,
                bold: input.fonts.heading.bold,
                italic: input.fonts.heading.italic,
                underline: input.fonts.heading.underline,
            },
            logos: input ? input.logos : null,
            logoSelected: null,
            logoSelectedId: null,
        };
        return theme;
    };

    if (type) {
        const style = new WidgetStyle(type, themeTranslate(th));
        if (styles) {
            style.fill(styles);
        }
        styles = style.export();
    }

    if (styles && typeof styles === "string") {
        try {
            styles = JSON.parse(styles);
        } catch (e) {
            console.error("Error parsing styles", e);
        }
    }

    const cfg = parseConfig
        ? parseConfig({
              data: data || {},
              styles: styles || {},
              theme: th,
              locationVOD,
              lang,
              parentCfg,
              deviceType,
          })
        : null;
    return { cfg, project, lang, locationVOD, landing, styles: styles || {} };
};

export default useWidget;
