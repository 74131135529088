import React from "react";
import Color from "zrender/color";
import { size as renderSize } from "zrender/sizes";

import { defaultTheme } from "../Theme";
import { getDeviceWidth } from "./Design/DesignUtils";

const Title = ({ id, style, children }) => {
    const size = (v) => renderSize(v, "mobile", getDeviceWidth());
    const theme = window?.appConfig?.theme || defaultTheme;
    const gradientStart = Color(theme?.accent).alpha(0.3).string();
    const gradientEnd = Color(theme?.accent).alpha(0.6).string();
    return (
        <div
            id={id}
            className="p-3 text-center text-accent-contrast bg-accent"
            style={{
                padding: size(1.05),
                fontSize: size(1.9),
                backgroundColor: theme?.accent,
                background: `linear-gradient(180deg, ${gradientStart} 0%, ${gradientEnd} 100%)`,
                ...style,
            }}
        >
            {children}
        </div>
    );
};

export default Title;
