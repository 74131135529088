import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const NoRoom = ({ title, description, className }) => {
    const { t } = useTranslation();

    return (
        <div className={`w-full text-center ${className}`}>
            <span className="block text-xl font-medium">{title || t("room required")}</span>
            <span className="block my-4 pb-2">{description || t("you need add room")}</span>
            <Link to="/site/addroom" className="btn btn-accent block mx-auto my-5 w-full text-lg">
                {t("add room")}
            </Link>
        </div>
    );
};

export default NoRoom;
