import React from "react";
import { useTranslation } from "react-i18next";

const StepProgress = ({ steps, currentStep, setCurrentStep, stepsEnabled, stepsVisited }) => {
    const { t } = useTranslation();

    const styleCurrentStepEqualToIndex = ({ currentStep, index }) => {
        if (currentStep === index) {
            return { circle: "bg-accent text-neutral", text: " text-neutral-contrast font-bold" };
        } else {
            return { circle: "border-2 border-accent text-neutral-contrast", text: "text-neutral-contrast" };
        }
    };

    const styleCurrentStepMinorToIndex = ({ currentStep, index }) => {
        if (index < currentStep || (stepsVisited?.includes(index) && currentStep !== index)) {
            return { circle: "border-2 border-accent text-neutral-contrast", text: " text-neutral-contrast" };
        } else if (currentStep !== index) {
            return {
                circle: " border-2 border-neutral-contrast-40 text-neutral-contrast-40",
                text: " text-neutral-contrast-40",
            };
        } else {
            return { circle: "", text: "" };
        }
    };

    return (
        <div className=" w-full flex justify-between">
            {/* steps.map((step) => {
            return <div>{step?.name}</div>})} */}
            {steps.map((step, index) => {
                const disabledStep = stepsEnabled[index] === false ? true : false;

                return (
                    <div className=" flex items-center w-full">
                        {index !== 0 ? (
                            <div
                                className=" bg-neutral-contrast-10"
                                style={{
                                    height: "1px",
                                    width: "100%",
                                    marginBottom: "1.5rem",
                                }}
                            />
                        ) : null}
                        <button
                            onClick={() => {
                                if (typeof setCurrentStep === "function" && !disabledStep) {
                                    setCurrentStep(index);
                                }
                            }}
                            id={`step-progress-${index}`}
                            className=" flex flex-col items-center justify-center"
                        >
                            <div
                                className={`w-6 h-6 text-sm flex items-center justify-center rounded-full 
                                ${styleCurrentStepEqualToIndex({ currentStep, index }).circle} 
                                ${styleCurrentStepMinorToIndex({ currentStep, index }).circle}
                                `}
                            >
                                {index + 1}
                            </div>
                            <div
                                className={`
                                mt-1 
                                ${styleCurrentStepEqualToIndex({ currentStep, index }).text}
                                ${styleCurrentStepMinorToIndex({ currentStep, index }).text}
                                `}
                            >
                                {t(step.name)}
                            </div>
                        </button>
                        {index !== steps.length - 1 ? (
                            <div
                                className=" bg-neutral-contrast-10"
                                style={{
                                    height: "1px",
                                    width: "100%",
                                    marginBottom: "1.5rem",
                                }}
                            />
                        ) : null}
                    </div>
                );
            })}
        </div>
    );
};

export default StepProgress;
