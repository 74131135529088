import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedRoom, setSelectedLanding, setSelectedHotel, getSelectedHotel } from "../actions/hotel";
import { useHotel } from "../graphql/useHotels";
import { getProjectRooms, getRoom, deleteRoom } from "../modules/rooms/store";
import { loginUser, logoutUser, getToken, getUser } from "../actions/user";
import { setIsEmbedded, getIsEmbedded } from "../actions/config";
import { useRooms, useLazyProfile } from "../graphql/useUser";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import Landing from "./Landing";
import HotelBasic from "./HotelBasic";
import { Redirect } from "react-router";
import _ from "lodash";
import useAddRoom from "../hooks/useAddRoom";
import Header from "../components/Header";
import Tabs from "../components/Tabs";

const Hotel = () => {
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.href.substring(window.location.href.lastIndexOf("/") + 1));
    const isEmbedded = useSelector(getIsEmbedded);
    const currentUser = useSelector(getUser);
    const userToken = useSelector(getToken);
    const user = useSelector((state) =>
        currentUser && state.user && state.user.data ? state.user.data[currentUser] : null
    );
    const project = useSelector(getSelectedHotel);
    const room = useSelector(getRoom);
    const rooms = useSelector(getProjectRooms);

    const selectedLanding = useSelector((state) =>
        project && state.session.selectedLandings ? state.session.selectedLandings[project?.ref] : null
    );

    const { addRooms, loading: addingRooms, checkRoomStatus } = useAddRoom({ project });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [redirectTo, setRedirectTo] = useState("");
    const [hasOnPremisesInterface, setHasOnPremisesInterface] = useState(false);
    const { hotel: loadHotel, loading: loadingHotel, error: errorHotel, data: hotelInfo } = useHotel();

    const { update: updateRooms } = useRooms();

    const hotelData = hotelInfo ? hotelInfo.getProject : null;
    const landingData = hotelData ? hotelData.landing : null;
    const salesData = ((data) => {
        try {
            return data ? JSON.parse(data) : null;
        } catch (error) {
            console.error(error);
            return null;
        }
    })(hotelData?.sales);

    const { lazyProfile } = useLazyProfile({
        onError: (error) => {
            console.error(error);
        },
    });

    useEffect(() => {
        if (params.get("projectRef")) {
            if (user) {
                dispatch(logoutUser(false));
            }
            dispatch(setIsEmbedded(true));
        } else if (!project) {
            setRedirectTo("/site/list");
        }
        if (project && rooms) {
            var forceUpdate = false;
            Object.entries(rooms).forEach(([key, value]) => {
                if (new Date().getTime() - value.added > 86400000 * 15) {
                    // Added 15 days ago (too much)
                    dispatch(
                        deleteRoom({
                            projectRef: project?.ref,
                            number: key,
                            code: value.code,
                            embedded: isEmbedded,
                        })
                    );
                    forceUpdate = true;
                }
            });
            if (forceUpdate) {
                dispatch(setSelectedRoom(null));
                updateRooms();
            }
        }
        updateRooms();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (isEmbedded) {
            if (params.get("userToken") && params.get("userRef")) {
                if (!userToken) {
                    dispatch(loginUser(params.get("userRef"), params.get("userToken"), null));
                } else {
                    if (!user || !user.email) {
                        lazyProfile();
                    }
                }
            }
            if (params.get("projectRef") && params.get("projectRef") !== project?.ref) {
                dispatch(setSelectedHotel(params.get("projectRef")));
            } else {
                if (params.get("rooms") && project) {
                    const roomsParams = _.split(params.get("rooms"), ",");
                    if (roomsParams && roomsParams.length > 0) {
                        const newRooms = [];
                        roomsParams.forEach((roomStr) => {
                            const room = _.split(roomStr, "|");
                            const hasChromecast = room && room.length >= 3 && room[2] == "true";
                            newRooms.push({
                                projectRef: project?.ref,
                                number: room[0],
                                code: room[1],
                                hasChromecast,
                            });
                        });
                        addRooms(newRooms, { check: false, checkWiFi: false });
                    }
                }
                if (params.get("landingRef") && params.get("designRef")) {
                    if (
                        !selectedLanding ||
                        (selectedLanding.landingRef !== params.get("landingRef") &&
                            selectedLanding.designRef !== params.get("designRef"))
                    ) {
                        dispatch(
                            setSelectedLanding({
                                mainLandingRef: params.get("landingRef"),
                                landingRef: params.get("landingRef"),
                                designRef: params.get("designRef"),
                                history: [],
                                theme: null,
                            })
                        );
                    }
                }
            }
        }
    }, [project?.ref, isEmbedded, userToken, user]); // eslint-disable-line

    useEffect(() => {
        if (project) {
            const hotelVariables = { ref: project?.ref };
            if (room && room.number) {
                hotelVariables.roomNumber = room.number;
                if (room.code) {
                    hotelVariables.password = room.code;
                }
                checkRoomStatus(room);
            }
            loadHotel({ variables: hotelVariables });
        }
    }, [project?.ref, room?.number]); // eslint-disable-line

    useEffect(() => {
        if (!room && rooms && Object.keys(rooms).length > 0) {
            const firstRoomNumber = Object.keys(rooms)[0];
            dispatch(setSelectedRoom(firstRoomNumber));
        }
    }, [room, rooms]); // eslint-disable-line

    useEffect(() => {
        if (hotelData) {
            let selectedLandingData = null;
            let hasOnPremisesInterface = hotelData.hasOnPremisesInterface;
            if (hotelData?.permissionsConfig?.landing) {
                if ((landingData && landingData.ref && landingData.ref > 0) || !hasOnPremisesInterface) {
                    selectedLandingData = {
                        mainLandingRef: null,
                        landingRef: null,
                        designRef: hotelData.designRef,
                        history: [],
                        theme: hotelData.theme,
                        salesStyles: salesData,
                        vodDomain: hotelData.VODDomain,
                        landingStyle: null,
                        googleApiKey: hotelData?.mapsGoogle?.apiKey ?? "",
                    };
                    if (landingData && landingData.ref && landingData.ref > 0) {
                        selectedLandingData.mainLandingRef = landingData.ref;
                        selectedLandingData.landingRef = landingData.ref;
                        selectedLandingData.landingStyle = landingData.style;
                    }
                }
            }

            setHasOnPremisesInterface(hasOnPremisesInterface);
            dispatch(setSelectedLanding(selectedLandingData));
        }
    }, [hotelData]); // eslint-disable-line

    useEffect(() => {
        setLoading(loadingHotel || addingRooms);
        if (errorHotel) {
            const matchErrors = ["invalid password", "room not found"];
            if (errorHotel.message && matchErrors.some((match) => errorHotel.message.indexOf(match) > -1)) {
                if (room) {
                    dispatch(
                        deleteRoom({
                            projectRef: project?.ref,
                            number: room.number,
                            code: room.code,
                            embedded: isEmbedded,
                        })
                    );
                    dispatch(setSelectedRoom(null));
                    updateRooms();
                } else {
                    setError(errorHotel);
                }
            } else {
                setError(errorHotel);
            }
        }
    }, [loadingHotel, addingRooms, errorHotel]);

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    const _renderInterfaceVersion = () => {
        let response = [];
        if (loading) {
            response.push(<Loading />);
        } else if (error) {
            response.push(
                <section className="basic has-top has-tabs">
                    <Header back="/site/list" title={project?.name} />
                    <section>
                        <ErrorMessage type="server" error={error} />
                    </section>
                    <Tabs />
                </section>
            );
        } else {
            if (selectedLanding) {
                response.push(<Landing data={hotelData} />);
            } else {
                response.push(<HotelBasic hotelData={hotelData} hasOnPremisesInterface={hasOnPremisesInterface} />);
            }
        }
        return response;
    };

    return _renderInterfaceVersion();
};

export default Hotel;
