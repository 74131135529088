import _ from "lodash";
import { calculateDecimals, getWeekDays , getNextDate , milisecondsToMinutes , getMonthabbreviation } from "../utils/others";

export const getRestaurants = async (apiData,params) => {
    const {requireGeneralInfo,requireCalendarInfo} = params;
    const {user,token,url} = apiData;
    var restaurantsData = [];
    var restaurantsError = null;
    await fetch(`${url}restaurants?user=${user}`,{
        method:'GET',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token,
        }
    })
    .then(res => res.json())
    .then(async (restaurantsList) => {
        if(restaurantsList && restaurantsList.length>0){
            for(var i = 0;i<restaurantsList.length;i++){
                var restaurantData = restaurantsList[i];
                restaurantData.generalInfo = null;
                restaurantData.calendarInfo = null;
                if(requireGeneralInfo){
                    await fetch(`${url}restaurant/${restaurantData.restaurant_id}`,{
                        method:'GET',
                        headers:{
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer '+token,
                        }
                    })
                    .then(res => res.json())
                    .then(async (restaurantInfo) => {
                        restaurantData.generalInfo = restaurantInfo;
                    },(error) => {
                        console.log(error);
                        restaurantsError = error;
                    });
                }
                if(requireCalendarInfo){
                    await fetch(`${url}restaurant/${restaurantData.restaurant_id}/calendar`,{
                        method:'GET',
                        headers:{
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer '+token,
                        }
                    }).then(res => res.json())
                    .then(async (restaurantCalendar) => {
                        restaurantData.calendarInfo = restaurantCalendar;
                    },
                    (error) => {
                        console.log(error);
                        restaurantsError = error;
                    });
                }
                restaurantsData.push(restaurantData);
            }
        }
    },
    (error) => {
        console.log(error);
        restaurantsError = error;
    });
    return {
        error:restaurantsError,
        data:restaurantsData
    };;
}

export const getMenus = async (apiData,params) => {
    const {restaurantId} = params;
    const {token,url,applicationId} = apiData;
    var menusData = [];
    var menusError = null;
    await fetch(`${url}restaurant/${restaurantId}/application/${applicationId}/menus`,{
        method:'GET',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token,
        }
    })
    .then(res => res.json())
    .then(async (menusList) => {
        if(menusList && menusList.length>0){
            menusData = menusList;
        }
    },
    (error) => {
        console.log(error);
        menusError = error;
    });
    return {
        error:menusError,
        data:menusData
    };;
}

export const getMenu = async (apiData,params) => {
    const {restaurantId,menuId,priceListIds,menuDate} = params;
    const {token,url,applicationId} = apiData;
    var menuData = [];
    var menuError = null;
    await fetch(`${url}restaurant/${restaurantId}/application/${applicationId}/menu/${menuId}?price_list_ids=${priceListIds.join(',')}${(menuDate)?`&menu_date_time=${menuDate}`:''}`,{
        method:'GET',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token,
        }
    })
    .then(res => res.json())
    .then(async (menuDetail) => {
        if(menuDetail){
            menuData = menuDetail;
        }
    },
    (error) => {
        console.log(error);
        menuError = error;
    });
    return {
        error:menuError,
        data:menuData
    };
}

export const createOrder = async (apiData,params) => {
    const {restaurantId,orderRef,bodyData} = params;
    const {token,url,applicationId} = apiData;
    var orderData = null;
    var orderError = null;
    await fetch(`${url}restaurant/${restaurantId}/application/${applicationId}/order/${orderRef}`,{
        method:'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token,
        },
        body:JSON.stringify(bodyData)
    })
    .then(res => res.json())
    .then(async (order) => {
        if(order){
            orderData = order;
        }
    },
    (error) => {
        console.log(error);
        orderError = error;
    });
    return {
        error:orderError,
        data:orderData
    };
}

export const validateAviability = (available,availabilities,modifierGroups,t,selectedLanguage) => {
    var response = {
        available:true,
        detail:null,
        requireModifier:false,
        currentAvailability:null,
        availableAt:null
    };
    var days = getWeekDays();
    let today = new Date();
    let weekDay = days[today.getDay()];
    let dynamicObject = null;
    if(available!==undefined && available!==true){
        response.available = false;
        response.detail = `${t("item")} ${t("not")} ${t("available")}`;   
    }
    if(response.available && (availabilities && availabilities.length>0)){
        response.available = false;
        for(var i = 0;i<availabilities.length;i++){
            var availability = availabilities[i];
            if(availability.date_from && availability.date_from!==""){
                response.available = false;
                dynamicObject = new Date(availability.date_from);
                if(dynamicObject && dynamicObject<=today){
                    response.available = true;
                }
            }
            if(availability.date_to && availability.date_to!==""){
                dynamicObject = new Date(availability.date_to);
                if(!dynamicObject || dynamicObject<today){
                    response.available = false;
                }
            }
            if(response.available && (availability.schedule && availability.schedule.length>0)){
                response.currentAvailability = availability;
                response.available = false;
                for(var j=0;j<availability.schedule.length;j++){
                    var scheduleDay = availability.schedule[j];
                    if(scheduleDay.week_day && scheduleDay.week_day.trim().toLowerCase() == weekDay){
                        response.available = true;
                        if(scheduleDay.time_periods && scheduleDay.time_periods.length>0){
                            response.available = false;
                            for(var k=0;k<scheduleDay.time_periods.length;k++){
                                var timePeriod = scheduleDay.time_periods[k];
                                if(timePeriod.start_time && timePeriod.start_time!==""){
                                    response.available = false;
                                    dynamicObject = new Date(today);
                                    dynamicObject.setHours(timePeriod.start_time.split(":")[0],timePeriod.start_time.split(":")[1],timePeriod.start_time.split(":")[2]);
                                    if(dynamicObject && dynamicObject<=today){
                                        response.available = true;
                                    }
                                }

                                if(timePeriod.end_time && timePeriod.end_time!==""){
                                    dynamicObject = new Date(today);
                                    dynamicObject.setHours(timePeriod.end_time.split(":")[0],timePeriod.end_time.split(":")[1],timePeriod.end_time.split(":")[2]);
                                    if(!dynamicObject || dynamicObject<today){
                                        response.available = false;
                                    }
                                }
                                if(response.available == true)break;
                            }
                        }
                        break;
                    }
                }
            }
            if(response.available == true)break;
        }
        if(!response.available){
            response.detail = `${t("without")} ${t("availability")}`;
            response.availableAt = getNextAvailableAt(today,availabilities,t);
        }
    }
    if(response.available && (modifierGroups && (modifierGroups.all && modifierGroups.all.length>0))){
        var requiredMinOneItem = modifierGroups.all.filter(m => m.units_min >= 1);
        if(requiredMinOneItem && requiredMinOneItem.length>0){
            var unitsAdded = 0;
            for(var i=0;i<requiredMinOneItem.length;i++){
                response.available = false;
                unitsAdded = 0;
                if(modifierGroups.selecteds && modifierGroups.selecteds.length>0){
                    dynamicObject = modifierGroups.selecteds.filter(m => m.id === requiredMinOneItem[i].id);
                    dynamicObject = (dynamicObject && dynamicObject.length>0)?dynamicObject[0]:null;
                    if(dynamicObject && (dynamicObject.items && dynamicObject.items.length>0)){
                        unitsAdded = _.sumBy(dynamicObject.items,'quantity');
                    }
                }
                if(unitsAdded>=requiredMinOneItem[i].units_min){
                    response.available = true;
                }
                if(response.available == false){
                    dynamicObject = requiredMinOneItem[i];
                    response.requireModifier = true;
                    response.detail = t("you must select at",{ min: dynamicObject.units_min, name: ((selectedLanguage && (dynamicObject.titles && dynamicObject.titles[selectedLanguage]))?dynamicObject.titles[selectedLanguage]:'')});
                    break;
                }
            }
        }
    }
    return response;
}

const getNextAvailableAt = (today,availabilities,t) => {
    var response = null;
    var days = getWeekDays();
    var indexWeekDay = today.getDay();
    var weekDay = days[indexWeekDay];
    if(availabilities && availabilities.length>0){
        for(var i = 0;i<availabilities.length;i++){
            var availability = availabilities[i];
            var add = true;
            var dynamicObject = null;
            if(availability.date_to && availability.date_to!==""){
                dynamicObject = new Date(availability.date_to);
                if(dynamicObject && dynamicObject<today){
                    add = false;
                }
            }
            if(add){
                if(availability.schedule && availability.schedule.length>0){
                    var postCurrentDay = false;
                    for(var j=0;j<availability.schedule.length;j++){
                        var scheduleDay = availability.schedule[j];
                        if(scheduleDay.week_day){
                            if(scheduleDay.week_day.trim().toLowerCase() !== weekDay){
                                response = t(scheduleDay.week_day);
                                if(postCurrentDay){
                                    add = false;
                                    var indexscheduleDay = days.indexOf(scheduleDay.week_day.trim().toLowerCase());
                                    if(indexscheduleDay > -1){
                                        if((indexWeekDay == 6 && indexscheduleDay==0) || (indexWeekDay+1 == indexscheduleDay)){
                                            response = t("tomorrow");
                                        }
                                    }
                                }
                                if(scheduleDay.time_periods && scheduleDay.time_periods.length>0){
                                    var timePeriod = scheduleDay.time_periods[0];
                                    if(timePeriod.start_time && timePeriod.start_time!=="")response+= " "+timePeriod.start_time;
                                }
                            }else{
                                if(scheduleDay.time_periods && scheduleDay.time_periods.length>0){
                                    for(var k=0;k<scheduleDay.time_periods.length;k++){
                                        var timePeriod = scheduleDay.time_periods[k];
                                        if(timePeriod.end_time && timePeriod.end_time!==""){
                                            dynamicObject = new Date(today);
                                            dynamicObject.setHours(timePeriod.end_time.split(":")[0],timePeriod.end_time.split(":")[1],timePeriod.end_time.split(":")[2]);
                                            if(dynamicObject && dynamicObject>today){
                                                add = false;
                                                response = t("today")+" "+timePeriod.start_time;
                                                break;
                                            }
                                        }else{
                                            add = false;
                                            response = t("today");
                                            break;
                                        }
                                    }
                                }else{
                                    add = false;
                                    response = t("today");
                                }
                                postCurrentDay = true;
                            }
                            if(!add)break;
                        }
                    }
                }else{
                    response = availability.date_from;
                }
            }
            if(response && response!=="")break;
        }
    }
    return response;
}

export const calculateAmounts = (item) => {
    if(item){
        item.subTotal = 0.0;
        item.unitTax = 0.0;
        item.taxes = 0.0;
        item.total = 0.0;
        if((item.taxPercent && item.taxPercent>0.0)
            && (item.unitPrice && item.unitPrice>0.0)){
            item.unitTax =  calculateDecimals((calculateDecimals((item.taxPercent*item.unitPrice),2)/100),2)
        }
        if((item.quantity && item.quantity>0)
            && (item.unitPrice && item.unitPrice>0.0)){
            item.subTotal = calculateDecimals((item.unitPrice*item.quantity),2);
            if(item.unitTax && item.unitTax>0.0){
                item.taxes = calculateDecimals((item.unitTax*item.quantity),2)
            }
            item.total = calculateDecimals((item.subTotal+item.taxes),2);
        }
    }
    return item;
}

export const validateAviabilityRestaurant = (calendarInfoRestaurant) => {
    const days = getWeekDays();
    const today = new Date();
    const weekDay = days[today.getDay()];
    let isAvailable = false;
    let dynamicObject = null;

    if (calendarInfoRestaurant.response === "No response data.") {
        isAvailable = true;
        return isAvailable;
    }

    if (calendarInfoRestaurant.open) {
        calendarInfoRestaurant.open.forEach((day) => {
            if (day.weekday.trim().toLowerCase() === weekDay.trim().toLowerCase()) {
                if (day.time_periods && day.time_periods.length > 0) {
                    day.time_periods.forEach((period) => {
                        if (period.start_time && period.start_time !== "") {
                            dynamicObject = new Date(today);
                            dynamicObject.setHours(period.start_time.split(":")[0], period.start_time.split(":")[1]);
                            if (dynamicObject && dynamicObject <= today) {
                                isAvailable = true;
                            }
                        }
                        if (period.end_time && period.end_time !== "") {
                            dynamicObject = new Date(today);
                            dynamicObject.setHours(period.end_time.split(":")[0], period.end_time.split(":")[1]);
                            if (!dynamicObject || dynamicObject < today) {
                                isAvailable = false;
                            }
                        }
                    });
                }
            }
        });
    }
    return isAvailable;
};
export const updateAmounts = (item) => {
    if(item){
        item = calculateAmounts(item);
        item.extraAmounts = [];
        if(item.modifierGroups && item.modifierGroups.length>0){
            var modifierGroup = null;
            var unitsSelecteds = null;
            var count = null;
            var nestedItems = null;
            var nestedItem = null;
            var extraQuantity = 0;
            for(var i = 0;i<item.modifierGroups.length;i++){
                modifierGroup = item.modifierGroups[i];
                nestedItems = (modifierGroup.items && modifierGroup.items.length>0)?modifierGroup.items:null;
                if(nestedItems && nestedItems.length>0){
                    nestedItems.sort((a, b) => a.total - b.total);
                    unitsSelecteds = _.sumBy(nestedItems,'quantity');
                    if(modifierGroup.units_charge_above && (modifierGroup.units_charge_above>0 && unitsSelecteds>modifierGroup.units_charge_above)){
                        count = modifierGroup.units_charge_above;
                        nestedItem = null;
                        extraQuantity = 0;
                        for(var j = 0;j<nestedItems.length;j++){
                            nestedItem = nestedItems[j];
                            if(nestedItem.quantity && nestedItem.quantity>0){
                                extraQuantity = 0;
                                if(count<=0){
                                    extraQuantity = nestedItem.quantity;
                                }else{
                                    extraQuantity = count-nestedItem.quantity;
                                    if(extraQuantity>0){
                                        extraQuantity = 0;
                                    }else{
                                        extraQuantity = Math.abs(extraQuantity);
                                    }
                                }
                                if(extraQuantity>0){
                                    item.extraAmounts.push({
                                        operator:"+",
                                        modifierGroup:{
                                            id:modifierGroup.id,
                                            title: modifierGroup.title
                                        },
                                        itemModifierGroup:{
                                            id:nestedItem.id,
                                            title: nestedItem.cuinerData.titles
                                        },
                                        ...calculateAmounts({
                                            quantity:extraQuantity,
                                            unitPrice: nestedItem.unitPrice,
                                            taxPercent: nestedItem.taxPercent
                                        })   
                                    })
                                }
                                count = count-nestedItem.quantity;
                            }
                        }
                    }
                    if(modifierGroup.units_refund_under && (modifierGroup.units_refund_under>0 && unitsSelecteds<modifierGroup.units_refund_under)){
                        count = modifierGroup.units_refund_under-unitsSelecteds;
                        nestedItem = null;
                        extraQuantity = 0;
                        for(var j = 0;j<nestedItems.length;j++){
                            nestedItem = nestedItems[j];
                            if(nestedItem.quantity && nestedItem.quantity>0){
                                extraQuantity = 0;
                                if(count>0){
                                    if(nestedItem.quantity>=count){
                                        extraQuantity = count;
                                    }else{
                                        extraQuantity = count-nestedItem.quantity;
                                    }
                                    if(extraQuantity>0){
                                        item.extraAmounts.push({
                                            operator:"-",
                                            modifierGroup:{
                                                id:modifierGroup.id,
                                                title: modifierGroup.title
                                            },
                                            itemModifierGroup:{
                                                id:nestedItem.id,
                                                title: nestedItem.cuinerData.titles
                                            },
                                            ...calculateAmounts({
                                                quantity:extraQuantity,
                                                unitPrice: nestedItem.unitPrice,
                                                taxPercent: nestedItem.taxPercent
                                            })   
                                        })
                                    }
                                    count = count-extraQuantity;
                                }
                            }
                        }
                        if(count>0){
                            nestedItem = item.extraAmounts.filter(ex => ex.operator ==="-")[0];
                            nestedItem.quantity = nestedItem.quantity+count;
                            nestedItem = calculateAmounts(nestedItem);
                            var index = _.findIndex(item.extraAmounts, {operator: "-"});
                            item.extraAmounts.splice(index, 1, nestedItem);
                        }
                    }
                }
            }
        }
        if(item.extraAmounts && item.extraAmounts.length>0){
            var subTotalAdd = {
                increase:0.0,
                discount:0.0
            };
            var totalAdd = {
                increase:0.0,
                discount:0.0
            }
            var taxesAdd = {
                increase:0.0,
                discount:0.0
            }
            var extraAmount = null;
            for(var i = 0;i<item.extraAmounts.length;i++){
                extraAmount = item.extraAmounts[i];
                if(extraAmount.operator){
                    switch(extraAmount.operator){
                        case "+":
                            if(extraAmount.subTotal && extraAmount.subTotal>0.0)subTotalAdd.increase = calculateDecimals(subTotalAdd.increase+extraAmount.subTotal,2);
                            if(extraAmount.taxes && extraAmount.taxes>0.0)taxesAdd.increase = calculateDecimals(taxesAdd.increase+extraAmount.taxes,2);
                            if(extraAmount.total && extraAmount.total>0.0)totalAdd.increase = calculateDecimals(totalAdd.increase+extraAmount.total,2);
                            break;
                        case "-":
                            if(extraAmount.subTotal && extraAmount.subTotal>0.0)subTotalAdd.discount = calculateDecimals(subTotalAdd.discount+extraAmount.subTotal,2);
                            if(extraAmount.taxes && extraAmount.taxes>0.0)taxesAdd.discount = calculateDecimals(taxesAdd.discount+extraAmount.taxes,2);
                            if(extraAmount.total && extraAmount.total>0.0)totalAdd.discount = calculateDecimals(totalAdd.discount+extraAmount.total,2);
                            break;
                    }
                }
            }
            if(subTotalAdd.increase>0.0)item.subTotal = calculateDecimals((item.subTotal+subTotalAdd.increase),2);
            if(subTotalAdd.discount>0.0)item.subTotal = calculateDecimals((item.subTotal-subTotalAdd.discount),2);
            if(taxesAdd.increase>0.0)item.taxes = calculateDecimals((item.taxes+taxesAdd.increase),2);
            if(taxesAdd.discount>0.0)item.taxes = calculateDecimals((item.taxes-taxesAdd.discount),2);
            if(totalAdd.increase>0.0)item.total = calculateDecimals((item.total+totalAdd.increase),2);
            if(totalAdd.discount>0.0)item.total = calculateDecimals((item.total-totalAdd.discount),2);
        }
    }
    return item;
}

export const getItemAttributeName = (attributeIndex,t) => {
    var response = "";
    var attributes = {
        1: 'novelty',
        2: 'offer',
        3: 'recommended product',
        4: 'to share',
        5: 'healthy',
        6: 'echo',
        7: 'vegan'
    }
    response = t(attributes[attributeIndex]);
    return response;
}

export const getItemAllergen = (allergenIndex,t) => {
    var response = "";
    var allergens = {
        1: {name:t('gluten'),icon:'icon-gluten'},
        2: {name:t('crustaceans'),icon:'icon-crustaceans'},
        3: {name:t('eggs'),icon:'icon-eggs'},
        4: {name:t('fish'),icon:'icon-fish'},
        5: {name:t('peanuts'),icon:'icon-peanuts'},
        6: {name:t('soy'),icon:'icon-soy'},
        7: {name:t('milk (lactose)'),icon:'icon-milk'},
        8: {name:t('nuts'),icon:'icon-nuts'},
        9: {name:t('celery'),icon:'icon-celery'},
        10: {name:t('mustard'),icon:'icon-mustard'},
        11: {name:t('sesame'),icon:'icon-sesame'},
        12: {name:t('so2'),icon:'icon-so2'},
        13: {name:t('lupins'),icon:'icon-lupins'},
        14: {name:t('mollusks'),icon:'icon-molluscs'}
    }
    response = allergens[allergenIndex];
    return response;
}


const sortCuinerDays = (calendarDays) => {
    const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    days.forEach((day) => {
        if (!calendarDays.some((cDay) => cDay.weekday.toLowerCase() === day.toLowerCase())) {
            calendarDays.push({ weekday: day, time_periods: [] });
        }
    });

    const sorter = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
    };

    const sortedCalendarDays = calendarDays.sort((a, b) => {
        let day1 = a.weekday.toLowerCase();
        let day2 = b.weekday.toLowerCase();
        return sorter[day1] - sorter[day2];
    });

    return sortedCalendarDays;
};

export const checkRestaurantSchedule = (calendar, t, lang) => {
    let scheduleRestaurantValues = "";
    if (calendar.response === "No response data.") {
        scheduleRestaurantValues = checkRestaurantPeriods(null);
        return scheduleRestaurantValues;
    }
    const weekday = getWeekDays();
    const currentDate = new Date();
    const currentDayNumber = currentDate.getDay();
    const currentDayName = weekday[currentDayNumber];

    if (calendar.open) {
        const cuinerDataCalendar = sortCuinerDays(calendar.open);

        cuinerDataCalendar.forEach((day, index) => {
            if (currentDayName.toLowerCase() === day.weekday.toLowerCase()) {
                if (day.time_periods && day.time_periods.length > 0) {
                    scheduleRestaurantValues = checkRestaurantPeriods(
                        day.time_periods,
                        currentDate,
                        cuinerDataCalendar,
                        true,
                        t,
                        lang
                    );
                } else {
                    const sliceCuinerDataCalendar = cuinerDataCalendar.slice(0, index);
                    const lastDayWithPeriods = sliceCuinerDataCalendar
                        .reverse()
                        .find((day) => day.time_periods.length > 0);
                    if (lastDayWithPeriods !== undefined) {
                        scheduleRestaurantValues = checkRestaurantPeriods(
                            sliceCuinerDataCalendar[lastDayWithPeriods].time_periods,
                            currentDate,
                            cuinerDataCalendar,
                            false,
                            t
                        );
                    } else {
                        const findFirstDayWithPeriods = cuinerDataCalendar.find((day) => day.time_periods.length > 0);

                        if (findFirstDayWithPeriods) {
                            scheduleRestaurantValues = checkRestaurantPeriods(
                                findFirstDayWithPeriods.time_periods,
                                currentDate,
                                cuinerDataCalendar,
                                false,
                                t,
                                lang
                            );
                        }
                    }
                }
            }
        });
    }
    return scheduleRestaurantValues;
};



const checkRestaurantPeriods = (restaurantPeriods, currentTime, calendar, isToday, t , lang) => {
    let statusRestaurantSchedule = { status: "open", period: "", color: "text-green-100" };
    if (restaurantPeriods === null) {
        return statusRestaurantSchedule;
    }

    restaurantPeriods.every((period) => {
        const currenDate = new Date(currentTime);
        const dynamicStartTime = new Date(currentTime);
        const dynamicEndTime = new Date(currentTime);
        const startTimeSplit = period.start_time && period.start_time !== "" ? period.start_time.split(":") : null;
        const endTimeSplit = period.end_time && period.end_time !== "" ? period.end_time.split(":") : null;

        if (startTimeSplit === null || endTimeSplit === null) return false;

        dynamicStartTime.setHours(startTimeSplit[0], startTimeSplit[1], 0);
        dynamicEndTime.setHours(endTimeSplit[0], endTimeSplit[1], 0);

        if (
            currenDate.getTime() >= dynamicStartTime.getTime() &&
            currenDate.getTime() <= dynamicEndTime.getTime() &&
            isToday === true
        ) {
            if (milisecondsToMinutes(dynamicEndTime) - milisecondsToMinutes(currenDate) <= 30) {
                statusRestaurantSchedule.status = "closes soon";
                statusRestaurantSchedule.period = ` ${period.start_time} - ${period.end_time}`;
                statusRestaurantSchedule.color = "text-orange-100";
                return false;
            }
            statusRestaurantSchedule.status = "open";
            statusRestaurantSchedule.period = ` ${period.start_time} - ${period.end_time}`;
            statusRestaurantSchedule.color = "text-green-100";
            return false;
        } else if (currenDate.getTime() < dynamicStartTime.getTime() && isToday === true) {
            statusRestaurantSchedule.status = "closed";
            statusRestaurantSchedule.period = ` ${period.start_time} - ${period.end_time}`;
            statusRestaurantSchedule.color = "text-red-100";
            return false;
        } else {
            if (statusRestaurantSchedule.period === "") {
                const weekday = getWeekDays();
                const monthAbbreviation = getMonthabbreviation();
                const currentDayNumber = currenDate.getDay();
                const currentDayName = weekday[currentDayNumber];
                calendar.forEach((day, index) => {
                    if (currentDayName.toLowerCase() === day.weekday.toLowerCase()) {
                        if (index + 1 < calendar.length && calendar[index + 1].time_periods.length > 0) {
                            statusRestaurantSchedule.period = `
                                ${t("tomorrow")}
                                ${calendar[index + 1].time_periods[0].start_time} - 
                                ${calendar[index + 1].time_periods[0].end_time}
                                `;
                        } else if (index === 6 && calendar[0].time_periods.length > 0) {
                            statusRestaurantSchedule.period = `
                                ${t("tomorrow")}
                                ${calendar[0].time_periods[0].start_time} - 
                                ${calendar[0].time_periods[0].end_time}
                                `;
                        } else {
                            const sliceCalendar = calendar.slice(index + 1);
                            const nextAvailableDayBySliceCalendar = sliceCalendar.find(
                                (cDay) => cDay.time_periods.length > 0
                            );
                            if (nextAvailableDayBySliceCalendar) {
                                const nextDate = getNextDate(currenDate, nextAvailableDayBySliceCalendar.weekday);
                                statusRestaurantSchedule.period = ` 
                                        ${t("Opens")}
                                        ${nextDate.getDate()}
                                        ${lang === "es" ? "de" : ""}
                                        ${t(monthAbbreviation[nextDate.getMonth()])}. 
                                        ${t("at-hour")} 
                                        ${nextAvailableDayBySliceCalendar.time_periods[0].start_time}
                                    `;
                            } else {
                                const nextAvailableDayByCurrentCalendar = calendar.find(
                                    (cDay) => cDay.time_periods.length > 0
                                );
                                const nexDate = getNextDate(currenDate, nextAvailableDayByCurrentCalendar.weekday);
                                statusRestaurantSchedule.period = `
                                        ${t("Opens")}
                                        ${nexDate.getDate()}
                                        ${lang === "es" ? "de" : ""}
                                        ${monthAbbreviation[nexDate.getMonth()]}. 
                                        ${t("at-hour")}
                                        ${nextAvailableDayByCurrentCalendar.time_periods[0].start_time}
                                    `;
                            }
                        }
                    }
                });
            }
            statusRestaurantSchedule.status = "closed";
            statusRestaurantSchedule.color = "text-red-100";
            return true;
        }
    });
    return statusRestaurantSchedule;
};