import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import googleIcon from "../assets/images/g.svg";
import { useTranslation } from "react-i18next";
import { setGoogleToken } from "../actions/integrations";
import { GoogleOAuthProvider } from "google-oauth-gsi";

const googleProvider = new GoogleOAuthProvider({
    clientId: window.appConfig && window.appConfig.authGoogle ? window.appConfig.authGoogle.clientID : null,
    onScriptLoadError: () => {
        console.error("Unable to connect with google");
    },
});

const SignIn = ({ sectionName, onGoogleToken, onGoogleError }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tokenID = useSelector((state) => state.session.googleToken);

    const googleReady = googleProvider.scriptLoadedSuccessfully;
    const [googleError, setGoogleError] = useState(null);

    const login = googleProvider.useGoogleLogin({
        flow: "auth-code",
        ux_mode: "redirect",
        prompt: "none",
        redirect_uri: window.location.origin,
        onError: (err) => {
            googleOnError("google error", err);
        },
        onNonOAuthError: (err) => {
            googleOnError("non OAuth error", err);
        },
    });

    const googleOnError = (origin, e) => {
        console.error("googleOnError", e);
        if (typeof e == "object") {
            if (e.details) {
                setGoogleError({ origin, msg: e.details });
            } else if (e.type) {
                setGoogleError({ origin, msg: e.type + ": " + e.message });
            } else {
                try {
                    setGoogleError({ origin, msg: e.message || JSON.stringify(e) });
                } catch (e) {
                    setGoogleError({ origin, msg: e });
                }
            }
        } else {
            setGoogleError({ origin, msg: e });
        }
        if (onGoogleError) {
            onGoogleError(e);
        }
    };

    useEffect(() => {
        if (tokenID) {
            setGoogleError(null);
            if (onGoogleToken) onGoogleToken(tokenID);
            dispatch(setGoogleToken(null));
        }
    }, [tokenID]);

    return (
        <>
            <h1 className="font-bold text-lg mb-2">{t("login title")}</h1>
            {googleProvider.clientId && (
                <>
                    <button
                        id="signin-google"
                        onClick={login}
                        disabled={!googleReady}
                        className={
                            "btn btn-google-light mx-auto w-full text-lg flex items-center justify-center" +
                            (!googleReady ? " opacity-50" : "") +
                            (googleError ? " mt-5" : " my-5")
                        }
                    >
                        <div className="p-2 mr-1 rounded inline-block">
                            <img src={googleIcon} alt="G" />
                        </div>
                        {t("signin google")}
                    </button>
                    {googleError ? (
                        <div className="text-xs mb-2 p-1 font-normal text-error">{`${googleError?.origin}: ${googleError?.msg}`}</div>
                    ) : null}
                    <p>{t("or access with your email")}</p>
                </>
            )}
            <Link to="signin/email" id="signin-email" className="btn btn-accent block mx-auto my-5 w-full text-lg">
                {t("signin email")}
            </Link>
            <p>{t("dont have account?")}</p>
            <Link to="signup" id="signup" className="text-accent inline-block align-baseline font-bold">
                {t("signup")}
            </Link>
        </>
    );
};

export default SignIn;
