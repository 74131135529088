import { getHotel } from "./hotel";

export const SET_CUINER_DATA = "SET_CUINER_DATA";

export function setCuinerData(value) {
    return setDispatch(SET_CUINER_DATA, value);
}

export function getCuinerData(state) {
    const projectRef = getHotel(state);
    const data = state?.session?.cuinerData || null;
    return projectRef && data ? data[projectRef] : null;
}

export function getCuinerRestaurant(state) {
    const data = getCuinerData(state);
    const restaurants = data?.restaurants;
    const currentID = data?.currentRestaurant;
    if (currentID && restaurants) {
        const match = restaurants.filter((r) => r.id === currentID);
        if (match.length > 0) {
            return match[0];
        }
    }
    return null;
}

export function getCuinerMenu(state) {
    const restaurant = getCuinerRestaurant(state);
    const menus = restaurant?.menus;
    const menuID = restaurant?.currentMenu;
    if (menuID && menus) {
        const match = menus.filter((m) => m.id === menuID);
        if (match.length > 0) {
            return match[0];
        }
    }
    return null;
}

export function getCuinerCart(state) {
    const restaurant = getCuinerRestaurant(state);
    return restaurant?.cart || [];
}

function setDispatch(type, value) {
    return (dispatch) => {
        dispatch({
            type: type,
            payload: value,
        });
    };
}
