import React, { useEffect, useState } from "react";

import { size as renderSize } from "zrender/sizes";
import { getDeviceWidth } from "./Design/DesignUtils";

const INCREMENT = 1;
const DECREMENT = -1;

export const QuantityButtons = (props) => {
    const size = (v) => renderSize(v, "mobile", getDeviceWidth());
    const hideZero = props?.hideZero || false;
    const quantity = props?.value || 0;

    const transferProps = { ...props };
    delete transferProps.className;
    delete transferProps.hideZero;

    return (
        <div className={`flex justify-center items-stretch mb-4 ${props?.className}`}>
            {!hideZero || quantity > 0 ? (
                <>
                    <QuantityButton {...transferProps} type="remove" />
                    <div className="px-5 py-1" style={{ fontSize: size(1.5) }}>
                        {quantity}
                    </div>
                </>
            ) : null}
            <QuantityButton {...transferProps} type="add" />
        </div>
    );
};

const QuantityButton = (props) => {
    const min = props?.min || 0;
    const max = props?.max >= 0 ? props?.max : null;
    const zoom = props?.size || 1;
    const step = props?.step || 1;
    const loading = props?.loading || false;
    const changeType = props?.type !== "add" ? DECREMENT : INCREMENT;

    const [units, setUnits] = useState(props?.value || 0);
    const size = (v) => renderSize(v * zoom, "mobile", getDeviceWidth());
    const icon = loading ? "ellipsis-h" : changeType === INCREMENT ? "add" : "less";

    const disabled =
        props?.disabled ||
        (changeType === INCREMENT && max !== null && units >= max) ||
        (changeType === DECREMENT && units <= min);

    const clickHandler = (e) => {
        let set = units;
        if (changeType === INCREMENT) {
            set += step;
        } else {
            set += step * -1;
        }
        set = Math.max(min, set);
        if (max !== null) {
            set = Math.min(max, set);
        }

        setUnits(set);
        if (props?.onNewValue) {
            props.onNewValue(set);
        }

        if (props?.onClick) {
            props?.onClick(e);
        }
    };

    useEffect(() => {
        if ((props?.value !== null && props?.value) !== undefined) {
            setUnits(props?.value || 0);
        }
    }, [props?.value]);

    useEffect(() => {
        if (max !== null && units > max) {
            setUnits(max);
        }
    }, [units]);

    const transferProps = { ...props };
    delete transferProps.onNewValue;
    delete transferProps.min;
    delete transferProps.max;
    delete transferProps.type;
    delete transferProps.value;
    delete transferProps.size;
    delete transferProps.loading;
    delete transferProps.step;
    delete transferProps.disabled;

    return (
        <div
            {...transferProps}
            style={{
                fontSize: size(1.8),
                lineHeight: "1em",
                textAlign: "center",
                verticalAlign: "middle",
                padding: size(0.5),
                borderWidth: "1px",
                borderRadius: size(0.35 / zoom),
                borderColor: "#D3DAE1",
                display: "grid",
                alignContent: "center",
                ...(disabled ? { color: "#D3DAE1" } : { cursor: "pointer" }),
                ...props?.style,
            }}
            onDoubleClick={(e) => {
                // This is because in iOS when double clicking it is doing an unwanted zoom
                e.stopPropagation();
            }}
            onClick={(e) => {
                // This is because in iOS when double clicking it is doing an unwanted zoom
                e.stopPropagation();
                if (!disabled) {
                    clickHandler(e);
                }
            }}
        >
            <i className={`icon icon-${icon}`}></i>
        </div>
    );
};

export default QuantityButton;
