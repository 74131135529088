import { useState } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import {
    updateToken,
    updateUserData,
    setVerificationEmail,
    getToken as getUserToken,
    getUserData,
} from "../actions/user";
import { setRooms } from "../modules/rooms/store";
import { setToken, getToken } from "../actions/config";
import { GET_ROOMS } from "./queries/rooms";
import {
    SIGN_UP,
    HELLO,
    SIGN_IN,
    SIGN_IN_GOOGLE,
    RESET_PASSWORD,
    SEND_RESET_CODE,
    VERIFY_RESET_CODE,
    SEND_EMAIL_VERIFICATION_CODE,
    VERIFY_EMAIL_VERIFICATION_CODE,
    UPDATE_PROFILE,
    ACCEPT_TERMS,
    UPDATE_PASSWORD,
    SET_TOKEN_LANGUAGE,
    SEND_CUINER_ORDER_MAIL,
    AUTHORIZE_PAYMENT_INVOICE,
    EXPRESS_CHECKOUT,
    SEND_TRACK_SCREEN,
    SEND_TRACK_EVENT,
    SEND_TRACK_EVENT_GENERAL_MOBILE,
    SEND_TRACK_SCREEN_GENERAL_MOBILE,
} from "./mutations/user";
import { GET_PROFILE, GET_INVOICE } from "./queries/user";
import handleGraphqlError from "./handleGraphqlError";
import { determineTrackInfo } from "utils/user";

export const useSignUp = (callbacks) => {
    const [signIn, setSignIn] = useState(null);
    const dispatch = useDispatch();
    const userToken = useSelector(getUserToken);
    const [signUp, { loading, error }] = useMutation(SIGN_UP.query, {
        onCompleted: (data) => {
            if (data && data.signIn) {
                setSignIn(data.signIn);
            }
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, null, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });

    return { signUp, loading, error, signIn };
};

export const useUpdateProfile = (params) => {
    const [user, setUser] = useState(null);
    const userToken = useSelector(getUserToken);
    const customToken = params && params.token ? params.token : null;
    const token = customToken || userToken;

    const dispatch = useDispatch();
    const [updateProfile, { loading, error }] = useMutation(UPDATE_PROFILE.query, {
        context: { customToken },
        onCompleted: (data) => {
            if (data && data.updateProfile) {
                setUser(data.updateProfile);
            }
            if (params && params.onCompleted) {
                params.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, null, token);
            if (params && params.onError) {
                params.onError(error);
            }
        },
    });

    const [acceptTerms, { loading: loadingTerms, error: errorTerms }] = useMutation(ACCEPT_TERMS.query, {
        context: { customToken },
        onCompleted: (data) => {
            if (params && params.onCompleted) {
                params.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, null, token);
            if (params && params.onError) {
                params.onError(error);
            }
        },
    });

    return {
        updateProfile,
        acceptTerms,
        loading: loading || loadingTerms,
        error: error || errorTerms,
        user: user,
    };
};

export const useProfile = () => {
    const dispatch = useDispatch();
    const userToken = useSelector(getUserToken);
    const user = useSelector(getUserData);

    const { loading, error } = useQuery(GET_PROFILE.query, {
        variables: GET_PROFILE.defaultVars,
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            if (data && data.getProfile) {
                dispatch(updateUserData(data.getProfile));
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, null, userToken);
            const authorized = handleGraphqlError(error, dispatch, null, userToken);
            if (!authorized) {
                dispatch(updateUserData(null));
            }
        },
    });

    if (user) {
        return [false, null, user];
    }

    return [loading, error, user];
};

export const useLazyProfile = (callbacks) => {
    const dispatch = useDispatch();
    const userToken = useSelector(getUserToken);
    const [lazyProfile] = useLazyQuery(GET_PROFILE.query, {
        variables: GET_PROFILE.defaultVars,
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            if (data && data.getProfile) {
                dispatch(updateUserData(data.getProfile));
            }
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, null, userToken);
            const authorized = handleGraphqlError(error, dispatch, null, userToken);
            if (!authorized) {
                dispatch(updateUserData(null));
            }
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });
    return { lazyProfile: lazyProfile };
};

export const useUpdatePassword = (callbacks) => {
    const userToken = useSelector(getUserToken);
    const [user, setUser] = useState(null);
    const dispatch = useDispatch();
    const [updatePassword, { loading, error }] = useMutation(UPDATE_PASSWORD.query, {
        onCompleted: (data) => {
            if (data && data.updatePassword) {
                setUser(data.updatePassword);
            }
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, null, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });

    return {
        updatePassword: updatePassword,
        loading,
        error,
        user: user,
    };
};

export const useSignIn = (callbacks) => {
    const [signInResponse, setSignIn] = useState(null);
    const userToken = useSelector(getUserToken);
    const appToken = useSelector(getToken);
    const dispatch = useDispatch();
    const [signIn, { loading, error }] = useMutation(SIGN_IN.query, {
        onCompleted: (data) => {
            if (data && data.signIn) {
                setSignIn(data.signIn);
            }
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });

    return { signIn, loading, error, signInResponse };
};

export const useSignInGoogle = (callbacks) => {
    const [signInResponse, setSignIn] = useState(null);
    const appToken = useSelector(getToken);
    const userToken = useSelector(getUserToken);
    const dispatch = useDispatch();
    const [signInGoogle, { loading, error }] = useMutation(SIGN_IN_GOOGLE.query, {
        onCompleted: (data) => {
            if (data && data.signInGoogle) {
                setSignIn(data.signInGoogle);
            }
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });

    return { signInGoogle, loading, error, signInResponse };
};

export const useSendResetCode = (callbacks) => {
    const [recovery, setRecovery] = useState(null);
    const appToken = useSelector(getToken);
    const userToken = useSelector(getUserToken);
    const dispatch = useDispatch();
    const [sendResetCode, { loading, error }] = useMutation(SEND_RESET_CODE.query, {
        onCompleted: (data) => {
            if (data && data.sendResetCode) {
                setRecovery(data.sendResetCode);
            }
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });

    return { sendResetCode, loading, error, recovery };
};

export const useVerifyResetCode = (callbacks) => {
    const [valid, setValid] = useState(null);
    const appToken = useSelector(getToken);
    const userToken = useSelector(getUserToken);
    const dispatch = useDispatch();
    const [verifyResetCode, { loading, error }] = useMutation(VERIFY_RESET_CODE.query, {
        onCompleted: (data) => {
            if (data && data.verifyResetCode) {
                setValid(data.verifyResetCode);
            }
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });

    return { verifyResetCode, loading, error, valid };
};

export const useResetPassword = (callbacks) => {
    const [signIn, setSignIn] = useState(null);
    const appToken = useSelector(getToken);
    const userToken = useSelector(getUserToken);
    const dispatch = useDispatch();
    const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD.query, {
        onCompleted: (data) => {
            if (data && data.signIn) {
                setSignIn(data.signIn);
            }
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });

    return { resetPassword, loading, error, signIn };
};

export const useHello = (callbacks) => {
    const dispatch = useDispatch();
    const userToken = useSelector(getUserToken);
    const [hello, { loading, error }] = useMutation(HELLO.query, {
        onCompleted: (data) => {
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            if (callbacks && callbacks.onError) {
                handleGraphqlError(error, dispatch, null, userToken);
                callbacks.onError(error);
            }
        },
    });
    return { hello, loading, error };
};

export const useSendEmailVerificationCode = (callbacks) => {
    const appToken = useSelector(getToken);
    const userToken = useSelector(getUserToken);
    const lastVerifEmail = useSelector((state) => state.user.verificationEmail);
    const [verification, setVerification] = useState({
        hash: lastVerifEmail ? lastVerifEmail.hash : null,
        valid: null,
    });
    const dispatch = useDispatch();
    const [sendEmailVerificationCode, { loading, error }] = useMutation(SEND_EMAIL_VERIFICATION_CODE.query, {
        onCompleted: (data) => {
            if (data && data.sendEmailVerificationCode) {
                const hash = data.sendEmailVerificationCode.hash;
                if (hash) {
                    dispatch(setVerificationEmail(hash));
                    setVerification({
                        ...verification,
                        hash: hash,
                    });
                } else {
                    callbacks.onError("unknown hash");
                }
            }
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });

    return {
        sendEmailVerificationCode,
        loading,
        error,
        verification,
        setVerification,
    };
};

export const useVerifyEmailVerificationCode = (callbacks) => {
    const [valid, setValid] = useState(null);
    const appToken = useSelector(getToken);
    const userToken = useSelector(getUserToken);
    const dispatch = useDispatch();
    const [verifyEmailVerificationCode, { loading, error }] = useMutation(VERIFY_EMAIL_VERIFICATION_CODE.query, {
        onCompleted: (data) => {
            if (data && data.verifyEmailVerificationCode) {
                setValid(data.verifyEmailVerificationCode);
            }
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });

    return {
        verifyEmailVerificationCode,
        loading,
        error,
        valid,
    };
};

export const useSetTokenLanguage = (callbacks) => {
    const dispatch = useDispatch();
    const userToken = useSelector(getUserToken);
    const appToken = useSelector(getToken);
    const activeToken = userToken ? userToken : appToken;
    const [setTokenLanguage, { loading, error }] = useMutation(SET_TOKEN_LANGUAGE.query, {
        onCompleted: (data) => {
            if (data && data.setTokenLanguage) {
                const newToken = data.setTokenLanguage.token;
                if (newToken) {
                    if (userToken) {
                        dispatch(updateToken(newToken));
                    } else {
                        dispatch(setToken(newToken));
                    }
                }
            }
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            if (callbacks && callbacks.onError) {
                handleGraphqlError(error, dispatch, null, activeToken);
                callbacks.onError(error);
            }
        },
    });
    return { setTokenLanguage, loading, error };
};

export const useSendCuinerOrderMail = (callbacks) => {
    const userToken = useSelector(getUserToken);
    const appToken = useSelector(getToken);
    const dispatch = useDispatch();
    const [sendCuinerOrderMail, { loading, error, data }] = useMutation(SEND_CUINER_ORDER_MAIL.query, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        onCompleted: (data) => {
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });
    return { sendCuinerOrderMail, loading, error, data };
};

export const useInvoice = (callbacks) => {
    const userToken = useSelector(getUserToken);
    const appToken = useSelector(getToken);
    const dispatch = useDispatch();
    const [getInvoice, { loading, error, data, refetch }] = useLazyQuery(GET_INVOICE.query, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        onCompleted: (data) => {
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });
    return { getInvoice, loading, error, data, refetch };
};

export const useAuthorizePaymentInvoice = (callbacks) => {
    const userToken = useSelector(getUserToken);
    const appToken = useSelector(getToken);
    const dispatch = useDispatch();
    const [authorizePaymentInvoice, { loading, error, data }] = useMutation(AUTHORIZE_PAYMENT_INVOICE.query, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        onCompleted: (data) => {
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });
    return { authorizePaymentInvoice, loading, error, data };
};

export const useExpressCheckout = (callbacks) => {
    const userToken = useSelector(getUserToken);
    const appToken = useSelector(getToken);
    const dispatch = useDispatch();
    const [expressCheckout, { loading, error, data }] = useMutation(EXPRESS_CHECKOUT.query, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        onCompleted: (data) => {
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });
    return { expressCheckout, loading, error, data };
};

export const useRooms = (callbacks) => {
    const dispatch = useDispatch();
    const user = useSelector(getUserData);
    const userToken = useSelector(getUserToken);

    const [getRooms] = useLazyQuery(GET_ROOMS.query, {
        variables: GET_ROOMS.defaultVars,
        fetchPolicy: "cache-and-network",
        cachePolicy: {
            maxAge: 5 * 60 * 1000, // 5 minutes
            eviction: "LIFO", // last in, first out
        },
        onCompleted: (data) => {
            if (data && data.getRooms) {
                dispatch(setRooms(data.getRooms.results));
            }
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, null, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });

    return {
        update: user && user.emailVerified ? getRooms : () => {},
    };
};

export const useSendTrackScreenGeneralMobile = ({ path, screenSelected, selectedRoom }) => {
    const { selectedHotel, selectedLandings } = useSelector((state) => state.session);
    const appType = window?.appConfig?.appType?.toUpperCase() || "PWA";
    const currentDate = new Date().toISOString();

    const { id, type } = determineTrackInfo(path, selectedLandings, selectedHotel, selectedRoom, screenSelected);

    const [sendTrackScreenMutation, { loading, error }] = useMutation(SEND_TRACK_SCREEN_GENERAL_MOBILE.query, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        variables: {
            path: path,
            hotelRef: selectedHotel,
            source: appType,
            date: currentDate,
            id: String(id),
            type: type,
        },
        onError: (error) => {
            console.error("Error sending track:", error);
        },
    });

    const sendTrackScreen = () => {
        if (id === null || type === null) {
            return;
        }
        sendTrackScreenMutation();
    };

    return { sendTrackScreen, loading, error };
};

export const useSendTrackEventGeneralMobile = () => {
    const { selectedHotel } = useSelector((state) => state.session);
    const appType = window?.appConfig?.appType?.toUpperCase() || "PWA";

    const [sendTrackEventGeneralMobileMutation, { loading, error }] = useMutation(
        SEND_TRACK_EVENT_GENERAL_MOBILE.query,
        {
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            onError: (error) => {
                console.log(error);
            },
        }
    );

    // console.log(error);

    const sendTrackEventGeneralMobile = (params, event) => {
        if (!selectedHotel) {
            return;
        }
        sendTrackEventGeneralMobileMutation({
            variables: {
                hotelRef: selectedHotel,
                date: new Date().toISOString(),
                params: params,
                event: event,
                source: appType,
            },
        });
    };

    return { sendTrackEventGeneralMobile };
};
