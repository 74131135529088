import React from "react";
import Color from "zrender/color";

import { size as renderSize } from "zrender/sizes";
import { getDeviceWidth } from "./Design/DesignUtils";

const Footer = (props) => {
    const size = (v) => renderSize(v, "mobile", getDeviceWidth());

    const theme = {
        fgColor: "black",
        bgColor: "white",
        ...props?.theme,
    };

    const transferProps = { ...props, theme: undefined };

    return (
        <div
            {...transferProps}
            style={{
                position: "fixed",
                zIndex: 10,
                bottom: 0,
                left: 0,
                right: 0,
                boxShadow: "0 0 .5em " + Color(theme.fgColor).alpha(0.1).string(),
                backgroundColor: theme.bgColor,
                color: theme.fgColor,
                paddingTop: size(1.5),
                paddingBottom: size(1.5),
                paddingLeft: size(2),
                paddingRight: size(2),
                textAlign: "center",
                ...props?.style,
            }}
            id="footer"
            onClick={(e) => {
                e.stopPropagation();
                if (props?.onClick) {
                    props?.onClick(e);
                }
            }}
        >
            {props?.children}
        </div>
    );
};

export default Footer;
