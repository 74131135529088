import React, { useContext, useEffect, useState } from "react";
import Color from "zrender/color";
import { useTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import { size as renderSize } from "zrender/sizes";

import { getDeviceWidth } from "./Design/DesignUtils";
import { ShopContext } from "../contexts/shop";
import { useShopProduct, useShopExtras } from "../graphql/shop";
import { printPrice, getAllergenColor, getAllergenIcon, getAllergenName } from "../utils/shop";

import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";
import Tag from "./Tag";
import { QuantityButtons } from "./QuantityButton";
import ShopExtra from "./ShopExtra";
import Footer from "./Footer";

import { useSwipeable } from "react-swipeable";

const ShopProduct = ({ lang, topBarRef }) => {
    const {
        shop,
        currencyCode,
        pricesIncludeTax,
        cart,
        addToCart,
        theme,
        productID,
        cartItemID,
        setProductID,
        setCartItemID,
        maxProductsPerOrder,
    } = useContext(ShopContext);

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            setActiveCover((activeCover + 1) % product?.images?.length);
        },
        onSwipedRight: () => {
            setActiveCover((activeCover - 1 + product?.images?.length) % product?.images?.length);
        },
    });

    const {
        query: loadProduct,
        called: loadProductCalled,
        loading: loadingProduct,
        error: loadProductError,
        data: product,
    } = useShopProduct({ shopToken: shop?.token, productID });

    const {
        query: loadExtras,
        called: loadExtrasCalled,
        loading: loadingExtras,
        error: loadExtrasError,
        data: extras,
    } = useShopExtras({ shopToken: shop?.token, productID });

    const inCart = cartItemID
        ? cart?.products?.[cartItemID - 1]
        : (() => {
              const items = cart?.products?.filter((p) => p?.productID === productID && extras && extras.length === 0);
              return items?.length > 0 ? items : null;
          })();

    const inCartSumQuantity = cartItemID ? inCart.quantity : inCart?.reduce((sum, p) => sum + p?.quantity, 0);
    const itemID =
        cartItemID || (inCart?.length === 1 ? cart?.products?.findIndex((p) => p?.productID === productID) + 1 : null);
    const inCartQuantity = (itemID ? cart?.products?.[itemID - 1]?.quantity : inCartSumQuantity) || 0;
    const initialQuantity = inCartQuantity || 1;
    const initialSelections = { ...inCart?.selections };

    const [quantity, setQuantity] = useState(initialQuantity);
    const [totalExtras, setTotalExtras] = useState(0);
    const [activeCover, setActiveCover] = useState(0);
    const [selections, setSelections] = useState(initialSelections);
    const [completed, setCompleted] = useState({
        ...extras?.map((e) => {
            return { [e.id]: false };
        }),
    });

    const { t: tr } = useTranslation();
    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const size = (v) => renderSize(v, "mobile", getDeviceWidth());

    useEffect(() => {
        if (product) {
            topBarRef?.current?.setTitle(product?.name);
            topBarRef?.current?.setBack(() => {
                setProductID(null);
                setCartItemID(null);
            });
        }
    }, [topBarRef, product]);

    useEffect(() => {
        if (!loadProductCalled) {
            loadProduct();
        }
        if (!loadExtrasCalled) {
            loadExtras();
        }
    }, [productID]);

    useEffect(() => {
        const selectionsTotal = Object.keys(selections).reduce(
            (a, b) => a + selections[b]?.price * (selections[b]?.quantity || 0),
            0
        );
        setTotalExtras(selectionsTotal);
    }, [selections]);

    // Preload images
    useEffect(() => {
        if (product?.images) {
            product.images.forEach((url) => {
                const image = new Image();
                image.src = url;
            });
        }
    }, [product?.images]);

    const loading = loadingProduct || loadingExtras;
    const error = loadProductError || loadExtrasError;

    const details = theme?.style?.details?.colors;
    const footer = theme?.style?.section2?.status?.default?.sections?.general?.colors;
    const footerButton = theme?.style?.section2?.status?.default?.sections?.button;
    const tags = product?.tags?.map((t) => t.name);
    const allergens = product?.allergens;

    const footerBorderColor = Color(footer?.fgColor).alpha(0.3).string();

    const cartPendingChanges =
        inCart && quantity === inCartQuantity && JSON.stringify(selections) === JSON.stringify(initialSelections);

    const requiredPending = !completed || Object.keys(completed)?.some((c) => !completed[c]);

    const disabled = maxUnitsToAdd === 0 || requiredPending || cartPendingChanges;

    const listTags = tags ? (
        <div>
            {tags.map((tag) => (
                <Tag
                    key={`tag-${tag}`}
                    style={{
                        background: details?.bgColor,
                        color: details?.fgColor,
                    }}
                >
                    {tag}
                </Tag>
            ))}
        </div>
    ) : null;

    const listAllergens = allergens ? (
        <div className="inline-block">
            {allergens.map((allergen) => (
                <i
                    key={`allergen-${allergen.id}`}
                    className={`icon ${getAllergenIcon(allergen.id)} pl-2`}
                    style={{ color: getAllergenColor(allergen.id), verticalAlign: "middle", fontSize: "1.4em" }}
                    title={allergen.name || getAllergenName(allergen.id)}
                ></i>
            ))}
        </div>
    ) : null;

    const price = pricesIncludeTax ? product?.priceWithTax : product?.price;

    // Sum of units in cart for the current product
    const sumProductUnitsInOrder = inCartSumQuantity || 0;
    // Sum of units in cart, except the current product
    const sumOtherUnitsInOrder =
        (cart?.products?.reduce((sum, p) => sum + p?.quantity, 0) || 0) - (sumProductUnitsInOrder || 0);
    // Limit of units per order (current product)
    const maxProductUnitsPerOrder = product?.maxUnitsPerOrder || 0;
    // Limit of units per order (all products)
    const maxAllUnitsPerOrder = maxProductsPerOrder || 0;
    // Determine max units
    const orderLimitToAdd =
        maxAllUnitsPerOrder > 0 ? maxAllUnitsPerOrder - sumOtherUnitsInOrder - sumProductUnitsInOrder : null;
    const productLimitToAdd =
        maxProductUnitsPerOrder > 0 ? maxProductUnitsPerOrder - (sumProductUnitsInOrder || 0) : null;
    let maxUnitsToAdd = null;
    let asteriskMessage;

    if (productLimitToAdd !== null && orderLimitToAdd !== null) {
        maxUnitsToAdd = Math.min(productLimitToAdd, orderLimitToAdd);
        if (orderLimitToAdd < productLimitToAdd) {
            asteriskMessage = t("limit of products per order reached");
        } else {
            asteriskMessage = t("limit of product units reached");
        }
    } else if (productLimitToAdd !== null) {
        maxUnitsToAdd = productLimitToAdd;
        asteriskMessage = t("limit of product units reached");
    } else if (orderLimitToAdd !== null) {
        maxUnitsToAdd = orderLimitToAdd;

        asteriskMessage = t("limit of products per order reached");
    }
    if (quantity != inCartQuantity) {
        asteriskMessage = null;
    }
    if (!asteriskMessage && requiredPending) {
        asteriskMessage = t("select the required options");
    }

    const styles = {
        section: {
            fontSize: size(1.4),
            color: "#323232",
            backgroundColor: "#FBFBFB",
            marginTop: size(0.75),
            paddingTop: size(1),
            paddingLeft: size(1),
            paddingRight: size(1),
            paddingBottom: size(1),
        },
    };

    const showAllergens = () => {
        swal({
            title: t("allergens"),
            buttons: {
                cancel: t("close"),
            },
            content: (
                <div>
                    {allergens
                        ? allergens.map((allergen) => (
                              <div key={`modal-allergen-${allergen.id}`} className="p-2 text-left">
                                  <i
                                      className={`icon ${getAllergenIcon(allergen.id)} pr-2`}
                                      style={{
                                          color: getAllergenColor(allergen.id),
                                          verticalAlign: "middle",
                                          fontSize: "1.4em",
                                      }}
                                  ></i>{" "}
                                  {allergen.name || getAllergenName(allergen.id)}
                              </div>
                          ))
                        : null}
                </div>
            ),
        });
    };

    const finalPrice = (price + totalExtras) * quantity;
    let buttonText = t("add x for price", {
        count: quantity,
        price: printPrice(finalPrice, currencyCode, lang),
    });
    if (inCart) {
        buttonText = t("update");
    } else if (maxUnitsToAdd === 0) {
        buttonText = t("add");
    }

    const minValue = inCart ? 0 : Math.min(1, maxUnitsToAdd === null ? 1 : maxUnitsToAdd);
    const maxValue = inCart && maxUnitsToAdd !== null ? maxUnitsToAdd + inCartQuantity : maxUnitsToAdd;

    return (
        <>
            {loading ? <Loading /> : null}
            {error ? <ErrorMessage type="connection" error={error} /> : null}
            {!loading && product ? (
                <>
                    {product.images?.length > 0 ? (
                        <>
                            <div
                                {...handlers}
                                style={{
                                    backgroundColor: "#FBFBFB",
                                    backgroundImage: `url(${product.images[activeCover]})`,
                                    backgroundPosition: "center center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    height: size(16),
                                }}
                            ></div>
                            {product.images.length > 1 ? (
                                <div
                                    style={{
                                        textAlign: "center",
                                        backgroundColor: "#FBFBFB",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "inline-block",
                                            // backgroundColor: details?.fgColor,
                                            borderRadius: ".5rem",
                                            padding: size(0.25) + " " + size(0.5),
                                        }}
                                    >
                                        {product.images.map((v, index) => (
                                            <span
                                                id={`action-image-${index + 1}`}
                                                key={`dot_${v}`}
                                                style={{
                                                    display: "inline-block",
                                                    borderWidth: "1px",
                                                    borderStyle: "solid",
                                                    borderColor: details?.bgColor,
                                                    backgroundColor:
                                                        index == activeCover ? details?.bgColor : "transparent",
                                                    width: size(0.75),
                                                    height: size(0.75),
                                                    lineHeight: size(0.75),
                                                    borderRadius: "100%",
                                                    margin: "0 " + size(0.25),
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    setActiveCover(index);
                                                }}
                                            ></span>
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                        </>
                    ) : null}

                    <div
                        style={{
                            ...styles.section,
                            ...(product.images?.length
                                ? {
                                      marginTop: 0,
                                      paddingTop: size(0.5),
                                  }
                                : null),
                        }}
                    >
                        <div>
                            {product.name}
                            <div
                                style={{
                                    marginTop: size(0.5),
                                    marginBottom: size(0.5),
                                    fontWeight: 500,
                                }}
                            >
                                {printPrice(price, currencyCode, lang)}
                            </div>
                            <div style={{ fontSize: size(1.25), paddingBottom: size(1), color: "rgb(118,136,155)" }}>
                                {product.description}
                            </div>
                        </div>
                        {listTags}
                        {allergens?.length > 0 ? (
                            <div
                                id="action-allergens"
                                onClick={showAllergens}
                                style={{
                                    fontSize: size(1.25),
                                    lineHeight: size(3),
                                    cursor: "pointer",
                                }}
                            >
                                <i
                                    className="icon icon-info-outline pr-2"
                                    style={{ color: "#76889B", verticalAlign: "middle", fontSize: "1.4em" }}
                                ></i>
                                <span>{t("allergens")}: </span>
                                {listAllergens}
                            </div>
                        ) : null}
                    </div>

                    {extras?.length > 0
                        ? extras.map((extra, index) => {
                              return (
                                  <ShopExtra
                                      id={`extra-${index + 1}`}
                                      key={`extra-${extra.id}`}
                                      style={styles.section}
                                      onChange={(success, value, price, quantity) => {
                                          const newSelections = {
                                              ...selections,
                                              [extra.id]: {
                                                  type: extra.type,
                                                  name: extra?.name,
                                                  price,
                                                  quantity,
                                                  selections: value,
                                              },
                                          };
                                          if (quantity == 0) {
                                              delete newSelections[extra.id];
                                          }
                                          setSelections(newSelections);
                                          setCompleted({ ...completed, [extra.id]: success });
                                      }}
                                      {...{
                                          lang,
                                          cart: selections[extra.id],
                                          name: extra.name,
                                          type: extra.type,
                                          currencyCode,
                                          min: extra.min,
                                          max: extra.max > 0 ? extra.max : null,
                                          options: extra.choices,
                                          price: extra.price,
                                          priceWithTax: extra.priceWithTax,
                                          pricesIncludeTax,
                                      }}
                                  ></ShopExtra>
                              );
                          })
                        : null}

                    <div style={{ height: size(15) }}></div>

                    <Footer
                        theme={{
                            fgColor: footer?.fgColor,
                            bgColor: footer?.bgColor,
                        }}
                    >
                        {minValue != maxValue ? (
                            <QuantityButtons
                                id={`product-quantity`}
                                min={minValue}
                                max={maxValue}
                                value={quantity}
                                onNewValue={setQuantity}
                                style={{ borderColor: footerBorderColor }}
                                className="mb-4"
                            />
                        ) : null}
                        {asteriskMessage ? (
                            <div
                                className="w-full text-center"
                                style={{
                                    paddingBottom: size(0.5),
                                }}
                            >
                                <span style={{ fontSize: ".9em", color: "rgba(50,50,50,.5)" }}>
                                    {`* ${asteriskMessage}`}
                                </span>
                            </div>
                        ) : null}

                        <div
                            style={{
                                fontSize: size(1.5),
                                fontWeight: "bold",
                                padding: size(0.75) + " " + size(1.5),
                                color: disabled ? "white" : footerButton?.colors?.fgColor,
                                background: disabled ? "rgba(50,50,50,.5)" : footerButton?.colors?.bgColor,
                                borderWidth: footerButton?.border?.width + "px",
                                borderRadius: footerButton?.border?.radius + "rem",
                                borderColor: disabled ? "transparent" : footerButton?.border?.color,
                                cursor: disabled ? "default" : "pointer",
                            }}
                            id={`action-add-to-cart`}
                            onClick={
                                disabled
                                    ? null
                                    : () => {
                                          addToCart({
                                              cartItemID: itemID,
                                              productID,
                                              name: product?.name,
                                              quantity,
                                              price,
                                              maxUnitsPerOrder: product?.maxUnitsPerOrder,
                                              selections,
                                          });
                                          setProductID(null);
                                          setCartItemID(null);
                                      }
                            }
                        >
                            {buttonText}
                        </div>
                    </Footer>
                </>
            ) : null}
        </>
    );
};

export default ShopProduct;
