import gql from "graphql-tag";

const GET_CONTENT_FRAGMENT = `
	grid{
		Cols:cols
		Rows:rows
	}
	ref
	style{
		bgColor,
		duration,
		fgColor,
		images{
			externalUrl,
			libraryRef
		}
		margin{
			W
			H
		},
		itemsMargin{
			W
			H
		},
		opacity
	}
	widgets{
		widgets{
			widgets{
				type
				position{
					X
					Y
				}
				size{
					W
					H
				}
				actions{
					type
					value
				}
				fonts{
					externalUrl
					libraryRef
					name
				}
				style
				config{
					carousel{
						adjust
						duration
						images{
							externalUrl
							libraryRef
						}
					}
					container{
						grid{
							Cols:cols
							Rows:rows
						}
						itemsMargin{
							W
							H
						}
						margin{
							W
							H
						}
					}
					html{
						code{
							id
							text
						}
					}
					icon{
						icon
						lib
						size
					}
					iframe{
						url
					}
					image{
						adjust
						blur
						gradient
						opacity
						src{
							externalUrl
							libraryRef
						}
					}
					qr{
						predefined
						url
					}
					text{
						text{
							id
							text
						}
					}
					video{
						autoplay
						repeat
						video{
							externalUrl
							libraryRef
						}
					}
					weather{
						design
						location{
							country
							id
							name
						}
						unit
					}
					topbar{
						title{
							id
							text
						}
					}
					chatbutton{
						showIcon
						title{
							id
							text
						}
					}
					button{
						adjust
						icon
						image{
							externalUrl
							libraryRef
						}
						lib
						showIcon
						title{
							id
							text
						}
					}
					logo{
						adjust
						blur
						gradient
						opacity
						src{
							externalUrl
							libraryRef
						}
					}
					messages{
						adjust
						icon
						image{
							externalUrl
							libraryRef
							name
						}
						lib
						showIcon
						title{
							id
							text
						}
					}
					wifilogin{
						text{
							id
							text
						}
					}
					slider{
						mode
						reelSize
						items {
						  id
						  visibility
						  name
						  text {
							id
							text
						  }
						  actions {
							type
							value
						  }
						  resource {
							externalUrl
							libraryRef
							name
						  }
						}
					  }
				}
			}
			type
			position{
				X
				Y
			}
			size{
				W
				H
			}
			actions{
				type
				value
			}
			fonts{
				externalUrl
				libraryRef
				name
			}
			style
			config{
				carousel{
					adjust
					duration
					images{
						externalUrl
						libraryRef
					}
				}
				container{
					grid{
						Cols:cols
						Rows:rows
					}
					itemsMargin{
						W
						H
					}
					margin{
						W
						H
					}
				}
				html{
					code{
						id
						text
					}
				}
				icon{
					icon
					lib
					size
				}
				iframe{
					url
				}
				image{
					adjust
					blur
					gradient
					opacity
					src{
						externalUrl
						libraryRef
					}
				}
				qr{
					predefined
					url
				}
				text{
					text{
						id
						text
					}
				}
				video{
					autoplay
					repeat
					video{
						externalUrl
						libraryRef
					}
				}
				weather{
					design
					location{
						country
						id
						name
					}
					unit
				}
				topbar{
					title{
						id
						text
					}
				}
				chatbutton{
					showIcon
					title{
						id
						text
					}
				}
				button{
					adjust
					icon
					image{
						externalUrl
						libraryRef
					}
					lib
					showIcon
					title{
						id
						text
					}
				}
				logo{
					adjust
					blur
					gradient
					opacity
					src{
						externalUrl
						libraryRef
					}
				}
				messages{
					adjust
					icon
					image{
						externalUrl
						libraryRef
						name
					}
					lib
					showIcon
					title{
						id
						text
					}
				}
				wifilogin{
					text{
						id
						text
					}
				}
				slider{
					mode
					reelSize
					items {
					  id
					  visibility
					  name
					  text {
						id
						text
					  }
					  actions {
						type
						value
					  }
					  resource {
						externalUrl
						libraryRef
						name
					  }
					}
				  }
			}
		}
		type
		position{
			X
			Y
		}
		size{
			W
			H
		}
		actions{
			type
			value
		}
		fonts{
			externalUrl
			libraryRef
			name
		}
		style
		config{
			carousel{
				adjust
				duration
				images{
					externalUrl
					libraryRef
				}
			}
			container{
				grid{
					Cols:cols
					Rows:rows
				}
				itemsMargin{
					W
					H
				}
				margin{
					W
					H
				}
			}
			html{
				code{
					id
					text
				}
			}
			icon{
				icon
				lib
				size
			}
			iframe{
				url
			}
			image{
				adjust
				blur
				gradient
				opacity
				src{
					externalUrl
					libraryRef
				}
			}
			qr{
				predefined
				url
			}
			text{
				text{
					id
					text
				}
			}
			video{
				autoplay
				repeat
				video{
					externalUrl
					libraryRef
				}
			}
			weather{
				design
				location{
					country
					id
					name
				}
				unit
			}
			topbar{
				title{
					id
					text
				}
			}
			chatbutton{
				showIcon
				title{
					id
					text
				}
			}
			button{
				adjust
				icon
				image{
					externalUrl
					libraryRef
				}
				lib
				showIcon
				title{
					id
					text
				}
			}
			logo{
				adjust
				blur
				gradient
				opacity
				src{
					externalUrl
					libraryRef
				}
			}
			messages{
				adjust
				icon
				image{
					externalUrl
					libraryRef
					name
				}
				lib
				showIcon
				title{
					id
					text
				}
			}
			wifilogin{
				text{
					id
					text
				}
			}
			slider{
				mode
				reelSize
				items {
				  id
				  visibility
				  name
				  text {
					id
					text
				  }
				  actions {
					type
					value
				  }
				  resource {
					externalUrl
					libraryRef
					name
				  }
				}
			  }
		}
	}
`;

export const GET_HOTELS = {
    query: gql`
        query getProjects($page: Int!, $size: Int!) {
            getProjects(page: $page, size: $size) {
                results {
                    appAvailable
                    ref
                    name
                    location
                    photo
                    mobilePath
                    chatURL
                    chatPublic
                    isBookmarked
                    keyBrand
                    timeZone
                    permissionsConfig {
                        manageGuests
                        landing
                        sales
                        myOrders
                        shopsOrders
                        preCheckIn
                    }
                }
            }
        }
    `,
    defaultVars: { page: 1, size: 1000 },
};

export const GET_HOTEL = {
    query: gql`
        query getProject($ref: ID!, $roomNumber: Int, $password: String) {
            getProject(ref: $ref, roomNumber: $roomNumber, password: $password) {
                name
                designRef
                sales
                mapsGoogle {
                    apiKey
                }
                permissionsConfig {
                    manageGuests
                    landing
                    sales
                    myOrders
                    shopsOrders
                    preCheckIn
                }
                hasOnPremisesInterface
                VODDomain
                landing {
                    ref
                    style {
                        images {
                            externalUrl
                            libraryRef
                            name
                        }
                        bgColor
                    }
                }
                theme {
                    colors {
                        defaultBackground
                        defaultForeground
                        activeBackground
                        activeForeground
                        focusBackground
                        focusForeground
                    }
                    fonts {
                        heading {
                            alignment
                            bold
                            font {
                                name
                                externalUrl
                                libraryRef
                            }
                            italic
                            size
                            underline
                        }
                        paragraph {
                            alignment
                            bold
                            font {
                                name
                                externalUrl
                                libraryRef
                            }
                            italic
                            size
                            underline
                        }
                    }
                    logos {
                        imageRef
                        type
                        version
                    }
                }
                cuiner {
                    appId
                    token
                    url
                    user
                }
            }
        }
    `,
};

export const GET_PORTAL = {
    query: gql`
        query getProject($ref: ID!) {
            getProject(ref: $ref) {
				name
				designRef
                VODDomain
				theme{
					colors{
						defaultBackground
						defaultForeground
						activeBackground
						activeForeground
						focusBackground
						focusForeground
					}
					fonts{
						heading{
							alignment
							bold
							font{
								name
								externalUrl
								libraryRef
							}
							italic
							size
							underline
						}
						paragraph{
							alignment
							bold
							font{
								name
								externalUrl
								libraryRef
							}
							italic
							size
							underline
						}
					}
					logos{
						imageRef
						type
						version
					}
				}
				portal{
					accessConditions
					privacyPolicy
					askMarketing
					authGoogle{
						web {
							clientID
						}
					}
					desktop{
						${GET_CONTENT_FRAGMENT}
					}
					mobile{
						${GET_CONTENT_FRAGMENT}
					}
				}
            }
        }
    `,
};

export const GET_CONTENT = {
    query: gql`
        query getContent($ref: ID!,$designRef: String!, $hotelRef: ID!, $roomNumber: Int, $password: String) {
            getContent(ref: $ref, designRef: $designRef, hotelRef: $hotelRef, roomNumber: $roomNumber, password: $password) {
				${GET_CONTENT_FRAGMENT}
            }
        }
    `,
};
