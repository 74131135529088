import {
    GET_STAY_BOOKING_BY_CODE,
    GET_PRECHECKIN_SETTINGS_STAY_BOOKINGS,
    ADD_STAY_BOOKING_GUEST,
    UPDATE_STAY_BOOKING_GUEST,
    SEND_STAY_BOOKING_PENDING_VALIDATION,
    UPDATE_STAY_BOOKING,
} from "./queries/stays";
import { useQuery, useMutation } from "./utils";
import React, { useEffect } from "react";
import { getSelectedHotel, getHotel } from "actions/hotel";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const defaultCache = {
    maxAge: 60000, // 1 minute
    fetchPolicy: "cache-first",
};

export const UseStayBookingCode = ({ setData, setLoading }) => {
    const project = useSelector(getSelectedHotel);
    const { code } = useParams();

    const useStayBookingByCode = ({ hotelRef, codes }) => {
        const query = useQuery({
            query: GET_STAY_BOOKING_BY_CODE.query,
            defaultVars: {
                hotelRef: hotelRef,
                codes: codes,
            },
        });

        return query;
    };

    const {
        query: loadData,
        refetch: refetchData,
        called: loadDataCalled,
        loading: loadingData,
        error: errorData,
        data: data,
    } = useStayBookingByCode({ hotelRef: project?.ref, codes: [code] });

    useEffect(() => {
        if (!loadDataCalled) {
            loadData();
        }
        if (setLoading) {
            setLoading(true);
        }
    }, []);

    useEffect(() => {
        const dataStayBookings = data?.getStayBookings?.results;
        if (dataStayBookings?.length > 0) {
            setData(dataStayBookings[0]);
            if (setLoading) {
                setLoading(false);
            }
        }
    }, [data]);

    return null;
};

export const UseStayBookings = (values) => {
    const projectRef = useSelector(getHotel);
    const query = useQuery(values);
    return {
        ...query,
        query: (params) => query.query({ hotelRef: projectRef, ...params }),
        data: query?.data?.getStayBookings,
    };
};

export const UsePrecheckinSettingsStayBookings = ({ setData, setLoading, refetch, setRefetch }) => {
    const project = useSelector(getSelectedHotel);
    const { code } = useParams();
    const usePrecheckinSettingsStayBookings = ({ hotelRef, codes }) => {
        const query = useQuery({
            query: GET_PRECHECKIN_SETTINGS_STAY_BOOKINGS.query,
            defaultVars: {
                hotelRef: hotelRef,
                codes: codes,
            },
        });

        return query;
    };
    const {
        query: loadData,
        refetch: refetchData,
        called: loadDataCalled,
        loading: loadingData,
        error: errorData,
        data: data,
    } = usePrecheckinSettingsStayBookings({ hotelRef: project?.ref, codes: [code] });
    useEffect(() => {
        if (!loadDataCalled) {
            loadData();
        }
        setLoading(true);
    }, []);

    useEffect(() => {
        if (refetch && typeof setRefetch === "function") {
            refetchData();
            setRefetch(false);
        }
    }, [refetch]);

    useEffect(() => {
        if (
            data?.getPreCheckInSettings &&
            data?.getPreCheckInSettings?.preCheckInSettings &&
            data?.getPreCheckInSettings?.preCheckInSettingsFields &&
            data?.getStayBookings?.results?.length > 0
        ) {
            setLoading(false);
            setData({
                preCheckInSettings: data?.getPreCheckInSettings?.preCheckInSettings,
                preCheckInSettingsFields: data?.getPreCheckInSettings?.preCheckInSettingsFields,
                getStayBookings: data?.getStayBookings?.results[0],
                preCheckInSettingsFieldsChildren: data?.getPreCheckInSettings?.preCheckInSettingsFieldsChildren,
                preCheckInSettingsCustomFields:
                    data?.getPreCheckInSettings?.preCheckInSettingsCustomFields?.fields || [],
                preCheckInSettingsCustomFieldsChildren:
                    data?.getPreCheckInSettings?.preCheckInSettingsCustomFieldsChildren?.fields || [],
            });
        }
    }, [data]);

    return null;
};

export const useAddStayBookingGuest = ({ hotelRef, stayBookingCode }) => {
    const query = useMutation(
        { query: ADD_STAY_BOOKING_GUEST.query, defaultVars: { hotelRef, stayBookingCode } },
        { fetchPolicy: "network-only" }
    );

    return {
        ...query,
        error: query?.error,
        result: query?.data?.addStayBookingGuest?.ok ? true : false,
        resultId: query?.data?.addStayBookingGuest?.id ? query?.data?.addStayBookingGuest?.id : null,
        // data: order?.errorCode ? null : order,
    };
};

export const useUpdateStayBookingGuest = ({ hotelRef, stayBookingCode, guestID }) => {
    const query = useMutation(
        { query: UPDATE_STAY_BOOKING_GUEST.query, defaultVars: { hotelRef, stayBookingCode, guestID } },
        { fetchPolicy: "network-only" }
    );

    return {
        ...query,
        error: query?.error,
        result: query?.data?.updateStayBookingGuest?.ok ? true : false,
        resultId: query?.data?.updateStayBookingGuest?.id ? query?.data?.updateStayBookingGuest?.id : null,
    };
};

export const useSendStayBookingPendingValidation = ({ hotelRef, code }) => {
    const query = useMutation(
        { query: SEND_STAY_BOOKING_PENDING_VALIDATION.query, defaultVars: { hotelRef, code } },
        { fetchPolicy: "network-only" }
    );

    return {
        ...query,
        error: query?.error,
        result: query?.data?.sendStayBookingPendingValidation?.ok ? true : false,
    };
};

export const useUpdateStayBooking = ({ hotelRef, code, termsAccepted, pendingChangesSend }) => {
    const query = useMutation(
        {
            query: UPDATE_STAY_BOOKING.query,
            defaultVars: { hotelRef, code, termsAccepted, pendingChangesSend },
        },
        { fetchPolicy: "network-only" }
    );

    return {
        ...query,
        error: query?.error,
        result: query?.data?.updateStayBooking?.ok ? true : false,
    };
};
