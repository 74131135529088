import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import pkg from "../../package.json";
import { useHotels } from "../graphql/useHotels";
import { postMessageToParent } from "./Design/DesignUtils";
import { getIsEmbedded } from "../actions/config";

const KeyButton = (props) => {
    const isEmbedded = useSelector(getIsEmbedded);
    const [redirectTo, setRedirectTo] = useState("");
    const { loading, error, hotels } = useHotels({ autoload: true });

    const goToKeys = () => {
        if (isEmbedded) {
            postMessageToParent({
                action: "ensureUser",
                params: {
                    page: "/keys",
                },
            });
        } else {
            setRedirectTo("/keys");
        }
    };

    var keyHotels = [];
    if (hotels && hotels.filter) {
        keyHotels = hotels.filter((hotel) => hotel && hotel.keyBrand);
    }

    const hasHotels = !loading && !error && hotels && keyHotels.length > 0;
    const showKeys = pkg.features.keys && hasHotels;

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    return (
        <>
            {showKeys ? (
                <button onClick={() => goToKeys()} className="btn-key" id="go-to-key-view-button">
                    <i className="icon icon-key"></i>
                </button>
            ) : null}
        </>
    );
};

export default withRouter(KeyButton);
