import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "./main.css";
import useDeviceToken from "./graphql/useDeviceToken";
import ErrorPage from "./components/ErrorPage";
import Loading from "./components/Loading";
import { defaultTheme, applyTheme } from "./Theme";
import useNetwork from "./hooks/useNetwork";
import { useTranslation } from "react-i18next";
import { getAssetsUrl, isReady } from "./actions/config";
import { isWifi } from "./utils/config";
import { decodePortalSessionToken } from "./actions/portal";

const searchParams = new URLSearchParams(window.location.search);
const paramToken = searchParams ? searchParams.get("token") : null;
const paramTokenData = decodePortalSessionToken(paramToken);
const regenerateToken = paramToken ? true : false;
if (window.appConfig && !window.appConfig.chainRef && paramTokenData) {
    window.appConfig.chainRef = paramTokenData.chainRef;
}

function Auth(props) {
    const { i18n } = useTranslation();
    const i18nLang = (i18n.language || "").match(/^[^-]+/)[0];
    const config = window.appConfig;
    const wifiPortal = isWifi();
    const isValidDomain = isReady() ? true : false;

    const isOnline = useNetwork();
    const [loading, tokenError, token] = useDeviceToken(i18nLang, regenerateToken);

    if (config && config.theme) {
        applyTheme(config.theme);
    } else {
        applyTheme(defaultTheme);
    }

    const error = tokenError || !isValidDomain || !isOnline;
    const bgStyle = wifiPortal
        ? { backgroundColor: "white" }
        : { backgroundImage: `url("${getAssetsUrl()}/background.jpg")` };
    let content;
    if (error) {
        if (!isOnline) {
            content = <ErrorPage type="wifi" />;
        } else if (!isValidDomain) {
            content = <ErrorPage type="404" retry={false} />;
        } else {
            content = <ErrorPage type="server" />;
        }
    } else if (loading || !token) {
        content = <Loading />;
    } else {
        content = props.children;
    }

    return (
        <>
            <div
                id="fullContainer"
                className="flex flex-wrap min-h-screen bg-cover bg-no-repeat bg-center bg-neutral"
                style={bgStyle}
            >
                {content}
            </div>
        </>
    );
}

export default Auth;
