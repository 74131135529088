import React, { useEffect, useState } from "react";

import { configToCSS, parseImageConfig } from "../DesignUtils";
import useWidget from "../../../hooks/useWidget";
import useInterval from "../../../hooks/useInterval";

const UseWidgetCarousel = ({ config, id, deviceType }) => {
    const { cfg } = useWidget({
        data: config ? config.carousel : null,
        styles: config ? config.styles : null,
        parseConfig,
    });
    const [active, setActive] = useState(0);

    useEffect(() => {
        const element = document.querySelector(`#${id}`);
        element.parentNode.style.backgroundColor = "transparent";
    }, [id]);

    useInterval(async () => {
        if (cfg.images && cfg.images.length > 1) {
            const next = active + 1 >= cfg.images.length ? 0 : active + 1;
            setActive(next);
        }
    }, cfg.interval * 1000);

    return (
        <div
            className={`flex w-full h-full bg-center bg-no-repeat`}
            id={id}
            style={configToCSS({ ...cfg, bgImage: cfg.images[active] }, deviceType)}
        ></div>
    );
};

const parseConfig = ({ data, styles, lang }) => {
    let images = [];
    if (data.images) {
        data.images.map((img) => {
            images.push({
                src: parseImageConfig(img, lang),
                adjust: data.adjust || "cover",
            });
            return img;
        });
    }
    return {
        images: images,
        interval: data.duration || 30,
        border: {
            color: styles.borderColor,
            size: styles.borderWidth,
            radius: styles.radius,
        },
    };
};

export default UseWidgetCarousel;
