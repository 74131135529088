import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import ErrorMessage from "../../../components/ErrorMessage";
import Tabs from "../../../components/Tabs";
import Header from "../../../components/Header";
import Loading from "../../../components/Loading";
import { getCuinerData } from "../../../actions/cuiner";
import { markdown } from "utils/others";

const Order = () => {
    const { orderRef } = useParams();
    const { t } = useTranslation();
    const cuinerData = useSelector(getCuinerData);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [order, setOrder] = useState(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (cuinerData && cuinerData.orders && cuinerData.orders.length > 0) {
            if (orderRef && cuinerData.orders.filter((o) => o.ref === orderRef).length > 0) {
                setOrder(cuinerData.orders.filter((o) => o.ref === orderRef)[0]);
                setLoading(false);
            } else {
                setError(t("error.unknown"));
                setLoading(false);
            }
        } else {
            setError(t("error.unknown"));
            setLoading(false);
        }
    }, []);

    const _renderItems = () => {
        var response = [];
        if (order && order.items && order.items.length > 0) {
            order.items.map(async (item, index) => {
                var selectedTitles = [];
                if (order.selectedLanguage && item.modifierGroups && item.modifierGroups.length > 0) {
                    item.modifierGroups.map(async (modifieGroup) => {
                        if (modifieGroup.items && modifieGroup.items.length > 0) {
                            modifieGroup.items.map(async (modifieGroupItem) => {
                                if (
                                    modifieGroupItem.cuinerData &&
                                    modifieGroupItem.cuinerData.titles &&
                                    modifieGroupItem.cuinerData.titles[order.selectedLanguage]
                                )
                                    selectedTitles.push(modifieGroupItem.cuinerData.titles[order.selectedLanguage]);
                            });
                        }
                    });
                }
                response.push(
                    <li key={index} className={`w-full py-3 border-${index + 1 >= order.items.length ? "none" : "b"}`}>
                        <div className="flex items-start align-start">
                            <div className="items-start align-start">
                                <span className="bg-neutral-contrast items-center rounded text-sm font-normal p-1 text-neutral mr-2">
                                    x{item.quantity && item.quantity > 0 ? item.quantity : 0}
                                </span>
                            </div>
                            <div className="w-full">
                                <div className="flex justify-between items-start">
                                    <div className="">
                                        <span className="block mb-1">
                                            {order.selectedLanguage &&
                                            item.cuinerData &&
                                            item.cuinerData.titles &&
                                            item.cuinerData.titles[order.selectedLanguage]
                                                ? item.cuinerData.titles[order.selectedLanguage]
                                                : ""}
                                        </span>
                                        {selectedTitles && selectedTitles.length ? (
                                            <span className="block text-neutral-contrast-50 mb-1">
                                                {selectedTitles.join(" - ")}
                                            </span>
                                        ) : order.selectedLanguage &&
                                          item.cuinerData &&
                                          item.cuinerData.descriptions &&
                                          item.cuinerData.descriptions[order.selectedLanguage] ? (
                                            <span className="block text-neutral-contrast-50 mb-1">
                                                {item.cuinerData.descriptions[order.selectedLanguage]}
                                            </span>
                                        ) : null}
                                        <span className="block font-medium">{`${item.total}\u20AC`}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                );
            });
        }
        return <ul>{response}</ul>;
    };

    return (
        <section className="basic has-tabs has-top">
            <Header title={t("order completed")}></Header>
            <section style={{ paddingLeft: 0, paddingRight: 0 }}>
                {loading ? <Loading /> : null}
                {!loading && error ? <ErrorMessage type="server" error={error} /> : null}
                {!loading && !error ? (
                    <>
                        <div className="w-full p-4 bg-white mb-3">
                            <span className="block mb-2">
                                {markdown(t("you will receive your order", { room: order ? order.room : 0 }))}
                            </span>
                        </div>
                        <div className="w-full p-4 bg-white mb-3">
                            <h3 className="block text-lg font-bold border-b mb-2">{t("your order")}</h3>
                            <span className="block mb-2">
                                {t("x products in your order", {
                                    count: order && order.items ? order.items.length : 0,
                                })}
                            </span>
                            {_renderItems()}
                        </div>
                        <div className="w-full p-4 bg-white mb-3">
                            <h3 className="block text-lg font-bold border-b mb-2">{t("summary")}</h3>
                            <div className="w-full px-2">
                                <div className="flex justify-between items-center my-3">
                                    <span className="">{t("products")}</span>
                                    <span className="font-bold">{`${
                                        order && order.summary ? order.summary.subTotal : 0
                                    }\u20AC`}</span>
                                </div>
                                <div className="flex justify-between items-center my-3 border-b">
                                    <span className="">
                                        {t("vat")}{" "}
                                        <span className="text-neutral-contrast-50 text-sm">
                                            ({t("included in the price")})
                                        </span>{" "}
                                    </span>
                                    <span className="font-bold">{`${
                                        order && order.summary ? order.summary.taxes : 0
                                    }\u20AC`}</span>
                                </div>
                                <div className="flex justify-between items-center my-3">
                                    <span className="font-bold">{t("total")}</span>
                                    <span className="font-bold">{`${
                                        order && order.summary ? order.summary.total : 0
                                    }\u20AC`}</span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full p-4 bg-white mb-3">
                            <h3 className="block text-lg font-bold border-b mb-2">{t("comments")}</h3>
                            <span className="block mb-2">{order && order.comments ? order.comments : ""}</span>
                        </div>
                    </>
                ) : null}
            </section>
            <Tabs hideKey={true} />
        </section>
    );
};

export default Order;
