import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import useIsMountedRef from "../hooks/useIsMountedRef";
import { getAppRef, getChainRef, setToken, getToken } from "../actions/config";
import { DEVICE_TOKEN } from "./mutations/auth";
import { appUID } from "../utils/others";
import handleGraphqlError from "./handleGraphqlError";
import { getToken as getUserToken } from "../actions/user";

const useDeviceToken = (deviceLang, force = false) => {
    const appToken = useSelector(getToken);
    const userToken = useSelector(getUserToken);
    const token = userToken ? userToken : appToken;

    const dispatch = useDispatch();
    const uid = useSelector((state) => state.uid);
    const appRef = getAppRef();
    const chainRef = getChainRef();
    const mounted = useIsMountedRef();

    const [deviceToken, { loading, error }] = useMutation(DEVICE_TOKEN.query, {
        context: {
            timeout: 10000,
        },
        onCompleted: (data) => {
            if (data && data.deviceToken && data.deviceToken.token) {
                dispatch(setToken(data.deviceToken.token));
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
        },
    });

    useEffect(() => {
        if ((!token || force) && appRef) {
            deviceToken({
                variables: {
                    app: appUID(),
                    device: uid,
                    language: deviceLang,
                    domain: appRef,
                    chainRef: chainRef,
                },
            });
        }
    }, [deviceToken, uid, token, appRef, deviceLang, chainRef]);

    if (token) {
        return [false, null, token];
    }
    if (!mounted.current || !appRef) {
        return [true, null, token];
    }
    return [loading, error, token];
};

export default useDeviceToken;
