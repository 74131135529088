import React from "react";
import { Link, useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import Tabs from "../components/Tabs";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";

const Verify = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const locationAddRoom = matchPath(location.pathname, {
        path: "/site/addroom",
    });
    const locationBooking = matchPath(location.pathname, {
        path: "/site/booking",
    });
    const locationZones = matchPath(location.pathname, {
        path: "/site/zones",
    });
    const locationStays = matchPath(location.pathname, {
        path: "/stays",
    });
    const locationPrecheckin = matchPath(location.pathname, {
        path: "/precheckin",
    });
    const locationLanding = matchPath(location.pathname, {
        path: "/site",
    });
    const locationCuiner = matchPath(location.pathname, {
        path: "/pos",
    });

    const title = locationBooking
        ? t("my hotel booking")
        : locationZones
        ? t("areas & activities")
        : locationStays
        ? t("stays")
        : locationPrecheckin
        ? t("precheckin")
        : locationAddRoom
        ? t("add room")
        : locationLanding
        ? t("landing")
        : "";

    const desc = locationBooking
        ? t("verify description booking")
        : locationZones
        ? t("verify description reservation")
        : locationStays
        ? t("verify description stay")
        : locationPrecheckin
        ? t("verify description precheckin")
        : locationAddRoom
        ? t("verify description room")
        : locationLanding
        ? t("verify description landing")
        : "";

    return (
        <section className="basic has-top has-tabs">
            <Header title={title} />
            <section>
                <p className="text-center text-lg font-bold mt-3 mb-8">{t("verify title")}</p>
                <p className="text-center mt-3 mb-8">{desc}</p>
                <Link to="verify/email" className="btn btn-accent block mx-auto my-5 w-full text-lg">
                    {t("verify account")}
                </Link>
            </section>
            <Tabs />
        </section>
    );
};

export default Verify;
