import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getSiteName } from "../actions/config";
import Header from "../components/Header";
import Tabs from "../components/Tabs";
import swal from "@sweetalert/with-react";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

const DeleteAccount = () => {
  const { t } = useTranslation();
  const [redirectTo, setRedirectTo] = useState("");
  const siteName = getSiteName();
  const deleteWord = t("delete word");

  const {
    register,
    handleSubmit,
    watch
  } = useForm();

  const onSubmit = (data) => {
    if (data.deleteAccount === deleteWord) {
      swal.close();
      toast(
        t("account inactive")
      );
      setRedirectTo("/signout");
    }
  };

  const enterDeleteCode = () => {
    swal({
      className: "custom",
      button: false,
      content: (
        <div>
          <div className="alert-title border-none">
            <span>{t("delete account")}</span>
          </div>
          <div className="my-3 px-8 text-left">
            <span>{t("delete account last step info 1")}</span>
          </div>
          <div className="mt-4">
            <span><Trans i18nKey="delete account last step info 2">
                Locale <strong title={t('deleteWord')}>{{deleteWord}}</strong>
            </Trans></span>
          </div>
          
          <form 
            onSubmit={handleSubmit(onSubmit)}
            className={"mt-8 mb-4"}
          >
            <div className="my-3 px-8">
              <div
                className={
                  "float-left text-sm pl-1 pb-1" + (!watch("deleteAccount") && "invisible")
                }
              >
              </div>
              <input 
                ref={register({
                  required: t("required input"),
                })}
                type="text"
                name="deleteAccount"
                autoComplete="off"
              />
            </div>
            <div className="flex justify-between mt-5">
              <button
                type="button"
                className="flex-1 mr-1 btn text-lg font-normal"
                onClick={() => swal.close()}
              >
                {t("cancel")}
              </button>
              <button
                type="submit"
                className="flex-1 mr-1 btn text-lg"
              >
                {t("delete")}
              </button>
            </div>
          </form>
        </div>
      ),
    });
  };

  if (redirectTo) {
    return <Redirect push to={redirectTo} />;
  }

  return (
    <section className="basic has-tabs has-top">
      <Header title={t("delete account")} />
      <section>
        <p>
            <Trans i18nKey="delete account with word">
                Locale <strong title={t('siteName')}>{{siteName}}</strong>
            </Trans>
        </p>
        
        <footer>
          <button
            onClick={() => {
              enterDeleteCode();
            }}
            className="btn btn-accent text-lg w-full mb-3"
          >
            {t("delete account")}
          </button>
        </footer>
      </section>
      <Tabs />
    </section>
  );
};

export default DeleteAccount;