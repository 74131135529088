import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import Color from "zrender/color";
import { size as renderSize } from "zrender/sizes";

import { getDeviceWidth } from "./Design/DesignUtils";
import { ShopContext } from "../contexts/shop";
import { useShopCategories } from "../graphql/shop";

import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";
import TopMenu from "../components/TopMenu";
import ButtonsMenu from "../components/ButtonsMenu";
import ProductsList from "../components/ProductsList";
import Title from "../components/Title";

const ShopProducts = ({ lang, topBarRef }) => {
    const {
        shop,
        categories,
        theme,
        categoryID,
        subCategoryID,
        setCategoryID,
        setSubCategoryID,
        latestProduct,
        latestScrollY,
        setLatestScrollY,
        setProductID,
        pricesIncludeTax,
        currencyCode,
    } = useContext(ShopContext);

    const [automaticScroll, setAutomaticScroll] = useState(true);

    const {
        query: loadCatsLevelTwo,
        called: loadCatsLevelTwoCalled,
        loading: loadingCatsLevelTwo,
        error: loadCatsLevelTwoError,
        data: categorieslevelTwo,
    } = useShopCategories({ shopToken: shop?.token, level: 2 });

    const {
        query: loadCatsLevelThree,
        called: loadCatsLevelThreeCalled,
        loading: loadingCatsLevelThree,
        error: loadCatsLevelThreeError,
        data: categorieslevelThree,
    } = useShopCategories({ shopToken: shop?.token, level: 3 });

    const getCategory = (items, id) => {
        const search = items?.filter((c) => c.id === id);
        return search?.length > 0 ? search[0] : null;
    };

    const tabsCategories = categories;
    const buttonsCategories = categorieslevelTwo?.categories?.filter((c) => c?.parent?.id === categoryID);
    const subcategories = categorieslevelThree.categories;

    const category = getCategory(tabsCategories, categoryID);

    useEffect(() => {
        if (!loadCatsLevelTwoCalled && categoryID) {
            loadCatsLevelTwo();
        }
        if (!loadCatsLevelThreeCalled && subCategoryID) {
            loadCatsLevelThree();
        }
    }, [categoryID, subCategoryID]);

    useEffect(() => {
        if (!categoryID && tabsCategories?.length > 0) {
            goToCategory(tabsCategories[0].id);
        }
    }, [tabsCategories]);

    useEffect(() => {
        if (!subCategoryID && buttonsCategories?.length > 0) {
            setSubCategoryID(buttonsCategories[0].id);
        }
    }, [buttonsCategories]);

    useEffect(() => {
        topBarRef?.current?.setTitle(shop?.name);
        topBarRef?.current?.setBack(
            categories?.length === 1
                ? null
                : () => {
                      setCategoryID(null);
                  }
        );
    }, []);

    useLayoutEffect(() => {
        if (latestScrollY) {
            scrollTo(latestScrollY);
        } else if (latestProduct?.categoryID) {
            scrollTo(`shop_product_${latestProduct?.categoryID}_${latestProduct?.productID}`);
        } else if (subCategoryID) {
            scrollTo(`category_title_${subCategoryID}`);
        }
    }, []);

    const loading = loadingCatsLevelTwo || loadingCatsLevelThree;

    const gradientStart = Color(theme?.style?.items?.status?.active?.sections?.tabs2?.colors?.bgColor)
        .alpha(0.3)
        .string();
    const gradientEnd = Color(theme?.style?.items?.status?.active?.sections?.tabs2?.colors?.bgColor)
        .alpha(0.6)
        .string();

    const size = (v) => renderSize(v, "mobile", getDeviceWidth());

    const listProducts = (products, categoryID) => {
        return products?.length > 0 ? (
            <ProductsList
                items={products?.map((product, index) => {
                    return {
                        index,
                        id: product.id,
                        keyID: `shop_product_${categoryID}_${product.id}`,
                        name: product.name,
                        description: product.description,
                        price: pricesIncludeTax ? product.priceWithTax : product.price,
                        currency: currencyCode,
                        maxUnitsPerOrder: product?.maxUnitsPerOrder,
                        lang,
                        tags: product.tags?.map((tag) => tag.name),
                        coverURL: product.coverURL,
                        style: {
                            tag: {
                                background: theme?.style?.details?.colors?.bgColor,
                                color: theme?.style?.details?.colors?.fgColor,
                            },
                        },
                        onClick: () => {
                            setProductID(product.id, categoryID);
                        },
                    };
                })}
            />
        ) : null;
    };

    const goToCategory = (categoryID) => {
        setSubCategoryID(null);
        setCategoryID(categoryID);
    };

    const goToSubCategory = (subCategoryID) => {
        setSubCategoryID(subCategoryID);
        scrollTo(`category_title_${subCategoryID}`);
    };

    const scrollTo = (to, smooth = true, tries = 0) => {
        if (typeof to === "number") {
            if (window) {
                window.scrollTo({ top: to, ...(smooth ? { behavior: "smooth" } : null) });
                if (to < 1 || window.scrollY > 0) {
                    return;
                }
            }
        } else {
            const element = document.getElementById(to);
            setAutomaticScroll(false);
            if (element) {
                element.scrollIntoView(smooth ? { behavior: "smooth" } : null);
                return;
            }
        }
        if (tries < 6) {
            setTimeout(() => scrollTo(to, smooth, ++tries), 500);
        }
    };

    // When the page scroll changes to the height of a category title, the category id is changed automatically
    useEffect(() => {
        const onScroll = () => {
            setLatestScrollY(window.scrollY);
            clearTimeout(window.scrollTimer);
            window.scrollTimer = setTimeout(() => {
                const titles = document.querySelectorAll(`[id^="category_title_"]`);
                const menu = document.getElementById(`shop-buttons-categories`);
                if (titles && menu) {
                    const visibilityLine = menu.offsetHeight + menu.offsetTop - window.scrollY + 45;
                    const currentPosition = window.scrollY + visibilityLine;
                    let lastVisible;
                    for (const title of titles) {
                        const titlePosition = title.offsetTop;
                        if (titlePosition <= currentPosition) {
                            lastVisible = title.id.replace("category_title_", "");
                        }
                    }
                    if (automaticScroll && lastVisible && lastVisible != subCategoryID) {
                        setSubCategoryID(lastVisible);
                    }
                    setAutomaticScroll(true);
                }
            }, 200);
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [automaticScroll, subCategoryID]);

    const error = loadCatsLevelTwoError || loadCatsLevelThreeError;
    return (
        <>
            {loading ? <Loading /> : null}
            {error ? <ErrorMessage type="connection" error={error} /> : null}

            {!loading ? (
                <div
                    style={{
                        paddingBottom: size(0.7),
                    }}
                >
                    {tabsCategories?.length > 1 ? (
                        <TopMenu
                            items={tabsCategories?.map((category) => {
                                return {
                                    keyID: `shop_cat_${category.id}`,
                                    name: category.name,
                                    selected: categoryID === category.id,
                                    onClick: () => goToCategory(category.id),
                                    style:
                                        categoryID === category.id
                                            ? {
                                                  color: theme?.style?.items?.status?.active?.sections?.tabs1?.colors
                                                      ?.fgColor,
                                                  borderColor:
                                                      theme?.style?.items?.status?.active?.sections?.tabs1?.colors
                                                          ?.fgColor,
                                              }
                                            : null,
                                };
                            })}
                            style={{
                                borderColor: Color(
                                    theme?.style?.items?.status?.default?.sections?.tabs1?.colors?.fgColor
                                )
                                    .alpha(0.2)
                                    .string(),
                                color: theme?.style?.items?.status?.default?.sections?.tabs1?.colors?.fgColor,
                                backgroundColor: theme?.style?.items?.status?.default?.sections?.tabs1?.colors?.bgColor,
                            }}
                        />
                    ) : null}

                    {buttonsCategories?.length > 1 ? (
                        <ButtonsMenu
                            id="shop-buttons-categories"
                            items={buttonsCategories?.map((category, index) => {
                                return {
                                    keyID: `shop_cat_${category.id}`,
                                    name: category.name,
                                    selected: subCategoryID === category.id,
                                    onClick: () => goToSubCategory(category.id),
                                    style: {
                                        color:
                                            subCategoryID === category.id
                                                ? theme?.style?.items?.status?.active?.sections?.tabs2?.colors?.fgColor
                                                : theme?.style?.items?.status?.default?.sections?.tabs2?.colors
                                                      ?.fgColor,
                                        backgroundColor:
                                            subCategoryID === category.id
                                                ? theme?.style?.items?.status?.active?.sections?.tabs2?.colors?.bgColor
                                                : theme?.style?.items?.status?.default?.sections?.tabs2?.colors
                                                      ?.bgColor,
                                        borderColor:
                                            subCategoryID === category.id
                                                ? theme?.style?.items?.status?.active?.sections?.tabs2?.border?.color
                                                : theme?.style?.items?.status?.default?.sections?.tabs2?.border?.color,
                                        borderWidth:
                                            (subCategoryID === category.id
                                                ? theme?.style?.items?.status?.active?.sections?.tabs2?.border?.width
                                                : theme?.style?.items?.status?.default?.sections?.tabs2?.border
                                                      ?.width) + "px",
                                        borderRadius:
                                            theme?.style?.items?.status?.default?.sections?.tabs2?.border?.radius +
                                            "rem",
                                    },
                                };
                            })}
                            style={{
                                position: "sticky",
                                top: (topBarRef?.current?.height() || 0) + "px",
                                backgroundColor: theme?.style?.items?.status?.default?.sections?.tabs2?.colors?.bgColor,
                                paddingTop: size(1),
                                paddingBottom: size(0.25),
                            }}
                        />
                    ) : null}

                    <div
                        style={{
                            marginTop: size(1.4),
                        }}
                    >
                        {category?.containsProducts ? (
                            <div
                                key={`shop_cat_${category.id}`}
                                style={{
                                    marginBottom: size(1.4),
                                }}
                            >
                                {tabsCategories?.length === 1 ? (
                                    <Title
                                        style={{
                                            color: theme?.style?.items?.status?.active?.sections?.tabs2?.colors
                                                ?.fgColor,
                                            backgroundColor:
                                                theme?.style?.items?.status?.active?.sections?.tabs2?.colors?.bgColor,
                                            background: `linear-gradient(180deg, ${gradientStart} 0%, ${gradientEnd} 100%)`,
                                            marginBottom: size(1.5),
                                        }}
                                    >
                                        {category.name}
                                    </Title>
                                ) : null}
                                {listProducts(category?.products, category?.id)}
                            </div>
                        ) : (
                            <>
                                {buttonsCategories?.map((category) => (
                                    <div
                                        key={`shop_cat_${category.id}`}
                                        style={{
                                            marginBottom: size(1.4),
                                        }}
                                    >
                                        {buttonsCategories?.length > 0 ? (
                                            <Title
                                                id={`category_title_${category.id}`}
                                                style={{
                                                    color: theme?.style?.items?.status?.active?.sections?.tabs2?.colors
                                                        ?.fgColor,
                                                    backgroundColor:
                                                        theme?.style?.items?.status?.active?.sections?.tabs2?.colors
                                                            ?.bgColor,
                                                    background: `linear-gradient(180deg, ${gradientStart} 0%, ${gradientEnd} 100%)`,
                                                    marginBottom: size(1.5),
                                                }}
                                            >
                                                {category.name}
                                            </Title>
                                        ) : null}
                                        {category?.containsProducts ? (
                                            listProducts(category?.products, category?.id)
                                        ) : (
                                            <>
                                                {subcategories
                                                    ?.filter((c) => c?.parent?.id === category.id)
                                                    .map((category) => (
                                                        <>
                                                            <div
                                                                className="font-bold"
                                                                style={{
                                                                    fontSize: "1.35rem",
                                                                    paddingBottom: size(0.35),
                                                                    paddingLeft: size(1.4),
                                                                    paddingRight: size(1.4),
                                                                }}
                                                            >
                                                                {category.name}
                                                            </div>
                                                            {category?.containsProducts
                                                                ? listProducts(category?.products, category?.id)
                                                                : null}
                                                        </>
                                                    ))}
                                            </>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default ShopProducts;
