import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { configToCSS, youTube } from "../DesignUtils";
import useWidget from "../../../hooks/useWidget";
import ReactHlsPlayer from "react-hls-player";
import { checkValidURL } from "../../../utils/url";
import { updateVODStatus } from "../../../actions/hotel";
import { getDasUrl } from "../../../actions/config";

const UseWidgetVideo = ({ config, id, deviceType, params }) => {
    const { cfg, project, locationVOD } = useWidget({
        data: config.video,
        styles: config.styles,
        parseConfig,
    });

    const dispatch = useDispatch();
    const VODReady = useSelector((state) => (state.cache.VODReady && project ? state.cache.VODReady[project] : null));
    const [mediaServerReady, SetMediaServerReady] = useState(null);

    const [video, setVideo] = useState({
        ...cfg.video,
        fullScreen: params && params.fullScreen ? params.fullScreen : false,
    });

    useEffect(() => {
        if (mediaServerReady === false && cfg.video && cfg.video.source === "vod") {
            setVideo({ ...video, url: null });
        }
        // eslint-disable-next-line
    }, [mediaServerReady]);

    useEffect(() => {
        const element = document.querySelector(`#${id}`);
        element.parentNode.style.backgroundColor = "transparent";

        if (VODReady != null) {
            const cacheTime = new Date().getTime() - VODReady.date;
            const cacheMax = 60000; // 1 min
            if (cacheTime <= cacheMax) {
                SetMediaServerReady(VODReady.ready);
                return;
            }
        }
        checkValidURL(locationVOD, 2, 500)
            .then((data) => {
                SetMediaServerReady(true);
                let status = {};
                status[project] = true;
                dispatch(updateVODStatus(status));
            })
            .catch((err) => {
                SetMediaServerReady(false);
                let status = {};
                status[project] = false;
                dispatch(updateVODStatus(status));
            });
    }, [VODReady, id, locationVOD, project]);

    const _renderVideoTag = () => {
        let response = [];

        const styles = configToCSS(
            {
                border: cfg.border,
                maxWidth: video.url || video.poster ? "100%" : "",
                maxHeight: video.url || video.poster ? "100%" : "",
            },
            deviceType
        );
        if (video && video.source) {
            switch (video.source) {
                case "youtube":
                    response.push(
                        <iframe
                            key="video"
                            title="video"
                            src={video.url}
                            style={styles}
                            allow={
                                "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" +
                                (video.autoplay ? "; autoplay" : "")
                            }
                            allowFullScreen={video.fullScreen}
                            frameBorder="0"
                            className={`flex w-full h-full items-center justify-center`}
                        ></iframe>
                    );
                    break;
                case "vod":
                default:
                    response.push(
                        <>
                            <ReactHlsPlayer
                                poster={video.poster}
                                src={video.url || "denied"}
                                autoPlay={video.autoplay}
                                muted={video.autoplay}
                                loop={video.repeat}
                                allowFullScreen={video.fullScreen}
                                controls={video.url ? true : false}
                                controlsList={video.fullScreen ? "" : "nofullscreen"}
                                className={`flex w-full h-full items-center justify-center`}
                                style={styles}
                            />
                        </>
                    );
            }
        }
        return response;
    };

    return (
        <div id={id} className={`flex w-full h-full items-center justify-center`}>
            {video.url ? null : (
                <div
                    style={{
                        position: "absolute",
                        textAlign: "center",
                        padding: ".5em",
                        background: "rgba(80,10,0,.8)",
                        color: "rgba(255,255,255,.8)",
                        left: 0,
                        right: 0,
                    }}
                >
                    Currently unable to play this video
                </div>
            )}
            {video.url || video.poster ? _renderVideoTag() : null}
        </div>
    );
};

const parseConfig = ({ data, styles, locationVOD, lang }) => {
    const video = (data.video && data.video.length > 0 ? data.video[0] : null) || {};
    const isYoutube = video && youTube.check(video.externalUrl);

    let videoURL =
        video && isYoutube
            ? new URL(youTube.embedURL(video.externalUrl))
            : video && locationVOD
            ? new URL(locationVOD + `/stream/${video.libraryRef}-${lang}.m3u8`)
            : null;
    if (videoURL && isYoutube) {
        videoURL.searchParams.set("controls", 1);
        videoURL.searchParams.set("showinfo", 0);
    }

    const posterURL = video
        ? isYoutube
            ? youTube.posterURL(videoURL)
            : getDasUrl() + `/thumb/${video.libraryRef}-${lang}?w=800`
        : null;

    return {
        video: videoURL
            ? {
                  source: videoURL ? (isYoutube ? "youtube" : "vod") : null,
                  url: videoURL.toString(),
                  poster: posterURL,
                  autoplay: data.autoplay || false,
                  repeat: data.repeat || false,
              }
            : null,
        border: {
            color: styles.borderColor,
            size: styles.borderWidth,
            radius: styles.radius,
        },
    };
};

export default UseWidgetVideo;
