import React from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { getDasUrl } from "../../actions/config";
import UseWidgetImage from "./Widgets/UseWidgetImage";
import UseWidgetText from "./Widgets/UseWidgetText";
import UseWidgetIcon from "./Widgets/UseWidgetIcon";
import UseWidgetWeather from "./Widgets/UseWidgetWeather";
import UseWidgetVideo from "./Widgets/UseWidgetVideo";
import UseWidgetCarousel from "./Widgets/UseWidgetCarousel";
import UseWidgetIframe from "./Widgets/UseWidgetIframe";
import UseWidgetHtml from "./Widgets/UseWidgetHtml";
import UseWidgetRooms from "./Widgets/UseWidgetRooms";
import UseWidgetChatButton from "./Widgets/UseWidgetChatButton";
import UseWidgetLogo from "./Widgets/UseWidgetLogo";
import UseWidgetButton from "./Widgets/UseWidgetButton";
import UseWidgetMessage from "./Widgets/UseWidgetMessage";
import UseWidgetWifiLogin from "./Widgets/UseWidgetWifiLogin";
import UseWidgetPortal from "./Widgets/UseWidgetPortal";
import useLandingSession from "../../hooks/useLandingSession";
import useWidget from "../../hooks/useWidget";
import { configToCSS, processActions } from "./DesignUtils";
import { useSelector } from "react-redux";
import { getHotel } from "../../actions/hotel";
import UseWidgetSlider from "./Widgets/UseWidgetSlider";

const UseWidgetContainer = ({
    id,
    type,
    currentPosition,
    data,
    nesteds,
    goToLanding,
    goToRoomShop,
    goToMessages,
    goToCuiner,
}) => {
    const { i18n } = useTranslation();
    const { landing } = useLandingSession();
    const projectRef = useSelector(getHotel);
    const lang = (i18n.language || "").match(/^[^-]+/)[0];
    const dasURL = getDasUrl();

    const { cfg } = useWidget({
        data: data && data.config && data.config.container ? data.config.container : null,
        styles: data && data.config && data.config.styles ? data.config.styles : null,
        parseConfig,
    });
    let response = null;
    let link = { target: null, id: null, href: null, onClick: null };
    let dynamicObject = [];

    library.add(fas);
    library.add(fab);

    link = processActions({ data, id, landing, lang, goToLanding, goToCuiner, goToRoomShop, dasURL, link });

    switch (type) {
        case "image":
            response = (
                <UseWidgetImage
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                />
            );
            break;
        case "text":
            response = (
                <UseWidgetText
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                />
            );
            break;
        case "icon":
            response = (
                <UseWidgetIcon
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                />
            );
            break;
        case "weather":
            response = (
                <UseWidgetWeather
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                />
            );
            break;
        case "video":
            dynamicObject["fullScreen"] =
                data.actions.filter((action) => action.type === "action" && action.value === "fullscreen").length > 0;
            response = (
                <UseWidgetVideo
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                    params={dynamicObject}
                />
            );
            break;
        case "carousel":
            response = (
                <UseWidgetCarousel
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                />
            );
            break;
        case "iframe":
            response = (
                <UseWidgetIframe
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                />
            );
            break;
        case "html":
            response = (
                <UseWidgetHtml
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                />
            );
            break;
        case "rooms":
            response = (
                <UseWidgetRooms
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                />
            );
            break;
        case "chatbutton":
            response = (
                <UseWidgetChatButton
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                />
            );
            break;
        case "button":
            response = (
                <UseWidgetButton
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                />
            );
            break;

        case "logo":
            response = (
                <UseWidgetLogo
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                />
            );
            break;
        case "messages":
            link.id = `widget-${id}-messages`;
            link.href = "#";
            link.onClick = goToMessages;
            response = (
                <UseWidgetMessage
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                />
            );
            break;
        case "portal":
            response = (
                <UseWidgetPortal key={"key-widget-" + data.id} config={data.config} deviceType={data.deviceType} />
            );
            break;
        case "wifilogin":
            response = (
                <UseWidgetWifiLogin
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                />
            );
            break;
        case "slider":
            response = (
                <UseWidgetSlider
                    key={"key-widget-" + data.id}
                    config={data.config}
                    id={data.id}
                    deviceType={data.deviceType}
                    data={data}
                    goToSection={{ goToCuiner, goToLanding, goToRoomShop, landing }}
                />
            );
            break;
        case "container":
            dynamicObject = [];
            let itemsMargin = [];
            let currentPositionContainer = { ...currentPosition };

            currentPositionContainer.left = 0.0;
            currentPositionContainer.top = 0.0;
            if (data && data.config && data.config.container) {
                if (data.config.container.margin) {
                    if (data.config.container.margin.W && data.config.container.margin.W > 0.0) {
                        currentPositionContainer.left = data.config.container.margin.W;
                        currentPositionContainer.width =
                            currentPositionContainer.width - currentPositionContainer.left * 2;
                    }
                    if (data.config.container.margin.W && data.config.container.margin.H > 0.0) {
                        currentPositionContainer.top = data.config.container.margin.H;
                        currentPositionContainer.height =
                            currentPositionContainer.height - currentPositionContainer.top * 2;
                    }
                }
                if (data.config.container.itemsMargin) {
                    if (
                        data.config.container.itemsMargin &&
                        data.config.container.itemsMargin.W &&
                        data.config.container.itemsMargin.W > 0.0
                    ) {
                        itemsMargin.paddingLeft = data.config.container.itemsMargin.W;
                        itemsMargin.paddingRight = data.config.container.itemsMargin.W;
                    }
                    if (
                        data.config.container.itemsMargin &&
                        data.config.container.itemsMargin.H &&
                        data.config.container.itemsMargin.H > 0.0
                    ) {
                        itemsMargin.paddingTop = data.config.container.itemsMargin.H;
                        itemsMargin.paddingBottom = data.config.container.itemsMargin.H;
                    }
                }
            }

            if (nesteds && nesteds.length > 0) {
                nesteds.map(async (nested, index) => {
                    if (nested) {
                        let widgetData = {
                            type: nested.type,
                            styles: {
                                position: "absolute",
                                width: 0.0,
                                height: 0.0,
                                left: currentPositionContainer.left,
                                top: currentPositionContainer.top,
                                backgroundColor: "transparent",
                                overflow: `${
                                    nested.type === "text" || nested.type === "messages" ? "visible" : "hidden"
                                }`,
                                fontFamily: "",
                                ...itemsMargin,
                            },
                            data: {
                                style: nested.style,
                                config: { ...nested.config, styles: nested.style, parentCfg: cfg },
                                id: `container-${id}-${nested.type}-${index}`,
                                actions: nested.actions,
                                fonts: nested.fonts,
                                deviceType: data.deviceType,
                            },
                            nesteds: nested.widgets,
                        };
                        let grid = {
                            maxRows: 0,
                            maxCols: 0,
                            widthCols: 0.0,
                            heightRows: 0.0,
                        };
                        if (data && data.config && data.config.container) {
                            if (data.config.container.grid) {
                                if (data.config.container.grid.Rows) grid.maxRows = data.config.container.grid.Rows;
                                if (data.config.container.grid.Cols) grid.maxCols = data.config.container.grid.Cols;
                            }
                        }
                        if (currentPositionContainer.width > 0.0 && grid.maxCols > 0) {
                            grid.widthCols = currentPositionContainer.width / grid.maxCols.toFixed(2);
                        }
                        if (currentPositionContainer.height > 0.0 && grid.maxRows > 0) {
                            grid.heightRows = currentPositionContainer.height / grid.maxRows.toFixed(2);
                        }

                        if (nested.size) {
                            if (nested.size.W) widgetData.styles.width = nested.size.W * grid.widthCols;
                            if (nested.size.H) widgetData.styles.height = nested.size.H * grid.heightRows;
                        }

                        if (nested.position) {
                            if (nested.position.X) widgetData.styles.left += nested.position.X * grid.widthCols;
                            if (nested.position.Y) widgetData.styles.top += nested.position.Y * grid.heightRows;
                        }

                        if (nested.type === "text") {
                            const valueToSum = () => {
                                const heightScreen = window.innerHeight;

                                if (heightScreen <= 700) {
                                    return heightScreen / 100;
                                } else if (heightScreen <= 750) {
                                    return heightScreen / 130;
                                } else if (heightScreen <= 800) {
                                    return heightScreen / 160;
                                } else if (heightScreen <= 850) {
                                    return heightScreen / 190;
                                } else if (heightScreen <= 900) {
                                    return heightScreen / 220;
                                } else {
                                    return -5;
                                }
                            };

                            const containerMarginY =
                                data &&
                                data.config &&
                                data.config.container &&
                                data.config.container &&
                                data.config.container.margin &&
                                data.config.container.margin.H
                                    ? data.config.container.margin.H / 10
                                    : 0;

                            widgetData.styles.height =
                                widgetData.styles.height +
                                widgetData.styles.paddingTop +
                                valueToSum() +
                                containerMarginY;

                            widgetData.styles.width = widgetData.styles.width + widgetData.styles.paddingRight;
                        } else {
                            widgetData.styles.height = widgetData.styles.height + widgetData.styles.paddingTop;
                            widgetData.styles.width = widgetData.styles.width + widgetData.styles.paddingRight;
                        }
                        dynamicObject.push(
                            <div key={index} style={widgetData.styles}>
                                <UseWidgetContainer
                                    id={id + "-child-" + index}
                                    type={widgetData.type}
                                    currentPosition={currentPositionContainer}
                                    data={widgetData.data}
                                    nesteds={widgetData.nesteds}
                                    goToLanding={goToLanding}
                                    goToRoomShop={goToRoomShop}
                                    goToMessages={goToMessages}
                                    goToCuiner={goToCuiner}
                                />
                            </div>
                        );
                    }
                });
            }
            response = (
                <div
                    key={"key-widget-" + data.id}
                    id={`${data.id}`}
                    className="flex w-full h-full"
                    style={configToCSS(cfg, data.deviceType)}
                >
                    {dynamicObject}
                </div>
            );
            break;
        default:
        // ignore
    }
    if (link && link.href) {
        response = (
            <a
                key="link"
                id={link.id}
                href={link.href}
                target={link.target}
                onClick={(e) => {
                    if (link.href === "#") e.preventDefault();
                    if (typeof link.onClick === "function") link.onClick();
                }}
            >
                {response}
            </a>
        );
    }
    return response;
};

const parseConfig = ({ styles, theme }) => {
    const dasURL = getDasUrl();
    return {
        bgColor: styles.bgColor || theme.colors.defaultBackground,
        bgImage: {
            src:
                styles?.images?.length > 0 && styles.images[0].libraryRef
                    ? `${dasURL}/${styles.images[0].libraryRef}`
                    : styles?.images?.length > 0 && styles.images[0].externalUrl,
            adjust: "cover",
            position: "50",
        },
        border: {
            color: styles.borderColor,
            size: styles.borderWidth,
            radius: styles.radius,
        },
    };
};

export default UseWidgetContainer;
