import React, { useEffect } from "react";

import { configToCSS, parseTextConfig, parseSize } from "../DesignUtils";
import useWidget from "../../../hooks/useWidget";
import Parser from "html-react-parser";

const UseWidgetText = ({ config, id, deviceType }) => {
    const { cfg } = useWidget({
        data: config ? config.text : null,
        styles: config ? config.styles : null,
        parentCfg: config ? config.parentCfg : null,
        parseConfig,
    });

    useEffect(() => {
        const element = document.querySelector(`#${id}`);
        element.parentNode.style.backgroundColor = "transparent";
    }, [id]);

    let html = cfg.text;
    if (html) {
        html = html.replace(/font-size:\s*(\d+|[.\d+]+)(\s*vw)/g, function (match, number) {
            return "font-size:" + parseSize(number, deviceType) + "px";
        });
        html = html.replace(/<p (.*?)/g, function () {
            return "<p class='my-0' ";
        });
    }

    return (
        <div className={`w-full h-full overflow-y-visible`} id={id} style={configToCSS(cfg, deviceType)}>
            {html ? Parser(html) : null}
        </div>
    );
};

const parseConfig = ({ data, styles, theme, parentCfg }) => {
    let bgColor = styles.bgColor || theme.colors.defaultBackground;
    if(bgColor && (parentCfg && parentCfg.bgColor === bgColor))bgColor = null;
    return {
        text: parseTextConfig(data.text),
        fgColor: theme.colors.defaultForeground,
        bgColor: bgColor,
        font: (styles.font ? styles.font.name : null) || theme.fonts.paragraph.font.name,
        size: styles.textSize || theme.fonts.paragraph.size,
        padding: styles.padding + "px",
        border: {
            color: styles.borderColor,
            size: styles.borderWidth,
            radius: styles.radius,
        },
    };
};

export default UseWidgetText;
