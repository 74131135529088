import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AUTO_REDIRECT = true;
const REDIRECT_IF_NONE = "http://localhost:3000";

const Mikrotik = () => {
    const searchParams = new URLSearchParams(useLocation().search);
    const action = searchParams.get("action");
    const username = searchParams.get("username");
    const password = searchParams.get("password");
    const dst = searchParams.get("dst");

    useEffect(() => {
        if (AUTO_REDIRECT) window.location.href = dst || REDIRECT_IF_NONE;
    }, []);

    return (
        <section className={"p-10 select-text text-center self-center mx-auto opacity-" + (AUTO_REDIRECT ? "0" : "75")}>
            <section>
                <div className="bg-white p-5 text-black">
                    <h1 className="text-lg font-bold">Mikrotik Simulator</h1>
                    <h1 className="font-bold">{action}</h1>
                    <div className="my-4 break-all">
                        Received params:
                        <dl className="text-sm pt-2">
                            <dt className={"font-bold" + (username ? "" : " text-red-100")}>Username</dt>
                            <dd className="pl-2">{username || "?"}</dd>

                            <dt className={"font-bold" + (password ? "" : " text-red-100")}>Password</dt>
                            <dd className="pl-2">{password || "?"}</dd>

                            <dt className={"font-bold" + (dst ? "" : " text-red-100")}>Dst</dt>
                            <dd className="pl-2">{dst || "?"}</dd>
                        </dl>
                    </div>
                    {!AUTO_REDIRECT ? (
                        <>
                            <a href={"javascript:history.back()"} className="btn btn-primary mr-2">
                                <i className="icon icon-back"></i>
                            </a>
                            <a href={dst || REDIRECT_IF_NONE} className="btn btn-accent px-10">
                                Continue
                            </a>
                        </>
                    ) : null}
                </div>
            </section>
        </section>
    );
};

export default Mikrotik;
