import React, { useContext, useEffect, useState } from "react";
import Tabs from "../components/Tabs";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateNotification } from "../graphql/useNotifications";
import { isToday } from "../i18n";
import { useHotels } from "../graphql/useHotels";
import { Redirect } from "react-router";
import { setSelectedRoom, getHotels, setSelectedHotel } from "../actions/hotel";
import { getAllRooms } from "../modules/rooms/store";
import { MessagesContext } from "../contexts/messages";
import { NotificationContext, NOTIF_KIND_ACCOUNT, NOTIF_ACTION_VERIFY } from "../contexts/notifications";

const Notifications = () => {
    const { t } = useTranslation(["interface"]);
    const dispatch = useDispatch();
    const projects = useSelector(getHotels);
    const rooms = useSelector(getAllRooms);

    const { notifications, loading: notifsLoading, error: notifsError } = useContext(NotificationContext);
    const { messagesFor, loading: messagesLoading, error: messagesError } = useContext(MessagesContext);

    const { hotels } = useHotels({ autoload: true });
    const [redirectTo, setRedirectTo] = useState("");

    const { updateNotification } = useUpdateNotification();

    const loading = messagesLoading || notifsLoading;
    const error = messagesError || notifsError;

    useEffect(() => {
        if (error) {
            console.error(error);
        }
    }, [error]);

    const sortNotifications = (a, b) => {
        const adate = new Date(a.dateTime).getTime();
        const bdate = new Date(b.dateTime).getTime();
        if (adate > bdate) {
            return 1;
        } else if (adate < bdate) {
            return -1;
        }
        return 0;
    };

    const parseNotif = (notification) => {
        var item = {
            ...notification,
        };
        item.highlight = !notification.isRead;
        item.today = false;
        item.hour = null;
        if (notification && notification.dateTime) {
            const msgDate = new Date(notification.dateTime);
            item.today = isToday(msgDate);
            item.hour = msgDate.getHours() + ":" + msgDate.getMinutes();
        }
        if (notification.kind === NOTIF_KIND_ACCOUNT && notification.operation === NOTIF_ACTION_VERIFY) {
            item.highlight = !notification.isCompleted;
        }

        if (hotels && item.projectRef) {
            item.project = hotels.filter((n) => {
                if (n.ref === item.projectRef) {
                    return n;
                }
                return false;
            })[0].name;
        }

        return item;
    };

    const goToMessages = (projectRef, roomNumber) => {
        dispatch(setSelectedHotel(projectRef));
        dispatch(setSelectedRoom(roomNumber));
        setRedirectTo(`/messages`);
    };

    const _renderNotifications = () => {
        const response = [];
        if (notifications && notifications.length > 0) {
            notifications.sort(sortNotifications).map((message, index) => {
                const n = parseNotif(message);
                response.push(
                    <li
                        key={index}
                        id={`notif-${index + 1}`}
                        onClick={() => {
                            if (!n.isRead) {
                                updateNotification({
                                    ref: n.ref,
                                    isCompleted: n.isCompleted,
                                    isRead: true,
                                });
                            }
                        }}
                        style={{ cursor: n.isRead ? "default" : "pointer" }}
                        className={n.highlight ? "selected" : ""}
                    >
                        <div className="title">{n.title}</div>
                        <div className="body">{n.description}</div>
                        <div>
                            <small className="date">
                                {n && n.dateTime
                                    ? n.today
                                        ? t("today at", { hour: n.hour })
                                        : t("{{date, date}}", {
                                              date: n.dateTime,
                                          })
                                    : "-"}
                            </small>
                            <div className="hotel">{n.project}</div>
                        </div>
                    </li>
                );
            });
        }
        return <ul className="notif-list">{response}</ul>;
    };

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    return (
        <section className="hotels notifications has-tabs">
            <section>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {(projects || []).map((project) => {
                            const projectMsgs = messagesFor(project?.ref);
                            if (projectMsgs) {
                                return Object.keys(rooms?.[project.ref] || {}).map((roomNumber) => {
                                    const roomMsgs = messagesFor(project?.ref, roomNumber);
                                    if (roomMsgs) {
                                        return (
                                            <div className="w-full border shadow-md text-center p-5 my-2 bg-neutral">
                                                <button
                                                    id={`messages-room-${roomNumber}`}
                                                    onClick={() => {
                                                        goToMessages(project.ref, roomNumber);
                                                    }}
                                                    className="flex justify-between w-full"
                                                >
                                                    <span className="text-accent text-lg flex align-middle">
                                                        {roomMsgs.some((m) => !m.isRead) ? (
                                                            <>
                                                                <i className="icon icon-mail-unread-badge absolute text-3xl text-red-100"></i>
                                                                <i className="icon icon-mail-unread text-3xl mr-5"></i>
                                                            </>
                                                        ) : (
                                                            <i className="icon icon-mail text-3xl mr-5"></i>
                                                        )}
                                                        {t("room x", { room: roomNumber })}
                                                    </span>
                                                    <span className="text-accent font-bold text-lg">
                                                        {project.name}
                                                    </span>
                                                </button>
                                            </div>
                                        );
                                    }
                                });
                            }
                            return null;
                        })}

                        {notifications?.length > 0 ? (
                            _renderNotifications()
                        ) : (
                            <p className="notif-none">{t("dont have notifications")}</p>
                        )}
                    </>
                )}
            </section>
            <Tabs />
        </section>
    );
};

export default Notifications;
