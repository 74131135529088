import React, { useState } from "react";
import Header from "../components/Header";
import Tabs from "../components/Tabs";
import { useTranslation } from "react-i18next";
import isoCountries from "../utils/countries";
import { getNationalitiesOptions } from "utils/nationalities";

const NationalitiesSearch = ({ setNationalitiesSearch, onChange }) => {
    const { t } = useTranslation();

    const [search, setSearch] = useState("");

    return (
        <section className="basic has-tabs has-top">
            <Header backAction={() => setNationalitiesSearch(false)}>
                <input
                    type="text"
                    className="search-top primary rounded"
                    placeholder={t("search-nationality")}
                    autoFocus={true}
                    onChange={(e) => {
                        if (typeof e.target.value === "string") {
                            setSearch(e.target.value);
                        }
                    }}
                />
            </Header>
            <section>
                <ul id="result-list">
                    {getNationalitiesOptions()
                        .filter((n) => search === "" || n.name.toLowerCase().includes(search.toLowerCase()))
                        .map((n, index) => {
                            return (
                                <li
                                    key={index}
                                    id={`nationlity-list-${n.id}`}
                                    onClick={() => {
                                        if (onChange) {
                                            onChange(n);
                                            setNationalitiesSearch(false);
                                        }
                                    }}
                                    className="border-b text-left"
                                >
                                    <button
                                        id={`nationlity-button-${n.id}`}
                                        className="flex justify-between w-full py-3"
                                    >
                                        <span>{n.name}</span>
                                    </button>
                                </li>
                            );
                        })}
                </ul>
            </section>

            {/* <Tabs /> */}
        </section>
    );
};

export default NationalitiesSearch;
