import React, { useEffect, useRef, useState, useCallback } from "react";
import Loading from "./Loading";
import ErrorPage from "./ErrorPage";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { queryString } from "../utils/url";
import useNetwork from "../hooks/useNetwork";
import useTimeout from "../hooks/useTimeout";
import { getToken, getUserData } from "../actions/user";

const Webview = (props) => {
    const webview = useRef();
    const isOnline = useNetwork();
    const [iframeReady, setIframeReady] = useState(false);
    const [iframeError, setIframeError] = useState("");
    const { i18n } = useTranslation();
    const i18nLang = (i18n.language || "").match(/^[^-]+/)[0];

    const user = useSelector(getUserData);
    const appToken = useSelector((state) => (state.cache && state.cache.auth ? state.cache.auth.token : null));
    const userToken = useSelector(getToken);
    const token = userToken ? userToken : appToken;

    let style = { height: "100vh" };
    if (props.topBar && props.bottomBar) {
        style.height = "calc(100vh - 7.5rem)";
    } else if (props.topBar) {
        style.height = "calc(100vh - 3.5rem)";
    } else if (props.bottomBar) {
        style.height = "calc(100vh - 4rem)";
    }
    if (props.style) {
        style = { ...style, ...props.style };
    }

    let className = "bg-neutral w-full";
    if (props.topBar) {
        className += " mt-14";
    }
    if (!iframeReady) {
        className += " hidden";
    }
    if (props.className) {
        className += ` ${props.className}`;
    }

    let src = props.url;
    if (props.addParams && props.addParams.length > 0) {
        const iframeParams = {};
        props.addParams.forEach((param) => {
            let value = undefined;
            switch (param) {
                case "lang":
                    value = i18nLang;
                    break;
                case "token":
                    value = token;
                    break;
                case "logged":
                    value = user && user.emailVerified;
                    break;
                case "embedded":
                    value = "";
                    break;
                case "appType":
                    value = window?.appConfig?.appType?.toUpperCase() || "PWA";
                    break;
                default:
                    value = undefined;
            }
            if (value !== undefined) {
                iframeParams[param] = value;
            }
            return param;
        });
        if (Object.entries(iframeParams).length > 0) {
            src += src && src.match(/\?/) ? "&" : "?";
            src += queryString(iframeParams);
        }
    }

    const handleOnLoad = useCallback(
        (e) => {
            if (props.verifyLocation) {
                try {
                    const doc = webview.current.contentDocument || webview.current.contentWindow.document;
                    if (props.verifyLocation(doc.location)) {
                        setIframeReady(true);
                    }
                } catch (error) {
                    console.error(error);
                    setIframeReady(true);
                }
            } else {
                setIframeReady(true);
            }

            try {
                const doc = webview.current.contentDocument || webview.current.contentWindow.document;
                if (doc.length === 0) {
                    console.error("iframe return empty body");
                }
            } catch (error) {
                console.error(error);
            }
        },
        [props]
    );

    useEffect(() => {
        const wv = webview.current;
        if (wv) {
            wv.addEventListener("load", handleOnLoad);
        }
        return () => {
            if (wv) {
                wv.removeEventListener("load", handleOnLoad);
            }
        };
    }, [handleOnLoad]);

    useTimeout(() => {
        if (!iframeReady) {
            console.error("Timeout error, trying to load page for 15 seconds");
            setIframeReady(true);
            setIframeError("server");
        }
    }, 15000);

    return (
        <>
            {!iframeReady && (
                <section className="basic">
                    <Loading />
                </section>
            )}
            {iframeError !== "" && <ErrorPage type={!isOnline ? "wifi" : iframeError} />}

            {!iframeError && (
                <iframe ref={webview} title={props.title} src={src} className={className} style={style}></iframe>
            )}
        </>
    );
};
export default Webview;
