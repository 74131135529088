import React, { useEffect } from "react";

import { configToCSS } from "../DesignUtils";
import useWidget from "../../../hooks/useWidget";

const UseWidgetIframe = ({ config, id, deviceType }) => {
    const { cfg } = useWidget({
        data: config.iframe,
        styles: config.styles,
        parseConfig,
    });

    useEffect(() => {
        const element = document.querySelector(`#${id}`);
        element.parentNode.style.backgroundColor = "transparent";
    }, [id]);

    return (
        <iframe
            id={id}
            title="widgetFrame"
            className="w-full h-full"
            style={configToCSS({ border: cfg.border }, deviceType)}
            src={cfg.url}
        ></iframe>
    );
};

const parseConfig = ({ data, styles }) => {
    return {
        url: data.url,
        border: {
            color: styles.borderColor,
            size: styles.borderWidth,
            radius: styles.radius,
        },
    };
};

export default UseWidgetIframe;
