import React from "react";
import crypto from "crypto";
import { useServiceWorker } from "./hooks/useServiceWorker";
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
import ApolloLinkTimeout from "apollo-link-timeout";
import { setContext } from "apollo-link-context";
import { CachePersistor } from "apollo-cache-persist";

import { useSelector } from "react-redux";
import { getToken, getApiUrl, getVendureShopAPIUrl, getVendureAdminAPIUrl } from "./actions/config";
import { getToken as getUserToken } from "actions/user";
import { storagePrefix } from "localStorage";

export const CLIENT_ZAFIRO_MOBILE = "zafiroMobile";
export const CLIENT_VENDURE_SHOP = "vendureShop";
export const CLIENT_VENDURE_ADMIN = "vendureAdmin";

const API_CLIENTS = [
    {
        id: CLIENT_ZAFIRO_MOBILE,
        url: getApiUrl(),
    },
    {
        id: CLIENT_VENDURE_SHOP,
        url: getVendureShopAPIUrl(),
    },
    {
        id: CLIENT_VENDURE_ADMIN,
        url: getVendureAdminAPIUrl(),
    },
];

function Apollo({ children }) {
    const { isUpdateAvailable, updateAssets } = useServiceWorker();
    const appToken = useSelector(getToken);
    const userToken = useSelector(getUserToken);

    const defaultClient = getApiClient(CLIENT_ZAFIRO_MOBILE, { token: userToken || appToken });

    if (isUpdateAvailable) {
        updateAssets();
    }

    return <ApolloProvider client={defaultClient}>{children}</ApolloProvider>;
}

export const evalQueryCache = (query, options, expiration, fetchPolicy) => {
    const storageKey = `${storagePrefix}cacheTimes`;

    const keyData = query?.id
        ? { id: query?.id, options }
        : { query: query?.query, variables: query?.defaultVars, options };
    const key = crypto.createHash("md5").update(JSON.stringify(keyData)).digest("hex");

    const now = Date.now();

    /// use local storage to store cache times
    const cacheTimes = JSON.parse(window.localStorage.getItem(storageKey) || "{}");

    if (expiration) {
        const lastFetch = cacheTimes[key];
        if (!lastFetch || lastFetch + expiration < now) {
            // Update cached data
            fetchPolicy = "network-only";
        }
    }
    return [
        fetchPolicy || "cache-first",
        () => {
            if (fetchPolicy === "network-only") {
                cacheTimes[key] = now;
                window.localStorage.setItem(storageKey, JSON.stringify(cacheTimes));
            }
        },
    ];
};

export const getApiClient = (clientID, { token, vendureToken, languageCode }) => {
    const client = (API_CLIENTS.filter((c) => c.id === clientID) || [null])[0];
    if (!client) {
        throw new Error(`Client ${clientID} not found`);
    }

    const ref = [clientID, token, vendureToken, languageCode].filter((v) => v).join("_");

    const instance = ((client.instances || []).filter((i) => i.ref === ref) || [null])[0];
    if (instance?.client) {
        return instance.client;
    }

    const uri = client.url + (languageCode ? `?languageCode=${languageCode}` : "");
    const httpLink = createHttpLink({ uri });
    const timeoutLink = new ApolloLinkTimeout(client.timeout || 30000);
    const timeoutHttpLink = timeoutLink.concat(httpLink);

    const cache = new InMemoryCache({ addTypename: true });

    const authLink = setContext((_, context) => {
        const { headers, customToken, token: tokenCtx, vendureToken: vendureTokenCtx } = context;
        // customToken is deprecated, use token instead
        const authToken = customToken || tokenCtx || token;
        const authVendure = vendureTokenCtx || vendureToken;
        return {
            ...context,
            headers: {
                ...headers,
                ...(authToken
                    ? {
                          authorization: authToken ? `Bearer ${authToken}` : "",
                      }
                    : null),
                ...(authVendure ? { "vendure-token": authVendure } : null),
            },
        };
    });

    const persistor = new CachePersistor({
        cache,
        storage: window.localStorage,
        key: `${storagePrefix}${ref}`,
    });
    persistor.restore();

    const newInstance = {
        ref,
        client: new ApolloClient({
            link: authLink.concat(timeoutHttpLink),
            cache: cache,
        }),
    };
    if (!client.instances) {
        client.instances = [];
    }
    client.instances.push(newInstance);

    return newInstance.client;
};

export default Apollo;
