import gql from "graphql-tag";

export const GET_KEYS = {
    id: "GET_KEYS",
    query: gql`
        query getKeys($page: Int!, $size: Int!, $count: Boolean) {
            getKeys(page: $page, size: $size, count: $count) {
                info {
                    count
                    page
                    size
                }
                results {
                    expireTime
                    hotelRef
                    keyBrand
                    keyData
                    rooms
                    startTime
                    zones
                }
            }
        }
    `,
    defaultVars: {
        page: 1,
        size: 100,
    },
};

export const USE_KEY_TESA = {
    query: gql`
        mutation useKey($hotelRef: ID!, $roomNumber: Int) {
            useKey(hotelRef: $hotelRef, roomNumber: $roomNumber)
        }
    `,
};
