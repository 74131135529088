import React, { useEffect } from "react";

import { configToCSS } from "../DesignUtils";
import useWidget from "../../../hooks/useWidget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UseWidgetIcon = ({ config, id, deviceType }) => {
    const { cfg } = useWidget({
        data: config ? config.icon : null,
        styles: config ? config.styles : null,
        parentCfg: config ? config.parentCfg : null,
        parseConfig,
    });

    useEffect(() => {
        const element = document.querySelector(`#${id}`);
        element.parentNode.style.backgroundColor = "inherit";
    }, [id]);

    return (
        <div
            className="flex h-full items-center justify-center"
            id={id}
            style={configToCSS(
                {
                    size: cfg.size,
                    border: cfg.border,
                    fgColor: cfg.colors.text,
                    bgColor: cfg.colors.bg,
                },
                deviceType
            )}
        >
            {cfg.icon.id ? <FontAwesomeIcon icon={[cfg.icon.lib, cfg.icon.id]} /> : null}
        </div>
    );
};

const parseConfig = ({ data, styles, parentCfg, theme }) => {
    let bgColor = styles.bgColor || theme.colors.defaultBackground;
    if(bgColor && (parentCfg && parentCfg.bgColor === bgColor))bgColor = null;
    return {
        size: data.size,
        icon: {
            lib: data.lib || "fa",
            id: data.icon,
        },
        colors: {
            text: styles.fgColor || theme.colors.defaultForeground,
            bg: bgColor,
        },
        border: {
            color: styles.borderColor,
            size: styles.borderWidth,
            radius: styles.radius,
        },
    };
};

export default UseWidgetIcon;
