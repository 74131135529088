import React, { useEffect, useState } from "react";
import fetchTimeout from "fetch-timeout";

import Tabs from "../components/Tabs";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Link, useParams } from "react-router-dom";
import { getSelectedHotel, getHotel } from "../actions/hotel";
import { useHotels, useSignInRoom } from "../graphql/useHotels";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import Webview from "../components/Webview";
import { useTranslation } from "react-i18next";
import { queryString } from "../utils/url";
import { GET } from "../utils/get";
import swal from "@sweetalert/with-react";
import { getProjectRooms, getRoomNumber, deleteRoom } from "../modules/rooms/store";
import useInterval from "../hooks/useInterval";
import { castSimulator, getMobileUrl, getIsEmbedded } from "../actions/config";
import { fetchTimeout as fetchTest } from "../utils/mockup";

const Room = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { signInRoom } = useSignInRoom();
    const { roomShopRef } = useParams();
    const [redirectTo, setRedirectTo] = useState("");
    const [urlWebView, setUrlWebView] = useState({ loading: true, url: null });
    const selectedHotel = useSelector(getSelectedHotel);
    const selectedHotelRef = useSelector(getHotel);
    const isEmbedded = useSelector(getIsEmbedded);
    const {
        loading: loadingHotel,
        error,
        hotels: hotel,
    } = useHotels({
        ref: selectedHotelRef,
        autoload: true,
    });
    const room = useSelector(getRoomNumber);
    const userRooms = useSelector(getProjectRooms);

    const loadUrl = async () => {
        let url = hotel && hotel.mobilePath ? getMobileUrl(selectedHotelRef) : null;
        const urlParams = { embedded: "" };
        const { load, token } = await validateRoomAccess();
        if (load) {
            if (token) {
                urlParams.token = token;
            }
            if (userRooms && userRooms[room]) {
                if (!token || token === "") {
                    urlParams.room = room;
                    urlParams.code = userRooms[room].code;
                }
            } else {
                if (!token) urlParams.logout = "";
            }
            if (url) {
                url += (url.match(/\?/) ? "&" : "?") + queryString(urlParams);
            }
            setUrlWebView({ loading: false, url: url });
        } else {
            dispatch(
                deleteRoom({
                    projectRef: selectedHotelRef,
                    number: room,
                    code: userRooms[room] ? userRooms[room].code : "",
                    embedded: isEmbedded,
                })
            );
            setRedirectTo("/site");
            swal({
                title: t("invalid password"),
                text: t("invalid room info"),
            });
        }
    };

    useEffect(() => {
        if (!loadingHotel) {
            loadUrl();
        }
    }, [loadingHotel]);

    useInterval(async () => {
        if (!urlWebView.loading) {
            const { load, token } = await validateRoomAccess();
            if ((!load || !token) && room && room > 0) {
                dispatch(
                    deleteRoom({
                        projectRef: selectedHotelRef,
                        number: room,
                        code: userRooms[room] ? userRooms[room].code : "",
                        embedded: isEmbedded,
                    })
                );
                setRedirectTo("/site");
                swal({
                    title: t("invalid password"),
                    text: t("invalid room info"),
                });
            }
        }
    }, 30000);

    const validateRoomAccess = async () => {
        let response = {
            load: true,
            token: null,
        };
        if (selectedHotel?.permissionsConfig?.manageGuests) {
            if ((room && room > 0) || (roomShopRef && roomShopRef > 0)) {
                response.load = false;
                const variables = {
                    hotelRef: selectedHotelRef,
                };

                if (roomShopRef && roomShopRef > 0) {
                    variables.roomShopRef = roomShopRef;
                }
                if (room && room > 0) {
                    variables.roomNumber = room;
                    variables.password = userRooms && userRooms[room] ? userRooms[room].code : "";
                }
                const signInRoomResponse = await signInRoom({
                    variables: variables,
                });
                if (
                    signInRoomResponse &&
                    signInRoomResponse.data &&
                    signInRoomResponse.data.signInRoom &&
                    signInRoomResponse.data.signInRoom.token &&
                    signInRoomResponse.data.signInRoom.token !== ""
                ) {
                    response.load = true;
                    response.token = signInRoomResponse.data.signInRoom.token;
                    if (signInRoomResponse.data.signInRoom.pairingURL) {
                        const simulator = castSimulator();
                        const pairingURL = signInRoomResponse.data.signInRoom.pairingURL
                            ? simulator
                                ? "/pairing/?tokenMobile=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9"
                                : signInRoomResponse.data.signInRoom.pairingURL
                            : null;
                        const fetch = simulator ? fetchTest : fetchTimeout;
                        fetch(pairingURL, null, 6000);
                    }
                }
            }
        }
        return response;
    };

    const handleLocation = (location) => {
        const params = GET(location);
        if ("denied" in params) {
            dispatch(
                deleteRoom({
                    projectRef: selectedHotelRef,
                    number: room,
                    code: userRooms[room] ? userRooms[room].code : "",
                    embedded: isEmbedded,
                })
            );
            setRedirectTo("/site");
            swal({
                title: t("invalid password"),
                text: t("invalid room info"),
            });
            return false;
        }
        return true;
    };

    const handleClickReturn = () => {
        setRedirectTo("/site");
    };

    if (!selectedHotelRef) {
        return <Redirect push to="/site/list" />;
    }

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    return (
        <>
            {!hotel && error ? (
                <ErrorMessage type="server" error={error} />
            ) : loadingHotel || urlWebView.loading ? (
                <Loading />
            ) : (
                <Webview
                    title={t("information & services")}
                    url={urlWebView.url}
                    addParams={["lang", "embedded"]}
                    verifyLocation={handleLocation}
                    bottomBar={true}
                    setLang={true}
                />
            )}
            <Tabs>
                <div className="relative max-w-lg m-auto p-2">
                    <Link onClick={handleClickReturn} to="#" className="absolute left-0 ml-2 p-3">
                        <i className="icon icon-back text-xl text-accent"></i>
                    </Link>
                    <div className="flex content-center h-12 flex-wrap">
                        <div className="flex flex-col w-full">
                            <span className="font-bold">{t("information & services")}</span>
                            {room !== null && room > 0 ? (
                                <span className="text-sm">{t("room x", { room: room })}</span>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </Tabs>
        </>
    );
};

export default Room;
