import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactInputMask from "react-input-mask";
import { useTranslation } from "react-i18next";

const InputTel = ({ value = "", isRequired, disabled, onChange }) => {
    const [countryCode, setCountryCode] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [dialCode, setDialCode] = useState("34");
    const [finalPhoneNumber, setFinalPhoneNumber] = useState(null);
    const [flagDropdownOpen, setFlagDropdownOpen] = useState(false);
    const firstRender = useRef(false);

    const { t } = useTranslation();

    useEffect(() => {
        // resetInitialStyles(id);
        const cleanupObserver = observeFlagDropdown(setFlagDropdownOpen);
        return cleanupObserver;
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (value && !firstRender.current) {
            const [dialCode, ...restOfNumbers] = value.split(" ");
            setDialCode(dialCode);
            setPhoneNumber(restOfNumbers.join(""));
        }
    }, [value]);

    useEffect(() => {
        const formattedDialCode = dialCode.startsWith("+") ? dialCode : `+${dialCode}`;
        const fullPhoneNumber = phoneNumber?.trim() ? `${formattedDialCode} ${phoneNumber.trim()}` : "";
        setFinalPhoneNumber(fullPhoneNumber);
        if (onChange) {
            onChange(fullPhoneNumber);
        }
    }, [dialCode, phoneNumber]);

    useEffect(() => {
        setupFlagDropdown(flagDropdownOpen);
    }, [flagDropdownOpen]);

    return (
        <div className=" w-full">
            <label
                // style={{ color: disabled ? "#323232" : null }}
                className={`${disabled ? " text-neutral-contrast-40" : ""} block text-sm mb-1`}
            >
                {t("phone-number")}
                {isRequired && !disabled ? " *" : ""}
            </label>

            <div className=" w-full flex space-x-4" id="phone-input-container">
                <div style={{ maxWidth: "50%" }} className=" w-1/2 flex relative">
                    <div className=" absolute  top-0 left-0">
                        <PhoneInput
                            country={countryCode}
                            onChange={(value, country, e, formattedValue) => {
                                setCountryCode(country.countryCode);
                                setDialCode(country.dialCode);
                            }}
                            value={dialCode}
                            inputStyle={{ display: "none" }}
                            containerStyle={{ height: "2.5rem" }}
                            enableSearch={true}
                            searchStyle={{ width: "100%" }}
                        />
                    </div>
                    {/* <div className=" pl-8"> */}
                    <ReactInputMask
                        mask="+99999"
                        className="  bg-neutral w-full pl-20 border border-neutral-contrast-20 focus:outline-none rounded"
                        maskChar=" "
                        style={{ height: "2.5rem" }}
                        placeholder="+000"
                        onChange={(e) => {
                            setDialCode(e.target.value.replace("+", ""));
                        }}
                        id={"input-tel-dial-code"}
                        value={dialCode}
                    >
                        {(inputProps) => <input {...inputProps} type="tel" />}
                    </ReactInputMask>
                    {disabled ? (
                        <div
                            className=" absolute bg-white opacity-75  border border-neutral-contrast-20 top-0 left-0 w-full  rounded"
                            style={{ height: "2.5rem" }}
                        ></div>
                    ) : null}
                    {/* </div> */}
                </div>
                <div className=" w-1/2 relative ">
                    <ReactInputMask
                        mask="999 99 99 99 99"
                        className=" bg-neutral px-2 border border-neutral-contrast-20 focus:outline-none rounded w-full"
                        maskChar=" "
                        style={{ height: "2.5rem" }}
                        placeholder="000 00 00 00"
                        value={phoneNumber}
                        id={"input-tel-phone-number"}
                        disabled={disabled}
                        onChange={(e) => {
                            setPhoneNumber(e.target.value.trim());
                        }}
                    >
                        {(inputProps) => <input {...inputProps} type="tel" />}
                    </ReactInputMask>
                    {disabled ? (
                        <div
                            className=" absolute bg-white opacity-75  border border-neutral-contrast-20 top-0 left-0 w-full  rounded"
                            style={{ height: "2.5rem" }}
                        ></div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default InputTel;

const observeFlagDropdown = (setFlagDropdownOpen) => {
    const flagContainer = document.querySelector(` #phone-input-container .react-tel-input .selected-flag`);

    if (!flagContainer) return;

    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === "class") {
                const isOpen = flagContainer.classList.contains("open");
                // customCountrySelector(id);
                setFlagDropdownOpen(isOpen);
            }
        });
    });

    observer.observe(flagContainer, { attributes: true });

    return () => observer.disconnect();
};

const setupFlagDropdown = (flagDropdownOpen) => {
    const phoneInput = document.querySelector("#phone-input-container");

    if (!phoneInput) return;

    const flagDropdown = phoneInput.querySelector(".flag-dropdown");
    if (!flagDropdown) return;

    flagDropdown.id = "input-tel-flag-dropdown";
    flagDropdown.classList.add("input-tel");

    const selectedFlag = flagDropdown.querySelector(".selected-flag");

    if (!selectedFlag) return;

    const arrowIcon = document.createElement("i");
    arrowIcon.classList.add("icon", `icon-${flagDropdownOpen ? "up" : "down"}`, "text-xl");
    arrowIcon.style.position = "absolute";
    arrowIcon.style.right = "0.5rem";
    arrowIcon.style.top = "0.6rem";
    arrowIcon.id = "arrow-icon-input-tel";
    selectedFlag.style.width = "4rem";
    selectedFlag.id = "selected-flag-input-tel";

    const existingArrowIcon = document.querySelector("#arrow-icon-input-tel");
    if (existingArrowIcon) {
        existingArrowIcon.remove();
    }

    selectedFlag.insertAdjacentElement("beforeend", arrowIcon);

    const arrow = phoneInput.querySelector(".arrow");
    if (arrow) {
        arrow.remove();
    }
    const searchContainer = flagDropdown.querySelector(".country-list .search");
    const searchEmoji = flagDropdown.querySelector(".country-list .search-emoji");

    if (searchEmoji) {
        searchEmoji.remove();
    }

    if (searchContainer) {
        const searchBox = searchContainer.querySelector(".search-box");
        searchContainer.style.display = "flex";
        searchContainer.style.padding = "10px 6px 6px 10px";

        const searchIcon = document.createElement("i");
        searchIcon.classList.add("icon", "icon-search", "text-xl");
        searchIcon.style.position = "absolute";
        searchIcon.style.right = "1rem";
        searchIcon.style.top = "1rem";
        searchIcon.id = "search-icon-input-tel";

        const existingSearchIcon = document.querySelector("#search-icon-input-tel");
        if (existingSearchIcon) {
            existingSearchIcon.remove();
        }

        searchContainer.insertAdjacentElement("beforeend", searchIcon);

        searchBox.style.borderWidth = "1px";
        searchBox.style.borderColor = "rgba(50,50,50,0.2)";
        searchBox.style.height = "2rem";
    }
};
