import { logoutUser } from "../actions/user";
import { invalidateToken } from "../actions/config";
import { setSelectedHotel } from "../actions/hotel";
import { deleteRoom } from "../modules/rooms/store";
import { getVarID } from "./queries/messages";

window.tries = 0;

const handleGraphqlError = (error, dispatch, appLogged, userLogged, variables) => {
    let authorized = true;
    if (error && error.graphQLErrors) {
        for (const element of error.graphQLErrors) {
            const err = element;
            if (err.message.toLowerCase().includes("invalid password")) {
                err?.path.forEach((path) => {
                    const getMessagesQuery = path.match(/^messages(\d+)/i);
                    if (getMessagesQuery) {
                        const varID = getVarID({
                            projectRef,
                            roomNumber,
                        });
                        const roomNumber = getMessagesQuery[1];
                        const projectRef = variables?.[`r${varID}ProjectRef`];
                        if (dispatch) {
                            dispatch(
                                deleteRoom({
                                    projectRef: projectRef,
                                    number: roomNumber,
                                })
                            );
                        }
                    }
                });
            } else if (
                err.message.toLowerCase().includes("unauthorized") ||
                err.message.toLowerCase().includes("location chain needed")
            ) {
                authorized = false;
            } else if (err.message.toLowerCase().includes("undefined project")) {
                if (dispatch) {
                    dispatch(setSelectedHotel(null));
                }
            } else {
                console.error(err.message);
            }
        }
    }
    if (!authorized && dispatch) {
        if (userLogged) {
            dispatch(logoutUser(true));
        } else if (appLogged && window.tries < 2) {
            dispatch(invalidateToken());
            window.tries++;
        }
    }
    return authorized;
};

export default handleGraphqlError;
