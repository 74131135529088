import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { setSelectedHotel } from "../actions/hotel";
import { setStaysData } from "actions/stays";
import { logoutUser } from "../actions/user";

const SignOut = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logoutUser(false));
        dispatch(setStaysData({ user: "any", values: null }));
        dispatch(setSelectedHotel(null));
    }, []);

    return <Redirect push to="/signin" />;
};

export default SignOut;
