import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import { markdown } from "utils/others";

const SignInEmail = (params) => {
    const { t } = useTranslation();
    const [passVisible, setPassVisible] = useState(false);
    const { email, type } = useParams();
    const { register, handleSubmit, watch, errors, triggerValidation } = useForm();

    const togglePassVisibility = () => {
        setPassVisible(!passVisible);
    };

    return (
        <form onSubmit={handleSubmit(params.onSubmit)}>
            <div className="my-3">
                {email ? (
                    <>
                        <h1 className="font-bold text-lg mb-4">{t("login title")}</h1>
                        {type === "code" ? (
                            <>
                                {markdown(t("password sent to email", { email: email }))}
                                <br />
                                <br />
                                {t("enter account password")}
                            </>
                        ) : (
                            <>
                                {t("enter account password")}
                                <br />
                                <strong>{email}</strong>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div className={"float-left text-sm pl-1 pb-1 " + (!watch("email") && "invisible")}>
                            {t("email")}
                        </div>
                        <input
                            ref={register({
                                required: t("required input"),
                                validate: (value) => isEmail(value) || t("invalid email"),
                            })}
                            type="email"
                            name="email"
                            id="input-email"
                            placeholder={t("email")}
                            onChange={() => triggerValidation("email")}
                            className={errors.email ? "error" : ""}
                        />
                        {errors.email && <div className="text-error float-right text-sm">{errors.email.message}</div>}
                    </>
                )}
            </div>

            <div className="mt-3 mb-5">
                <div className={"float-left text-sm pl-1 pb-1 " + (!watch("password") && "invisible")}>
                    {t("password")}
                </div>
                <div className="relative">
                    <input
                        ref={register({
                            required: true,
                        })}
                        type={passVisible ? "text" : "password"}
                        name={type === "code" ? type : "password"}
                        id="input-pass"
                        placeholder={t("password")}
                        onChange={() => triggerValidation("password")}
                        className={errors.password ? "error" : ""}
                    />
                    <i
                        className={
                            "absolute right-0 m-3 text-neutral-contrast-50 text-xl cursor-pointer icon icon-eye-" +
                            (passVisible ? "on" : "off")
                        }
                        id="toggle-pass-visibility"
                        onClick={togglePassVisibility}
                    ></i>
                </div>
            </div>

            <Link
                to={email ? "../forgot" : "forgot"}
                id="action-forgotpass"
                className="text-accent block align-baseline"
            >
                {t("forgot password?")}
            </Link>

            <button id="action-signin" type="submit" className="btn btn-accent block mx-auto mt-5 mb-2 w-full text-lg">
                {t("signin")}
            </button>
        </form>
    );
};

export default SignInEmail;
