import Notifications from "../views/Notifications";
import pkg from "../../package.json";

export const notificationsRoutes = (prefix = "") => {
	if (!pkg.features.notifications) {
		return [];
	}
	return [
		{
			path: prefix,
			exact: true,
			section: Notifications,
		}
	];
};
