import React, { useState } from "react";
import Header from "../components/Header";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useUpdatePassword } from "../graphql/useUser";
import { updateUserData, storePassHash, compareUserPass, getUserData } from "../actions/user";
import Loading from "../components/Loading";
import swal from "@sweetalert/with-react";
import { toast } from "react-toastify";

const ProfilePass = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(getUserData);
    const hash = useSelector((state) => state.user.passHash);
    const [redirectTo, setRedirectTo] = useState("");
    const [lastPass, setLastPass] = useState("");

    const { register, handleSubmit, watch, errors, triggerValidation } = useForm();

    const [passVisible, setPassVisible] = useState({
        currentpass: false,
        password: false,
    });

    const togglePassVisibility = (input) => {
        const set = {};
        set[input] = !passVisible[input];
        setPassVisible({
            ...passVisible,
            ...set,
        });
    };

    const updateError = () => {
        swal({
            title: t("error.title"),
            text: t("error.unknown") + "\n" + t("error.unknownAction"),
            button: t("alert ok"),
        });
    };

    const { updatePassword, loading } = useUpdatePassword({
        onCompleted: (data) => {
            const user = data.updatePassword;
            if (user) {
                dispatch(updateUserData(user));
                dispatch(storePassHash(lastPass));
                toast(t("password changed"));
                setRedirectTo("/profile");
            } else {
                updateError();
            }
        },
        onError: (error) => {
            updateError();
        },
    });

    if (!user) {
        return <Redirect to="/signin" />;
    }

    if (!user.auth || !user.auth.password) {
        return <Redirect to="/profile/config" />;
    }

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    const onSubmit = (data, e) => {
        setLastPass(data.password);
        updatePassword({
            variables: {
                password: data.password,
            },
        });
    };

    if (!user || !user.auth || user.auth.password !== true) {
        return <Redirect push to="./config" />;
    }

    const checkPass = (exp) => {
        return (watch("password") || "").match(exp) ? "line-through" : errors.password ? " text-error" : "";
    };

    return (
        <section className="basic has-top">
            <Header title={t("change my pass")} />
            <section>
                {loading ? (
                    <Loading />
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p>{t("write your pass and change")}</p>

                        <div className="mt-3 mb-1">
                            <div
                                className={
                                    "float-left font-medium text-sm pl-1 pb-1 " + (!watch("currentpass") && "invisible")
                                }
                            >
                                {t("current pass")}
                            </div>
                            <div className="relative">
                                <input
                                    ref={register({
                                        required: true,
                                        validate: (value) => compareUserPass(hash, value),
                                    })}
                                    type={passVisible.currentpass ? "text" : "password"}
                                    name="currentpass"
                                    placeholder={t("current pass")}
                                    onBlur={() => triggerValidation("currentpass")}
                                    className={errors.currentpass ? "error" : ""}
                                />
                                <i
                                    className={
                                        "absolute right-0 m-3 text-neutral-contrast-50 text-xl cursor-pointer icon icon-eye-" +
                                        (passVisible.currentpass ? "on" : "off")
                                    }
                                    onClick={() => togglePassVisibility("currentpass")}
                                ></i>
                            </div>
                        </div>
                        {errors.currentpass ? errors.currentpass.message : ""}

                        <div className="mt-3 mb-1">
                            <div
                                className={
                                    "float-left font-medium text-sm pl-1 pb-1 " + (!watch("password") && "invisible")
                                }
                            >
                                {t("password")}
                            </div>
                            <div className="relative">
                                <input
                                    ref={register({
                                        required: true,
                                        pattern: /^(?=.{6,50}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).*$/gm,
                                    })}
                                    type={passVisible.password ? "text" : "password"}
                                    name="password"
                                    placeholder={t("password")}
                                    onChange={() => triggerValidation("password")}
                                    className={errors.password ? "error" : ""}
                                />
                                <i
                                    className={
                                        "absolute right-0 m-3 text-neutral-contrast-50 text-xl cursor-pointer icon icon-eye-" +
                                        (passVisible.password ? "on" : "off")
                                    }
                                    onClick={() => togglePassVisibility("password")}
                                ></i>
                            </div>
                        </div>
                        {errors.password ? errors.password.message : ""}
                        <p className={"text-sm whitespace-pre-line"}>
                            <span className={checkPass(/(?=.{6,50}$)/gm)}>{t("passrules.chars")}</span>
                            <br />
                            <span className={checkPass(/(?=.*[a-z])(?=.*[A-Z])/gm)}>{t("passrules.case")}</span>
                            <br />
                            <span className={checkPass(/(?=.*[0-9])/gm)}>{t("passrules.num")}</span>
                        </p>

                        <footer>
                            <div className="text-right">
                                <button type="submit" className="btn btn-accent w-full my-5 text-lg">
                                    {t("change pass")}
                                </button>
                            </div>
                        </footer>
                    </form>
                )}
            </section>
        </section>
    );
};

export default ProfilePass;
