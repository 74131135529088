import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

const SelectorButton = ({ name, options, initialOptionSelected, onChange, isRequired, disabled }) => {
    const { t } = useTranslation();
    const [optionSelected, setOptionSelected] = useState(null);

    const firstRender = useRef(false);

    useEffect(() => {
        if (!firstRender.current && initialOptionSelected) {
            setOptionSelected(initialOptionSelected);
        }
    }, [initialOptionSelected]);

    useEffect(() => {
        if (optionSelected && typeof onChange === "function") {
            onChange(optionSelected);
        }
    }, [optionSelected]);

    const styles = (optionSelected, item) => {
        if (optionSelected === item.id) {
            if (disabled) {
                return " bg-accent-30 text-accent-contrast border border-transparent";
            } else {
                return " bg-accent text-accent-contrast border border-transparent";
            }
        } else {
            if (disabled) {
                return " bg-neutral text-neutral-contrast-20 border border-neutral-contrast-20";
            } else {
                return " bg-neutral text-neutral-contrast border border-neutral-contrast-20";
            }
        }
    };

    return (
        <div className=" w-full">
            <label className={`block text-sm mb-1 ${disabled ? " text-neutral-contrast-40" : ""}`}>
                {t(name)} {isRequired && !disabled ? " *" : ""}
            </label>
            <div className=" flex space-x-4">
                {options.map((item, index) => {
                    return (
                        <div
                            style={{ height: "2.5rem" }}
                            className={` flex rounded px-2 justify-center items-center py-1 w-full ${styles(
                                optionSelected,
                                item
                            )} 
                            `}
                            id={`selector-button-option-${index}`}
                            onClick={() => {
                                if (!disabled) {
                                    setOptionSelected(item.id);
                                }
                            }}
                        >
                            {t(item.name)}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SelectorButton;
