import React from "react";
import { size as renderSize } from "zrender/sizes";

import { getDeviceWidth } from "./Design/DesignUtils";

const TopMenu = ({ keyID, style, items }) => {
    const size = (v) => renderSize(v, "mobile", getDeviceWidth());
    return (
        <div
            key={keyID}
            className="border-b shadow-inner"
            style={{
                fontSize: size(1.4),
                paddingTop: size(1.05),
                paddingLeft: size(1.05),
                ...style,
            }}
        >
            <ul target="items" className="whitespace-nowrap" style={{ overflow: "overlay" }}>
                {items?.map((item) => (
                    <MenuItem {...item}>{item?.name}</MenuItem>
                ))}
            </ul>
        </div>
    );
};

const MenuItem = ({ style, children, selected, key, onClick }) => {
    const size = (v) => renderSize(v, "mobile", getDeviceWidth());
    return (
        <li
            key={key}
            onClick={onClick}
            className={"cursor-pointer inline-block " + (selected ? "border-b-2 border-accent text-accent" : "")}
            style={{
                paddingBottom: size(0.35),
                paddingLeft: size(1.05),
                paddingRight: size(1.05),
                ...style,
            }}
        >
            {children}
        </li>
    );
};

export default TopMenu;
