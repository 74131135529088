import React, { useRef, useEffect, Children, isValidElement, cloneElement, useState } from "react";

function CenterUbication(map, center) {
    const controlButton = document.createElement("button");
    controlButton.className = "m-4 w-10 h-10 bg-white text-black rounded-full";
    const icon = document.createElement("i");
    icon.className = "icon icon-centre-location-1 text-xl";
    controlButton.appendChild(icon);
    controlButton.id = "button-center-location";
    icon.id = "button-center-location-icon";
    controlButton.addEventListener("click", () => {
        map.setCenter(center);
    });
    controlButton.addEventListener("click", () => {
        icon.className = "icon icon-centre-location-1 text-xl";
    });

    return controlButton;
}

export function MapComponent({ current, children, onClick, setMap, map }) {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current && !map) {
            const pivotMap = new window.google.maps.Map(ref.current, {
                zoom: 18,
                center: current,
                mapTypeId: "satellite",
                disableDefaultUI: true,
                rotateControl: true,
                minZoom: 18,
                maxZoom: 20,
            });
            const centerControlDiv = document.createElement("div");
            const button = CenterUbication(pivotMap, current);
            centerControlDiv.appendChild(button);
            pivotMap.controls[google.maps.ControlPosition.RIGHT_TOP].push(centerControlDiv);
            setMap(pivotMap);
        }
    }, [ref, map]);

    useEffect(() => {
        if (map) {
            ["click", "center_changed"].forEach((eventName) => google.maps.event.clearListeners(map, eventName));
            if (onClick) {
                map.addListener("click", onClick);
            }
            map.addListener("center_changed", () => {
                const icon = document.getElementById("button-center-location-icon");
                icon.className = "icon icon-centre-location-2 text-xl";
            });
        }
    }, [map, onClick]);

    return (
        <>
            <div ref={ref} id="map" className=" w-full" style={{ height: "50vh" }} />
            {Children.map(children, (child) => {
                if (isValidElement(child)) {
                    return cloneElement(child, { map });
                }
            })}
        </>
    );
}
