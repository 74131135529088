import React, { useRef, useCallback, useLayoutEffect, useState, useEffect } from "react";
import { matchPath } from "react-router";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { debounce } from "lodash";

import { useKeyDown } from "../hooks/useKeyDown";

import history from "../routes/history";
import useWidget from "../hooks/useWidget";
import { parseTextConfig, configToCSS } from "./Design/DesignUtils";

const Header = React.forwardRef(function HeaderComponent(
    {
        title: defaultTitle,
        config = undefined,
        back = undefined,
        backAction = undefined,
        search = undefined,
        edit = undefined,
        orders = null,
        custom,
        searchFunction,
        searchButtonDisabled,
        onCloseSearch,
        onOpenSearch,
        children,
        alertIcon,
    },
    ref
) {
    const { cfg } = useWidget({
        data: custom ? custom.data : null,
        styles: custom ? custom.styles : null,
        parseConfig,
    });
    const location = useLocation();
    const { t } = useTranslation();
    const [title, setTitle] = useState(null);
    const [enabledSearch, setEnabledSearch] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [backHandler, setBackHandler] = useState(null);
    const [backIcon, setBackIcon] = useState(null);

    const searchInput = useRef(null);

    const customTitle = location
        ? matchPath(location.pathname, {
              path: "/site",
              exact: true,
          })
        : false;

    const showtitle = title || (customTitle ? cfg.title : null) || defaultTitle;

    const hasSearch = search === true || enabledSearch;
    const searchActive = hasSearch && showSearchBar === true;

    // Exponer el método doSomething a través de la referencia
    React.useImperativeHandle(ref, () => ({
        setSearch: (enable) => {
            if (!enable) {
                handleCloseSearch();
            }
            setEnabledSearch(enable);
        },
        closeSearch: () => {
            handleCloseSearch();
        },
        setBack: (handler, options) => {
            setBackHandler({ handler });
            setBackIcon(options?.icon);
        },
        setTitle: (title) => {
            setTitle(title);
        },
        height: () => {
            return document.getElementById("topbar")?.offsetHeight;
        },
    }));

    const handleSearch = () => {
        setShowSearchBar(true);
    };

    const handleCloseSearch = () => {
        if (searchFunction) {
            searchFunction("");
        }
        setShowSearchBar(false);
    };

    useKeyDown({
        keys: ["s", "S"],
        callback: useCallback(
            ({ event, isInput }) => {
                if (hasSearch && !searchActive && !isInput) {
                    handleSearch();
                    event.preventDefault();
                }
            },
            [hasSearch, searchActive, handleSearch]
        ),
    });

    useKeyDown({
        keys: ["Escape"],
        callback: useCallback(() => {
            if (hasSearch && searchActive) {
                handleCloseSearch();
            }
        }, [hasSearch, searchActive, handleCloseSearch]),
    });

    useLayoutEffect(() => {
        if (searchActive && searchInput.current) {
            searchInput.current.focus();
        }
    }, [searchActive, searchInput.current]);

    useLayoutEffect(() => {
        if (!hasSearch) {
            setShowSearchBar(false);
        }
    }, [hasSearch]);

    useEffect(() => {
        if (showSearchBar) {
            if (typeof onOpenSearch === "function") {
                onOpenSearch();
            }
        } else {
            if (typeof onCloseSearch === "function") {
                onCloseSearch();
            }
        }
    }, [showSearchBar]);

    const icon = ({ iconType, to, id, onClick, disabled }) => {
        const icon = iconType ? <i style={configToCSS(cfg.icon)} className={`icon ${iconType} text-accent`}></i> : null;
        const isLink = to !== undefined;
        return (
            <div className="pt-1" style={{ minWidth: "3rem" }}>
                {icon && isLink ? (
                    <Link id={id} to={to} onClick={onClick} disabled={disabled}>
                        {icon}
                    </Link>
                ) : null}
                {icon && !isLink ? (
                    <button id={id} onClick={onClick} disabled={disabled}>
                        {icon}
                    </button>
                ) : null}
            </div>
        );
    };

    const iconLeft = () => {
        let iconType, to, id, onClick;
        if (searchActive) {
            id = "action-search";
            iconType = "icon-search text-xl py-3 pl-3";
        } else if (config !== undefined) {
            iconType = "icon-gear text-2xl p-3";
            to = config;
            id = "action-config";
            onClick = () => toast.dismiss();
        } else if (back !== false) {
            id = "action-back";
            iconType = `${backIcon || "icon-back"} text-xl p-3`;
            if (back) {
                to = { pathname: back, state: { prevLocation: location.pathname } };
            }
            onClick = () => {
                toast.dismiss();
                if (backHandler?.handler) {
                    backHandler?.handler();
                } else if (backAction) {
                    backAction();
                } else if (!back) {
                    history.goBack();
                }
            };
        }
        return icon({ iconType, to, id, onClick });
    };

    const iconRight = () => {
        let iconType, to, id, onClick, disabled;
        if (edit !== undefined) {
            id = "action-edit";
            iconType = "icon-edit text-2xl p-3";
            to = edit;
            onClick = () => toast.dismiss();
        } else if (orders) {
            id = "action-myorders";
            iconType = "icon-orders text-2xl p-3";
            to = orders;
            onClick = () => toast.dismiss();
        } else if (hasSearch) {
            if (searchActive) {
                id = "action-close-search";
                iconType = "icon-close text-xl p-3";
                onClick = handleCloseSearch;
            } else {
                id = "action-search";
                iconType = "icon-search text-2xl p-3";
                onClick = handleSearch;
                disabled = searchButtonDisabled;
            }
        }

        return icon({ iconType, to, id, onClick, disabled });
    };
    return (
        <div
            id="topbar"
            className="fixed bg-primary text-primary-contrast text-center w-full h-14 z-30"
            style={{
                boxShadow: "0px -4px 5px rgba(0,0,0,.2),0 -3px 14px rgba(0,0,0,.12),0 -8px 10px rgba(0,0,0,.14)",
                ...configToCSS(cfg.global),
            }}
        >
            <section className="flex justify-between place-items-center align-middle w-full h-full max-w-lg mx-auto">
                {iconLeft()}
                <div className="flex-grow">
                    {!searchActive ? (
                        children || (
                            <div
                                className="text-lg font-bold truncate flex items-center justify-center "
                                style={configToCSS({ ...cfg.text })}
                            >
                                {showtitle}
                                {alertIcon ? (
                                    <i className=" ml-2 block text-2xl icon icon-alert text-orange-100"></i>
                                ) : null}
                            </div>
                        )
                    ) : (
                        <>
                            <style type="text/css">{`#topbar-search-input::placeholder {color: ${cfg?.input?.fgColor}; opacity:.5 }`}</style>
                            <input
                                ref={searchInput}
                                id={"topbar-search-input"}
                                onChange={(e) => {
                                    if (typeof searchFunction === "function") {
                                        debounce(searchFunction, 500)(e?.target?.value);
                                    }
                                }}
                                className="p-1 border-none rounded-none w-full flex-1 inline-block text-lg text-left"
                                style={{ ...configToCSS(cfg.input), background: "transparent" }}
                                placeholder={`${t("search")}...`}
                                type="text"
                            ></input>
                        </>
                    )}
                </div>

                {iconRight()}
            </section>
        </div>
    );
});

const parseConfig = ({ data, styles, theme }) => {
    if (!theme) theme = {};
    if (!theme.colors) theme.colors = {};
    return {
        title: parseTextConfig(data?.title),
        global: {
            fgColor: styles?.fgColor || theme?.colors?.defaultForeground,
            bgColor: styles?.bgColor || theme?.colors?.defaultBackground,
        },
        input: {
            fgColor: styles?.fgColor || theme?.colors?.defaultForeground,
        },
        text: {
            font: styles?.font,
        },
        icon: {
            fgColor: styles?.fgActiveColor || styles?.status?.active?.fgColor || theme?.colors?.activeForeground,
        },
    };
};

Header.displayName = "Header";

export default Header;
