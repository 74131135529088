import React from "react";
import Header from "../components/Header";
import Tabs from "../components/Tabs";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useProfile } from "../graphql/useUser";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import { setUpdateUserFormValues, getUser } from "../actions/user";
import { convertDateBasedOnLocale } from "utils/dayjs";

const Profile = () => {
    const { t } = useTranslation(["interface", "country"]);
    const dispatch = useDispatch();
    const currentUser = useSelector(getUser);
    const lastUpdateUserFormValues = useSelector((state) => state.user.lastUpdateUserFormValues);
    const [loading, error, user] = useProfile();

    const authInfo = [];

    if (!currentUser) {
        return <Redirect to="/profile/signin" />;
    }

    if (lastUpdateUserFormValues) {
        dispatch(setUpdateUserFormValues(null));
    }

    if (!loading && !error && user) {
        if (user.userAuth === "google") {
            authInfo.push("Google");
        }
        if (user.userAuth === "apple") {
            authInfo.push("Apple");
        }
    }

    return (
        <section className="basic has-tabs has-top">
            {!user && error ? (
                <ErrorMessage type="server" error={error} />
            ) : loading ? (
                <Loading />
            ) : (
                <>
                    <Header
                        title={t("hello user", { name: user ? user.name : "" })}
                        config="/profile/config"
                        edit="/profile/edit"
                    />
                    <section>
                        <div className="mb-3">
                            <span className="block text-sm font-medium text-neutral-contrast-50">{t("treatment")}</span>
                            <span>{user && user.gender ? t(user.gender) : "-"}</span>
                        </div>
                        <div className="mb-3">
                            <span className="block text-sm font-medium text-neutral-contrast-50">{t("name")}</span>
                            <span>{(user ? user.name : null) || "-"}</span>
                        </div>
                        <div className="mb-3">
                            <span className="block text-sm font-medium text-neutral-contrast-50">{t("surname")}</span>
                            <span>{(user ? user.surname : null) || "-"}</span>
                        </div>
                        <div className="mb-3 flex justify-between">
                            <div className="flex-1">
                                <span className="block text-sm font-medium text-neutral-contrast-50">{t("birth")}</span>
                                <span>
                                    {user && user.birthDate && convertDateBasedOnLocale(user.birthDate)
                                        ? convertDateBasedOnLocale(user.birthDate)
                                        : "-"}
                                </span>
                            </div>
                            <div className="flex-1">
                                <span className="block text-sm font-medium text-neutral-contrast-50">
                                    {t("country")}
                                </span>
                                <span>{user && user.country ? t("country:" + user.country) : "-"}</span>
                            </div>
                        </div>
                        <hr className="my-6" />
                        <div className="mb-3">
                            <span className="block text-sm font-medium text-neutral-contrast-50">{t("phone")}</span>
                            <span>{(user ? user.phone : null) || "-"}</span>
                        </div>
                        <div className="mb-3">
                            <span className="block text-sm font-medium text-neutral-contrast-50">{t("email")}</span>
                            <span>
                                {(user ? user.email : null) || "-"}
                                {authInfo && authInfo.length > 0 && <em>{" (" + authInfo.join(", ") + ")"}</em>}
                            </span>
                        </div>
                    </section>
                </>
            )}
            <Tabs />
        </section>
    );
};

export default Profile;
