import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import _ from "lodash";
import { getMenus } from "../../../integrations/useCuiner";
import { normalizeToSearch } from "../../../utils/others";
import { useLocation } from "react-router-dom";
import ErrorMessage from "../../../components/ErrorMessage";
import Loading from "../../../components/Loading";
import Tabs from "../../../components/Tabs";
import Header from "../../../components/Header";
import { setCuinerData, getCuinerData, getCuinerRestaurant } from "../../../actions/cuiner";

const Menus = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cuinerData = useSelector(getCuinerData);
    const selectedRestaurant = useSelector(getCuinerRestaurant);
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [menus, setMenus] = useState(null);
    const [redirectTo, setRedirectTo] = useState("");
    const [menusSearchResults, setMenusSearchResults] = useState(null);
    const [isOnSearchMenu, setIsOnSearchMenu] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (menus !== null && menus.length === 1) {
            if (localStorage.getItem("prevLocation") === "/pos/categories") {
                setRedirectTo("/pos");
                return;
            }
            const menu = menus[0];
            goToCategories(menu.menu_id, menu.name, menu.price_lists);
        }
    }, [menus]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        refetch();
        return () => {
            localStorage.setItem("prevLocation", location.pathname);
        };
    }, []);

    const refetch = async () => {
        setLoading(true);
        setError(null);
        setMenus(null);
        if (cuinerData && cuinerData.api && selectedRestaurant && selectedRestaurant.id) {
            const menusResponse = await getMenus(cuinerData.api, {
                restaurantId: selectedRestaurant.id,
            });
            if (menusResponse && menusResponse.data) {
                setMenus(arrangeMenus(menusResponse.data));
            } else {
                setError(menusResponse.error);
            }
            setLoading(false);
        } else {
            setRedirectTo("/site");
        }
    };

    const noResultsFound =
        menusSearchResults !== null && menusSearchResults.length === 0 && isOnSearchMenu === true
            ? t("no results found")
            : null;

    const arrangeMenus = (menusData) => {
        const response = [];
        if (menusData != null && menusData.length > 0) {
            menusData.map(async (menuData) => {
                var add = true;
                if (menuData.valid_at && menuData.valid_at.length > 0) {
                    var today = new Date();
                    add = false;
                    for (var i = 0; i < menuData.valid_at.length; i++) {
                        var validAt = menuData.valid_at[i];
                        if (validAt) {
                            var arr = validAt.split(/-|\s|:/);
                            validAt = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
                            if (validAt && validAt <= today) {
                                add = true;
                                break;
                            }
                        }
                    }
                }
                if (add) {
                    response.push(menuData);
                }
            });
        }
        return response;
    };

    const goToCategories = (id, name, priceLists) => {
        let cuinerDataClone = { ...cuinerData };
        let selectedRestaurantClone = { ...selectedRestaurant };
        selectedRestaurantClone.currentMenu = id;
        var index = null;
        let menu = {
            id: id,
            name: name,
            priceLists: priceLists,
            currentCategory: null,
        };
        if (selectedRestaurantClone.menus && selectedRestaurantClone.menus.length > 0) {
            if (selectedRestaurantClone.menus.filter((item) => item.id === id).length > 0) {
                menu = selectedRestaurantClone.menus.filter((item) => item.id === id)[0];
                menu.name = name;
                menu.priceLists = priceLists;
                index = _.findIndex(selectedRestaurantClone.menus, { id: id });
                selectedRestaurantClone.menus.splice(index, 1, menu);
            } else {
                selectedRestaurantClone.menus.push(menu);
            }
        } else {
            selectedRestaurantClone.menus = [];
            selectedRestaurantClone.menus.push(menu);
        }
        // index = _.findIndex(cuinerDataClone.restaurants, { id: id });
        index = _.findIndex(cuinerDataClone.restaurants, { id: selectedRestaurant.id });
        cuinerDataClone.restaurants.splice(index, 1, selectedRestaurantClone);
        cuinerDataClone.menusNumber = menus ? menus.length : 0;
        dispatch(setCuinerData(cuinerDataClone));
        setRedirectTo("/pos/categories");
    };

    const menusSearch = (menus, menuToSearch) => {
        setMenusSearchResults(
            menus.filter((restaurant) =>
                normalizeToSearch(restaurant.name).trim().includes(menuToSearch.trim().toLowerCase())
            )
        );
        setIsOnSearchMenu(true);
    };

    const _renderMenus = () => {
        var response = [];
        if (menus != null && menus.length > 0) {
            (isOnSearchMenu ? menusSearchResults : menus).forEach((menu, index) => {
                response.push(
                    <li
                        key={index}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "88px",
                        }}
                        className="relative bg-neutral inline-block w-full whitespace-normal text-center shadow-md rounded-lg mb-4"
                        onClick={() => goToCategories(menu.menu_id, menu.name, menu.price_lists)}
                    >
                        <h1 className=" text-xl text-neutral-contrast font-medium capitalize ">{menu.name}</h1>
                    </li>
                );
            });
            response = <ul className="hotel-list">{response}</ul>;
        }
        return response;
    };

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    return (
        <>
            <section className="basic has-tabs has-top">
                <Header
                    title={`${selectedRestaurant ? selectedRestaurant.name : null}`}
                    back="/pos"
                    search={true}
                    searchFunction={(value) => menusSearch(menus, value)}
                    onCloseSearch={() => setIsOnSearchMenu(false)}
                    searchButtonDisabled={loading === true && menus === null}
                ></Header>
                <section style={{ paddingLeft: "16px", paddingRight: "16px" }}>
                    {loading ? <Loading /> : error ? <ErrorMessage type="server" error={error} /> : _renderMenus()}
                    <p className=" text-center text-neutral-contrast text-lg">{noResultsFound}</p>
                </section>
                <Tabs hideKey={true} />
            </section>
        </>
    );
};

export default Menus;
