import React, { useRef, useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";

import { ProjectContext } from "../contexts/project";
import { getFontURL, configToCSS } from "../components/Design/DesignUtils";

import ShopsOrders from "../components/ShopsOrders";
import Shops from "../components/Shops";
import Shop from "../components/Shop";
import Header from "../components/Header";
import NoRoom from "../components/CheckOut/NoRoom";

const Sales = () => {
    const history = useHistory();
    const { project, room, salesStyles, theme: projectTheme, customBackground } = useContext(ProjectContext);
    const { id } = useParams();
    const { i18n, t } = useTranslation();
    const lang = (i18n.language || "").match(/^[^-]+/)[0];
    const shopToken = id != "orders" ? id : null;
    const isOrders = id == "orders";

    const roomNumber = room?.number;

    const topBarRef = useRef(null);

    const title = shopToken ? null : t("shops title");

    const [search, setSearch] = useState(null);

    const theme = { ...defaultStyles(projectTheme), ...salesStyles };

    const titleFont = theme?.typography?.title;
    const textFont = theme?.typography?.text;
    const fontFiles = [
        ...new Set([getFontURL("Lato"), getFontURL(titleFont), getFontURL(textFont)].filter((f) => f !== null)),
    ];
    const themeGeneral = {
        ...theme?.style?.general?.colors,
        ...(customBackground ? { bgColor: customBackground } : null),
        font: textFont?.name,
    };
    const themeTopBar = {
        ...theme?.style?.section1?.colors,
        font: titleFont?.name,
    };

    const salesEnabled = roomNumber;
    const myOrdersEnabled = project?.permissionsConfig?.myOrders;
    const myOrdersButtonEnabled = myOrdersEnabled && salesStyles?.ordersButton;

    const displayShop = salesEnabled && shopToken;
    const displayOrders = salesEnabled && myOrdersEnabled && isOrders;
    const displayShopsList = salesEnabled && !displayShop && !displayOrders;

    const goToMain = () => {
        setSearch(null);
        if (id) {
            history.replace("/sales");
        }
    };

    return (
        <>
            {fontFiles?.map((fontURL) => (
                <link key={fontURL} href={fontURL} type="text/css" rel="stylesheet" />
            ))}
            <section className="basic has-top" style={configToCSS(themeGeneral)}>
                <Header
                    ref={topBarRef}
                    title={title}
                    orders={myOrdersButtonEnabled && displayShopsList ? "/sales/orders" : null}
                    searchFunction={(value) => {
                        setSearch({
                            ...search,
                            value,
                        });
                    }}
                    onOpenSearch={() => {
                        setSearch({
                            ...search,
                            opened: true,
                        });
                    }}
                    onCloseSearch={() => {
                        setSearch({
                            opened: false,
                            value: null,
                        });
                    }}
                    custom={{
                        styles: {
                            bgColor: themeTopBar?.bgColor,
                            fgColor: themeTopBar?.fgColor,
                            font: themeTopBar?.font,
                            status: {
                                active: {
                                    fgColor: themeTopBar?.iconColor,
                                },
                            },
                        },
                    }}
                />
                <section className="full">
                    {!roomNumber ? (
                        <NoRoom className="p-5" />
                    ) : (
                        <>
                            {displayOrders ? (
                                <ShopsOrders
                                    {...{
                                        lang,
                                        topBarRef,
                                        goToMain,
                                        theme,
                                    }}
                                />
                            ) : null}
                            {displayShop ? (
                                <Shop
                                    {...{
                                        lang,
                                        topBarRef,
                                        goToMain,
                                        search,
                                        theme,
                                    }}
                                />
                            ) : null}
                            {displayShopsList ? (
                                <Shops
                                    {...{
                                        lang,
                                        topBarRef,
                                        goToMain,
                                        theme,
                                    }}
                                />
                            ) : null}
                        </>
                    )}
                </section>
            </section>
        </>
    );
};

const defaultStyles = (theme) => {
    return {
        typography: {
            title: null, //{ name: null, externalUrl: null },
            text: null, //{ name: null, externalUrl: null },
        },
        style: {
            general: {
                colors: {
                    bgColor: "#F5F6F8",
                    fgColor: "#2E3843",
                },
            },
            items: {
                status: {
                    default: {
                        sections: {
                            tabs1: {
                                colors: {
                                    bgColor: theme?.colors?.defaultBackground,
                                    fgColor: theme?.colors?.defaultForeground,
                                },
                            },
                            tabs2: {
                                colors: {
                                    fgColor: "#2E3843",
                                    bgColor: "#FBFBFB",
                                },
                                border: {
                                    color: "#D3DAE1",
                                    width: 1,
                                    radius: 0.5,
                                },
                            },
                        },
                    },
                    active: {
                        sections: {
                            tabs1: {
                                colors: {
                                    fgColor: theme?.colors?.activeForeground,
                                },
                            },
                            tabs2: {
                                colors: {
                                    fgColor: "#FFFFFF",
                                    bgColor: "#76889B",
                                },
                                border: {
                                    color: null,
                                    width: 1,
                                },
                            },
                        },
                    },
                },
            },
            details: {
                colors: {
                    bgColor: "#2E3843",
                    fgColor: "#FBFBFB",
                },
            },
            section1: {
                colors: {
                    bgColor: theme?.colors?.defaultBackground,
                    fgColor: theme?.colors?.defaultForeground,
                    iconColor: theme?.colors?.activeForeground,
                },
            },
            section2: {
                status: {
                    default: {
                        sections: {
                            general: {
                                colors: {
                                    bgColor: theme?.colors?.defaultBackground,
                                    fgColor: theme?.colors?.defaultForeground,
                                },
                            },
                            button: {
                                colors: {
                                    bgColor: "#FBFBFB",
                                    fgColor: "#2E3843",
                                },
                                border: {
                                    color: "#D3DAE1",
                                    width: 1,
                                    radius: 0,
                                },
                            },
                        },
                    },
                    disabled: {
                        sections: {
                            button: {
                                colors: {
                                    bgColor: "#D3DAE1",
                                    fgColor: "#FBFBFB",
                                },
                                border: {
                                    color: null,
                                    width: 0,
                                },
                            },
                        },
                    },
                },
            },
        },
    };
};

export default Sales;
