import pkg from "../package.json";
import { getChainRef, getAppRef } from "./actions/config";
import { isPortal } from "./utils/config";

export const storagePrefix = isPortal() ? "zportal_" : "zapp_";

export const loadState = () => {
    try {
        const serializedData = localStorage.getItem(storagePrefix + "state");
        if (!serializedData) {
            return undefined;
        }
        let lastState = JSON.parse(serializedData);
        // We use this data to be able to control and invalidate the local storages in future versions if necessary
        if (lastState !== undefined && lastState !== null) {
            if (
                lastState.storeVersion === undefined ||
                !lastState.storeVersion ||
                lastState.storeVersion < pkg.storeVersion ||
                lastState.chainRef !== getChainRef() ||
                lastState.appRef !== getAppRef()
            ) {
                console.info("Invalid local storage", {
                    store: {
                        version: lastState.storeVersion,
                        chainRef: lastState.chainRef,
                        appRef: lastState.appRef,
                    },
                    valid: {
                        version: pkg.storeVersion,
                        chainRef: getChainRef(),
                        appRef: getAppRef(),
                    },
                });
                lastState = undefined;
            }
        }
        return lastState;
    } catch (error) {
        console.error(error);
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        if (state === null || state === undefined) {
            localStorage.removeItem(storagePrefix + "state");
        } else {
            const serializedData = JSON.stringify(state);
            localStorage.setItem(storagePrefix + "state", serializedData);
        }
    } catch (error) {
        console.error(error);
    }
};
