import React from "react";
import { useTranslation } from "react-i18next";

const TextDivider = ({ text, translatable }) => {
    const { t } = useTranslation();

    return <div className=" border-b border-neutral-contrast-10 font-bold">{translatable ? t(text) : text}</div>;
};

export default TextDivider;
