export const fetchInvoiceData = (selectedHotelRef, roomNumber, rooms, hotels, setLoading, getInvoice) => {
    if (selectedHotelRef && roomNumber && rooms && hotels && hotels.length > 0) {
        const hotel = hotels.filter((h) => h.ref === selectedHotelRef);
        let roomsInHotel = rooms[selectedHotelRef];
        if (hotel && hotel.length > 0 && roomsInHotel) {
            let roomInHotel = roomsInHotel[roomNumber];
            if (roomInHotel) {
                roomInHotel.number = roomNumber;
                getInvoice({
                    variables: {
                        hotelRef: selectedHotelRef,
                        roomNumber: roomNumber,
                        password: roomInHotel.code,
                    },
                });
            } else {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    } else {
        setLoading(false);
    }
};

export const arrangeItems = (items) => {
    const response = [];
    if (items && items.length > 0) {
        items.forEach((item) => {
            let date = new Date(item.date).toISOString().split("T")[0];
            response.push({
                name: item.description,
                price: item.price,
                date: date,
                quantity: item.quantity,
            });
        });
    }
    return response;
};
