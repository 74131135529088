import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Tabs from "../components/Tabs";
import imageHelper from "../assets/images/addroom-help.png";
import swal from "@sweetalert/with-react";
import AddRoomAlert from "../components/AddRoom";
import { getSelectedHotel, setSelectedHotel, setSelectedRoom } from "../actions/hotel";
import { useHotels } from "../graphql/useHotels";
import Loading from "../components/Loading";
import useAddRoom from "../hooks/useAddRoom";
import { markdown } from "utils/others";

const AddRoom = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { project: paramProject } = useParams();
    const searchParams = new URLSearchParams(window.location.search);
    const paramRoom = searchParams ? searchParams.get("room") : null;
    const paramCode = searchParams ? searchParams.get("code") : null;

    const project = useSelector(getSelectedHotel);

    const [redirectTo, setRedirectTo] = useState("");
    const { loading: loadingHotels } = useHotels({ autoload: true });
    const { addRooms, loading: addingRoom } = useAddRoom({ project });

    const switchingProject = paramProject && project?.ref !== paramProject;

    if (switchingProject) {
        dispatch(setSelectedHotel(paramProject));
    }

    const onRoomAdded = (number) => {
        return (success) => {
            if (success) {
                dispatch(setSelectedRoom(number));
                setRedirectTo("/site");
            }
        };
    };

    const onError = () => {
        return (e) => {
            console.error(e);
        };
    };

    const handleForm = async (data) => {
        addRooms([{ number: data.room, code: data.code }], { check: true, checkWiFi: true })
            .then(onRoomAdded(data.room))
            .catch(onError());
    };

    useEffect(() => {
        if (paramRoom && paramCode) {
            window.history.replaceState({}, "", window.location.pathname);
            addRooms([{ number: paramRoom, code: paramCode }], { check: true, checkWiFi: true })
                .then(onRoomAdded(paramRoom))
                .catch(onError());
        }
    }, []);

    if (!switchingProject && !project) {
        return <Redirect push to="/site/list" />;
    }

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    return (
        <>
            {switchingProject || loadingHotels ? (
                <Loading />
            ) : (
                <section className={"basic has-top" + (addingRoom ? "" : " has-tabs")}>
                    <Header
                        title={t("add room")}
                        backAction={
                            addingRoom
                                ? null
                                : () => {
                                      setRedirectTo("/site");
                                  }
                        }
                    />
                    <section>
                        {addingRoom ? (
                            <Loading />
                        ) : (
                            <div className=" shadow-xl p-8">
                                <p className=" w-full text-center font-bold">{markdown(t("add room info"))}</p>
                                <p className=" w-full text-center">
                                    {markdown(t("contact-our-staff-more-information"))}
                                </p>
                                <AddRoomAlert onSuccess={handleForm}></AddRoomAlert>
                                <footer>
                                    <Link to="addroom/qr" className="btn text-accent font-bold text-lg w-full">
                                        {t("scan qr")}
                                    </Link>
                                </footer>
                            </div>
                        )}
                    </section>
                    {addingRoom ? null : <Tabs />}
                </section>
            )}
        </>
    );
};

export default AddRoom;
