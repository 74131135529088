import React from "react";
import Color from "color";

const Loading = ({ className, color }) => {
    const color20 = color ? Color(color).alpha(0.2).string() : null;
    const color30 = color ? Color(color).alpha(0.3).string() : null;
    const styleA = color ? { borderBottomColor: color20 } : null;
    const styleB = color ? { borderBottomColor: color30 } : null;    
    return (
        <div className={"zloading " + className}>
            <div>
                <div>
                    <div className="up outer outer-18" style={styleB}></div>
                    <div className="down outer outer-17" style={styleB}></div>
                    <div className="up outer outer-16" style={styleA}></div>
                    <div className="down outer outer-15" style={styleB}></div>
                    <div className="up outer outer-14" style={styleB}></div>
                </div>
                <div>
                    <div className="up outer outer-1" style={styleB}></div>
                    <div className="down outer outer-2" style={styleA}></div>
                    <div className="up inner inner-6" style={styleA}></div>
                    <div className="down inner inner-5" style={styleA}></div>
                    <div className="up inner inner-4" style={styleA}></div>
                    <div className="down outer outer-13" style={styleA}></div>
                    <div className="up outer outer-12" style={styleB}></div>
                </div>
                <div>
                    <div className="down outer outer-3" style={styleB}></div>
                    <div className="up outer outer-4" style={styleA}></div>
                    <div className="down inner inner-1" style={styleA}></div>
                    <div className="up inner inner-2" style={styleA}></div>
                    <div className="down inner inner-3" style={styleA}></div>
                    <div className="up outer outer-11" style={styleA}></div>
                    <div className="down outer outer-10" style={styleB}></div>
                </div>
                <div>
                    <div className="down outer outer-5" style={styleB}></div>
                    <div className="up outer outer-6" style={styleB}></div>
                    <div className="down outer outer-7" style={styleA}></div>
                    <div className="up outer outer-8" style={styleB}></div>
                    <div className="down outer outer-9" style={styleB}></div>
                </div>
            </div>
        </div>
    );
};

export default Loading;
