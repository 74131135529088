import React, { useRef, useEffect, useState } from "react";

import useWidget from "../../../hooks/useWidget";
import Render from "zrender/render";
import { getDasUrl } from "../../../actions/config";
import { getDeviceWidth } from "../DesignUtils";
import { useSwipeable } from "react-swipeable";
import { processActions } from "../DesignUtils";

const UseWidgetSlider = ({ config, id, deviceType, data, goToSection }) => {
    const { styles, lang } = useWidget({ type: "SLIDER", styles: config ? config.styles : null });
    const configSlider = data?.config?.slider;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(false);
    const [isReel, setIsReel] = useState(true);

    const deviceWidth = getDeviceWidth();
    const items = arrangeItems({
        items: configSlider.items || [],
        lang: lang,
        id: id,
        currentImage: currentImage,
        setCurrentImage: setCurrentImage,
        goToSection: goToSection,
    });

    const renderRef = useRef(new Render("SLIDER"));
    const render = renderRef.current;
    render.DAS = getDasUrl;

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if (isReel !== true) {
                // console.log(eventData);
                if (eventData.dir === "Left") {
                    updateIndex(currentIndex + 1);
                } else if (eventData.dir === "Right") {
                    updateIndex(currentIndex - 1);
                }
            } else {
                const directionMultiplier = eventData.dir === "Left" ? 1 : -1;
                const swipeVelocity = Math.abs(eventData.velocity);
                const scrollDistance = Math.min(100 + swipeVelocity * 100, 500);
                setHorizontalScrollPosition(id, directionMultiplier * scrollDistance);
            }
        },
        trackMouse: true,
    });

    const updateIndex = (newIndex) => {
        if (newIndex < 0) {
            newIndex = 0;
        } else if (newIndex >= items.length) {
            newIndex = items.length - 1;
        }
        setCurrentIndex(newIndex);
        setCurrentImage(items[newIndex]);
    };

    useEffect(() => {
        setIsReel(configSlider?.mode === "simple" ? false : true);
    }, [configSlider]);

    useEffect(() => {
        if (render) {
            render.setStyle(styles);
            render.setDevice(deviceType, deviceWidth);
            render.updateConfig({
                static: false,
                mode: configSlider?.mode || "simple",
                reelSize: configSlider?.reelSize || 27,
                lang: lang,
                items: items || [],
                swipeEvents: true,
                events: {
                    click: (e) => {
                        const indexBullet = e?.target?.dataset?.indexBullet || 0;
                        setCurrentIndex(Number(indexBullet));
                        setCurrentImage(items[indexBullet]);
                    },
                },
                id: id,
                imageSimple: {
                    currentImage: currentImage,
                    currentIndex: currentIndex,
                },
            });
            render.update();
        }
    }, [styles, deviceType, configSlider, currentImage]);

    useEffect(() => {
        const renderNode = document.getElementById(id);

        if (renderNode) {
            while (renderNode.firstChild) {
                renderNode.removeChild(renderNode.firstChild);
            }
            if (render) {
                renderNode.appendChild(render.run());
            }
        }
    }, [id]);

    const setHorizontalScrollPosition = (containerId, deltaScrollX) => {
        const container = document.getElementById(containerId);
        if (container && window.innerWidth >= 768) {
            container.scrollLeft += deltaScrollX;
        }
    };

    return (
        <div
            {...handlers}
            id={id}
            style={{
                scrollBehavior: "smooth",
                height: "100%",
            }}
            className="w-full widget-slider-container overflow-x-scroll overflow-y-hidden h-full"
        ></div>
    );
};

export default UseWidgetSlider;

export const arrangeItems = ({ items, lang, setCurrentImage, currentImage, id, goToSection }) => {
    const { goToCuiner, goToLanding, goToRoomShop, landing } = goToSection;

    const finalItems = items
        .sort((a, b) => a.position - b.position)
        .filter((item) => item?.visibility)
        .map((item) => {
            let link = { target: null, id: null, href: null, onClick: null };
            link = processActions({
                data: { actions: arrangeActions(item) },
                id,
                landing,
                lang,
                goToLanding,
                goToCuiner,
                goToRoomShop,
                dasURL: getDasUrl(),
                link,
            });

            return {
                actions: arrangeActions(item),
                text: item.text.text,
                link: link,
                events: {
                    click: () => {
                        alert("click!");
                    },
                },
                src:
                    item?.resource?.libraryRef || item?.resource.externalUrl
                        ? { externalUrl: item?.resource.externalUrl, libraryRef: item?.resource?.libraryRef }
                        : null,
            };
        });

    if (!currentImage && finalItems.length > 0) {
        setCurrentImage(finalItems[0]);
    }

    return finalItems;
};

const arrangeActions = (item) => {
    return item.actions.filter((action) => !(action.type === null && action.value === ""));
};
