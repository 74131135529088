import { combineReducers } from "redux";
import reducerUser from "./reducerUser";
import reducerSession from "./reducerSession";
import reducerCache from "./reducerCache";
import reducerPortal from "./reducerPortal";
import { v5 as uuidv5 } from "uuid";
import pkg from "../../package.json";
import { getChainRef, getAppRef } from "../actions/config";

export const deviceUID = () => {
    // device unique uid (generated first time)
    return uuidv5(window.location.host, uuidv5.URL);
};

const appReducer = combineReducers({
    session: reducerSession,
    user: reducerUser,
    cache: reducerCache,
    uid: deviceUID,
    chainRef: getChainRef,
    appRef: getAppRef,
    portal: reducerPortal,
    storeVersion: () => pkg.storeVersion,
});

const reducer = (state, action) => {
    if (action.type === "clear") {
        state = {
            session: reducerSession(),
            user: reducerUser(),
            cache: reducerCache(),
            uid: deviceUID,
            storeVersion: () => pkg.storeVersion,
        };
    }
    return appReducer(state, action);
};

export default reducer;
