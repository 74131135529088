import Keys from "../views/Keys";
import pkg from "../../package.json";

export const keysRoutes = (prefix = "") => {
  if (!pkg.features.keys) {
    return [];
  }
  return [
    {
      path: prefix,
      exact: true,
      section: Keys,
    },
  ];
};
