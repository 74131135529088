import i18n from "i18n";

export const handleShowTerms = (accessConditions, swal) => {
    let span = document.createElement("span");
    span.innerHTML = `<div class="text-left">${accessConditions}</div>`;
    swal({
        title: i18n.t("terms & conditions"),
        content: span,
        className: "w-full",
        buttons: {
            cancel: i18n.t("close"),
        },
    });
    return false;
};

export const handleShowPrivacy = (privacyPolicy, swal) => {
    let span = document.createElement("span");
    span.innerHTML = '<div class="text-left">' + privacyPolicy + "</div>";
    swal({
        title: i18n.t("privacy policy"),
        content: span,
        className: "w-full",
        buttons: {
            cancel: i18n.t("close"),
        },
    });
    return false;
};
