import { decode as base64_decode } from "base-64";

export function decodeToken(token) {
    const info = {
        projectRef: null,
        rooms: null,
        error: null,
    };
    try {
        const data = JSON.parse(base64_decode(decodeURIComponent(token)));
        info.projectRef = data?.projectRef;
        if (data?.rooms?.length > 0) {
            const selectedRoom = data.rooms.find((room) => room.number === data.selectedRoom);
            info.rooms = data.rooms.filter((room) => room.number !== data.selected).concat(selectedRoom);
            info.selected = data.selectedRoom;
        }
    } catch (e) {
        console.error(e);
        info.error = e;
    }
    return info;
}
