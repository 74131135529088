import color from "@jiaminghi/color";

export const applyTheme = (theme) => {
    const themeObject = mapTheme(theme);
    if (!themeObject) {
        return;
    }
    const root = document.documentElement;
    Object.keys(themeObject).forEach((property) => {
        if (property === "mode") {
            return;
        }
        root.style.setProperty(property, themeObject[property]);
    });
};

const mapTheme = (variables) => {
    const variants = ["accent", "neutral", "primary", "secondary", "error", "warning", "success", "login"];

    if (!variables) {
        variables = {};
    }
    const colors = {
        "--color-error": variables ? variables.error || "rgba(255,0,0,.5)" : "rgba(255,0,0,.5)",
    };

    for (const element of variants) {
        const result = mapVariant(element, variables);
        Object.keys(result).forEach(function (key) {
            colors[key] = result[key];
            return null;
        });
    }
    return colors;
};

const mapVariant = (themeColor, variables) => {
    const colors = {};
    const subvariants = ["Medium", "Contrast"];

    for (const element of subvariants) {
        const result = mapSubvariant(themeColor, element, variables);
        Object.keys(result).forEach(function (key) {
            colors[key] = result[key];
            return null;
        });
    }
    return colors;
};

const mapSubvariant = (themeColor, themeVariant, variables) => {
    const colors = {};
    const cssProps = [themeColor, themeColor + "-" + themeVariant.toLowerCase()];
    const varProps = [themeColor, themeColor + themeVariant];
    for (let i = 0; i < cssProps.length; i++) {
        const cssKey = cssProps[i];
        const varKey = varProps[i];

        // Color
        const colorKey = `--color-${cssKey}`;
        let colorValue = "rgba(0,0,0,.5)";
        if (variables && variables[varKey]) {
            colorValue = variables[varKey];
        }
        colors[colorKey] = colorValue;

        // Color alphas
        let alpha;
        for (alpha = 10; alpha < 100; alpha += 10) {
            const colorAlphaKey = `--color-${cssKey}-${alpha}`;
            let colorAlphaValue = `rgba(0,0,0,${alpha / 100})`;
            if (variables && variables[varKey]) {
                colorAlphaValue = color.toRgb(variables[varKey], alpha / 100);
            }
            colors[colorAlphaKey] = colorAlphaValue;
        }
    }
    return colors;
};

export const defaultTheme = {
    mode: "light",
    accent: "#333",
    accentContrast: "#FFF",
    error: "#F00",
    warning: "#F90",
    success: "#690",
    neutral: "#FFF",
    neutralMedium: "#DDD",
    neutralContrast: "#000",
    login: "#FFF",
    loginContrast: "#000",
    primary: "#EEE",
    primaryContrast: "#000",
    secondary: "#666",
    secondaryContrast: "#FFF",
};
