import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useIncomingAlert from "../hooks/useIncomingAlert";
import { setEnsureUserCallback } from "../actions/hotel";
import { useTranslation } from "react-i18next";
import { getUserData } from "../actions/user";

const useZonesMessage = (initialIframePath, setIncoming) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [iframePath, setIframePath] = useState(initialIframePath);
    const [redirectTo, setRedirectTo] = useState("");
    const { incomingAlert } = useIncomingAlert();
    const user = useSelector(getUserData);

    const parseMessage = (page, message) => {
        if (message && message.action) {
            switch (message.action) {
                case "ensureUser":
                    if (user && user.emailVerified) {
                        if (message.params && message.params.page) {
                            setIframePath(message.params.page);
                        }
                    } else {
                        if (message.params && message.params.page) {
                            dispatch(setEnsureUserCallback(message.params.page));
                        }
                        if (user) {
                            setRedirectTo(page + "/verify");
                        } else {
                            setRedirectTo(page + "/signin");
                        }
                    }
                    break;
                case "showPage":
                    if (message.params && message.params.page) {
                        setIframePath(message.params.page);
                    }
                    break;
                case "showAreas":
                    if (page === "zones") {
                        setIframePath("/");
                    } else {
                        setRedirectTo("/site/zones");
                    }
                    break;
                case "showReserves":
                    if (page === "booking") {
                        setIframePath("/?status=pending");
                    } else {
                        setRedirectTo("/site/booking?status=pending");
                    }
                    break;
                case "exit":
                    setRedirectTo("/site");
                    break;
                case "showAlert":
                    incomingAlert(message.params, setIncoming, t("alert ok"));
                    break;
                default:
                    console.error("Unknown action");
                    console.error(message);
            }
        }
    };
    return {
        parseMessage,
        iframePath,
        redirectTo,
    };
};

export default useZonesMessage;
