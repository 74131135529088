import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApolloError } from "@apollo/client";
import { nl2br } from "./Design/DesignUtils";

const ErrorMessage = ({ children, type, title: forceTitle, error }) => {
    const { t } = useTranslation();

    const [errorTouches, setErrorTouches] = useState(0);
    const showDebug = errorTouches >= 5;

    const errorDebug = (() => {
        if (error) {
            if (error instanceof ApolloError) {
                if (error.graphQLErrors?.length > 0) {
                    return JSON.stringify(error.graphQLErrors);
                } else if (error.networkError) {
                    if (error.networkError.result?.errors?.length > 0) {
                        return error.networkError.result.errors.map((e) => e.message).join("\n");
                    }
                    return JSON.stringify(error.networkError);
                } else {
                    return `${error.name}: ${error.message}`;
                }
            } else if (error instanceof Error) {
                return error.stack || error.message;
            } else {
                return error;
            }
        }
        return null;
    })();

    const errorMessage = (() => {
        if (error) {
            if (error instanceof Error) {
                return error.toString();
            } else {
                return error;
            }
        }
        return null;
    })();

    const errorID = (() => {
        if (error) {
            if (error?.code) {
                return error.code;
            } else if (error instanceof ApolloError) {
                return "GQL_ERROR";
            } else if (error instanceof Error) {
                return "JS_ERROR";
            }
        }
        return null;
    })();

    const title = forceTitle || t("error.title");

    if (type) {
        children = (
            <>
                {t([`error.${type}`, "error.unknown"])}
                <br />
                <small>{t([`error.${type}Action`, "error.unknownAction"])}</small>
            </>
        );
    }

    return (
        <div
            className="bg-neutral-50 text-error p-5 rounded shadow"
            id="error-message"
            onClick={() => {
                setErrorTouches(errorTouches > 10 ? 0 : errorTouches + 1);
            }}
        >
            <p className="font-bold text-lg">{title}</p>
            {children}
            {showDebug && errorMessage ? <div className="text-xs mt-5 leading-6">{nl2br(errorMessage)}</div> : null}
            {showDebug && errorID ? <div className="text-xs mt-5 leading-6">Error {errorID}</div> : null}
            {showDebug && errorDebug ? (
                <div className="select-all text-left text-xs mt-5 leading-6 break-all text-error bg-white p-2">
                    {nl2br(errorDebug)}
                </div>
            ) : null}
        </div>
    );
};

export default ErrorMessage;
