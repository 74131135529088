import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ErrorMessage from "../components/ErrorMessage";
import Header from "../components/Header";
import Loading from "../components/Loading";
import Tabs from "../components/Tabs";
import { getRoomNumber } from "../modules/rooms/store";
import { getSelectedHotel } from "../actions/hotel";
import { MessagesContext } from "../contexts/messages";
import { MESSAGE_ALERT_TYPES } from "../utils/messages";
import Message from "../components/Message";
import { useMarkMessagesAsRead } from "../graphql/useMessages";
import { nl2br } from "../components/Design/DesignUtils";

const Messages = () => {
    const { t } = useTranslation();

    const { messages, loading, error } = useContext(MessagesContext);

    const project = useSelector(getSelectedHotel);
    const roomNumber = useSelector(getRoomNumber);

    const [message, setMessage] = useState(null);

    const { markMessagesAsRead, error: setReadError } = useMarkMessagesAsRead();

    const roomMessages = messages
        ?.filter((m) => m.roomNumber === roomNumber && m.projectRef === project?.ref)
        .sort(function (a, b) {
            return new Date(b?.sentTime) - new Date(a?.sentTime);
        });

    useEffect(() => {
        if (setReadError) {
            console.error(setReadError);
        }
    }, [setReadError]);

    return (
        <section className="hotels has-tabs has-top">
            <Header title={t("messages")}></Header>
            <section style={{ paddingLeft: 0, paddingRight: 0 }}>
                {loading ? <Loading /> : null}
                {error ? <ErrorMessage type="connection" error={error} /> : null}
                {message ? (
                    <Message
                        message={message}
                        onClose={() => {
                            setMessage(null);
                            if (message?.isRead !== true) {
                                markMessagesAsRead({
                                    variables: {
                                        projectRef: message?.projectRef,
                                        messageIDs: [message?.id],
                                    },
                                });
                            }
                        }}
                    />
                ) : null}
                {!message && messages ? (
                    <>
                        <div className="w-full text-center pb-4 border-b">
                            <span className="block text-3xl font-bold mb-3">{t("room x", { room: roomNumber })}</span>
                            <span className="block text-lg font-medium">{project?.name}</span>
                        </div>
                        {roomMessages?.length>0 ? (
                            <ul className="notif-list">
                                {roomMessages.map((message) => {
                                    const id = message?.id;
                                    const isRead = message?.isRead === true;
                                    const dateInfo = message?.today
                                        ? t("today at", { hour: message?.hour })
                                        : t("{{date, date}}", { date: message?.sentTime });
                                    const title = message?.title;
                                    const bodyLength = message?.plainBody?.length;
                                    const body = message?.plainBody?.split("\n").slice(0, 2).join("\n");
                                    const showBookmarked = MESSAGE_ALERT_TYPES.includes(message?.priority);

                                    return (
                                        <li
                                            key={`message_${id}`}
                                            onClick={() => {
                                                setMessage(message);
                                            }}
                                            className={!isRead ? "selected" : ""}
                                        >
                                            <div className="title">
                                                {showBookmarked ? (
                                                    <i className="icon icon-union text-yellow-500 mr-1  "></i>
                                                ) : null}
                                                {title}
                                            </div>
                                            <div className="body">
                                                {nl2br(bodyLength > 140 ? body.substring(0, 140) + "..." : body)}
                                            </div>
                                            <div>
                                                <small className="date">{dateInfo}</small>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        ): (
                            <div className="w-full text-center mt-5">
                                <span className="">{t("you have not received any messages for this room")}</span>
                            </div>
                        )}
                    </>
                ) : null}
            </section>
            <Tabs />
        </section>
    );
};

export default Messages;
