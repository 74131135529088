import SignOut from "../views/SignOut";
import SignIn from "../views/SignIn";
import SignInEmail from "../views/SignInEmail";
import Forgot from "../views/Forgot";
import SignUp from "../views/SignUp";
import Verify from "../views/Verify";
import VerifyEmail from "../views/VerifyEmail";
import Terms from "../views/Terms";
import Privacy from "../views/Privacy";

export const authRoutes = (prefix = "") => {
    return [
        {
            path: prefix + "/signout",
            exact: true,
            section: SignOut,
        },
        {
            path: prefix + "/signin",
            exact: true,
            section: SignIn,
        },
        {
            path: prefix + "/signin/google/:googleTokenID",
            exact: true,
            section: SignIn,
        },
        {
            path: prefix + "/signin/email",
            exact: true,
            section: SignInEmail,
        },
        {
            path: prefix + "/signin/email/:type/:email",
            exact: true,
            section: SignInEmail,
        },
        {
            path: prefix + "/signin/email/:email",
            exact: true,
            section: SignInEmail,
        },
        {
            path: prefix + "/signin/forgot",
            exact: true,
            section: Forgot,
        },
        {
            path: prefix + "/signup",
            exact: true,
            section: SignUp,
        },
        {
            path: prefix + "/signup/terms",
            exact: true,
            section: Terms,
        },
        {
            path: prefix + "/signup/privacy",
            exact: true,
            section: Privacy,
        },
        {
            path: prefix + "/verify",
            exact: true,
            section: Verify,
        },
        {
            path: prefix + "/verify/email",
            exact: true,
            section: VerifyEmail,
        },
    ];
};
