import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { size as renderSize } from "zrender/sizes";
import { getDeviceWidth } from "./Design/DesignUtils";
import { printPrice } from "../utils/shop";
import CheckBox from "./CheckBox";
import { QuantityButtons } from "./QuantityButton";

const ShopExtra = (props) => {
    const size = (v) => renderSize(v, "mobile", getDeviceWidth());

    const id = props?.id;
    const lang = props?.lang || "en";
    const type = props?.type;
    const isCheckbox = type === "normal";
    const isNumeric = type === "numeric";
    const isComboBox = type === "combobox" || type === "multicombobox";
    const isMultiComboBox = type === "multicombobox";
    const name = props?.name;
    const options = props?.options;
    const hasOptions = options?.length > 0;
    const requiredOptions = props?.min || 0;
    const maxQuantity = props?.max;
    const currency = props?.currencyCode;
    const pricesIncludeTax = props?.pricesIncludeTax;
    const extraPrice = pricesIncludeTax ? props?.priceWithTax : props?.price;
    const useRadioButton = isComboBox && !isMultiComboBox && maxQuantity === 1;

    const cartSelections = props?.cart?.selections;
    const cartQuantity = props?.cart?.quantity;

    const { t: tr } = useTranslation();
    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const [total, setTotal] = useState(0);
    const [quantity, setQuantity] = useState(isComboBox ? 1 : cartQuantity);
    const [checked, setChecked] = useState(cartQuantity > 0);
    const [open, setOpen] = useState(false);
    const [selections, setSelections] = useState({ ...cartSelections });
    const price = isCheckbox || isNumeric ? extraPrice : total;

    const selectedQuantity = Object.keys(selections)
        .filter((k) => selections[k])
        .reduce((a, b) => a + selections[b]?.quantity, 0);

    const disabled = maxQuantity > 0 && selectedQuantity >= maxQuantity;
    const completed = selectedQuantity >= requiredOptions;

    const transferProps = { ...props };
    delete transferProps.name;
    delete transferProps.type;
    delete transferProps.pricesIncludeTax;
    delete transferProps.currencyCode;
    delete transferProps.min;
    delete transferProps.max;
    delete transferProps.options;
    delete transferProps.price;
    delete transferProps.priceWithTax;
    delete transferProps.lang;
    delete transferProps.total;
    delete transferProps.onChange;
    delete transferProps.selections;

    const styles = {
        extraItem: {
            paddingTop: size(0.5),
            paddingBottom: size(0.5),
        },
    };

    const isClickable = isCheckbox || isComboBox || props?.onClick;

    const clickHandler = (e) => {
        e.stopPropagation();
        if (isCheckbox) {
            setChecked(!checked);
        } else if (isComboBox) {
            setOpen(!open);
        }
        if (props?.onClick) {
            props?.onClick(e);
        }
    };

    const setSelection = (selection, selected, quantity, price) => {
        if (selection?.id) {
            const newSelections = {
                ...(useRadioButton ? null : selections),
                [selection?.id]: {
                    quantity,
                    type,
                    name: selection?.name,
                    price,
                },
            };
            if (!selected || quantity === 0) {
                delete newSelections[selection?.id];
            }
            setSelections(newSelections);
        }
    };

    const fullClickable = isClickable && (isCheckbox || !open);

    useEffect(() => {
        const totalPrice = isComboBox
            ? Object.keys(selections).reduce((a, b) => a + selections[b]?.price * selections[b]?.quantity, 0)
            : price;
        setTotal(totalPrice * quantity);
        if (props?.onChange) {
            props.onChange(
                completed,
                selections,
                totalPrice,
                !isComboBox || Object.keys(selections).length > 0 ? quantity : 0
            );
        }
    }, [completed, selections, price, quantity]);

    useEffect(() => {
        if (isCheckbox) {
            setQuantity(checked ? 1 : 0);
        }
    }, [checked]);

    return (
        <div
            {...transferProps}
            style={{
                ...(fullClickable ? { cursor: "pointer" } : null),
                ...props?.style,
            }}
            onClick={fullClickable ? clickHandler : null}
        >
            <div
                style={{
                    fontSize: size(1.25),
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: isCheckbox || isNumeric ? "center" : "flex-start",
                    flexWrap: "nowrap",
                }}
            >
                <div
                    style={{
                        padding: size(0.5) + " " + size(1),
                        textAlign: "center",
                        ...(isClickable && !fullClickable ? { cursor: "pointer" } : null),
                    }}
                    id={`${id}-icon`}
                    onClick={isClickable && !fullClickable ? clickHandler : null}
                >
                    {isComboBox ? <i className={`icon icon-${open ? "up" : "down"}`}></i> : null}
                    {isCheckbox ? (
                        <CheckBox
                            id={`${id}-action-check`}
                            disabled={disabled && !checked}
                            checked={checked}
                            onChange={setChecked}
                        />
                    ) : null}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            ...styles.extraItem,
                            ...(isClickable && !fullClickable ? { cursor: "pointer" } : null),
                        }}
                        id={`${id}-name`}
                        onClick={isClickable && !fullClickable ? clickHandler : null}
                    >
                        <div>
                            {name}
                            {selectedQuantity > 0 ? (
                                <span style={{ color: "#76889B", fontSize: ".8em", paddingLeft: size(0.5) }}>
                                    ({t("x selected", { count: selectedQuantity })})
                                </span>
                            ) : null}
                            {isNumeric && price > 0 ? (
                                <div style={{ color: "#76889B", paddingTop: size(0.5) }}>
                                    {"+ " + printPrice(price, currency, lang)}
                                </div>
                            ) : null}
                            {requiredOptions > 0 ? (
                                <div
                                    style={{
                                        color: "#76889B",
                                        ...(open
                                            ? {
                                                  fontSize: "1em",
                                                  marginTop: ".5em",
                                              }
                                            : {
                                                  fontSize: ".8em",
                                              }),
                                    }}
                                >
                                    {(!open ? "* " : "") + t("x options required", { count: requiredOptions })}
                                </div>
                            ) : null}
                        </div>
                        {isNumeric ? (
                            <QuantityButtons
                                id={`${id}-quantity`}
                                disabled={disabled && quantity < 1}
                                max={disabled ? quantity : maxQuantity}
                                value={quantity}
                                hideZero={true}
                                onNewValue={setQuantity}
                            />
                        ) : null}
                        {!isNumeric && price > 0 ? (
                            <div style={{ color: "#76889B" }}>{"+ " + printPrice(price, currency, lang)}</div>
                        ) : null}
                    </div>

                    {hasOptions && open ? (
                        <div style={{ marginTop: size(0.5) }}>
                            {options?.map((c, index) => {
                                const price = pricesIncludeTax ? c.priceWithTax : c.price;
                                const selected = selections[c.id] || false;
                                const quantity = selections[c.id]?.quantity || 0;
                                return (
                                    <div key={`choice_${c.id}`} style={{ ...styles.extraItem }}>
                                        {isMultiComboBox ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    flexWrap: "nowrap",
                                                }}
                                            >
                                                <div>
                                                    {c.name}
                                                    {price > 0 ? (
                                                        <div style={{ color: "#76889B", fontSize: ".8em" }}>
                                                            {"+ " + printPrice(price, currency, lang)}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <QuantityButtons
                                                    id={`${id}-${index + 1}-quantity`}
                                                    value={quantity}
                                                    hideZero={true}
                                                    disabled={disabled && quantity < 1}
                                                    max={disabled ? quantity : maxQuantity}
                                                    onNewValue={(value) => {
                                                        setSelection(c, value > 0, value, price);
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    flexWrap: "nowrap",
                                                }}
                                            >
                                                <CheckBox
                                                    id={`${id}-${index + 1}-check`}
                                                    checked={selected}
                                                    disabled={useRadioButton ? false : disabled && !selected}
                                                    style={{ marginRight: size(0.5) }}
                                                    onChange={(value) => {
                                                        setSelection(c, value, value ? 1 : 0, price);
                                                    }}
                                                    text={_.escape(c.name)}
                                                    radioButton={useRadioButton}
                                                />
                                                {price > 0 ? (
                                                    <div style={{ color: "#76889B", fontSize: ".8em" }}>
                                                        {"+ " + printPrice(price, currency, lang)}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ShopExtra;
