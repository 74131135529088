import React, { useState } from "react";
import Header from "../components/Header";
import Tabs from "../components/Tabs";
import { useTranslation } from "react-i18next";
import isoCountries from "../utils/countries";

const CountrySearch = (params) => {
    const { t } = useTranslation();

    const setCountry = params.set || function () {};
    const setCountrySearch = params.show || function () {};

    const [search, setSearch] = useState(params.search || "");

    const selectCountry = (iso) => {
        setCountry(iso);
        setCountrySearch(false);
    };

    // Inicializar countriesFiltered con todos los países
    let countriesFiltered = isoCountries || [];

    if (search.trim() !== "") {
        const lowerSearch = search.toLowerCase();
        countriesFiltered = countriesFiltered.filter((iso) => {
            const name = t("country:" + iso);
            return name.toLowerCase().includes(lowerSearch) || lowerSearch === iso;
        });
    }

    return (
        <section className="basic has-tabs has-top">
            <Header backAction={() => selectCountry("")}>
                <input
                    type="text"
                    className="search-top primary"
                    placeholder={t("search country")}
                    autoFocus={true}
                    onChange={(e) => {
                        if (typeof e.target.value === "string") {
                            setSearch(e.target.value);
                        }
                    }}
                />
            </Header>
            <section>
                <ul id="result-list">
                    {countriesFiltered.map((iso, index) => {
                        return (
                            <li key={index} onClick={() => selectCountry(iso)} className="border-b text-left">
                                <button className="flex justify-between w-full py-3">
                                    <span>{t("country:" + iso)}</span>
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </section>
            <Tabs />
        </section>
    );
};

export default CountrySearch;
