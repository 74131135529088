import gql from "graphql-tag";

export const SIGN_IN_ROOM = {
    query: gql`
        mutation signInRoom($hotelRef: ID!, $roomNumber: Int, $password: String, $roomShopRef: Int) {
            signInRoom(hotelRef: $hotelRef, roomNumber: $roomNumber, password: $password, roomShopRef: $roomShopRef) {
                token
                guestID
                pairingURL
                salesToken
            }
        }
    `,
};
