import { useEffect } from "react";

const useKeyboard = (event, params) => {
    useEffect(() => {
        const handleKeyDown = (e) => {
            const key = e?.key;
            const targetType = e?.target?.tagName;
            const isInput = targetType === "INPUT" || targetType === "TEXTAREA";
            if (!params?.keys || params?.keys?.includes(key)) {
                if (params?.callback && typeof params?.callback === "function") {
                    params?.callback({
                        event: e,
                        target: e?.target,
                        targetType,
                        key,
                        isInput: isInput,
                    });
                }
            }
        };
        document.addEventListener(event, handleKeyDown);
        return () => {
            document.removeEventListener(event, handleKeyDown);
        };
    }, [params]);
};

export const useKeyDown = (params) => {
    return useKeyboard("keydown", params);
};

export default useKeyboard;
