import { useState, useCallback } from "react";

const usePostMessage = () => {
  const [incoming, setIncoming] = useState({
    action: null,
    params: null,
  });

  const handleIcomingMessage = useCallback(
    (event) => {
      const message = getMessage(event);
      if (message) {
        setIncoming(message);
      }
    },
    [setIncoming]
  );

  return {
    incoming,
    setIncoming,
    handleIcomingMessage,
  };
};

const getMessage = (event) => {
  if (!event || !event.data || event.data.length === 0 || !event.isTrusted) {
    return false;
  }

  if (
    typeof event.data !== "string" &&
    event.data.source &&
    (event.data.source.substr(0, 6) === "react-" ||
      event.data.source.substr(0, 10) === "@devtools-")
  ) {
    return false;
  }

  try {
    return JSON.parse(event.data);
  } catch (e) {
    console.error(e);
  }
  return false;
};

export default usePostMessage;
