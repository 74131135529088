import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { getChainRef, getWifiToken, getToken } from "../actions/config";
import { getToken as getUserToken } from "../actions/user";

export const usePortalData = () => {
    const appToken = useSelector(getToken);
    const userToken = useSelector(getUserToken);
    const portalSession = useSelector((state) => state.portal);
    const token = userToken ? userToken : appToken;
    const portalWiFi = getWifiToken();

    let error;
    let config;

    if (!portalWiFi) {
        error = "Portal data not found";
    } else {
        try {
            config = jwtDecode(portalWiFi);
            if (config && typeof config === "object") {
                if (config.ChainRef != getChainRef()) {
                    error = "Invalid token for this chain";
                } else if (!config.ProjectRef) {
                    error = "Unknown project data";
                } else {
                    const accessTypes = config.AccessTypes?.filter((i) => /^(email|pms|code|open)$/.test(i.Name));
                    if (!accessTypes || accessTypes.length === 0) {
                        error = "Unconfigured portal. Not found access types";
                    }
                }
            }
        } catch (err) {
            console.error(err);
            error = err;
        }
    }

    return {
        config,
        chainRef: config?.ChainRef,
        projectRef: config?.ProjectRef,
        sessionKey: config?.Session,
        session: portalSession,
        token,
        error,
    };
};

export default usePortalData;
