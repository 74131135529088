import React from "react";
import { useTranslation } from "react-i18next";

const TextLabel = ({ label, value }) => {
    const { t } = useTranslation();

    return (
        <div className="label-and-value">
            <label className="text-neutral-contrast-50 font-bold text-sm">{t(label)}</label>
            <div className="text-neutral-contrast">{value ? value : "-"}</div>
        </div>
    );
};

export default TextLabel;
