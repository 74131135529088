import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import _ from "lodash";
import swal from "@sweetalert/with-react";
import {
    validateAviabilityRestaurant,
    getRestaurants,
    checkRestaurantSchedule,
    getMenus,
} from "../../../integrations/useCuiner";
import { normalizeToSearch } from "../../../utils/others";
import ErrorMessage from "../../../components/ErrorMessage";
import Loading from "../../../components/Loading";
import Tabs from "../../../components/Tabs";
import Header from "../../../components/Header";
import { setCuinerData, getCuinerData } from "../../../actions/cuiner";

const Restaurants = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const lang = (i18n.language || "").match(/^[^-]+/)[0];
    const cuinerData = useSelector(getCuinerData);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [restaurants, setRestaurants] = useState(null);
    const [redirectTo, setRedirectTo] = useState("");
    const [restaurantsSearchResults, setRestaurantsSearchResults] = useState(null);
    const [isOnSearchRestaurant, setIsOnSearchRestaurant] = useState(false);

    useEffect(() => {
        refetch();
        return () => {
            localStorage.removeItem("prevLocation");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (restaurants !== null && restaurants.length === 1) {
            if (localStorage.getItem("prevLocation") === "/pos/menus") {
                setRedirectTo("/site");
                return;
            }
            const restaurant = restaurants[0];
            goToRestaurantOrCart(restaurant.restaurant_id, restaurant.name, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurants]);

    const validateMenuAvailability = (menus) => {
        const today = new Date();
        return menus.every((menu) => {
            if (menu.valid_at && menu.valid_at.length > 0) {
                for (var i = 0; i < menu.valid_at.length; i++) {
                    var validAt = menu.valid_at[i];
                    if (validAt) {
                        validAt = new Date(menu.valid_at);
                        return validAt && today <= validAt;
                    }
                }
            }
            return true;
        });
    };

    const arrangeRestaurants = (menus, restaurants) => {
        const newRestaurantsArray = [];
        restaurants.forEach((restaurant) =>
            menus.forEach((menu) => {
                if (
                    menu.restaurant_id === restaurant.restaurant_id &&
                    menu.menus instanceof Array &&
                    menu.menus.length > 0 &&
                    validateMenuAvailability(menu.menus) === false
                ) {
                    newRestaurantsArray.push(restaurant);
                }
            })
        );
        return newRestaurantsArray;
    };

    const refetch = async () => {
        setLoading(true);
        setError(null);
        setRestaurants(null);
        if (cuinerData && cuinerData.api && cuinerData.selectedRoom) {
            const restaurantsResponse = await getRestaurants(cuinerData.api, {
                requireGeneralInfo: true,
                requireCalendarInfo: true,
            });
            if (restaurantsResponse && restaurantsResponse.data) {
                const menusResponse = [];
                await Promise.all(
                    restaurantsResponse.data.map(async (resp) => {
                        const menusResp = await getMenus(cuinerData.api, {
                            restaurantId: resp.restaurant_id,
                        });

                        if (menusResp && menusResp.data) {
                            const menuInfo = {
                                restaurant_id: resp.restaurant_id,
                                menus: menusResp.data,
                            };
                            menusResponse.push(menuInfo);
                        }
                    })
                );
                setRestaurants(arrangeRestaurants(menusResponse, restaurantsResponse.data));
            } else {
                setError(restaurantsResponse.error);
            }
            setLoading(false);
        } else {
            setRedirectTo("/site");
        }
    };

    const goToRestaurantOrCart = (id, name, seeRestaurant = false, goToCart = false) => {
        const currentRestaurant = restaurants.find((element) => element.restaurant_id === id);
        const infoScheduleRestaurant = checkRestaurantSchedule(currentRestaurant.calendarInfo, t, lang);

        if (infoScheduleRestaurant === undefined) {
            return;
        }

        if (
            infoScheduleRestaurant &&
            validateAviabilityRestaurant(currentRestaurant.calendarInfo) === false &&
            seeRestaurant === false
        ) {
            alertOpensRestaurant(infoScheduleRestaurant, currentRestaurant);
            return;
        }

        let cuinerDataClone = { ...cuinerData };
        cuinerDataClone.currentRestaurant = id;
        let restaurant = {
            id: id,
            name: name,
            currentMenu: null,
            availabilities: false,
        };

        if (cuinerDataClone.restaurants && cuinerDataClone.restaurants.length > 0) {
            //Change all selected restaurante to false.
            if (cuinerDataClone.restaurants.filter((item) => item.id === id).length > 0) {
                //pre-selected, only change name variable
                restaurant = cuinerDataClone.restaurants.filter((item) => item.id === id)[0];
                restaurant.name = name;
                restaurant.availabilities = validateAviabilityRestaurant(currentRestaurant.calendarInfo);
                restaurant.calendar = currentRestaurant.calendarInfo;
                var index = _.findIndex(cuinerDataClone.restaurants, { id: id });
                cuinerDataClone.restaurants.splice(index, 1, restaurant);
            } else {
                cuinerDataClone.restaurants.push(restaurant);
            }
        } else {
            cuinerDataClone.restaurants = [];
            restaurant.calendar = currentRestaurant.calendarInfo;
            restaurant.availabilities = validateAviabilityRestaurant(currentRestaurant.calendarInfo);
            cuinerDataClone.restaurants.push(restaurant);
        }
        cuinerDataClone.restaurantNumber = restaurants ? restaurants.length : 0;
        dispatch(setCuinerData(cuinerDataClone));
        setRedirectTo(goToCart === false ? "/pos/menus" : "/pos/cart");
    };

    const restaurantsFilter = (restaurants, restaurantToSearch) => {
        setRestaurantsSearchResults(
            restaurants.filter((restaurant) =>
                normalizeToSearch(restaurant.name).trim().includes(restaurantToSearch.trim().toLowerCase())
            )
        );
        setIsOnSearchRestaurant(true);
    };

    const getCartRestaurant = (restaurants, idRestaurant) => {
        let cartLength = null;
        restaurants.forEach((restaurant) => {
            if (restaurant.id === idRestaurant) {
                cartLength = restaurant.cart ? restaurant.cart.length : null;
            }
        });

        return cartLength;
    };

    const isDataRestaurants = cuinerData && cuinerData.restaurants ? cuinerData.restaurants : [];

    const handleTextAlert = (text) => {
        if (text.includes(t("tomorrow"))) {
            const textSplit = String(text.split(t("tomorrow")));
            return `${t("opens-tomorrow-at")} ${textSplit.replace(",", "").split("-")[0]}`;
        } else if (text.includes(t("Opens"))) {
            return text.trim().charAt(0).toUpperCase() + text.trim().slice(1);
        } else {
            return `${t("Opens at")} ${text.split("-")[0]}`;
        }
    };

    const noResultsFound =
        restaurantsSearchResults !== null && restaurantsSearchResults.length === 0 && isOnSearchRestaurant === true
            ? t("no results found")
            : null;

    const alertOpensRestaurant = (infoScheduleRestaurant, restaurantInfo) => {
        return swal({
            className: "menu-list",
            button: false,
            content: (
                <div className=" h-48 px-5">
                    <div className=" my-4 mx-5 text-neutral-contrast flex justify-between items-center">
                        <h3 className=" flex-1 text-center text-primary-contrast font-medium">
                            {handleTextAlert(infoScheduleRestaurant.period)}
                        </h3>
                        <button type="button" className=" icon icon-close" onClick={() => swal.close()}></button>
                    </div>
                    <ul style={{ padding: "0" }} className="px-5 mb-5 flex justify-center">
                        <li style={{ padding: "0", color: "#505050" }} className="font-normal text-base text-center">
                            {t("product only be accepted during the store opening")}
                        </li>
                    </ul>
                    <button
                        onClick={() => {
                            goToRestaurantOrCart(restaurantInfo.restaurant_id, restaurantInfo.name, true);
                        }}
                        className=" w-full mt-5 bg-neutral-contrast py-2 rounded-sm font-medium text-neutral "
                    >
                        {t("see-store")}
                    </button>
                </div>
            ),
        });
    };

    const _renderRestaurants = () => {
        var response = [];
        if (restaurants && restaurants.length > 0) {
            (isOnSearchRestaurant ? restaurantsSearchResults : restaurants).forEach((restaurant, index) => {
                const cartRestaurantLength = getCartRestaurant(isDataRestaurants, restaurant.restaurant_id);
                const statusRestaurant = checkRestaurantSchedule(restaurant.calendarInfo, t, lang)
                    ? checkRestaurantSchedule(restaurant.calendarInfo, t, lang)["status"]
                    : null;
                const periodRestaurant = checkRestaurantSchedule(restaurant.calendarInfo, t)
                    ? checkRestaurantSchedule(restaurant.calendarInfo, t, lang)["period"]
                    : null;
                const colorStatusRestaurant = checkRestaurantSchedule(restaurant.calendarInfo, t)
                    ? checkRestaurantSchedule(restaurant.calendarInfo, t, lang)["color"]
                    : null;
                response.push(
                    <li
                        key={index}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: `${restaurant.type || cartRestaurantLength ? "start" : "center"}`,
                        }}
                        className=" bg-white rounded shadow-md"
                        onClick={(e) => {
                            if (e.target.id === "cart-button") return;
                            goToRestaurantOrCart(restaurant.restaurant_id, restaurant.name, false);
                        }}
                    >
                        {restaurant.type || cartRestaurantLength ? (
                            <div className="flex justify-between items-center mt-4 mb-2 px-4 w-full ">
                                <span
                                    style={{ opacity: `${restaurant.type ? "1" : "0"}` }}
                                    className=" bg-neutral-contrast flex justify-center items-center rounded text-sm font-normal px-4"
                                >
                                    {restaurant.type ? restaurant.type : null}
                                </span>
                                <span
                                    id="cart-button"
                                    style={{
                                        opacity: `${cartRestaurantLength !== null ? "1" : "0"} `,
                                    }}
                                    className="text-neutral-contrast font-normal"
                                    onClick={() => {
                                        if (cartRestaurantLength && cartRestaurantLength > 0) {
                                            goToRestaurantOrCart(restaurant.restaurant_id, restaurant.name, true, true);
                                        }
                                    }}
                                >
                                    Basket ({cartRestaurantLength})
                                </span>
                            </div>
                        ) : null}
                        <h2 className="text-xl text-neutral-contrast font-medium">{restaurant.name}</h2>
                        <div className="text-sm text-black mt-2">
                            <span className={`${colorStatusRestaurant} mr-1`}>{t(statusRestaurant)}</span>
                            <span>{statusRestaurant && periodRestaurant ? "·" : null}</span>
                            <span className=" text-neutral-contrast">{periodRestaurant}</span>
                        </div>
                    </li>
                );
            });
            response = <ul className="hotel-list">{response}</ul>;
        }
        return response;
    };

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    return (
        <>
            <section className="basic has-tabs has-top">
                <Header
                    title="Shops"
                    back="/site"
                    search={true}
                    searchFunction={(value) => restaurantsFilter(restaurants, value)}
                    onCloseSearch={() => setIsOnSearchRestaurant(false)}
                    searchButtonDisabled={loading === true && restaurants === null}
                ></Header>
                <section style={{ paddingLeft: "16px", paddingRight: "16px" }}>
                    {loading ? (
                        <Loading />
                    ) : error ? (
                        <ErrorMessage type="server" error={error} />
                    ) : (
                        _renderRestaurants()
                    )}
                    <p className=" text-center text-neutral-contrast text-lg">{noResultsFound}</p>
                </section>
                <Tabs hideKey={true} />
            </section>
        </>
    );
};

export default Restaurants;
