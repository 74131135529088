import React, { useState } from "react";

export const ProjectContext = React.createContext({
    project: null,
    room: null,
    salesStyles: null,
    theme: null,
    customBackground: null,
    setCustomBackground: () => {},
});

export const ProjectProvider = ({ children, value }) => {
    const [customBackground, setCustomBackground] = useState(value?.customBackground);

    const contextValue = {
        ...value,
        customBackground,
        setCustomBackground,
    };

    return <ProjectContext.Provider value={contextValue}>{children}</ProjectContext.Provider>;
};
