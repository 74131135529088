import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ProjectContext } from "../contexts/project";
import { ShopProvider } from "../contexts/shop";
import { useShops, useShopNewOrder, useShopCategories } from "../graphql/shop";
import { getDeviceWidth } from "./Design/DesignUtils";
import { size as renderSize } from "zrender/sizes";
import { SHIPPING_METHOD_GEOLOCATION, SHIPPING_METHOD_PICKUP, getShippingMethods, printPrice } from "../utils/shop";
import ShopSearch from "./ShopSearch";
import ShopCategories from "./ShopCategories";
import ShopProducts from "./ShopProducts";
import ShopProduct from "./ShopProduct";
import ShopCart from "./ShopCart";
import ShopOrder from "./ShopOrder";

import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";
import Footer from "./Footer";
import { storagePrefix } from "localStorage";
import { ShopCustomLocation } from "./ShopCustomLocation";
import swal from "@sweetalert/with-react";

const Shop = ({ lang, topBarRef, goToMain, search, theme }) => {
    const { project, room } = useContext(ProjectContext);
    const ordersManagementEnabled = project?.permissionsConfig?.shopsOrders;

    const { t: tr } = useTranslation();
    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const size = (v) => renderSize(v, "mobile", getDeviceWidth());

    const { id: shopToken, sub: paramCategoryID } = useParams();

    const storageCartID = `${storagePrefix}cart_${shopToken}`;
    const storageSearchID = `${storagePrefix}search_${shopToken}`;

    const [forceLoading, setLoading] = useState(true);
    const [showingCart, setShowingCart] = useState(false);
    const [showCategoriesList, setShowCategoriesList] = useState(true);
    const [categoryID, setCategoryID] = useState(paramCategoryID);
    const [subCategoryID, setSubCategoryID] = useState(null);
    const [productID, setProductID] = useState(null);
    const [latestProduct, setLatestProduct] = useState(null);
    const [latestScrollY, setLatestScrollY] = useState(0);
    const [searchHistory, setSearchHistory] = useState(
        localStorage.getItem(storageCartID) ? JSON.parse(localStorage.getItem(storageSearchID)) : []
    );
    const [cartItemID, setCartItemID] = useState(null);
    const [cart, setCart] = useState(
        localStorage.getItem(storageCartID) ? JSON.parse(localStorage.getItem(storageCartID)) : {}
    );

    const [displayOrder, setDisplayOrder] = useState(null);
    const [displaySelectLocation, setDisplaySelectLocation] = useState(false);
    const [customLocation, setCustomLocation] = useState({ lat: 0, lng: 0 });
    const [productUnavailable, setProductUnavailable] = useState({});

    const {
        query: loadShop,
        called: loadShopCalled,
        loading: loadingShop,
        error: loadShopError,
        data: shop,
    } = useShops({ projectRef: project?.ref, shopToken });

    const {
        query: newOrder,
        loading: loadingNewOrder,
        error: newOrderError,
        data: newOrderResult,
        modal: newOrderModal,
    } = useShopNewOrder({ shopToken });

    const {
        query: loadCategories,
        called: loadCategoriesCalled,
        loading: loadingCategories,
        error: loadCategoriesError,
        data: categories,
    } = useShopCategories({ shopToken, level: 1 });

    const addToCart = (props) => {
        if (props?.productID) {
            const newItems = Array.isArray(cart?.products) ? [...cart.products] : [];
            if (props?.cartItemID) {
                newItems[props.cartItemID - 1] = { ...props };
                delete newItems[props.cartItemID - 1].cartItemID;
            } else {
                newItems.push({ ...props });
            }
            newItems.forEach((i, index) => {
                if (!i || i?.quantity === 0) {
                    newItems.splice(index, 1);
                }
            });
            setCart({
                ...cart,
                products: newItems,
            });
        }
    };

    const setComments = (value) => {
        setCart({ ...cart, comments: value });
    };

    const setShippingMethod = (value) => {
        setCart({
            ...cart,
            shippingMethodID: value?.id,
            shippingMethod: value?.code,
            shippingLocation: value?.location,
        });
    };

    const setShippingPrice = (value) => {
        setCart((prev) => ({
            ...prev,
            shippingPrice: value.price,
            shippingPriceWithTax: value.priceWithTax,
        }));
    };

    const setDeliveryType = (value) => {
        setCart({ ...cart, deliveryType: value });
    };

    const setDeliveryTime = (value) => {
        setCart({ ...cart, deliveryTime: value });
    };

    const setPickupOption = (value) => {
        setCart((prev) => {
            return { ...prev, shippingLocation: value.name, shippingLocationID: value.shippingLocationID };
        });
    };
    const orderNow = () => {
        if (Object.values(productUnavailable).includes(true)) {
            swal({
                title: t("unavailable products"),
                text: t("unavailable products details"),
                buttons: {
                    cancel: t("close"),
                },
            });
        } else {
            setDisplayOrder(true);
            setShowingCart(false);
            newOrder({
                guestId: room?.guestID,
                roomNumber: room?.number,
                cart,
            });
        }
    };

    const updateCartQuantity = (cartItemID, quantity) => {
        if (cartItemID && quantity >= 0) {
            addToCart({
                ...cart?.products?.[cartItemID - 1],
                cartItemID,
                quantity,
            });
        }
    };

    const showCart = () => {
        setProductID(null);
        setCartItemID(null);
        setShowingCart(true);
    };

    const hideCart = () => {
        setShowingCart(false);
        setDisplayOrder(false);
    };

    const cleanCart = () => {
        setCart({});
    };

    useEffect(() => {
        if (categories?.categories?.length === 1) {
            setShowCategoriesList(false);
            if (!categoryID) {
                setCategoryID(categories.categories[0].id);
            }
        } else {
            setShowCategoriesList(true);
        }
    }, [categories]);

    useEffect(() => {
        setCategoryID(paramCategoryID);
    }, [paramCategoryID]);

    useEffect(() => {
        setLoading(false);
        if (!loadShopCalled) {
            loadShop();
        }
        if (!loadCategoriesCalled) {
            loadCategories();
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(storageCartID, JSON.stringify(cart));
    }, [cart]);

    useEffect(() => {
        localStorage.setItem(storageSearchID, JSON.stringify(searchHistory));
    }, [searchHistory]);

    const footer = theme?.style?.section2?.status?.default?.sections?.general?.colors;
    const footerButton = theme?.style?.section2?.status?.default?.sections?.button;
    const generalFgColor = theme?.style?.general?.colors?.fgColor;

    const maxProductsPerOrder = shop?.maxProductsPerOrder;
    const currencyCode = shop?.currencyCode;
    const deliverySchedule = shop?.deliverySchedule;
    const pricesIncludeTax = shop?.pricesIncludeTax;
    const allowScheduledDelivery = shop?.allowScheduledDelivery;
    const delayedDeliveryThreshold = shop?.delayedDeliveryThreshold;
    const autoAcceptOrders = shop?.autoAcceptOrders;
    const isOrderCancellationAllowed = ordersManagementEnabled && shop?.isOrderCancellationAllowed;
    const shipping = shop?.shipping;
    const cartProductsCount = cart?.products?.length || 0;
    const cartQuantitySum = cartProductsCount > 0 ? cart.products.reduce((sum, item) => sum + item.quantity, 0) : 0;
    const cartPriceSum =
        cartProductsCount > 0
            ? cart.products.reduce((sum, item) => {
                  const qty = item?.quantity || 0;
                  if (qty > 0) {
                      const total = item?.price || 0;
                      const extrasTotal = item.selections
                          ? Object.keys(item.selections).reduce(
                                (a, b) => a + (item.selections[b]?.price || 0) * (item.selections[b]?.quantity || 0),
                                0
                            )
                          : 0;
                      return sum + (total + extrasTotal) * qty;
                  }
                  return sum;
              }, 0)
            : 0;

    const showSearch = !displayOrder && search?.opened;
    const showProduct = !displayOrder && !showSearch && productID;
    const displayCart = !displayOrder && !showSearch && !showProduct && showingCart;
    const displayMap = showingCart && displaySelectLocation;
    const showProducts = !displayOrder && !showSearch && !showProduct && !displayCart && categoryID;
    const showCategories =
        !displayOrder && !showSearch && !showProduct && !displayCart && !showProducts && showCategoriesList;
    const showCartFooter = !displayOrder && !showProduct && !displayCart && cartQuantitySum > 0;

    const error = loadShopError || loadCategoriesError || (displayOrder && newOrderError);

    const loading = !error && (forceLoading || loadingShop || loadingCategories || loadingNewOrder);

    const shippingMethods = getShippingMethods(shipping, t("room x", { room: room?.number }));

    useEffect(() => {
        if (!showProduct && !displayCart && !showProducts && !displayOrder) {
            topBarRef?.current?.setTitle(showSearch ? t("search") : shop?.name);
            topBarRef?.current?.setBack(null);
        }
        if (displayOrder) {
            topBarRef?.current?.setBack(goToMain);
        }
    }, [topBarRef, shop, showSearch, showProduct, displayCart, showProducts, displayOrder]);

    useEffect(() => {
        topBarRef?.current?.setSearch(showSearch | showProducts | showCategories);
    }, [topBarRef, showSearch, showProducts, showCategories]);

    useEffect(() => {
        // Verify shippingMethods in cart
        if (
            cart.shippingMethod != SHIPPING_METHOD_PICKUP.code &&
            cart.shippingMethod != SHIPPING_METHOD_GEOLOCATION.code &&
            cart?.shippingMethodID &&
            !shippingMethods?.find((m) => m.id === cart?.shippingMethodID && m.location === cart?.shippingLocation)
        ) {
            setShippingMethod(null);
        }
        // Verify deliveryType in cart
        if (cart?.deliveryType === "scheduled" && !shop?.allowScheduledDelivery) {
            setDeliveryType(null);
        }
    }, [shop?.shipping, shop?.allowScheduledDelivery]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [showProduct, displayCart, showProducts, showCategories, displayOrder]);

    const contextData = {
        shopToken,
        theme,
        shop,
        cart,
        cartPriceSum,
        pickupLocations: categories?.pickupLocations,
        categories: categories?.categories,
        geolocationTranslations: categories?.geolocationTranslations,
        currencyCode,
        deliverySchedule,
        pricesIncludeTax,
        allowScheduledDelivery,
        delayedDeliveryThreshold,
        autoAcceptOrders,
        isOrderCancellationAllowed,
        categoryID,
        subCategoryID,
        productUnavailable,
        setProductUnavailable: (ref, value) => {
            setProductUnavailable((val) => {
                return { ...val, [ref]: value };
            });
        },
        setCategoryID: (id) => {
            setCategoryID(id);
            setSubCategoryID(null);
            setProductID(null);
        },
        setSubCategoryID: (id) => {
            setSubCategoryID(id);
            setProductID(null);
        },
        productID,
        latestProduct,
        latestScrollY,
        cartItemID,
        setProductID: (id, categoryID = null) => {
            setProductID(id);
            if (id) {
                setLatestProduct({
                    categoryID,
                    productID: id,
                });
            }
        },
        setLatestScrollY,
        setCartItemID,
        searchHistory,
        setSearchHistory: (data) => {
            // Remove duplicates
            const history = data ? data.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i) : null;
            setSearchHistory(history);
        },
        addToCart,
        updateCartQuantity,
        hideCart,
        showCart,
        cleanCart,
        showingCart,
        setComments,
        setShippingMethod,
        setDeliveryType,
        setDeliveryTime,
        orderNow,
        setPickupOption,
        setShippingPrice,
        maxProductsPerOrder,
        shipping,
        shippingMethods,
        hideMap: setDisplaySelectLocation,
        setCustomLocation,
        customLocation,
    };

    useEffect(() => {
        if (error && newOrderModal) {
            setDisplayOrder(false);
            setShowingCart(true);
            swal({
                title: t("unavailable products"),
                text: t("unavailable products details"),
                buttons: {
                    cancel: t("close"),
                },
            });
        }
    }, [newOrderModal]);
    return (
        <ShopProvider value={contextData}>
            {loading ? <Loading /> : null}
            {error ? <ErrorMessage type="connection" error={error} /> : null}
            {shop?.isActive ? (
                <>
                    {!loading && displayOrder && !newOrderError ? (
                        <ShopOrder
                            {...{
                                lang,
                                topBarRef,
                                isNew: true,
                                orderID: newOrderResult?.id,
                            }}
                        />
                    ) : null}
                    {!loading && displayCart && !displayMap ? <ShopCart {...{ lang, topBarRef }} /> : null}
                    {!loading && displayMap ? <ShopCustomLocation {...{ lang, topBarRef }} /> : null}
                    {!loading && showSearch ? <ShopSearch {...{ lang, search: search?.value, topBarRef }} /> : null}
                    {!loading && showCategories ? <ShopCategories {...{ lang, topBarRef }} /> : null}
                    {!loading && showProducts ? <ShopProducts {...{ lang, topBarRef }} /> : null}
                    {!loading && showProduct ? <ShopProduct {...{ lang, topBarRef }} /> : null}
                    {!loading && showCartFooter ? (
                        <>
                            <div style={{ height: size(7) }}></div>
                            <Footer
                                theme={{
                                    fgColor: footer?.fgColor,
                                    bgColor: footer?.bgColor,
                                }}
                            >
                                {maxProductsPerOrder > 0 && maxProductsPerOrder <= cartQuantitySum ? (
                                    <div
                                        className="w-full text-center"
                                        style={{
                                            paddingBottom: size(0.5),
                                        }}
                                    >
                                        <span style={{ fontSize: ".9em", color: "rgba(50,50,50,.5)" }}>
                                            {`* ${t("limit of products per order reached")}`}
                                        </span>
                                    </div>
                                ) : null}
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",

                                        fontSize: size(1.5),
                                        fontWeight: "bold",
                                        padding: size(0.75) + " " + size(1.5),
                                        color: footerButton?.colors?.fgColor,
                                        background: footerButton?.colors?.bgColor,
                                        borderWidth: footerButton?.border?.width + "px",
                                        borderRadius: footerButton?.border?.radius + "rem",
                                        borderColor: footerButton?.border?.color,
                                        cursor: "pointer",

                                        marginTop: size(0.5),
                                        marginLeft: size(1.5),
                                        marginRight: size(1.5),
                                    }}
                                    id="action-shop-cart"
                                    onClick={showCart}
                                >
                                    <div>{`${t("view cart")} (${cartQuantitySum})`}</div>
                                    <div>{printPrice(cartPriceSum, currencyCode, lang)}</div>
                                </div>
                            </Footer>
                        </>
                    ) : null}
                </>
            ) : null}
            {!loading && !shop?.isActive ? (
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: generalFgColor,
                    }}
                    className="basic w-full error-page full flex items-center justify-center"
                >
                    <div className="flex flex-col items-center justify-center">
                        <div className=" font-bold ">{t("shop-not-available")}.</div>
                        <div className=" font-bold text-center ">{t("please-contact-the-hotel-staff")}.</div>
                    </div>
                </div>
            ) : null}
        </ShopProvider>
    );
};

export default Shop;
