import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ProjectContext } from "../contexts/project";
import { useShops } from "../graphql/shop";

import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";
import ShopItem from "../components/widgets/ShopItem";
import { storagePrefix } from "localStorage";

const Shops = ({ lang, topBarRef }) => {
    const { project } = useContext(ProjectContext);
    const history = useHistory();
    const { t: tr } = useTranslation();
    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const {
        query: loadShops,
        called: loadShopsCalled,
        loading,
        error,
        data: shops,
    } = useShops({ projectRef: project?.ref, lang, state: true });

    useEffect(() => {
        topBarRef?.current?.setTitle(t("shops title"));
        topBarRef?.current?.setSearch(false);
        topBarRef?.current?.setBack(null);
    }, [topBarRef]);

    useEffect(() => {
        if (!loadShopsCalled) {
            loadShops();
        }
    }, []);

    useEffect(() => {
        if (shops?.length === 1) {
            history.replace(`/sales/${shops[0].token}`);
        }
    }, [shops]);

    return (
        <>
            {loading ? <Loading /> : null}
            {error ? <ErrorMessage type="connection" error={error} /> : null}
            {shops ? (
                <div className="px-4 py-5">
                    {shops.map((shop, index) => {
                        const storageID = `${storagePrefix}cart_${shop.token}`;
                        const cart = localStorage.getItem(storageID) ? JSON.parse(localStorage.getItem(storageID)) : {};
                        const cartProductsCount = cart?.products?.length || 0;
                        const cartQuantitySum =
                            cartProductsCount > 0 ? cart.products.reduce((sum, item) => sum + item.quantity, 0) : 0;
                        return (
                            <div className="mx-1 mb-2" key={`shop_${shop.token}`}>
                                <ShopItem
                                    {...{
                                        config: {
                                            id: `shop-${index + 1}`,
                                            shopToken: shop.token,
                                            name: shop.name,
                                            coverURL: shop.coverURL,
                                            coverColor: shop.coverColor,
                                            deliverySchedule: shop.deliverySchedule,
                                            cart: cartQuantitySum,
                                            events: {
                                                click: () => {
                                                    history.push(`/sales/${shop.token}`);
                                                },
                                            },
                                        },
                                        style: {
                                            fgColor: "rgb(255, 255, 255)",
                                            bgColor: "rgb(245, 246, 248)",
                                        },
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </>
    );
};

export default Shops;
