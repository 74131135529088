import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { HashRouter, BrowserRouter, Route } from "react-router-dom";
import { ToastContainer, cssTransition, toast } from "react-toastify";

import Portal from "./views/Portal";
import Section from "./components/Section";
import Messages from "./components/Messages";
import ScrollToTop from "./components/ScrollToTop";
import { unauthorizedUserLoggedAlert, getPushToken } from "./actions/user";
import { useHello } from "./graphql/useUser";
import Terms from "./views/Terms";
import Privacy from "./views/Privacy";
import Mikrotik from "./views/Mikrotik";
import { isWifi } from "./utils/config";

import { NotificationProvider } from "./contexts/notifications";
import { MessagesProvider } from "./contexts/messages";
import SendTrackScreenTrigger from "components/SendTrackScreenTrigger";

function App() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const wifiPortal = isWifi();
    const { hello } = useHello();
    const pushToken = useSelector(getPushToken);
    const unauthorizedUserLogged = useSelector((state) => state.user.unauthorizedUserLogged);

    const Zoom = cssTransition({
        enter: "zoomIn",
        exit: "zoomOut",
        duration: 200,
    });

    useEffect(() => {
        if (process.env.NODE_ENV === "development" && window.appConfig && window.appConfig.name) {
            document.title = window.appConfig.name;
        }
        hello({ variables: { push: pushToken } });
    }, [hello, pushToken]);

    if (!wifiPortal && unauthorizedUserLogged) {
        toast(t("unauthorizedUserLogged"));
        dispatch(unauthorizedUserLoggedAlert(false));
    }

    if (wifiPortal) {
        return (
            <BrowserRouter>
                <Route path={"/mikrotik-simulator"} exact={true}>
                    <Mikrotik />
                </Route>
                <Route path={"/conditions/terms"} exact={true}>
                    <Terms />
                </Route>
                <Route path={"/conditions/privacy"} exact={true}>
                    <Privacy />
                </Route>
                <Route path={"/portal"} exact={true}>
                    <Portal></Portal>
                </Route>
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    draggablePercent={20}
                    pauseOnHover={false}
                    transition={Zoom}
                />
            </BrowserRouter>
        );
    } else {
        return (
            <HashRouter>
                <NotificationProvider>
                    <MessagesProvider>
                        <Messages />
                        <Section />
                    </MessagesProvider>
                    <SendTrackScreenTrigger />
                    <ScrollToTop />
                    <ToastContainer
                        position="bottom-center"
                        autoClose={6000}
                        hideProgressBar
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable
                        draggablePercent={20}
                        pauseOnHover={false}
                        transition={Zoom}
                    />
                </NotificationProvider>
            </HashRouter>
        );
    }
}

export default App;
