import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedHotel, getSelectedHotel, setSelectedRoom } from "../actions/hotel";
import Loading from "../components/Loading";
import { setGoogleToken, setVerifiedToken } from "../actions/integrations";
import { useHotels } from "../graphql/useHotels";
import ErrorPage from "../components/ErrorPage";
import { getUser } from "../actions/user";

import { getRoomsProjects } from "../modules/rooms/store";
import { decodeToken } from "../modules/rooms/utils";
import useAddRoom from "../hooks/useAddRoom";

const Start = () => {
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams(window.location.search);
    // Google Sign-in
    const paramCode = searchParams ? searchParams.get("code") : null;
    // Google Sign-in from WiFi Portal
    const paramAccessToken = searchParams ? searchParams.get("access_token") : null;
    // Email Sign-in from WiFi Portal (verified by code)
    const paramVerified = searchParams ? searchParams.get("verified") : null;
    // Email Sign-in from WiFi Portal
    const paramEmail = searchParams ? searchParams.get("email") : null;
    const paramEnterCode = searchParams ? searchParams.get("enterCode") === "true" : null;
    // Add rooms from WiFi Portal
    const paramRooms = searchParams ? searchParams.get("rooms") : null;
    const {
        getHotels,
        loading: loadingHotels,
        error,
        hotels: hotelsReady,
    } = useHotels({ autoload: paramRooms ? true : false });
    const project = useSelector(getSelectedHotel);
    const { addRooms, loading: addingRooms } = useAddRoom({ project });
    const [roomsData, setRoomsData] = useState(null);
    const currentUser = useSelector(getUser);
    const user = useSelector((state) => (currentUser && state.user.data ? state.user.data[currentUser] : null));
    const roomsProjects = useSelector(getRoomsProjects);
    const bookmarks = useSelector((state) =>
        currentUser && state.user.bookmarks && state.user.bookmarks[currentUser]
            ? state.user.bookmarks[currentUser].data
            : null
    );

    const isBookmarked = (hotelRef) => {
        if (hotelRef && bookmarks) {
            if (bookmarks.length > 0 && bookmarks.includes(hotelRef)) {
                return true;
            }
        }
        return false;
    };

    const hasRooms = (hotelRef) => hotelRef && roomsProjects && roomsProjects.includes(hotelRef);

    const isAutologin = () => paramVerified || paramAccessToken || paramEmail;

    const redirectURL = () => {
        let path = "";
        if (paramCode || isAutologin()) {
            path += "/signin";
            if (paramEmail || paramVerified) {
                path += "/email";
                if (paramEmail) {
                    if (paramEnterCode) {
                        path += "/code";
                    }
                    path += "/" + paramEmail;
                }
            }
        } else {
            path += "/site";
        }
        return "/#" + path;
    };

    useEffect(() => {
        if (paramCode) {
            dispatch(setGoogleToken("code=" + paramCode + "&origin=" + encodeURIComponent(window.location.origin)));
            document.location.href = redirectURL();
        }
    }, [paramCode]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (isAutologin() || paramRooms) {
            let awaitAddingRooms = false;
            if (paramAccessToken) {
                dispatch(setGoogleToken("access_token=" + paramAccessToken));
            } else if (paramVerified) {
                dispatch(setVerifiedToken(paramVerified));
            }
            if (paramRooms) {
                if (hotelsReady) {
                    const data = decodeToken(paramRooms);
                    if (data.projectRef && project?.ref != data.projectRef) {
                        dispatch(setSelectedHotel(data.projectRef));
                    }
                    if (data && data.rooms && data.rooms.length > 0) {
                        awaitAddingRooms = true;
                        setRoomsData(data);
                    }
                } else {
                    getHotels();
                }
                if (hotelsReady && !awaitAddingRooms) {
                    document.location.href = redirectURL();
                }
            } else if (!awaitAddingRooms) {
                document.location.href = redirectURL();
            }
        }
    }, [paramEmail, paramVerified, paramAccessToken, paramRooms, hotelsReady]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (project && roomsData && roomsData.rooms && roomsData.rooms.length > 0) {
            addRooms(roomsData.rooms, { check: false, checkWiFi: false }).then((success) => {
                if (success && roomsData.selected) {
                    dispatch(setSelectedRoom(roomsData.selected));
                }
                setTimeout(function () {
                    document.location.href = redirectURL();
                }, 1000);
            });
        }
    }, [roomsData, project?.ref]);

    if (error) {
        return <ErrorPage type="server">{error}</ErrorPage>;
    }

    if (paramCode || isAutologin() || paramRooms || addingRooms || loadingHotels) {
        return <Loading />;
    }

    if (project && !hasRooms(project?.ref) && !isBookmarked(project?.ref)) {
        dispatch(setSelectedHotel(null));
    }

    return <Redirect push to={project || user ? "/site" : "/signin"} />;
};

export default Start;
