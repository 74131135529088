import gql from "graphql-tag";

export const getVarID = (params) => `${params?.projectRef}-${params?.roomNumber}`.replace(/[^a-z0-9]/gi, "");

export const GET_MESSAGES_MULTIPLE = (rooms) => {
    const roomsVariables = rooms
        ?.map((room) => {
            const varID = getVarID(room);
            return {
                [`r${varID}ProjectRef`]: room.projectRef,
                [`r${varID}RoomNumber`]: room.roomNumber,
                [`r${varID}Password`]: room.password,
            };
        })
        .reduce((a, b) => ({ ...a, ...b }), {});

    const strGql = `
            query getMessages(
                ${rooms
                    ?.map((room) => {
                        const varID = getVarID(room);
                        return `$r${varID}ProjectRef: ID!
                $r${varID}RoomNumber: Int!
                $r${varID}Password: String!
                `;
                    })
                    .join("\n")}
            ) {
                ${rooms
                    ?.map((room) => {
                        const varID = getVarID(room);
                        return `messages${varID}: getReceivedMessages(
                    hotelRef: $r${varID}ProjectRef
                    roomNumber: $r${varID}RoomNumber
                    password: $r${varID}Password
                ) {
                    results {
                        id
                        title
                        body
                        information
                        isRead
                        priority
                        sentTime
                        projectRef
                        roomNumber
                    }
                }`;
                    })
                    .join("\n")}
            }
        `;

    return {
        id: "GET_MESSAGES_MULTIPLE_" + rooms?.map((room) => room.roomNumber).join("-"),
        query:
            rooms?.length > 0
                ? gql(strGql)
                : gql`
                      {
                          getLanguage(ref: "en") {
                              name
                          }
                      }
                  `,
        defaultVars: {
            ...roomsVariables,
        },
    };
};

export const MARK_MESSAGES_AS_READ = {
    id: "MARK_MESSAGES_AS_READ",
    query: gql`
        mutation setReceivedMessagesAsRead($projectRef: ID!, $messageIDs: [Int64]!) {
            setReceivedMessagesAsRead(hotelRef: $projectRef, messageIDs: $messageIDs)
        }
    `,
    defaultVars: {
        projectRef: "",
        messageIDs: [],
    },
};
