import React from "react";
import { GET } from "./get";
import { queryString } from "./url";

export const markdown = (txt, returnHTML = false) => {
    const data = String(txt)
        .split("**")
        .map((part, index) => {
            if (index % 2 === 0) {
                return part.split("\n").map((line, index) => {
                    if (index === 0) {
                        return line;
                    }
                    return returnHTML ? (
                        `<br />${line}`
                    ) : (
                        <>
                            <br />
                            {line}
                        </>
                    );
                });
            } else {
                return returnHTML ? `<strong>${part}</strong>` : <strong key={`${part}`}>{part}</strong>;
            }
        });
    return returnHTML ? data.join("") : data;
};

export const markdownClean = (txt) => {
    return String(txt)
        .replace(/\*\*/g, "")
        .replace(/<br \/>/g, "\n")
        .replace(/<strong>/g, "")
        .replace(/<\/strong>/g, "");
};

export const appUID = () => {
    var _0x5509 = [
        "\x73\x6C\x69\x63\x65",
        "\x30",
        "\x67\x65\x74\x4D\x6F\x6E\x74\x68",
        "",
        "\x67\x65\x74\x46\x75\x6C\x6C\x59\x65\x61\x72",
        "\x33\x38\x33\x62\x63\x65\x33\x63",
        "\x31\x38\x32\x32\x34\x64\x34\x66\x38\x36",
        "\x33\x36\x61\x63\x61\x33\x63\x30\x63\x35\x32\x38\x38\x38",
        "\x67\x65\x74\x44\x61\x74\x65",
    ];
    const d = new Date();
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    let app = (_0x5509[1] + (d[_0x5509[2]]() + 1))[_0x5509[0]](-2);
    app += (_0x5509[3] + d[_0x5509[4]]())[_0x5509[0]](-2);
    app += _0x5509[5];
    app += _0x5509[6];
    app += _0x5509[7];
    app += (_0x5509[1] + d[_0x5509[8]]())[_0x5509[0]](-2);
    return app;
};

// Validate room/code in ZMobile 3.8
export const validateRoomCode = async (url, room, code) => {
    if (!url) return false;
    url +=
        (url.match(/\?/) ? "&" : "?") +
        queryString({
            embedded: "",
            room: room,
            code: code,
        });

    let success = false;
    try {
        const response = await fetch(url);
        success = response.url ? true : false;
        const params = GET(response.url);
        if ("denied" in params) {
            success = false;
        }
    } catch (error) {
        console.error(error);
        success = true;
    }
    return success;
};

export const fetchWithTimeout = async (resource, options = {}) => {
    const { timeout = 8000 } = options;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    let response = false;
    try {
        response = await fetch(resource, {
            ...options,
            signal: controller.signal,
        });
    } catch (error) {
        console.error(error);
        response = false;
    }
    clearTimeout(id);
    return response;
};

export function hexToRgbA(hex, alpha) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
            "rgba(" +
            [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
            "," +
            (alpha && alpha > 0.0 ? alpha : "1") +
            ")"
        );
    }
    throw new Error("Bad Hex");
}

export function getWeekDays(withoutWeekend) {
    if (withoutWeekend) {
        return ["monday", "tuesday", "wednesday", "thursday", "friday"];
    } else {
        return ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    }
}

export function getMonthabbreviation() {
    return ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
}

export function calculateDecimals(value, maxPositions) {
    var decimals = countDecimals(value);
    if (decimals && decimals > maxPositions) {
        value = parseFloat(value.toFixed(maxPositions));
    }
    return value;
}

function countDecimals(value) {
    if (Math.floor(value.valueOf()) === value.valueOf()) return 0;
    return value.toString().split(".")[1].length || 0;
}

export const normalizeToSearch = (str) => {
    return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
};

export const getNextDate = (date = new Date(), day) => {
    let dayNumber;
    const dateCopy = new Date(date.getTime());

    switch (day) {
        case "sunday":
            dayNumber = 0;
            break;
        case "monday":
            dayNumber = 1;
            break;
        case "tuesday":
            dayNumber = 2;
            break;
        case "wednesday":
            dayNumber = 3;
            break;
        case "thursday":
            dayNumber = 4;
            break;
        case "friday":
            dayNumber = 5;
            break;
        case "saturday":
            dayNumber = 6;
            break;
        default:
            break;
    }
    const nextDate = new Date(dateCopy.setDate(dateCopy.getDate() + ((7 - dateCopy.getDay() + dayNumber) % 7 || 7)));
    return nextDate;
};

export const milisecondsToMinutes = (miliseconds) => Math.floor(miliseconds / 60000);

export const capitalize = ([first, ...rest]) => first.toUpperCase() + rest.join("");
