import { decode as base64_decode, encode as base64_encode } from "base-64";

export const AUTHORIZE_MARKETING = "AUTHORIZE_MARKETING";
export const PORTAL_CONFIG = "PORTAL_CONFIG";
export const PORTAL_SESSION_REFRESH = "PORTAL_SESSION_REFRESH";
export const PORTAL_SESSION_LOAD = "PORTAL_SESSION_LOAD";

export function portalConfig(config) {
    return setDispatch(PORTAL_CONFIG, config);
}

export function authMarketing(accepted) {
    return setDispatch(AUTHORIZE_MARKETING, accepted);
}

export function updatePortalSession(data) {
    return setDispatch(PORTAL_SESSION_REFRESH, data);
}

export function loadPortalSession(data) {
    return setDispatch(PORTAL_SESSION_LOAD, data);
}

export function getPortalSession(state) {
    let session = state.portal.session || {};

    // Methods
    const pairingRooms = session.rooms ? session.rooms.filter((r) => r && r.cc) : null;
    const hasPairing = pairingRooms && pairingRooms.length > 0;
    const whenVerifyNotif =
        session.verify &&
        session.ref &&
        !session.verified &&
        session.mikrotik &&
        !session.internet &&
        !session.verifyNotif;
    const needInternetToVerify =
        session.verify &&
        session.ref &&
        !session.verified &&
        session.mikrotik &&
        !session.internet &&
        session.verifyNotif;
    const needOpenBrowser = !session.inBrowser && session.redirect && session.redirect.pwa;

    const step =
        session.email && !session.ref && !session.skipSignup
            ? "signup"
            : session.google && !session.ref
            ? "login"
            : session.google && !session.marketing
            ? "marketing"
            : !session.google && session.ref && session.verify && !session.verified
            ? "verify"
            : hasPairing && !session.ccSkip
            ? "pairing"
            : needOpenBrowser && session.redirect
            ? "openbrowser"
            : session.redirect
            ? "redirect"
            : "login";
    const needLogout = step === "redirect" && session.internet && !needOpenBrowser;

    session.getStep = () => step;
    session.hasPairing = () => hasPairing;
    session.getPairingRooms = () => (step === "pairing" ? pairingRooms : null);

    session.getPWAURL = (projectRef) => {
        let href = "";
        let tokenRooms = null;
        if (session.rooms && session.rooms.length > 0) {
            tokenRooms = {};
            tokenRooms.projectRef = projectRef;
            tokenRooms.rooms = [];
            tokenRooms.selectedRoom = session.room ? session.room.number : null;
            session.rooms.map((room) => {
                tokenRooms.rooms.push({
                    number: room.number,
                    code: room.code,
                    cc: room.cc,
                    ccUrl: room.ccUrl,
                });
                return room;
            });
        }
        if (session.google) {
            href += "?access_token=" + encodeURIComponent(session.google);
        } else if (session.email) {
            if (session.verified) {
                href += "?verified=" + encodeURIComponent(base64_encode(session.email + ":::" + session.verified));
            } else {
                href += "?email=" + encodeURIComponent(session.email);
                if (session.signup) {
                    href += "&enterCode=true";
                }
            }
        }
        if (tokenRooms) {
            href += (href ? "&" : "?") + "rooms=" + encodeURIComponent(base64_encode(JSON.stringify(tokenRooms)));
        } else if (!href) {
            href += "#/site/list";
        }
        return (session.redirect ? session.redirect.url : "/") + href;
    };

    const isAndroid = /android/i.test(navigator.userAgent || navigator.vendor || window.opera);

    session.returnURL = (chainRef, projectRef, sessionKey) => {
        if (whenVerifyNotif) {
            // Email link
            return getPortalURL({ ...session, sessionKey, chainRef, internet: true, verifyNotif: true });
        } else if (needInternetToVerify) {
            // Mikrotik 10min
            return getPortalURL({ ...session, sessionKey, chainRef, internet: true });
        } else if (needLogout) {
            // Mikrotik logout (after 10min)
            if (isAndroid) {
                // En android se desactiva la redirección en el portal, ya que se cierra solo al conectarse
                return "";
            }
            return getPortalURL({ ...session, sessionKey, chainRef, internet: false });
        } else if (step === "openbrowser") {
            // Open last step in browser
            return getPortalURL({ ...session, sessionKey, chainRef, inBrowser: true });
        } else if (step === "redirect") {
            // Mikrotik redirect to final url
            if (isAndroid) {
                // En android se desactiva la redirección en el portal, ya que se cierra solo al conectarse
                return "";
            }
            return session.redirect && session.redirect.pwa ? session.getPWAURL(projectRef) : session.redirect.url;
        } else {
            return getPortalURL({ ...session, sessionKey, chainRef });
        }
    };

    session.redirectToMikrotik = (chainRef, projectRef, sessionKey) => {
        return session.mikrotik && session.mikrotik.url && (needInternetToVerify || needLogout || step === "redirect")
            ? {
                  url: needLogout ? session.mikrotik.url.replace("/login", "/logout") : session.mikrotik.url,
                  data: { ...session.mikrotik.urlData, dst: session.returnURL(chainRef, projectRef, sessionKey) },
              }
            : null;
    };

    return session;
}

function setDispatch(type, value) {
    return (dispatch) => {
        dispatch({
            type: type,
            payload: value,
        });
    };
}

export function getPortalURL(session) {
    let url = window.location && window.location.href ? window.location.href.match(/^[^#|?]*/)[0] : "/";
    return url + "?token=" + encodeURIComponent(encodePortalSession(session));
}

export function encodePortalSession(session) {
    let cleanedSession = Object.fromEntries(
        Object.entries(session).filter(([_, v]) => v !== null && v !== undefined && v !== "")
    );
    return base64_encode(JSON.stringify(cleanedSession));
}

export function decodePortalSessionToken(token) {
    let session = {};
    if (token) {
        try {
            const storedSession = JSON.parse(base64_decode(token));
            session = { ...storedSession };
        } catch (e) {
            console.error(e);
        }
    }
    return session;
}
