// Funcionalidades de React
import React, { useEffect, useState, useRef } from "react";
// Internacionalización
import { useTranslation } from "react-i18next";
// Manejo del estado global de la aplicación
import { useDispatch, useSelector } from "react-redux";
// Componentes personalizados
import Loading from "../../Loading";
import { nl2br, deviceTypes } from "../DesignUtils";
import ErrorPage from "../../ErrorPage";
// Acciones del portal
import { getPortalSession, updatePortalSession } from "../../../actions/portal";
// Custom hooks
import { parseConfig } from "./UseWidgetWifiLogin";
import useWidget from "../../../hooks/useWidget";
import usePortalData from "../../../hooks/usePortalData";
import useWifiService from "../../../hooks/useWifiService";
import UseVerify from "./WiFiLogin/UseVerify";

const UseWidgetPortal = ({ config, deviceType }) => {
    const openLink = useRef(null);
    const dispatch = useDispatch();
    const session = useSelector(getPortalSession);
    const hash = session.hash;
    const step = session.getStep();
    const pairingRooms = session.getPairingRooms();
    const searchParams = new URLSearchParams(window.location.search);
    const paramCode = searchParams ? searchParams.get("vcode") : null;
    const { t } = useTranslation();

    const { cfg } = useWidget({
        type: "WIFILOGIN",
        deviceType,
        data: config ? config.wifilogin : null,
        styles: config ? config.styles : null,
        parentCfg: config ? config.parentCfg : null,
        parseConfig,
    });

    const { session: portalSession, config: tokenConfig } = usePortalData();
    const projectName = portalSession && portalSession.config ? portalSession.config.projectName : null;

    const {
        chromecastPairing,
        updateCommercialConsent,
        sendEmailValidation,
        validateEmail,
        loading,
        error,
        ignoreError,
    } = useWifiService();

    if (tokenConfig && tokenConfig.AccessTypes) {
        tokenConfig.AccessTypes.map((item) => {
            switch (item.Name) {
                case "email":
                case "pms":
                case "code":
                case "open":
                    return item;
                case "mac":
                    // ignore
                    break;
                default:
                    console.error("Unknown access type: " + item.Name);
            }

            return null;
        });
    }

    const [marketingAccepted, setMarketingAccepted] = useState(false);
    const [selectedPairingRoom, setSelectedPairingRoom] = useState(null);
    const [hasChromecast, setHasChromecast] = useState(null);

    const submitCode = () => {
        return sendEmailValidation();
    };

    const submitVerify = (code) => {
        return validateEmail(code);
    };

    const submitMarketing = () => {
        updateCommercialConsent(marketingAccepted);
    };

    const cssCheckMarketing = marketingAccepted ? cfg.checkOn : cfg.check;

    useEffect(() => {
        if (session.verify && !session.verified && !hash) {
            submitCode();
        }
    }, [hash]);

    useEffect(() => {
        if (step === "pairing" && !session.room && pairingRooms && pairingRooms.length === 1) {
            dispatch(updatePortalSession({ room: pairingRooms[0] }));
        }
    }, [pairingRooms]);

    useEffect(() => {
        if (step === "pairing" && session.room) {
            setHasChromecast(null);
            chromecastPairing()
                .then(() => {
                    setHasChromecast(true);
                })
                .catch(() => {
                    setHasChromecast(false);
                })
                .finally(() => {
                    dispatch(updatePortalSession({ ccDone: true }));
                });
        }
    }, [session.room]);

    const dispatchEvent = (event, element) => {
        return element
            ? element.dispatchEvent(
                  new MouseEvent(event, {
                      bubbles: true,
                      cancelable: true,
                      view: window,
                  })
              )
            : null;
    };

    useEffect(() => {
        setTimeout(() => {
            if (openLink && openLink.current) {
                dispatchEvent("click", openLink.current);
                dispatchEvent("touchend", openLink.current); // Esto para cuando iOS no permite click
            }
        }, 10000);
    }, [openLink]);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById("landing-container").scrollTo(0, 0);
    }, [step]);

    if (error) {
        return error.message === "unauthorized" ? (
            <ErrorPage type="404" retry={ignoreError} error={error} />
        ) : (
            <ErrorPage title={t("error.title")} retry={ignoreError} error={error} />
        );
    }

    return step === "redirect" ? null : (
        <div
            style={{
                ...cfg.global,
                width: "100%",
                maxWidth: "60rem",
                margin: deviceType === deviceTypes.DESKTOP ? "5rem auto" : "0 auto",
            }}
        >
            {loading && step !== "pairing" ? <Loading color={cfg.link.color} /> : null}
            {step === "pairing" ? (
                <div
                    className="flex flex-col text-center mt-6 py-4 px-8"
                    style={{
                        lineHeight: "1.7em",
                        minHeight: "80vh",
                    }}
                >
                    <div className="flex-row">
                        {session.room ? (
                            <div
                                style={{
                                    fontWeight: 500,
                                    fontSize: "1.5em",
                                    margin: "1.3em",
                                }}
                            >
                                {t("connecting to hotel wifi", { hotel: projectName })}
                            </div>
                        ) : null}

                        <div
                            style={{
                                fontWeight: 500,
                                fontSize: "1.3em",
                                margin: "1.3em",
                            }}
                        >
                            {session.room
                                ? hasChromecast === true
                                    ? t("paired with your room")
                                    : hasChromecast === false
                                    ? t("pairing error")
                                    : t("pairing with your room")
                                : t("pairing title")}
                        </div>

                        {session.room && hasChromecast !== null ? (
                            <i className="icon icon-cast" style={{ fontSize: "4.2em" }}></i>
                        ) : null}

                        {!session.room || hasChromecast !== null ? (
                            <div
                                style={{
                                    margin: "1.3em auto",
                                    width: "90%",
                                    maxWidth: "20rem",
                                }}
                            >
                                {nl2br(
                                    !session.room
                                        ? t("select room to pairing")
                                        : hasChromecast
                                        ? t("you are paired")
                                        : t("error.unknown")
                                )}
                            </div>
                        ) : null}

                        {session.room ? (
                            <>
                                {hasChromecast === null ? (
                                    <div
                                        style={{
                                            padding: "1.3em",
                                        }}
                                    >
                                        <Loading className="inline" color={cfg.link.color} />
                                    </div>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <div
                                    style={{
                                        padding: "1.3em",
                                        width: "90%",
                                        maxWidth: "20rem",
                                        margin: "auto",
                                        marginBottom: "1.3em",
                                    }}
                                >
                                    <div className={"float-left font-medium text-sm pl-1 pb-1 "}>{t("room")}</div>
                                    <select
                                        type="text"
                                        name="room"
                                        placeholder={t("select room")}
                                        onChange={(e) => {
                                            const selected = e && e.target ? e.target.value : null;
                                            setSelectedPairingRoom(selected !== null ? pairingRooms[selected] : null);
                                        }}
                                        style={selectedPairingRoom ? cfg.input : cfg.inputError}
                                    >
                                        <option value="">{t("select room")}</option>
                                        {pairingRooms &&
                                            pairingRooms.map((room, index) => (
                                                <option key={index} value={index}>
                                                    {room ? room.name : room.number}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="mt-auto">
                        {session.room ? (
                            hasChromecast !== null ? (
                                <button
                                    ref={openLink}
                                    type="button"
                                    onClick={() => {
                                        dispatch(updatePortalSession({ ccSkip: true }));
                                    }}
                                    className="btn py-2 px-10 mt-2 mb-10"
                                    style={cfg.button}
                                >
                                    {t("continue")}
                                </button>
                            ) : null
                        ) : (
                            <>
                                <button
                                    type="button"
                                    onClick={() => {
                                        dispatch(updatePortalSession({ room: selectedPairingRoom }));
                                    }}
                                    className="btn py-2 px-10 mb-5"
                                    disabled={selectedPairingRoom ? false : true}
                                    style={selectedPairingRoom ? cfg.button : cfg.buttonDisabled}
                                >
                                    {t("continue")}
                                </button>

                                <a
                                    onClick={() => {
                                        dispatch(updatePortalSession({ ccSkip: true }));
                                    }}
                                    className="block font-bold text-base"
                                    style={cfg.link}
                                >
                                    {t("skip and continue")}
                                </a>
                            </>
                        )}
                    </div>
                </div>
            ) : step === "verify" ? (
                !hash ? null : (
                    <div className="text-center mt-6 py-4 px-8" style={{ lineHeight: "1.7em" }}>
                        <div
                            style={{
                                fontWeight: 500,
                                fontSize: "1.3em",
                                margin: "1.3em",
                            }}
                        >
                            {t("validate your email")}
                        </div>
                        {!session.verifyNotif ? (
                            <>
                                <div
                                    style={{
                                        margin: "1.3em",
                                    }}
                                >
                                    {nl2br(t("a code has been sent to email", { email: session.email }))}
                                </div>
                                <button
                                    ref={openLink}
                                    type="button"
                                    onClick={() => {
                                        dispatch(updatePortalSession({ verifyNotif: true }));
                                    }}
                                    className="btn py-2 px-10 mb-5"
                                    style={cfg.button}
                                >
                                    {t("continue")}
                                </button>
                            </>
                        ) : (
                            <>
                                <div
                                    style={{
                                        margin: "1.3em",
                                    }}
                                >
                                    {nl2br(t("a code has been sent to email", { email: session.email }))}
                                </div>
                                <div
                                    style={{
                                        width: "90%",
                                        maxWidth: "20rem",
                                        margin: "2em auto",
                                    }}
                                >
                                    <UseVerify
                                        cfg={cfg}
                                        code={paramCode}
                                        deviceType={deviceType}
                                        submitCode={submitCode}
                                        submitVerify={submitVerify}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                )
            ) : step === "marketing" ? (
                <div className="text-center mt-6 py-4 px-8" style={{ lineHeight: "1.7em" }}>
                    <div
                        style={{
                            fontWeight: 500,
                            fontSize: "1.3em",
                            margin: "1.3em",
                        }}
                    >
                        {t("marketing title")}
                    </div>

                    <div
                        style={{
                            margin: "1.3em",
                        }}
                    >
                        {nl2br(
                            t("do you want marketing at email", {
                                email: session.email,
                            })
                        )}
                    </div>

                    <div
                        style={{
                            margin: "1.3em",
                        }}
                    >
                        <input
                            id="checkbox-marketing"
                            type="checkbox"
                            name="marketing"
                            onChange={(e) => {
                                setMarketingAccepted(e && e.target ? e.target.checked : !marketingAccepted);
                            }}
                            style={cssCheckMarketing}
                        />
                        <label className="ml-1 pl-1" htmlFor="checkbox-marketing">
                            {t("authorize marketing")}
                        </label>
                    </div>

                    <button type="button" onClick={submitMarketing} className="btn py-2 px-10 mb-5" style={cfg.button}>
                        {t("continue")}
                    </button>
                </div>
            ) : null}
        </div>
    );
};

export default UseWidgetPortal;
