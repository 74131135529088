import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, updateUserData, getPushToken } from "../actions/user";
import { matchPath } from "react-router";
import { useTranslation } from "react-i18next";
import { getSiteName, setSignupFormValues } from "../actions/config";
import { useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import { useSignUp, useHello } from "../graphql/useUser";
import Loading from "../components/Loading";
import ErrorPage from "../components/ErrorPage";
import { toast } from "react-toastify";
import swal from "@sweetalert/with-react";
import { FirebaseGetNewToken } from "../firebase";
import { setPushToken } from "../actions/notifications";
import { useConfiguredTerms, useConfiguredPrivacy, useAskMarketing } from "../utils/config";
import { useSendTrackEventGeneralMobile } from "../graphql/useUser";

const SignUp = () => {
    const { t, i18n } = useTranslation();
    const i18nLang = (i18n.language || "").match(/^[^-]+/)[0];
    const location = useLocation();
    const dispatch = useDispatch();
    const { hello } = useHello();
    const pushToken = useSelector(getPushToken);
    const lastSignupFormValues = useSelector((state) => state.session.lastSignupFormValues);
    const stateRedirect = useRef(null);
    const { sendTrackEventGeneralMobile } = useSendTrackEventGeneralMobile();
    const [passVisible, setPassVisible] = useState(false);
    const [redirectTo, setRedirectTo] = useState("");
    const [lastPass, setLastPass] = useState("");

    const terms = useConfiguredTerms(i18nLang);
    const privacy = useConfiguredPrivacy(i18nLang);
    const askMarketing = useAskMarketing();

    const { register, getValues, setValue, handleSubmit, watch, errors, triggerValidation } = useForm();

    const signupError = (alreadyExists) => {
        var title, text;
        if (alreadyExists) {
            title = t("error.signupExistsTitle");
            text = t("error.signupExists");
        } else {
            title = t("error.signupTitle");
            text = t("error.signup") + "\n" + t("error.try again");
        }
        swal({
            title: title,
            text: text,
            button: t("alert ok"),
        });
    };
    const { signUp, loading } = useSignUp({
        onCompleted: (data) => {
            const user = data.signUp.user;
            const token = data.signUp.auth.token;
            if (user && token) {
                FirebaseGetNewToken().then((token) => {
                    dispatch(setPushToken(token));
                    hello({ variables: { push: token } });
                });
                dispatch(loginUser(user.ref, token, lastPass));
                dispatch(updateUserData(user, "password"));
                sendTrackEventGeneralMobile(undefined, "SIGN_UP");
                toast(t("hello user", { name: user.name }) + "\n" + t("welcome message"));
                setRedirectTo(redirectAfterLogin);
            } else {
                signupError(false);
            }
        },
        onError: (error) => {
            console.error(error);
            signupError(error && error.message && error.message.match(/data conflict error/i));
        },
    });

    const locationProfile = matchPath(location.pathname, { path: "/profile" });
    const locationBooking = matchPath(location.pathname, {
        path: "/site/booking",
    });
    const locationAddRoom = matchPath(location.pathname, {
        path: "/site/addroom",
    });
    const locationZones = matchPath(location.pathname, { path: "/site/zones" });
    const locationCuiner = matchPath(location.pathname, { path: "/pos" });

    let locationSection = "login";
    if (locationAddRoom) {
        locationSection = "add_room";
    } else if (locationBooking) {
        locationSection = "my_bookings";
    } else if (locationZones) {
        locationSection = "hotel_areas";
    } else if (locationProfile) {
        locationSection = "profile";
    }
    const sectionName = locationSection;

    let redirectAfterLogin = "/verify/email";
    if (locationAddRoom) {
        locationSection = "/site/addroom/verify/email";
    } else if (locationProfile) {
        redirectAfterLogin = "/profile/verify/email";
    } else if (locationBooking) {
        redirectAfterLogin = "/site/booking/verify/email";
    } else if (locationZones) {
        redirectAfterLogin = "/site/zones/verify/email";
    } else if (locationCuiner) {
        redirectAfterLogin = "/pos/cart";
    }

    const togglePassVisibility = () => {
        setPassVisible(!passVisible);
    };

    const saveFormState = () => {
        dispatch(setSignupFormValues(getValues()));
    };

    const onSubmit = (data, e) => {
        setLastPass(data.password);
        signUp({
            variables: {
                email: data.email,
                name: data.name,
                surname: data.surname,
                password: data.password,
                language: i18nLang,
                push: pushToken,
                marketingAccepted: data.marketing,
            },
        });
    };

    useEffect(() => {
        if (lastSignupFormValues) {
            Object.keys(lastSignupFormValues).forEach(function (key) {
                setValue(key, lastSignupFormValues[key]);
            });
        }
        dispatch(setSignupFormValues(null));
    }, [lastSignupFormValues, setValue]);

    if (!terms || !privacy) {
        return <ErrorPage type="config-terms" />;
    }

    if (redirectTo) {
        return <Redirect push to={{ pathname: redirectTo, state: "SIGN_UP" }} />;
    }

    const labelClass = "float-left font-medium text-sm pl-1 pb-1 ";
    const txtRequired = t("required input");
    const checkPass = (exp) => {
        return (watch("password") || "").match(exp) ? "line-through" : errors.password ? " text-error" : "";
    };

    return (
        <section className={"basic has-top"}>
            <Header title={t("signup")} />
            <section>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <p className="text-center">{t("signup intro", { site: getSiteName() })}</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="my-3 flex">
                                <div className="flex-1 mr-1">
                                    <div className={labelClass + (!watch("name") && "invisible")}>{t("name")}</div>
                                    <input
                                        ref={register({ required: txtRequired })}
                                        type="text"
                                        name="name"
                                        id="input-name"
                                        placeholder={t("name")}
                                        onChange={() => triggerValidation("name")}
                                        className={errors.name ? "error" : ""}
                                        autoFocus={true}
                                    />
                                    {errors.name && (
                                        <div className="text-error float-right text-sm">{errors.name.message}</div>
                                    )}
                                </div>
                                <div className="flex-1 ml-1">
                                    <div className={labelClass + (!watch("surname") && "invisible")}>
                                        {t("surname")}
                                    </div>
                                    <input
                                        ref={register({ required: txtRequired })}
                                        type="text"
                                        name="surname"
                                        id="input-surname"
                                        placeholder={t("surname")}
                                        onChange={() => triggerValidation("surname")}
                                        className={errors.surname ? "error" : ""}
                                    />
                                    {errors.surname && (
                                        <div className="text-error float-right text-sm">{errors.surname.message}</div>
                                    )}
                                </div>
                            </div>

                            <div className="my-3">
                                <div className={labelClass + (!watch("email") && "invisible")}>{t("email")}</div>
                                <input
                                    ref={register({
                                        required: txtRequired,
                                        validate: (value) => isEmail(value) || t("invalid email"),
                                    })}
                                    type="email"
                                    name="email"
                                    id="input-email"
                                    placeholder={t("email")}
                                    onChange={() => triggerValidation("email")}
                                    className={errors.email ? "error" : ""}
                                />
                                {errors.email && (
                                    <div className="text-error float-right text-sm">{errors.email.message}</div>
                                )}
                            </div>

                            <div className="mt-3 mb-1">
                                <div className={labelClass + (!watch("password") && "invisible")}>{t("password")}</div>
                                <div className="relative">
                                    <input
                                        ref={register({
                                            required: true,
                                            pattern: /^(?=.{6,50}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).*$/gm,
                                        })}
                                        type={passVisible ? "text" : "password"}
                                        name="password"
                                        id="input-pass"
                                        placeholder={t("password")}
                                        onChange={() => triggerValidation("password")}
                                        className={errors.password ? "error" : ""}
                                    />
                                    <i
                                        className={
                                            "absolute right-0 m-3 text-neutral-contrast-50 text-xl cursor-pointer icon icon-eye-" +
                                            (passVisible ? "on" : "off")
                                        }
                                        id="toggle-pass-visibility"
                                        onClick={togglePassVisibility}
                                    ></i>
                                </div>
                            </div>
                            {errors.password ? errors.password.message : ""}
                            <p className={"text-sm whitespace-pre-line"}>
                                <span className={checkPass(/(?=.{6,50}$)/gm)}>{t("passrules.chars")}</span>
                                <br />
                                <span className={checkPass(/(?=.*[a-z])(?=.*[A-Z])/gm)}>{t("passrules.case")}</span>
                                <br />
                                <span className={checkPass(/(?=.*[0-9])/gm)}>{t("passrules.num")}</span>
                            </p>

                            <div className="text-left mt-3 text-sm">
                                <div>
                                    <input
                                        id="checkbox-terms"
                                        ref={register({ required: true })}
                                        type="checkbox"
                                        name="terms"
                                        onChange={() => triggerValidation("terms")}
                                        className={errors.name ? "error" : ""}
                                    />
                                    <label htmlFor="checkbox-terms" className="pl-2">
                                        {t("accept terms", { terms: " " })}
                                    </label>
                                    <Link
                                        id="link-terms"
                                        onClick={saveFormState}
                                        to="signup/terms"
                                        className="underline font-bold"
                                    >
                                        {t("terms & conditions")}
                                    </Link>
                                    {t("terms & policy", { terms: " ", privacy: " " })}
                                    <Link
                                        id="link-privacy"
                                        onClick={saveFormState}
                                        to="signup/privacy"
                                        className="underline font-bold"
                                    >
                                        {t("privacy policy")}
                                    </Link>
                                </div>
                                {askMarketing ? (
                                    <div className="mt-2">
                                        <input
                                            id="checkbox-marketing"
                                            ref={register({ required: false })}
                                            type="checkbox"
                                            name="marketing"
                                            onChange={() => triggerValidation("marketing")}
                                            className={errors.name ? "error" : ""}
                                        />
                                        <label htmlFor="checkbox-marketing" className="pl-2">
                                            {t("authorize marketing")}
                                        </label>
                                    </div>
                                ) : null}
                            </div>
                            <button
                                type="submit"
                                id="action-signup"
                                disabled={watch("terms") ? false : true}
                                className={
                                    "btn block mx-auto my-5 w-full text-lg " +
                                    (watch("terms") ? "btn-accent" : "btn-neutral-contrast-20")
                                }
                            >
                                {t("signup submit")}
                            </button>
                        </form>
                    </>
                )}
            </section>
        </section>
    );
};

export default SignUp;
