import gql from "graphql-tag";
import { appUID } from "../../utils/others";
import { deviceUID } from "../../reducers";

export const DEVICE_TOKEN = {
    query: gql`
        mutation deviceToken(
            $app: String!
            $device: String!
            $domain: String!
            $language: LanguageCode
            $chainRef: ID!
        ) {
            deviceToken(app: $app, device: $device, domain: $domain, language: $language, chainRef: $chainRef) {
                token
            }
        }
    `,
    defaultVars: {
        app: appUID(),
        device: deviceUID(),
        domain: "app-test",
        language: "en",
        chainRef: "",
    },
};
