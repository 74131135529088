import React, { useEffect, useState } from "react";
import Tabs from "../components/Tabs";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserData } from "actions/user";
import { getStaysData, setStaysData } from "actions/stays";
import { getHotel } from "actions/hotel";
import { getSelectedHotel } from "actions/hotel";
import { capitalize } from "utils/others";
import { UseStayBookings } from "graphql/stays";
import swal from "@sweetalert/with-react";
import { GET_UPCOMING_BOOKINGS_WHIT_CODES, GET_UPCOMING_BOOKINGS_WHIT_EMAIL } from "graphql/queries/stays";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const Sections = ({ sections, currentSelected, setCurrentSelected }) => {
    const { t } = useTranslation(["interface"]);
    return (
        <div className="w-full flex justify-between bg-white">
            {sections.map((section, index) => {
                return (
                    <button
                        id={`section-tab-${index}`}
                        key={`section-tab-${index}`}
                        className={`w-full p-6 flex justify-center ${
                            currentSelected == section.id ? "border-b-2 border-accent" : ""
                        }`}
                        onClick={() => {
                            setCurrentSelected(section.id);
                        }}
                    >
                        <span className=" text-center w-full">{t(section.name)}</span>
                    </button>
                );
            })}
        </div>
    );
};

const AddBookingButton = ({ redirect }) => {
    const { t } = useTranslation(["interface"]);
    return (
        <div className="flex justify-center p-4 w-full">
            <button
                id="add-booking-button"
                className=" text-accent text-center hover:cursor-pointer"
                onClick={() => {
                    redirect("/stays/add");
                }}
            >
                + {t("add-booking")}
            </button>
        </div>
    );
};

const BookingCard = ({ status, arrivalDate, departureDate, code, guests, openMenu, pendingChangesSend }) => {
    const { t } = useTranslation(["interface"]);
    const project = useSelector(getSelectedHotel);

    const typeOfStatus = {
        PENDING_COMPLETION: {
            style: "bg-black",
            text: "available",
        },
        PENDING_MODIFICATION: {
            style: "bg-warning",
            text: "pending_modification",
        },
        PENDING_VALIDATION: {
            style: "bg-green-500",
            text: "sent",
        },
        VALIDATED: {
            style: "bg-green-500",
            text: "sent",
        },
        PENDING_TO_BE_SENT: {
            style: "bg-black",
            text: "pending-to-be-sent",
        },
    };

    const formatDate = (date) => {
        const formatDate = new Date(date);
        let day = formatDate.getUTCDate().toString();
        if (day.length === 1) {
            day = "0".concat("", day);
        }
        let month = (formatDate.getUTCMonth() + 1).toString();
        if (month.length === 1) {
            month = "0".concat("", month);
        }
        const year = formatDate.getUTCFullYear().toString();
        return `${day}/${month}/${year}`;
    };
    return (
        <div
            className=" bg-white p-6 rounded-md mb-4 cursor-pointer"
            id={`reservation-card-${code}`}
            onClick={() => {
                openMenu({ code: code, status: status });
            }}
        >
            <h1 className=" font-bold text-xl">{project.name}</h1>
            <div className="flex flex-col border-b-2 mb-2 py-4 gap-2">
                <div className="flex justify-between">
                    <span className=" font-bold">{capitalize(t("arrival"))}</span>
                    <span>{formatDate(arrivalDate)}</span>
                </div>
                <div className="flex justify-between">
                    <span className=" font-bold">{capitalize(t("departure"))}</span>
                    <span>{departureDate ? formatDate(departureDate) : "-"}</span>
                </div>
            </div>
            {status != "COMPLETED" ? (
                <div
                    className={` px-4 py-2 flex flex-row gap-4 rounded-md text-white ${
                        !pendingChangesSend ? typeOfStatus[status].style : typeOfStatus["PENDING_TO_BE_SENT"].style
                    }`}
                >
                    <span>{capitalize(t("pre-check-in"))}:</span>
                    <span>
                        {capitalize(
                            t(!pendingChangesSend ? typeOfStatus[status].text : typeOfStatus["PENDING_TO_BE_SENT"].text)
                        )}
                    </span>
                </div>
            ) : (
                <div className="flex justify-between">
                    <span className=" font-bold">{capitalize(t("rooms"))}</span>
                    <span>
                        {guests
                            .map((guest) => {
                                return guest.stayGuestRooms.reduce((acc, current) => {
                                    if (!current.checkedout) {
                                        acc.push(current.room.number);
                                    }
                                    return acc;
                                }, []);
                            })
                            .reduce((acc, current) => {
                                acc = [...acc, ...current];
                                return acc;
                            }, [])
                            .reduce((acc, current) => {
                                if ((acc.length > 0 && !acc.includes(current)) || acc.length == 0) {
                                    acc = [...acc, current];
                                } else {
                                    acc = [...acc];
                                }
                                return acc;
                            }, [])
                            .toString()}
                    </span>
                </div>
            )}
        </div>
    );
};

const Stays = () => {
    const sections = [
        { name: "currents", id: 0 },
        { name: "upcoming", id: 1 },
    ];
    const { status, id } = useParams();
    const { t } = useTranslation(["interface"]);
    const hotel = useSelector(getHotel);
    const user = useSelector(getUserData);
    const staysData = useSelector(getStaysData);
    const dispatch = useDispatch();
    const [currentSection, setCurrentSection] = useState(sections[0].id);
    const [stays, setStays] = useState([]);
    const [redirectTo, setRedirectTo] = useState("");

    const { data: bookingsWithEmail, query: queryBookingsWithEmail } = UseStayBookings(
        GET_UPCOMING_BOOKINGS_WHIT_EMAIL
    );
    const { data: bookingsWithCodes, query: queryBookingsWithCodes } = UseStayBookings(
        GET_UPCOMING_BOOKINGS_WHIT_CODES
    );

    const filterStays = (staysWithEmail, staysWithCode) => {
        setStays(
            [...staysWithEmail, ...staysWithCode]
                .filter((stay) => (currentSection == 0 ? stay.state == "COMPLETED" : stay.state != "COMPLETED"))
                .reduce((acc, current) => {
                    if (!acc.some((element) => element.code === current.code)) {
                        acc.push(current);
                    }
                    return acc;
                }, [])
                .filter((stay) => {
                    if (stay.state == "COMPLETED") {
                        const guests = stay.guests
                            .find((guest) => guest.guestRole == "MAIN")
                            .stayGuestRooms.filter((room) => !room.checkedout);
                        return guests.length > 0;
                    } else {
                        return true;
                    }
                })
        );
    };
    const openMenu = (currentBookingCard) => {
        const closeMenu = () => {
            swal.close();
        };
        const PENDING_MODIFICATION_STATUS = "PENDING_MODIFICATION";
        const PENDING_COMPLETION_STATUS = "PENDING_COMPLETION";
        const listOfSecondStatus = ["PENDING_VALIDATION", "VALIDATED"];

        const menuOptions = [
            {
                title: t("information-about-stay"),
                id: "information-about-stay",
                onClick: () => {
                    closeMenu();
                    setRedirectTo(`/stays/details/${currentBookingCard.code}`);
                },
            },
            PENDING_COMPLETION_STATUS == currentBookingCard.status && {
                title: t("complete-the-pre-check-in"),
                id: "complete-the-pre-check-in",
                onClick: () => {
                    closeMenu();
                    setRedirectTo(`/stays/details/${currentBookingCard.code}/precheckin`);
                },
            },
            PENDING_MODIFICATION_STATUS == currentBookingCard.status && {
                title: t("modify-guest-information"),
                id: "modify-guest-information",
                onClick: () => {
                    closeMenu();
                    setRedirectTo(`/stays/details/${currentBookingCard.code}/precheckin`);
                },
            },
            listOfSecondStatus.includes(currentBookingCard.status) && {
                title: t("modify-guest-information"),
                id: "modify-guest-information",
                onClick: () => {
                    closeMenu();
                    setRedirectTo(`/stays/details/${currentBookingCard.code}/precheckin`);
                },
            },
            {
                title: t("go-to-hotel"),
                id: "go-to-hotel",
                onClick: () => {
                    setRedirectTo("/site");
                },
            },
        ];
        swal({
            className: "menu-list",
            button: false,
            content: (
                <div>
                    <ul className="px-5 mb-5">
                        {menuOptions.map((item) =>
                            item ? (
                                <li key={"room-menu-" + item.title} id={item.id} onClick={item.onClick}>
                                    <span>{item.title}</span>
                                </li>
                            ) : null
                        )}
                    </ul>
                </div>
            ),
        });
    };

    useEffect(() => {
        if (id) {
            if (staysData && !staysData.includes(id)) {
                dispatch(setStaysData({ user: user?.ref ?? "any", values: [...staysData, id] }));
            } else {
                dispatch(setStaysData({ user: user?.ref ?? "any", values: [id] }));
            }
            setRedirectTo("/stays/status=upcoming");
        }
        if (user?.email) {
            queryBookingsWithEmail({ email: user.email });
        }
        if (staysData) {
            queryBookingsWithCodes({ codes: staysData });
        }
        if (status && sections.some((section) => section.name === status)) {
            setCurrentSection(
                sections.reduce((acc, current) => {
                    if (current.name === status) {
                        acc = current.id;
                    }
                    return acc;
                }, 0)
            );
        }
    }, []);

    useEffect(() => {
        if (!hotel) {
            setRedirectTo("/site");
        }
    }, [hotel]);

    useEffect(() => {
        filterStays(bookingsWithEmail?.results ?? [], bookingsWithCodes?.results ?? []);
    }, [bookingsWithEmail, currentSection, bookingsWithCodes]);

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }
    return (
        <section className="basic has-tabs has-top">
            <section
                style={{
                    position: "relative",
                    padding: 0,
                    ...(stays.length > 2 && { paddingBottom: "80px" }),
                }}
            >
                <Sections
                    sections={sections}
                    currentSelected={currentSection}
                    setCurrentSelected={setCurrentSection}
                ></Sections>
                <AddBookingButton redirect={setRedirectTo}></AddBookingButton>

                {stays.map(({ arrivalDate, departureDate, state, code, guests, pendingChangesSend }, index) => (
                    <BookingCard
                        key={`booking-card-${index}`}
                        status={state}
                        arrivalDate={arrivalDate}
                        departureDate={departureDate}
                        code={code}
                        guests={guests}
                        openMenu={openMenu}
                        redirect={setRedirectTo}
                        pendingChangesSend={pendingChangesSend}
                    ></BookingCard>
                ))}
            </section>
            <Tabs />
        </section>
    );
};

export default Stays;
