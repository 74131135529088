import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getSelectedHotel } from "actions/hotel";
import { useSendStayBookingPendingValidation } from "graphql/stays";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getUser } from "actions/user";
import { toast } from "react-toastify";
import { useUpdateStayBooking } from "graphql/stays";

const PrecheckinTab = ({
    currentStep,
    setCurrentStep,
    stepsEnabled,
    setStepsEnabled,
    setLoading,
    termsAccepted,
    dataStayBooking,
}) => {
    const { t } = useTranslation();

    const project = useSelector(getSelectedHotel);
    const { code } = useParams();
    const history = useHistory();
    const currentUser = useSelector(getUser);

    const {
        query: querySend,
        error: errorSend,
        result: resultSend,
    } = useSendStayBookingPendingValidation({ hotelRef: project?.ref, code: code });

    const { query: queryUpdateTerms } = useUpdateStayBooking({ hotelRef: project?.ref, code: code });

    let disabledNextButton = false;

    if (!termsAccepted) {
        disabledNextButton = true;
    } else if (currentStep === 1 && stepsEnabled[2] === false) {
        disabledNextButton = true;
    } else {
        disabledNextButton = stepsEnabled[currentStep] === true ? false : true;
    }

    useEffect(() => {
        if (errorSend || resultSend) {
            if (!currentUser) {
                history.push("/stays/precheckin/signin");
            } else {
                toast(<ToastSucces />);
                history.push("/stays/status=upcoming");
            }
        }
    }, [errorSend, resultSend]);

    return (
        <div style={{ zIndex: 20 }} className=" tab-bar flex justify-center h-20">
            <div className=" w-full flex items-center px-4 space-x-2 h-full justify-center">
                <button
                    onClick={() => {
                        if (currentStep === 0) {
                            setCurrentStep(0);
                            history.push("/stays/status=upcoming");
                        } else {
                            setCurrentStep((currentStep) => currentStep - 1);
                        }
                    }}
                    id="cancel-tab-button"
                    className=" w-1/2 font-bold text-gray-900 rounded py-2 text-lg  "
                >
                    {t("cancel")}
                </button>
                <button
                    onClick={() => {
                        if (currentStep === 0 && termsAccepted) {
                            queryUpdateTerms({ hotelRef: project?.ref, code: code, termsAccepted: true });
                        }

                        if (currentStep === 2) {
                            setCurrentStep(2);
                            querySend();
                            setLoading(true);
                        } else {
                            setCurrentStep((currentStep) => currentStep + 1);

                            if (currentStep === 0) {
                                setStepsEnabled((prevSteps) => {
                                    return { ...prevSteps, 1: true };
                                });
                            }
                        }
                    }}
                    disabled={disabledNextButton}
                    className={` w-1/2 font-bold rounded py-2 text-lg ${
                        disabledNextButton ? " bg-neutral-contrast-40" : "bg-accent"
                    } text-accent-contrast`}
                    id="accept-tab-button"
                >
                    {currentStep === 2 ? t("send") : t("next")}
                </button>
            </div>
        </div>
    );
};

const ToastSucces = () => {
    const { t } = useTranslation();

    return (
        <div className=" flex items-center justify-center">
            <i className=" icon icon-checkmark mr-2"></i> {t("pre-check-in-successfully-sent")}
        </div>
    );
};

export default PrecheckinTab;
