import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TextInput = ({ placeholder = "", name = "name", onChange, value, isRequired, error, errorMsg, disabled }) => {
    const { t } = useTranslation();

    const [currentValue, setCurrentValue] = useState("");

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    return (
        <div className=" w-full">
            <label
                // style={{ color: disabled ? "#323232" : null }}
                className={`${disabled ? " text-neutral-contrast-40" : ""} block text-sm mb-1`}
                htmlFor={`text-input-${name}-input`}
            >
                {t(name)}
                {isRequired && !disabled ? " *" : ""}
            </label>
            <input
                onChange={(e) => {
                    if (onChange) {
                        onChange(e);
                    }
                }}
                disabled={disabled}
                className={error ? "error" : null}
                value={currentValue}
                id={`text-input-${name}-input`}
                type="text"
                placeholder={t(placeholder)}
            />
            {error && errorMsg ? <div className=" text-error float-right text-sm">{errorMsg}</div> : null}
        </div>
    );
};

export default TextInput;
