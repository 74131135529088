import React, { useState, useEffect } from "react";
import { getDasUrl } from "../../actions/config";

export const getDeviceWidth = () => {
    const isPortal = window.location.href.match(/\/portal($|#|\?|\/)/);
    const fullWidth = document.body.getClientRects()[0].width;
    return isPortal ? fullWidth : Math.min(512, fullWidth);
};

export const transformEditorVWtoPX = (size, zoom, width) => {
    if (!zoom) {
        zoom = 1;
    }
    if (!width) {
        width = getDeviceWidth();
    }
    return Math.round(((width * zoom) / 100) * size);
};

export const parseSize = (size, deviceType) =>
    transformEditorVWtoPX(size, deviceType && deviceType === deviceTypes.DESKTOP ? 1 : 3);

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        let doit;
        const handleResize = () => {
            clearTimeout(doit);
            doit = setTimeout(() => {
                // If the current active element is a input,
                // we can assume the soft keyboard is visible(ignore resize event)
                const activeType = document.activeElement.type;
                if (
                    activeType !== "text" &&
                    activeType !== "password" &&
                    activeType !== "textarea" &&
                    activeType !== "select"
                ) {
                    setWindowDimensions(getWindowDimensions());
                }
            }, 500);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}

const getWindowDimensions = () => {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
    };
};

export const translateWeatherLang = (lang) => {
    switch (lang) {
        case "es":
            return 4;
        case "fr":
            return 3;
        case "it":
            return 5;
        case "ge":
            return 6;
        default:
            return 1;
    }
};

export const arrangeThemeStyles = ({ elementStyles, theme, widgetStyles }) => {
    let styles = { ...elementStyles };
    if (widgetStyles === true && theme) {
        if (theme.colors) {
            styles.bgColor = styles.bgColor || theme.colors.defaultBackground;
            styles.fgColor = styles.fgColor || theme.colors.defaultForeground;
            styles.focusBgColor = styles.focusBgColor || theme.colors.focusBackground;
            styles.focusFgColor = styles.focusFgColor || theme.colors.focusForeground;
            styles.fgActiveColor = styles.fgActiveColor || theme.colors.activeForeground;
        }
        if (theme.fonts && theme.fonts.paragraph) {
            styles.textSize = styles.textSize || theme.fonts.paragraph.size;
            styles.font = styles.font || theme.fonts.paragraph.font;
        }
    }
    return styles;
};

const arrangTextStyles = (styles) => {
    let css = {};
    if (styles.alignment) {
        css.textAlign = styles.alignment;
    }
    if (styles.bold) {
        css.fontWeight = 500;
    }
    if (styles.fgColor) {
        css.color = styles.fgColor;
    }
    if (styles.font) {
        css.fontFamily = styles.font.name;
    }
    if (styles.fontStyle) {
        css.fontStyle = styles.fontStyle;
    }
    if (styles.italic) {
        css.fontStyle = "italic";
    }
    if (styles.textSize) {
        css.textSize = styles.textSize;
    }
    if (styles.underline) {
        css.textDecoration = "underline";
    }
    if (styles.focusFgColor) {
        css.focusFgColor = styles.focusFgColor;
    }
    if (styles.disabledFgColor) {
        css.disabledFgColor = styles.disabledFgColor;
    }
    if (styles.fgActiveColor) {
        css.fgActiveColor = styles.fgActiveColor;
    }
    return css;
};

const arrangeBackgroundStyles = (styles, useBackground, dasURL, lang, useParentBackground) => {
    let css = {};
    let dynamicObject;
    if (useBackground && styles.bgColor) {
        css.backgroundColor = styles.bgColor;
    }
    if (styles.focusBgColor) {
        css.focusBgColor = styles.focusBgColor;
    }
    if (styles.disabledBgColor) {
        css.disabledBgColor = styles.disabledBgColor;
    }

    if (useBackground && styles.images && styles.images.length > 0) {
        dynamicObject = null;
        if (styles.images[0].libraryRef) {
            dynamicObject = `${dasURL}/${styles.images[0].libraryRef}?lang=${lang}`;
        } else {
            dynamicObject = styles.images[0].externalUrl;
        }
        if (dynamicObject) {
            if (useParentBackground) {
                if (css.backgroundColor) {
                    delete css.backgroundColor;
                }
                if (css.disabledBgColor) {
                    delete css.disabledBgColor;
                }
                css.parentBackground = {};
                css.parentBackground.position = "fixed";
                css.parentBackground.left = 0;
                css.parentBackground.right = 0;
                css.parentBackground.top = 0;
                css.parentBackground.bottom = 0;
                css.parentBackground.zIndex = "0";
                css.parentBackground.backgroundImage = `url('${dynamicObject}')`;
                css.parentBackground.backgroundPosition = "center";
                css.parentBackground.backgroundSize = "cover";
                css.parentBackground.backgroundRepeat = "no-repeat";
                if (styles.opacity) {
                    css.parentBackground.opacity = styles.opacity / 100;
                }
                if (styles.bgColor) {
                    css.parentBackground.backgroundColor = styles.bgColor;
                }
            } else {
                css.backgroundImage = `url('${dynamicObject}')`;
                css.backgroundPosition = "center";
                css.backgroundSize = "cover";
                css.backgroundRepeat = "no-repeat";
            }
        }
    }
    return css;
};

const arrangeBorderStyles = (styles) => {
    let css = {};

    if (styles.borderColor) {
        css.borderColor = styles.borderColor;
    }
    if (styles.borderWidth) {
        css.borderWidth = `${styles.borderWidth}px`;
    }
    if (styles.disabledBorderColor) {
        css.disabledBorderColor = styles.disabledBorderColor;
    }
    if (styles.disabledBorderWidth) {
        css.disabledBorderWidth = `${styles.disabledBorderWidth}px`;
    }
    if (styles.radius) {
        css.borderRadius = `${styles.radius}rem`;
    }
    return css;
};

const arrangeAlignment = (styles) => {
    let css = {};

    if (styles.iconMargin) {
        css.iconMargin = styles.iconMargin;
    }
    if (styles.iconPosition) {
        css.iconPosition = styles.iconPosition;
    }
    if (styles.iconSize) {
        css.iconSize = styles.iconSize;
    }
    if (styles.vAlignment) {
        switch (styles.vAlignment) {
            case "top":
                css.alignItems = "flex-start";
                break;
            case "center":
                css.alignItems = "center";
                break;
            case "bottom":
                css.alignItems = "flex-end";
                break;
            default:
                css.alignItems = styles.vAlignment;
                break;
        }
    }
    return css;
};

const arrangeDimensionsStyles = (styles) => {
    let css = {};

    if (styles.padding) {
        css.padding = `${styles.padding}px`;
    }

    if (styles.itemsMargin && styles.itemsMargin.W && styles.itemsMargin.W > 0.0) {
        css.paddingLeft = styles.itemsMargin.W;
        css.paddingRight = styles.itemsMargin.W;
    }
    if (styles.itemsMargin && styles.itemsMargin.H && styles.itemsMargin.H > 0.0) {
        css.paddingTop = styles.itemsMargin.H;
        css.paddingBottom = styles.itemsMargin.H;
    }
    if (styles.margin && styles.margin.W && styles.margin.W > 0.0) {
        css.marginLeft = styles.margin.W;
        css.marginRight = styles.margin.W;
    }
    if (styles.margin && styles.margin.H && styles.margin.H > 0.0) {
        css.marginTop = styles.margin.H;
        css.marginBottom = styles.margin.H;
    }

    return css;
};

export const arrangeStyles = ({ style: styles, dasURL, lang, useParentBackground, useBackground = true }) => {
    let css = {};
    if (styles) {
        css = {
            ...arrangTextStyles(styles),
            ...arrangeBorderStyles(styles),
            ...arrangeAlignment(styles),
            ...arrangeDimensionsStyles(styles),
            ...arrangeBackgroundStyles(styles, useBackground, dasURL, lang, useParentBackground),
        };
    }
    return css;
};

export const getFontURL = (font) => {
    if (font === "Lato") {
        return "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap";
    } else if (font) {
        if (font.libraryRef) {
            const dasURL = getDasUrl();
            return `${dasURL}/${font.libraryRef}?format=css`;
        } else if (font.externalUrl) {
            return font.externalUrl;
        }
    }
    return null;
};

export const postMessageToParent = (message, transferList = "*") => {
    if (
        window.parent &&
        window.parent.webkit &&
        window.parent.webkit.messageHandlers &&
        window.parent.webkit.messageHandlers.jsHandler
    ) {
        window.parent.webkit.messageHandlers.jsHandler.postMessage(JSON.stringify(message));
    } else if (window.parent && window.parent.postMessage) {
        window.parent.postMessage(JSON.stringify(message), transferList);
    }
};

export const deviceTypes = {
    MOBILE: "Mobile",
    DESKTOP: "Desktop",
};

export const getDeviceInfo = ({ width, height, isPortal }) => {
    // Make info variants
    const info = {};
    info[deviceTypes.MOBILE] = {
        type: deviceTypes.MOBILE,
        ratio: isPortal ? 12 / 25 : 9 / 16,
    };
    info[deviceTypes.DESKTOP] = {
        type: deviceTypes.DESKTOP,
        ratio: 16 / 9,
    };

    // Define device
    let device;
    const allowedDevices = isPortal ? [deviceTypes.MOBILE, deviceTypes.DESKTOP] : [deviceTypes.MOBILE];
    if (allowedDevices.length > 1) {
        if (width > height) {
            device = deviceTypes.DESKTOP;
        }
        if (!device || !allowedDevices.includes(device)) {
            device = deviceTypes.MOBILE;
        }
    }
    if (!device && allowedDevices.length > 0) {
        device = allowedDevices[0];
    }
    return {
        width: width,
        height: height,
        realRatio: width / height,
        ...info[device],
    };
};

// Parse widget configs
export const parseTextConfig = (o) => (o && o.text ? o.text : null);

export const parseImageConfig = (o, lang) => {
    if (!o) {
        return null;
    }

    const dasURL = getDasUrl();
    if (o.libraryRef) {
        return `${dasURL}/${o.libraryRef}?lang=${lang}`;
    } else {
        return o.externalUrl;
    }
};
// CSS Props
export const cssBackgroundImage = (v) => `url('${v}')`;
export const cssBackgroundSize = (v) => {
    switch (v) {
        case "contain":
            return "contain";
        case "cover-adjust":
            return "100% 100%";
        case "cover":
        default:
            return "cover";
    }
};
export const cssBackgroundPosition = (v) => {
    return `${v}%`;
};

export const cssAlignItems = (v) => {
    switch (v) {
        case "top":
            return "flex-start";
        case "center":
            return "center";
        case "bottom":
            return "flex-end";
        default:
            return v;
    }
};

const configToCSSTextStyles = (cfg, sizeFn) => {
    let css = {};

    if (cfg.fgColor) {
        css.color = cfg.fgColor;
    }
    if (cfg.bold !== undefined) {
        css.fontWeight = cfg.bold ? 800 : "normal";
    }
    if (cfg.italic !== undefined) {
        css.fontStyle = cfg.italic ? "italic" : "none";
    }
    if (cfg.underline !== undefined) {
        css.textDecoration = cfg.underline ? "underline" : "none";
    }
    if (cfg.font) {
        css.fontFamily = cfg.font;
    }
    if (cfg.size) {
        css.fontSize = sizeFn(cfg.size);
    }

    return css;
};

const configToCSSBorderStyles = (cfg) => {
    let css = {};
    if (cfg.border) {
        if (cfg.border.color) {
            css.borderColor = cfg.border.color;
        }
        if (cfg.border.size) {
            css.borderWidth = cfg.border.size + "px";
        }
        if (cfg.border.radius) {
            css.borderRadius = cfg.border.radius + "rem";
        }
    }

    return css;
};

const configToCSSDimensionsStyles = (cfg) => {
    let css = {};

    if (cfg.width) {
        css.width = cfg.width;
    }
    if (cfg.height) {
        css.height = cfg.height;
    }
    if (cfg.maxWidth) {
        css.maxWidth = cfg.maxWidth;
    }
    if (cfg.maxHeight) {
        css.maxHeight = cfg.maxHeight;
    }
    if (cfg.top) {
        css.top = cfg.top;
    }
    if (cfg.bottom) {
        css.bottom = cfg.bottom;
    }
    if (cfg.left) {
        css.left = cfg.left;
    }
    if (cfg.right) {
        css.right = cfg.right;
    }

    return css;
};

const configToCSSBgStyles = (cfg) => {
    let css = {};
    if (cfg.bgColor) {
        css.backgroundColor = cfg.bgColor;
    }
    if (cfg.bgImage && cfg.bgImage.src) {
        css.backgroundImage = cssBackgroundImage(cfg.bgImage.src);
        css.backgroundSize = cssBackgroundSize(cfg.bgImage.adjust);
        css.backgroundPosition = cssBackgroundPosition(cfg.bgImage.position);
    }

    return css;
};

const configToCSSLayoutStyles = (cfg, sizeFn) => {
    let css = {};
    if (cfg.display) {
        css.display = cfg.display;
    }
    if (cfg.align) {
        css.textAlign = cfg.align;
    }
    if (cfg.vAlign) {
        css.alignItems = cssAlignItems(cfg.vAlign);
    }
    if (cfg.padding) {
        css.padding = cfg.padding;
    }
    if (cfg.positionMargin) {
        if (cfg.positionMargin.margin && cfg.positionMargin.margin > 0) {
            switch (cfg.positionMargin.pos) {
                case "top":
                    css.paddingBottom = sizeFn(cfg.positionMargin.margin);
                    break;
                case "bottom":
                    css.paddingTop = sizeFn(cfg.positionMargin.margin);
                    break;
                case "left":
                    css.paddingRight = sizeFn(cfg.positionMargin.margin);
                    break;
                case "right":
                default:
                    css.paddingLeft = sizeFn(cfg.positionMargin.margin);
            }
        }
    }
    return css;
};

export const configToCSS = (cfg, deviceType) => {
    const size = (s) => parseSize(s, deviceType) + "px";

    let css = {};
    if (cfg) {
        css = {
            ...css,
            ...configToCSSTextStyles(cfg, size),
            ...configToCSSBorderStyles(cfg),
            ...configToCSSDimensionsStyles(cfg),
            ...configToCSSBgStyles(cfg),
            ...configToCSSLayoutStyles(cfg, size),
        };
    }
    return css;
};

const getYouTubeID = (url) => {
    if (url) {
        url = String(url);
        //https://www.youtube.com/embed/{id}
        let match = url.match(/\/embed\/(\w+)/);
        if (match) {
            return match[1];
        }
        //https://www.youtube.com/watch?v={id}
        match = url.match(/\?v=(\w+)/);
        if (match) {
            return match[1];
        }
        //https://youtu.be/{id}
        match = url.match(/\.be\/(\w+)/);
        if (match) {
            return match[1];
        }
    }
    return null;
};

export const youTube = {
    check: (url) => {
        return url && url.match(/youtube|youtu\.be/) ? true : false;
    },
    embedURL: (url) => {
        if (url && !url.match(/\/embed\/(\w+)/)) {
            const id = getYouTubeID(url);
            if (id) {
                return "https://www.youtube.com/embed/" + id;
            }
        }
        return url;
    },
    posterURL: (url) => {
        const id = getYouTubeID(url);
        if (id) {
            return "https://img.youtube.com/vi/" + id + "/0.jpg";
        }
        return null;
    },
};

export const nl2br = (string) =>
    String(string)
        .split("\n")
        .map((item, index) => (index === 0 ? item : [<br key={index} />, item]));

export const processActions = ({ data, id, landing, lang, goToLanding, goToCuiner, goToRoomShop, dasURL, link }) => {
    if (data.actions && data.actions.length > 0) {
        data.actions.map(async (action) => {
            link = {
                id: `widget-${id}-action-${action.type}`,
                href: "#",
            };
            switch (action.type) {
                case "content":
                    link.onClick = () => goToLanding(action.value);
                    break;
                case "section":
                    switch (action.value) {
                        case "landing":
                            console.log(action.value);

                            link.onClick = () =>
                                goToLanding(landing.mainLandingRef ? landing.mainLandingRef : landing.landingRef);
                            break;
                        case "bill":
                            link.href = "#/checkout/bills";
                            break;
                        case "checkout":
                            link.href = "#/checkout";
                            break;
                        case "external-pos":
                            link.onClick = () => goToCuiner();
                            break;
                        case "roomshops":
                            link.href = `/#/sales`;
                            break;
                        case "myorders":
                            link.href = `/#/sales/orders`;
                            break;
                        default:
                            if (action.value.match(/^roomshop:(\d+)$/)) {
                                const shopID = action.value.split(":")[1];
                                link.onClick = () => goToRoomShop(shopID);
                            } else if (action.value.match(/^roomshop:(\w+)$/)) {
                                const shopToken = action.value.split(":")[1];
                                link.href = `/#/sales/${shopToken}`;
                            } else {
                                link.href = `/#/site/${action.value}`;
                            }
                    }
                    break;
                case "url":
                    link.href = action.value;
                    link.target = "_blank";
                    break;
                case "library":
                    link.href = `${dasURL}/${action.value}?lang=${lang}`;
                    link.target = "_blank";
                    break;
                default:
                    console.error("Unknown action type: " + action.type);
            }
        });
    }

    return link;
};
