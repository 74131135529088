export function determineTrackInfo(path, selectedLandings, selectedHotel, selectedRoom, screenSelected) {
    if (path?.includes("/signup/privacy")) {
        return { id: "privacypolicy", type: "SECTION" };
    }
    if (path?.includes("/signup/terms")) {
        return { id: "terms", type: "SECTION" };
    }
    if (path?.includes("/signup")) {
        return { id: "signup", type: "SECTION" };
    }
    if (path?.includes("/signin")) {
        return { id: "signin", type: "SECTION" };
    }
    if (path?.includes("/signout")) {
        return { id: "signout", type: "SECTION" };
    }
    if (path?.includes("addroom") && !path.includes("signin") && !path.includes("signup")) {
        return { id: "addroom", type: "SECTION" };
    }
    if (path === "/site" && selectedLandings?.[selectedHotel] && !screenSelected) {
        return { id: "landing", type: "SECTION" };
    }
    if (path === "/site" && selectedLandings?.[selectedHotel] && screenSelected) {
        return { id: screenSelected, type: "CONTENT" };
    }
    if (path === "/stays") {
        return { id: "staybookings", type: "SECTION" };
    }
    if (path?.startsWith("/sales/")) {
        const code = path.split("/")[2];
        return { id: "roomshops:" + code, type: "SECTION" };
    }
    if (path === "/sales") {
        return { id: "roomshops", type: "SECTION" };
    }
    if (path?.includes("/stays/details/")) {
        const code = path.split("/")[3];
        if (code) {
            return { id: "staybookings:" + code, type: "SECTION" };
        }
    }

    if (path === "/profile") {
        return { id: "profile", type: "SECTION" };
    }
    if (path === "/messages") {
        return { id: "messages:" + selectedRoom, type: "SECTION" };
    }
    if (path === "/notifications") {
        return { id: "notifications", type: "SECTION" };
    }
    if (path === "/site/booking") {
        return { id: "booking", type: "SECTION" };
    }
    if (path === "/site/zones") {
        return { id: "zones", type: "SECTION" };
    }
    if (path === "/checkout/bills") {
        return { id: "bill", type: "SECTION" };
    }
    if (path === "/checkout") {
        return { id: "checkout", type: "SECTION" };
    }
    if (path === "/keys") {
        return { id: "keys", type: "SECTION" };
    }

    return { id: null, type: null };
}
