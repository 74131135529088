import React, { useRef, useEffect } from "react";

import useWidget from "../../../hooks/useWidget";
import Render from "zrender/render";
import { getDasUrl } from "../../../actions/config";

const UseWidgetImage = ({ config, id, deviceType }) => {
    const { styles, lang } = useWidget({ type: "IMAGE", styles: config ? config.styles : null });

    const renderRef = useRef(new Render("IMAGE"));
    const render = renderRef.current;
    render.DAS = getDasUrl;

    useEffect(() => {
        if (render) {
            render.setStyle(styles);
            render.setDevice(deviceType);
            render.updateConfig({
                static: false,
                src: config.image?.src,
                adjust: config.image?.adjust,
                lang: lang,
            });
            render.update();
        }
    }, [styles, deviceType]);

    useEffect(() => {
        const renderNode = document.getElementById(id);
        if (renderNode) {
            while (renderNode.firstChild) {
                renderNode.removeChild(renderNode.firstChild);
            }
            if (render) {
                renderNode.appendChild(render.run());
            }
        }
    }, [id]);

    return <div id={id} className="w-full h-full"></div>;
};

export default UseWidgetImage;
