import bcrypt from "bcryptjs";
import {
    UPDATE_TOKEN,
    UPDATE_USER_DATA,
    SET_UPDATE_USER_FORM_VALUES,
    STORE_USER_PASS_HASH,
    STORE_USER_VERIFY_SENT,
    LOGIN_USER,
    LOGOUT_USER,
    UNAUTHORIZED_USER_LOGGED_ALERT,
} from "../actions/user";

import { UPDATE_SERVER_BOOKMARKS } from "../actions/hotel";
import Rooms, { DATABASE_ROOMS, initialState as initialStateRooms } from "../modules/rooms/store";

const initialState = {
    current: null,
    token: null,
    passHash: null,
    verificationEmail: {
        hash: null,
        sent: null,
    },
    date: null,
    unauthorizedUserLogged: false,
    lastUpdateUserFormValues: null,
    data: {},
    bookmarks: {},
    ...initialStateRooms,
};

const reducerUser = function (state = initialState, action = {}) {
    const newState = { ...state };
    switch (action.type) {
        case UNAUTHORIZED_USER_LOGGED_ALERT:
            newState.unauthorizedUserLogged = action.payload;
            break;
        case UPDATE_TOKEN:
            newState.token = action.payload;
            break;
        case LOGIN_USER:
            const [ref, token, pass] = action.payload;
            newState.current = ref;
            newState.token = token;
            newState.passHash = pass ? bcrypt.hashSync(pass, 10) : null;
            newState.lastLogin = new Date().getTime();
            break;
        case LOGOUT_USER:
            newState.current = null;
            newState.token = null;
            newState.passHash = null;
            newState.lastLogin = null;
            newState.verificationEmail = {
                hash: null,
                sent: null,
            };
            newState.userRooms = null;
            newState.unauthorizedUserLogged = action.payload;
            break;
        case STORE_USER_PASS_HASH:
            newState.passHash = bcrypt.hashSync(action.payload, 10);
            break;
        case STORE_USER_VERIFY_SENT:
            newState.verificationEmail = {
                hash: action.payload,
                sent: action.payload ? new Date().getTime() : null,
            };
            break;
        case UPDATE_USER_DATA:
            const [userData, userAuth] = action.payload;
            if (newState.current) {
                newState.data[newState.current] = {
                    ...userData,
                    userAuth: userAuth
                        ? userAuth
                        : newState.data[newState.current] && newState.data[newState.current].userAuth
                        ? newState.data[newState.current].userAuth
                        : null,
                    lastUpdate: new Date().getTime(),
                };
            }
            break;
        case DATABASE_ROOMS:
            Rooms(newState, action.payload);
            break;
        case SET_UPDATE_USER_FORM_VALUES:
            newState.lastUpdateUserFormValues = action.payload;
            break;
        case UPDATE_SERVER_BOOKMARKS:
            if (newState.current) {
                if (!newState.bookmarks) newState.bookmarks = {};
                newState.bookmarks[newState.current] = {
                    data: action.payload,
                    userAuth:
                        newState.data[newState.current] && newState.data[newState.current].userAuth
                            ? newState.data[newState.current].userAuth
                            : null,
                    lastUpdate: new Date().getTime(),
                };
            }
            break;
        default:
    }
    return newState;
};

export default reducerUser;
