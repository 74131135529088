import bcrypt from "bcryptjs";
import { googleLogout } from "google-oauth-gsi";
import { FirebaseLogoutPush } from "../firebase";

export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const SET_UPDATE_USER_FORM_VALUES = "SET_UPDATE_USER_FORM_VALUES";
export const STORE_USER_PASS_HASH = "STORE_USER_PASS_HASH";
export const STORE_USER_VERIFY_SENT = "STORE_USER_VERIFY_SENT";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UNAUTHORIZED_USER_LOGGED_ALERT = "UNAUTHORIZED_USER_LOGGED_ALERT";

export function updateUserData(value, userAuth) {
    return setDispatch(UPDATE_USER_DATA, [value, userAuth]);
}

export function setUpdateUserFormValues(value) {
    return setDispatch(SET_UPDATE_USER_FORM_VALUES, value);
}

export function storePassHash(value) {
    return setDispatch(STORE_USER_PASS_HASH, value);
}

export function setVerificationEmail(value) {
    return setDispatch(STORE_USER_VERIFY_SENT, value);
}

export function loginUser(value, token, pass) {
    return setDispatch(LOGIN_USER, [value, token, pass]);
}

export function getUser(state) {
    return state?.user?.current || null;
}

export function getUserData(state) {
    const user = getUser(state);
    const data = state?.user?.data;
    return data && user ? data[user] : null;
}

export function updateToken(token) {
    return setDispatch(UPDATE_TOKEN, [token]);
}

export function getToken(state) {
    return state?.user?.token || null;
}

export function getPushToken(state) {
    return state?.session?.pushToken || null;
}

export function logoutUser(unauthorizedUserLogged) {
    FirebaseLogoutPush();
    googleLogout();
    return setDispatch(LOGOUT_USER, unauthorizedUserLogged);
}

export function unauthorizedUserLoggedAlert(unauthorizedUserLogged) {
    return setDispatch(UNAUTHORIZED_USER_LOGGED_ALERT, unauthorizedUserLogged);
}

export function compareUserPass(hash, pass) {
    try {
        if (!pass || !hash) {
            return false;
        } else {
            return bcrypt.compareSync(pass, hash);
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}

function setDispatch(type, value) {
    return (dispatch) => {
        dispatch({
            type: type,
            payload: value,
        });
    };
}
