import i18n from "i18n";
import isoCountries from "./countries";

export const nationalities = {
    af: { en: "Afghan", es: "Afgana" },
    al: { en: "Albanian", es: "Albanesa" },
    dz: { en: "Algerian", es: "Argelina" },
    as: { en: "American Samoan", es: "Samoana Americana" },
    ad: { en: "Andorran", es: "Andorrana" },
    ao: { en: "Angolan", es: "Angoleña" },
    ai: { en: "Anguillan", es: "Anguilana" },
    aq: { en: "Antarctican", es: "Antártica" },
    ag: { en: "Antiguan, Barbudan", es: "Antiguana, Barbudeña" },
    ar: { en: "Argentine", es: "Argentina" },
    am: { en: "Armenian", es: "Armenia" },
    aw: { en: "Aruban", es: "Arubeña" },
    au: { en: "Australian", es: "Australiana" },
    at: { en: "Austrian", es: "Austriaca" },
    az: { en: "Azerbaijani", es: "Azerbaiyana" },
    bs: { en: "Bahamian", es: "Bahameña" },
    bh: { en: "Bahraini", es: "Bareiní" },
    bd: { en: "Bangladeshi", es: "Bangladesí" },
    bb: { en: "Barbadian", es: "Barbadense" },
    by: { en: "Belarusian", es: "Bielorrusa" },
    be: { en: "Belgian", es: "Belga" },
    bz: { en: "Belizean", es: "Beliceña" },
    bj: { en: "Beninese", es: "Beninesa" },
    bm: { en: "Bermudian", es: "Bermudeña" },
    bt: { en: "Bhutanese", es: "Butanesa" },
    bo: { en: "Bolivian", es: "Boliviana" },
    bq: { en: "Bonaire", es: "De Bonaire" },
    ba: { en: "Bosnian, Herzegovinian", es: "Bosnia, Herzegovina" },
    bw: { en: "Botswanan", es: "Botsuana" },
    bv: { en: "Bouvet Island", es: "De la Isla Bouvet" },
    br: { en: "Brazilian", es: "Brasileña" },
    io: { en: "British Indian Ocean Territory", es: "Del Territorio Británico del Océano Índico" },
    bn: { en: "Bruneian", es: "Bruneana" },
    bg: { en: "Bulgarian", es: "Búlgara" },
    bf: { en: "Burkinabe", es: "Burkinesa" },
    bi: { en: "Burundian", es: "Burundesa" },
    cv: { en: "Cape Verdean", es: "Cabo Verdiana" },
    kh: { en: "Cambodian", es: "Camboyana" },
    cm: { en: "Cameroonian", es: "Camerunesa" },
    ca: { en: "Canadian", es: "Canadiense" },
    ky: { en: "Caymanian", es: "Caimanesa" },
    cf: { en: "Central African", es: "Centroafricana" },
    td: { en: "Chadian", es: "Chadiana" },
    cl: { en: "Chilean", es: "Chilena" },
    cn: { en: "Chinese", es: "China" },
    cx: { en: "Christmas Island", es: "De la Isla de Navidad" },
    cc: { en: "Cocos Islands", es: "De las Islas Cocos" },
    co: { en: "Colombian", es: "Colombiana" },
    km: { en: "Comoran", es: "Comorense" },
    cd: { en: "Congolese", es: "Congoleña" },
    cg: { en: "Congolese", es: "Congoleña" },
    ck: { en: "Cook Islander", es: "Cookiana" },
    cr: { en: "Costa Rican", es: "Costarricense" },
    hr: { en: "Croatian", es: "Croata" },
    cu: { en: "Cuban", es: "Cubana" },
    cw: { en: "Curaçaoan", es: "Curazoleña" },
    cy: { en: "Cypriot", es: "Chipriota" },
    cz: { en: "Czech", es: "Checa" },
    ci: { en: "Ivorian", es: "Marfileña" },
    dk: { en: "Danish", es: "Danesa" },
    dj: { en: "Djiboutian", es: "Yibutiana" },
    dm: { en: "Dominican", es: "Dominiquesa" },
    do: { en: "Dominican", es: "Dominicana" },
    ec: { en: "Ecuadorian", es: "Ecuatoriana" },
    eg: { en: "Egyptian", es: "Egipcia" },
    sv: { en: "Salvadoran", es: "Salvadoreña" },
    gq: { en: "Equatorial Guinean", es: "Ecuatoguineana" },
    er: { en: "Eritrean", es: "Eritrea" },
    ee: { en: "Estonian", es: "Estonia" },
    sz: { en: "Swazi", es: "Suazilandesa" },
    et: { en: "Ethiopian", es: "Etíope" },
    fk: { en: "Falkland Island", es: "Malvinense" },
    fo: { en: "Faroese", es: "Feroesa" },
    fj: { en: "Fijian", es: "Fiyiana" },
    fi: { en: "Finnish", es: "Finlandesa" },
    fr: { en: "French", es: "Francesa" },
    gf: { en: "French Guianese", es: "Guayanesa Francesa" },
    pf: { en: "French Polynesian", es: "Polinesia Francesa" },
    tf: { en: "French Southern Territories", es: "De los Territorios Australes Franceses" },
    ga: { en: "Gabonese", es: "Gabonesa" },
    gm: { en: "Gambian", es: "Gambiana" },
    ge: { en: "Georgian", es: "Georgiana" },
    de: { en: "German", es: "Alemana" },
    gh: { en: "Ghanaian", es: "Ghanesa" },
    gi: { en: "Gibraltar", es: "Gibraltareña" },
    gr: { en: "Greek", es: "Griega" },
    gl: { en: "Greenlander", es: "Groenlandesa" },
    gd: { en: "Grenadian", es: "Granadina" },
    gp: { en: "Guadeloupe", es: "Guadalupeña" },
    gu: { en: "Guamanian", es: "Guameña" },
    gt: { en: "Guatemalan", es: "Guatemalteca" },
    gg: { en: "Channel Islander", es: "De Guernsey" },
    gn: { en: "Guinean", es: "Guineana" },
    gw: { en: "Guinea-Bissauan", es: "Guineana-Bissau" },
    gy: { en: "Guyanese", es: "Guyanesa" },
    ht: { en: "Haitian", es: "Haitiana" },
    hm: { en: "Heard Island and McDonald Islands", es: "De las Islas Heard y McDonald" },
    va: { en: "Vatican", es: "Vaticana" },
    hn: { en: "Honduran", es: "Hondureña" },
    hk: { en: "Hong Konger", es: "Hongkonesa" },
    hu: { en: "Hungarian", es: "Húngara" },
    is: { en: "Icelandic", es: "Islandesa" },
    in: { en: "Indian", es: "India" },
    id: { en: "Indonesian", es: "Indonesia" },
    ir: { en: "Iranian", es: "Iraní" },
    iq: { en: "Iraqi", es: "Iraquí" },
    ie: { en: "Irish", es: "Irlandesa" },
    im: { en: "Manx", es: "Manesa" },
    il: { en: "Israeli", es: "Israelí" },
    it: { en: "Italian", es: "Italiana" },
    jm: { en: "Jamaican", es: "Jamaiquina" },
    jp: { en: "Japanese", es: "Japonesa" },
    je: { en: "Channel Islander", es: "De Jersey" },
    jo: { en: "Jordanian", es: "Jordana" },
    kz: { en: "Kazakhstani", es: "Kazaja" },
    ke: { en: "Kenyan", es: "Keniana" },
    ki: { en: "I-Kiribati", es: "Kiribatiana" },
    kp: { en: "North Korean", es: "Norcoreana" },
    kr: { en: "South Korean", es: "Surcoreana" },
    kw: { en: "Kuwaiti", es: "Kuwaití" },
    kg: { en: "Kyrgyz", es: "Kirguisa" },
    la: { en: "Laotian", es: "Laosiana" },
    lv: { en: "Latvian", es: "Letona" },
    lb: { en: "Lebanese", es: "Libanesa" },
    ls: { en: "Basotho", es: "Lesotense" },
    lr: { en: "Liberian", es: "Liberiana" },
    ly: { en: "Libyan", es: "Libia" },
    li: { en: "Liechtensteiner", es: "Liechtensteiniana" },
    lt: { en: "Lithuanian", es: "Lituana" },
    lu: { en: "Luxembourger", es: "Luxemburguesa" },
    mo: { en: "Macanese", es: "Macaense" },
    mg: { en: "Malagasy", es: "Malgache" },
    mw: { en: "Malawian", es: "Malaui" },
    my: { en: "Malaysian", es: "Malasia" },
    mv: { en: "Maldivian", es: "Maldiva" },
    ml: { en: "Malian", es: "Malí" },
    mt: { en: "Maltese", es: "Maltesa" },
    mh: { en: "Marshallese", es: "Marshallés" },
    mq: { en: "Martinican", es: "Martiniqueña" },
    mr: { en: "Mauritanian", es: "Mauritana" },
    mu: { en: "Mauritian", es: "Mauriciana" },
    yt: { en: "Mahoran", es: "Mayotense" },
    mx: { en: "Mexican", es: "Mexicana" },
    fm: { en: "Micronesian", es: "Micronesia" },
    md: { en: "Moldovan", es: "Moldava" },
    mc: { en: "Monégasque", es: "Monegasca" },
    mn: { en: "Mongolian", es: "Mongola" },
    me: { en: "Montenegrin", es: "Montenegrina" },
    ms: { en: "Montserratian", es: "Montserratense" },
    ma: { en: "Moroccan", es: "Marroquí" },
    mz: { en: "Mozambican", es: "Mozambiqueña" },
    mm: { en: "Burmese", es: "Birmana" },
    na: { en: "Namibian", es: "Namibia" },
    nr: { en: "Nauruan", es: "Nauruana" },
    np: { en: "Nepali", es: "Nepalí" },
    nl: { en: "Dutch", es: "Neerlandesa" },
    nc: { en: "New Caledonian", es: "Neocaledonia" },
    nz: { en: "New Zealander", es: "Neozelandesa" },
    ni: { en: "Nicaraguan", es: "Nicaragüense" },
    ne: { en: "Nigerien", es: "Nigerina" },
    ng: { en: "Nigerian", es: "Nigeriana" },
    nu: { en: "Niuean", es: "Niueana" },
    nf: { en: "Norfolk Islander", es: "Norfolkense" },
    mp: { en: "Northern Mariana Islander", es: "Norte-Marianense" },
    no: { en: "Norwegian", es: "Noruega" },
    om: { en: "Omani", es: "Omaní" },
    pk: { en: "Pakistani", es: "Pakistaní" },
    pw: { en: "Palauan", es: "Palauana" },
    ps: { en: "Palestinian", es: "Palestina" },
    pa: { en: "Panamanian", es: "Panameña" },
    pg: { en: "Papua New Guinean", es: "Papúa" },
    py: { en: "Paraguayan", es: "Paraguaya" },
    pe: { en: "Peruvian", es: "Peruana" },
    ph: { en: "Filipino", es: "Filipina" },
    pn: { en: "Pitcairn Islander", es: "Pitcairnesa" },
    pl: { en: "Polish", es: "Polaca" },
    pt: { en: "Portuguese", es: "Portuguesa" },
    pr: { en: "Puerto Rican", es: "Puertorriqueña" },
    qa: { en: "Qatari", es: "Catarí" },
    mk: { en: "Macedonian", es: "Macedonia" },
    ro: { en: "Romanian", es: "Rumana" },
    ru: { en: "Russian", es: "Rusa" },
    rw: { en: "Rwandan", es: "Ruandesa" },
    re: { en: "Réunionese", es: "Reunionense" },
    bl: { en: "Barthélemois", es: "Barthélemois" },
    sh: { en: "Saint Helenian", es: "Santa Heleniana" },
    kn: { en: "Kittitian or Nevisian", es: "Kitsiana o Nevisiana" },
    lc: { en: "Saint Lucian", es: "Santalucense" },
    mf: { en: "Saint-Martinoise", es: "Sanmartinés" },
    pm: { en: "Saint-Pierrais or Miquelonnais", es: "Sanpedrino o Miquelonnais" },
    vc: { en: "Saint Vincentian", es: "Sanvicentina" },
    ws: { en: "Samoan", es: "Samoana" },
    sm: { en: "Sammarinese", es: "Sanmarinense" },
    st: { en: "São Toméan", es: "Santotomense" },
    sa: { en: "Saudi", es: "Saudí" },
    sn: { en: "Senegalese", es: "Senegalesa" },
    rs: { en: "Serbian", es: "Serbia" },
    sc: { en: "Seychellois", es: "Seychelense" },
    sl: { en: "Sierra Leonean", es: "Sierraleonesa" },
    sg: { en: "Singaporean", es: "Singapurense" },
    sx: { en: "Sint Maarten", es: "Sint Maarten" },
    sk: { en: "Slovak", es: "Eslovaca" },
    si: { en: "Slovenian", es: "Eslovena" },
    sb: { en: "Solomon Islander", es: "Salomonense" },
    so: { en: "Somali", es: "Somalí" },
    za: { en: "South African", es: "Sudafricana" },
    gs: { en: "South Georgia and the South Sandwich Islands", es: "De Georgia del Sur y las Islas Sandwich del Sur" },
    ss: { en: "South Sudanese", es: "Sursudanesa" },
    es: { en: "Spanish", es: "Española" },
    lk: { en: "Sri Lankan", es: "Ceilanesa" },
    sd: { en: "Sudanese", es: "Sudanesa" },
    sr: { en: "Surinamese", es: "Surinamesa" },
    sj: { en: "Svalbard", es: "De Svalbard" },
    se: { en: "Swedish", es: "Sueca" },
    ch: { en: "Swiss", es: "Suiza" },
    sy: { en: "Syrian", es: "Siria" },
    tw: { en: "Taiwanese", es: "Taiwanesa" },
    tj: { en: "Tajik", es: "Tayika" },
    tz: { en: "Tanzanian", es: "Tanzana" },
    th: { en: "Thai", es: "Tailandesa" },
    tl: { en: "Timorese", es: "Timorense" },
    tg: { en: "Togolese", es: "Togolesa" },
    tk: { en: "Tokelauan", es: "Tokelauana" },
    to: { en: "Tongan", es: "Tongana" },
    tt: { en: "Trinidadian or Tobagonian", es: "Trinitense o Tobagüense" },
    tn: { en: "Tunisian", es: "Tunecina" },
    tr: { en: "Turkish", es: "Turca" },
    tm: { en: "Turkmen", es: "Turcomana" },
    tc: { en: "Turks and Caicos Islander", es: "Turquense y Caicoseña" },
    tv: { en: "Tuvaluan", es: "Tuvaluana" },
    ug: { en: "Ugandan", es: "Ugandesa" },
    ua: { en: "Ukrainian", es: "Ucraniana" },
    ae: { en: "Emirati", es: "Emiratí" },
    gb: { en: "British", es: "Británica" },
    um: { en: "United States Minor Outlying Islands", es: "De las Islas Ultramarinas Menores de Estados Unidos" },
    us: { en: "American", es: "Estadounidense" },
    uy: { en: "Uruguayan", es: "Uruguaya" },
    uz: { en: "Uzbekistani", es: "Uzbeka" },
    vu: { en: "Ni-Vanuatu", es: "Vanuatuense" },
    ve: { en: "Venezuelan", es: "Venezolana" },
    vn: { en: "Vietnamese", es: "Vietnamita" },
    vg: { en: "British Virgin Islander", es: "Virgen Británica" },
    vi: { en: "U.S. Virgin Islander", es: "Virgen Estadounidense" },
    wf: { en: "Wallis and Futuna", es: "Wallisiana y Futunés" },
    eh: { en: "Western Saharan", es: "Saharaui" },
    ye: { en: "Yemeni", es: "Yemení" },
    zm: { en: "Zambian", es: "Zambiana" },
    zw: { en: "Zimbabwean", es: "Zimbabuense" },
    ax: { en: "Åland Islander", es: "Ålandesa" },
};

export const getNationalitiesOptions = () => {
    const language = (i18n.language || "").match(/^[^-]+/)[0];

    const arrNationalitiesOptions = [];

    isoCountries.forEach((code) => {
        if (nationalities[code]) {
            const name = nationalities[code][language];
            arrNationalitiesOptions.push({ name, id: code });
        }
    });

    return arrNationalitiesOptions;
};
