import { useState, useEffect } from "react";

import { isWifi } from "../utils/config";

const useNetwork = () => {
    const wifiPortal = isWifi();

    const [isOnline, setNetwork] = useState(wifiPortal ? true : window.navigator.onLine);

    const updateNetwork = () => {
        setNetwork(window.navigator.onLine);
    };

    useEffect(() => {
        if (!wifiPortal) {
            window.addEventListener("offline", updateNetwork);
            window.addEventListener("online", updateNetwork);
            return () => {
                window.removeEventListener("offline", updateNetwork);
                window.removeEventListener("online", updateNetwork);
            };
        }
    });
    return isOnline;
};

export default useNetwork;
