import swal from "@sweetalert/with-react";

const useIncomingAlert = () => {
  const incomingAlert = (config, parseAction, alertOK) => {
    const buttons = config.buttons || null;
    const alert = {};
    if (config.title) {
      alert.title = config.title;
    }
    if (config.text) {
      alert.text = config.text;
    }
    if (buttons && buttons.length > 0) {
      alert.buttons = {};
      buttons.map((button, index) => {
        if (button.type === "close") {
          alert.buttons.cancel = true;
        } else {
          alert.buttons[index] = {
            text: button.text,
            value: button.onclick,
            visible: true,
            closeModal: true,
          };
        }
        return button;
      });
    } else {
      alert.button = alertOK;
    }
    swal(alert).then((onClick) => {
      if (onClick && typeof onClick === "object") {
        if (onClick.action && parseAction) {
          parseAction(onClick);
        }
      }
    });
  };

  return { incomingAlert };
};

export default useIncomingAlert;
