import React, { useEffect, useState } from "react";
export const Marker = ({ onClick, ...options }) => {
    const [marker, setMarker] = useState();

    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);
    useEffect(() => {
        if (marker) {
            marker.setOptions({
                ...options,
            });
            if (onClick) {
                marker.addListener("click", () => {
                    onClick();
                    marker.setMap(null);
                });
            }
        }
    }, [marker, options]);
    return null;
};
