import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHello, useRooms } from "../graphql/useUser";
import useInterval from "../hooks/useInterval";
import useLandingSession from "../hooks/useLandingSession";
import { routes } from "../routes";
import browserHistory from "../routes/history";
import { useSeleniumChecker } from "zdev";
import { getRoom } from "../modules/rooms/store";
import { getSelectedHotel } from "../actions/hotel";
import { ProjectProvider } from "../contexts/project";
import swal from "@sweetalert/with-react";

const Section = () => {
    useHello();

    const { check: checkPage } = useSeleniumChecker();

    const project = useSelector(getSelectedHotel);
    const room = useSelector(getRoom);
    const [section, setSection] = useState(null);

    const { salesStyles, theme } = useLandingSession();

    const { update: updateRooms } = useRooms();

    browserHistory.listen((location, action) => {
        setSection(location.hash.substr(1) + location.search);
    });

    useEffect(() => {
        // Dismiss any possible dialog
        try {
            swal.close();
        } catch (error) {}

        // Check page
        checkPage();
    }, [section]);

    // Update rooms every 5min
    useInterval(async () => {
        updateRooms();
    }, 300000);

    return (
        <Switch history={browserHistory}>
            {routes
                .filter((route) => typeof route.section !== "undefined" || typeof route.redirect !== "undefined")
                .map((route, index) => {
                    return (
                        <Route key={index} {...route}>
                            {typeof route.redirect !== "undefined" ? (
                                <Redirect push to={route.redirect} key={"redirect-" + index} />
                            ) : (
                                <ProjectProvider value={{ project, room, salesStyles, theme }}>
                                    <route.section key={"section-" + index} />
                                </ProjectProvider>
                            )}
                        </Route>
                    );
                })}
        </Switch>
    );
};

export default Section;
