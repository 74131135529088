import React, { useEffect } from "react";

import { configToCSS, parseTextConfig } from "../DesignUtils";
import useWidget from "../../../hooks/useWidget";

const UseWidgetHtml = ({ config, id, deviceType }) => {
    const { cfg } = useWidget({
        data: config.html,
        styles: config.styles,
        parseConfig,
    });

    useEffect(() => {
        const element = document.querySelector(`#${id}`);
        element.parentNode.style.backgroundColor = "transparent";
    }, [id]);

    return (
        <div className="overflow-hidden w-full h-full" id={id}>
            {cfg.html ? (
                <iframe
                    style={configToCSS(cfg, deviceType)}
                    title="preview"
                    className={`w-full h-full mx-auto`}
                    srcDoc={cfg.html}
                ></iframe>
            ) : null}
        </div>
    );
};

const parseConfig = ({ data, styles }) => {
    return {
        html: parseTextConfig(data.code),
        border: {
            color: styles.borderColor,
            size: styles.borderWidth,
            radius: styles.radius,
        },
    };
};

export default UseWidgetHtml;
