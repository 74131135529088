export const SET_PUSH_TOKEN = "SET_PUSH_TOKEN";
export const SET_UNREAD_MESSAGES = "SET_UNREAD_MESSAGES";

export function setPushToken(value) {
    return setDispatch(SET_PUSH_TOKEN, value);
}

export function setAllUnreadMessages(value) {
    return setDispatch(SET_UNREAD_MESSAGES, value);
}

function setDispatch(type, value) {
    return (dispatch) => {
        dispatch({
            type: type,
            payload: value,
        });
    };
}
