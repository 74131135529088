import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./apocalypseHandler";
import Apollo from "./Apollo";
import Auth from "./Auth";
import App from "./App";
import { ServiceWorkerProvider } from "./hooks/useServiceWorker";
import { Provider } from "react-redux";
import store from "./store";
import "./i18n";

const path = window.location.pathname;
if (
    path.startsWith("/ext/") ||
    path.startsWith("/wifi-srv/") ||
    path.startsWith("/zones/") ||
    path.startsWith("/das/")
) {
    document.getElementById("root").innerHTML = "404 Not found";
} else {
    ReactDOM.render(
        <React.StrictMode>
            <ServiceWorkerProvider>
                <Provider store={store}>
                    <Apollo>
                        <Auth>
                            <App />
                        </Auth>
                    </Apollo>
                </Provider>
            </ServiceWorkerProvider>
        </React.StrictMode>,
        document.getElementById("root")
    );
}
