import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getMenu } from "../../../integrations/useCuiner";
import { Redirect, useParams, useLocation } from "react-router";
import { normalizeToSearch } from "../../../utils/others";
import swal from "@sweetalert/with-react";
import ErrorMessage from "../../../components/ErrorMessage";
import Loading from "../../../components/Loading";
import Tabs from "../../../components/Tabs";
import Header from "../../../components/Header";
import _ from "lodash";
import { setCuinerData, getCuinerData, getCuinerRestaurant, getCuinerMenu } from "../../../actions/cuiner";

const Categories = () => {
    const { t, i18n } = useTranslation();
    const { selectedCategoy } = useParams();
    const location = useLocation();
    const i18nLang = (i18n.language || "").match(/^[^-]+/)[0];
    const dispatch = useDispatch();
    const cuinerData = useSelector(getCuinerData);
    const selectedRestaurant = useSelector(getCuinerRestaurant);
    const selectedMenu = useSelector(getCuinerMenu);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [categories, setCategories] = useState(null);
    const [categoriesFiltered, setCategoriesFiltered] = useState(null);
    const [valSearch, setValSearch] = useState("");
    const [searching, setSearching] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [menuInfo, setMenuInfo] = useState(null);
    const [redirectTo, setRedirectTo] = useState("");
    const [selectedPriceList, setSelectedPriceList] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        refetch();
        localStorage.setItem("prevLocation", location.pathname);
    }, []);

    useEffect(() => {
        if (menuInfo) {
            if (!selectedLanguage && menuInfo.languages && menuInfo.languages.length > 0) {
                if (i18nLang && menuInfo.languages.includes(i18nLang)) {
                    setSelectedLanguage(i18nLang);
                } else {
                    setSelectedLanguage(menuInfo.defaultLanguage ? menuInfo.defaultLanguage : menuInfo.languages[0]);
                }
            }
            if (!selectedPriceList && menuInfo.pricesList && menuInfo.pricesList.length > 0) {
                setSelectedPriceList(menuInfo.pricesList[0]);
            }
        }
    }, [menuInfo]);

    useEffect(() => {
        setCategoriesFiltered(categories);
    }, [categories]);

    const refetch = async () => {
        setLoading(true);
        setError(null);
        setCategories(null);
        setMenuInfo(null);
        if (cuinerData && cuinerData.api && selectedMenu && selectedMenu.id) {
            var priceListIds = [];
            if (selectedMenu.priceLists && selectedMenu.priceLists.length > 0) {
                priceListIds = _.map(selectedMenu.priceLists, "id");
            }
            const menuResponse = await getMenu(cuinerData.api, {
                restaurantId: selectedRestaurant.id,
                menuId: selectedMenu.id,
                priceListIds: priceListIds,
            });
            if (menuResponse && menuResponse.data) {
                setMenuInfo(
                    arrangeMenuInfo(
                        menuResponse.data && menuResponse.data.menu_info ? menuResponse.data.menu_info : null,
                        menuResponse.data && menuResponse.data.price_lists
                            ? convertObjectToList(menuResponse.data.price_lists)
                            : null
                    )
                );
                setCategories(
                    arrangeCategories(
                        menuResponse.data && menuResponse.data.categories
                            ? convertObjectToList(menuResponse.data.categories)
                            : null
                    )
                );
                var currentCategoryData = null;
                if (selectedCategoy && selectedCategoy > 0 && menuResponse.data && menuResponse.data.categories) {
                    currentCategoryData = convertObjectToList(menuResponse.data.categories);
                    currentCategoryData = currentCategoryData.filter((c) => c.id == selectedCategoy);
                    if (currentCategoryData && currentCategoryData.length > 0) {
                        currentCategoryData = await arrangeCategory(currentCategoryData[0]);
                    } else {
                        currentCategoryData = null;
                    }
                }
                setCurrentCategory(currentCategoryData);
            } else {
                setError(menuResponse.error);
            }
            setTimeout(() => {
                setLoading(false);
            }, 500);
        } else {
            setRedirectTo("/site");
        }
    };
    const convertObjectToList = (object) => {
        let items = [];
        let item = null;
        if (object != null) {
            Object.keys(object).forEach((key, index) => {
                item = object[key];
                items.push(item);
            });
        }
        return items;
    };
    const arrangeMenuInfo = (menuObject, pricesList) => {
        let response = {
            name: null,
            allergens: false,
            validAt: null,
            languages: null,
            pricesList: null,
        };
        if (menuObject) {
            response.name = menuObject.name ? menuObject.name : null;
            response.allergens = menuObject.allergens_info && menuObject.allergens_info === "true" ? true : false;
            response.validAt = menuObject.valid_at ? menuObject.valid_at : null;
            if (menuObject.languages && menuObject.languages.length > 0) {
                response.languages = menuObject.languages;
            }
        }
        if (pricesList && pricesList.length > 0) {
            response.pricesList = pricesList;
        }
        return response;
    };
    const arrangeCategories = (categoriesInfo) => {
        const response = [];
        let categoriesData = categoriesInfo;
        if (categoriesData && categoriesData.length > 0) {
            if (selectedCategoy && selectedCategoy > 0) {
                categoriesData = categoriesData.filter((c) => c.parent_id == selectedCategoy);
                categoriesData = categoriesData.map((c) => ({ ...c, parent_id: null }));
            }
            let category = null;
            let parentCategory = null;
            let index = null;
            categoriesData.map(async (categoryData) => {
                parentCategory = null;
                index = null;
                category = await arrangeCategory(categoryData);
                if (category.parent && category.parent > 0) {
                    parentCategory = response.filter((u) => u.id === category.parent);
                    if (!parentCategory || parentCategory.length <= 0) {
                        parentCategory = categoriesData.filter((u) => u.id === category.parent);
                        if (parentCategory && parentCategory.length > 0) {
                            parentCategory = await arrangeCategory(parentCategory[0]);
                            parentCategory.nesteds.push(category);
                            response.push(parentCategory);
                        }
                    } else {
                        parentCategory = parentCategory[0];
                        parentCategory.nesteds.push(category);
                        index = _.findIndex(response, { id: category.parent });
                        response.splice(index, 1, parentCategory);
                    }
                } else {
                    response.push(category);
                }
            });
        }
        return response;
    };

    const arrangeCategory = async (categoryData) => {
        let category = {
            id: categoryData.id,
            titles: categoryData.title ? categoryData.title : null,
            order: categoryData.display_order ? categoryData.display_order : null,
            parent: categoryData.parent_id ? categoryData.parent_id : null,
            color: categoryData.color ? categoryData.color : null,
            imageUrl: categoryData.image_url ? categoryData.image_url : null,
            descriptions: categoryData.description ? categoryData.description : null,
            nesteds: [],
        };
        return category;
    };

    const handleChangeSearch = (value) => {
        setValSearch(value);
        let categoriesClone = [];
        if (value && value !== "") {
            if (categories && selectedLanguage) {
                categories.map((category) => {
                    let categoryClone = { ...category };
                    let addElement = normalizeToSearch(category.titles[selectedLanguage]).includes(
                        normalizeToSearch(value)
                    );
                    if (addElement) {
                        categoriesClone.push(categoryClone);
                    }
                });
            }
        } else {
            categoriesClone = categories;
        }
        setCategoriesFiltered(categoriesClone);
    };

    const goToNested = (category) => {
        if (category.nesteds && category.nesteds.length > 0) {
            setValSearch("");
            setRedirectTo("/pos/categories/" + category.id);
        } else {
            let cuinerDataClone = { ...cuinerData };
            let selectedRestaurantClone = { ...selectedRestaurant };
            let selectedMenuClone = { ...selectedMenu };
            let index = _.findIndex(selectedRestaurantClone.menus, { id: selectedMenu.id });
            let categoryClone = { ...category };
            categoryClone.selectedLanguage = selectedLanguage;
            categoryClone.selectedPriceList = selectedPriceList;
            selectedMenuClone.currentCategory = categoryClone;
            selectedRestaurantClone.menus.splice(index, 1, selectedMenuClone);
            index = _.findIndex(cuinerDataClone.restaurants, { id: selectedRestaurant.id });
            cuinerDataClone.restaurants.splice(index, 1, selectedRestaurantClone);
            dispatch(setCuinerData(cuinerDataClone));
            setRedirectTo("/pos/menu");
        }
    };

    const showDescription = (title, description) => {
        swal({
            title: title,
            text: description,
            buttons: {
                cancel: t("close"),
            },
        });
    };

    const handlerCategoriesTitleTopBar = (cuinerData) => {
        if (cuinerData) {
            if (
                cuinerData.restaurantNumber &&
                cuinerData.menusNumber &&
                cuinerData.restaurantNumber === 0 &&
                cuinerData.menusNumber === 0
            ) {
                return "shop";
            } else if (cuinerData.menusNumber && cuinerData.menusNumber === 1 && cuinerData.restaurants) {
                return selectedRestaurant && selectedRestaurant.name ? selectedRestaurant.name : "";
            } else if (cuinerData.menusNumber && cuinerData.menusNumber > 1 && menuInfo && !selectedCategoy) {
                return menuInfo.name;
            } else if (selectedCategoy && currentCategory) {
                if (currentCategory.titles[selectedLanguage]) {
                    return currentCategory.titles[selectedLanguage];
                } else if (currentCategory.titles["en"]) {
                    return currentCategory.titles["en"];
                } else {
                    return currentCategory.titles[Object.keys(currentCategory.titles)[0]];
                }
            } else {
                return "";
            }
        }
    };

    const handlerCategoryTitle = (categoryInfo, selectedLanguge) => {
        if (categoryInfo.titles[selectedLanguge]) {
            return categoryInfo.titles[selectedLanguge];
        } else if (categoryInfo.titles["en"]) {
            return categoryInfo.titles["en"];
        } else {
            return categoryInfo.titles[Object.keys(categoryInfo.titles)[0]];
        }
    };

    const _renderCategories = () => {
        const response = [];
        if (categoriesFiltered && categoriesFiltered.length > 0) {
            categoriesFiltered.map((category, index) => {
                response.push(
                    <li
                        key={index}
                        onClick={() => {
                            goToNested(category);
                        }}
                        className="z-20"
                        style={{
                            backgroundColor: category.color,
                            backgroundImage:
                                "url(" + (category.imageUrl && category.imageUrl !== "" ? category.imageUrl : "") + ")",
                        }}
                    >
                        <div className="overlay"></div>
                        &nbsp;
                        <div className="w-full h-full text-center">
                            <h2 className="absolute w-full h-full text-lg font-medium pt-6">
                                {handlerCategoryTitle(category, selectedLanguage)}
                            </h2>
                            {selectedLanguage &&
                            category.descriptions &&
                            category.descriptions[selectedLanguage] &&
                            category.titles &&
                            category.titles[selectedLanguage] ? (
                                <div className="absolute right-0 bottom-0 pr-3 pb-1">
                                    <i
                                        className="icon icon-info text-xl cursor-pointer "
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            showDescription(
                                                category.titles[selectedLanguage],
                                                category.descriptions[selectedLanguage]
                                            );
                                        }}
                                    ></i>
                                </div>
                            ) : null}
                        </div>
                    </li>
                );
            });
        } else {
            response.push(<p className="text-center">{t("no available products now")}</p>);
        }
        return <ul className="hotel-list">{response}</ul>;
    };

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }
    return (
        <>
            <section className="basic has-tabs has-top">
                {searching ? (
                    <Header back={false}>
                        <div className="flex justify-between w-full">
                            <div className="h-full pt-4 pr-3">
                                <i className="icon icon-search text-xl primary "></i>
                            </div>
                            <input
                                type="text"
                                className="search-top primary"
                                placeholder={t("search")}
                                onChange={(e) => {
                                    handleChangeSearch(e.target.value);
                                }}
                                value={valSearch}
                            />
                            <div
                                className="h-full pt-4"
                                onClick={() => {
                                    handleChangeSearch("");
                                    setSearching(false);
                                }}
                            >
                                <i className="icon icon-close text-xl cursor-pointer primary "></i>
                            </div>
                        </div>
                    </Header>
                ) : (
                    <Header
                        back={"/pos/menus"}
                        title={
                            handlerCategoriesTitleTopBar(cuinerData)
                                ? handlerCategoriesTitleTopBar(cuinerData)
                                : selectedRestaurant && selectedRestaurant.name
                                ? selectedRestaurant.name
                                : ""
                        }
                    >
                        <div
                            className="h-full pt-5"
                            onClick={() => {
                                handleChangeSearch("");
                                setSearching(true);
                            }}
                        >
                            <i className="icon icon-search text-xl cursor-pointer primary "></i>
                        </div>
                    </Header>
                )}

                <section style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {loading ? (
                        <Loading />
                    ) : error ? (
                        <ErrorMessage type="server" error={error} />
                    ) : (
                        <>{_renderCategories()}</>
                    )}
                </section>
                <Tabs hideKey={true} />
            </section>
        </>
    );
};

export default Categories;
