import gql from "graphql-tag";

export const GET_NOTIFICATIONS = {
    id: "GET_NOTIFICATIONS",
    query: gql`
        query getNotifications($page: Int!, $size: Int!) {
            getNotifications(page: $page, size: $size) {
                results {
                    ref
                    title
                    description
                    dateTime
                    projectRef
                    isCompleted
                    isRead
                    kind
                    operation
                }
            }
        }
    `,
    defaultVars: { page: 1, size: 1000 },
};
