import React, { useContext, useState, useEffect } from "react";
import Header from "../components/Header";
import Tabs from "../components/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { setSelectedRoom, getSelectedHotel, getHotel } from "../actions/hotel";
import { useHotels } from "../graphql/useHotels";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import { deleteRoom, getProjectRooms } from "../modules/rooms/store";
import useForceUpdate from "../hooks/useForceUpdate";
import { toast } from "react-toastify";
import { postMessageToParent } from "../components/Design/DesignUtils";
import { getIsEmbedded, getDasUrl, getLogoRef } from "../actions/config";
import pkg from "../../package.json";
import { getCuinerData, setCuinerData } from "../actions/cuiner";
import { getUserData } from "../actions/user";
import { NotificationContext, NOTIF_KIND_ZONES } from "contexts/notifications";
import { isLight } from "actions/ui";

const HotelBasic = ({ hotelData, hasOnPremisesInterface }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const forceUpdate = useForceUpdate();
    const user = useSelector(getUserData);
    const selectedHotel = useSelector(getSelectedHotel);
    const selectedHotelRef = useSelector(getHotel);
    const cuinerData = useSelector(getCuinerData);
    const selectedHotelName = selectedHotel ? selectedHotel.name : null;
    const isEmbedded = useSelector(getIsEmbedded);
    const userRooms = useSelector(getProjectRooms);
    const rooms = [];
    const [redirectTo, setRedirectTo] = useState("");
    const [passVisible, setPassVisible] = useState({});
    const [circleBookings, setCircleBookings] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { notifications } = useContext(NotificationContext);
    const projectNotifs = notifications?.filter((n) => n.projectRef === selectedHotelRef);

    const { loading: loadingHH, error: errorHH, hotels } = useHotels({ autoload: true });
    const {
        loading: loadingHotels,
        error: errorHotels,
        hotel: hotel,
    } = useHotels({ ref: selectedHotelRef, autoload: true });
    const showChat = pkg.features.chat && hotel && hotel.chatURL;
    const canUseChat = showChat && ((hotel && hotel.chatPublic) || (user && user.emailVerified));
    const canUseCuiner =
        hotelData &&
        hotelData.cuiner &&
        hotelData.cuiner.appId &&
        hotelData.cuiner.token &&
        hotelData.cuiner.url &&
        hotelData.cuiner.user
            ? true
            : false;

    var hasUnreadBooking = false;
    let uniqueHotel = !loadingHH && !errorHH && hotels && hotels.length === 1;

    if (projectNotifs) {
        projectNotifs.map((n, i) => {
            if (!n.isRead && n.kind === NOTIF_KIND_ZONES) {
                hasUnreadBooking = true;
            }
            return n;
        });
    }

    if (userRooms) {
        Object.entries(userRooms).forEach(([key, value]) => {
            rooms.push({ number: key, code: value.code, added: value.added });
        });
    }

    useEffect(() => {
        let errorResponse = errorHH ? errorHH : errorHotels ? errorHotels : null;
        setError(errorResponse);
    }, [errorHH, errorHotels]);

    useEffect(() => {
        let loadingResponse = true;
        if (!loadingHH && !loadingHotels) {
            loadingResponse = false;
        }
        setLoading(loadingResponse);
    }, [loadingHH, loadingHotels]);

    useEffect(() => {
        setCircleBookings(hasUnreadBooking);
    }, [hasUnreadBooking]);

    const isPassVisible = (roomNumber) => {
        if (roomNumber in passVisible) {
            return passVisible[roomNumber];
        }
        return false;
    };

    const togglePassVisibility = (roomNumber) => {
        const set = {};
        set[roomNumber] = !isPassVisible(roomNumber);
        setPassVisible({
            ...passVisible,
            ...set,
        });
    };

    const goToRoom = (roomNumber) => {
        dispatch(setSelectedRoom(roomNumber));
        setRedirectTo("/site/room");
    };

    const goToCuiner = (roomNumber) => {
        var cuinerDataClone = { ...cuinerData };
        if (!cuinerDataClone) cuinerDataClone = {};
        cuinerDataClone.api = {
            url: hotelData.cuiner.url,
            token: hotelData.cuiner.token,
            user: hotelData.cuiner.user,
            applicationId: hotelData.cuiner.appId,
        };
        cuinerDataClone.selectedRoom = roomNumber;
        dispatch(setCuinerData(cuinerDataClone));
        setRedirectTo("/pos");
    };

    const showRoomOptions = (room) => {
        const menu = [
            {
                title: isPassVisible(room.number) ? t("hide password") : t("show password"),
                onClick: () => {
                    togglePassVisibility(room.number);
                    swal.close();
                },
            },
            {
                title: t("remove"),
                onClick: () => {
                    swal({
                        title: t("remove room"),
                        text: t("remove room info"),
                        buttons: {
                            cancel: t("alert cancel"),
                            confirm: t("remove"),
                        },
                    }).then((confirm) => {
                        if (confirm) {
                            dispatch(
                                deleteRoom({
                                    projectRef: selectedHotelRef,
                                    number: room.number,
                                    code: room.code,
                                    embedded: isEmbedded,
                                })
                            );
                            forceUpdate();
                            toast(t("room deleted"));
                        }
                        swal.close();
                    });
                },
            },
        ];
        swal({
            className: "menu-list",
            button: false,
            content: (
                <div>
                    <ul className="px-5 mb-5">
                        {menu.map((item, index) => (
                            <li key={index} onClick={item.onClick}>
                                <span>{item.title}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            ),
        });
    };

    const goBack = async () => {
        if (isEmbedded) {
            postMessageToParent({ action: "exit" });
            return;
        } else {
            if (!uniqueHotel) {
                setRedirectTo("/site/list");
            } else {
                return;
            }
        }
    };

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    return (
        <section className="basic has-tabs has-top">
            <Header
                title={!hotel || uniqueHotel ? undefined : hotel.name}
                back={uniqueHotel ? false : undefined}
                backAction={() => goBack()}
            >
                {uniqueHotel ? (
                    <h1 style={{ paddingTop: 0 }}>
                        <img
                            src={`${getDasUrl()}/${getLogoRef(isLight(window.appConfig.theme.login))}`}
                            className="d-inline-block h-10 mx-auto my-2"
                            onError={(e) => {
                                e.target.style.display = "none";
                            }}
                        />
                    </h1>
                ) : (
                    ""
                )}
            </Header>
            <section
                style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                }}
            >
                {loading ? (
                    <Loading />
                ) : error ? (
                    <ErrorMessage type="server" error={error} />
                ) : (
                    <>
                        {hasOnPremisesInterface ? (
                            <div className="mb-4">
                                <div className="px-8">
                                    <p className="border-b text-lg font-medium flex content-between w-full">
                                        <span className="flex-1">{t("information")}</span>
                                        {rooms.length > 0 ? (
                                            <Link
                                                to="site/addroom"
                                                className="flex-none text-accent text-sm font-medium"
                                            >
                                                {t("add room")}
                                            </Link>
                                        ) : null}
                                    </p>
                                </div>

                                <ul
                                    className={
                                        "overflow-x-auto whitespace-nowrap py-2 px-8 scrolling-touch w-full " +
                                        (showChat || rooms.length > 0 ? "pb-0" : "pb-6")
                                    }
                                >
                                    {rooms.length === 0 ? (
                                        <li className="relative inline-block w-full whitespace-normal text-center">
                                            <span className="block p-3 mx-1">{t("have a room?")}</span>

                                            <Link
                                                to="site/addroom"
                                                className="text-accent text-lg font-medium mt-2 mb-4 px-10"
                                            >
                                                <i className="icon icon-add"></i> {t("add room")}
                                            </Link>

                                            <div className="py-5 px-0">
                                                <button
                                                    onClick={() => {
                                                        goToRoom(0);
                                                    }}
                                                    className="btn btn-accent w-full text-lg"
                                                >
                                                    {t("information & services")}
                                                </button>
                                            </div>
                                        </li>
                                    ) : (
                                        rooms
                                            .sort((a, b) => {
                                                return a.added < b.added ? 1 : -1;
                                            })
                                            .map((room, index) => {
                                                const last = index === rooms.length - 1;
                                                let classname =
                                                    "relative bg-neutral inline-block w-full whitespace-normal text-center shadow rounded-lg mb-4";
                                                if (!last) {
                                                    classname += " mr-5";
                                                }
                                                return (
                                                    <li key={index} className={classname}>
                                                        <button
                                                            onClick={() => {
                                                                showRoomOptions(room);
                                                            }}
                                                            className="absolute top-0 right-0 p-2 text-xl"
                                                        >
                                                            <i className="icon icon-ellipsis-v"></i>
                                                        </button>

                                                        <span className="block p-3 mx-1 text-lg">{t("my room")}</span>

                                                        <span className="block text-3xl font-medium my-1 px-10">
                                                            {room.number}

                                                            <span
                                                                className={
                                                                    "block font-normal text-sm" +
                                                                    (!isPassVisible(room.number) ? " invisible" : "")
                                                                }
                                                            >
                                                                {t("password is", { password: room.code })}
                                                            </span>
                                                        </span>
                                                        <div className="p-5">
                                                            <button
                                                                onClick={() => {
                                                                    goToRoom(room.number);
                                                                }}
                                                                className="btn btn-accent w-full text-lg test-info"
                                                            >
                                                                {t("information & services")}
                                                            </button>
                                                        </div>

                                                        {canUseCuiner ? (
                                                            <div className="p-5">
                                                                <button
                                                                    onClick={() => {
                                                                        goToCuiner(room.number);
                                                                    }}
                                                                    className="btn btn-accent w-full text-lg test-info"
                                                                >
                                                                    {t("POS Restaurants")}
                                                                </button>
                                                            </div>
                                                        ) : null}
                                                    </li>
                                                );
                                            })
                                    )}
                                </ul>
                                {showChat ? (
                                    <>
                                        <div className="px-8 pb-2 w-full">
                                            <a
                                                onClick={() => {
                                                    if (!canUseChat) {
                                                        if (user && !user.emailVerified) {
                                                            toast(t("must verify to chat"));
                                                        } else {
                                                            toast(t("must login to chat"));
                                                        }
                                                    }
                                                }}
                                                href={canUseChat && hotel ? hotel.chatURL : undefined}
                                                target={canUseChat ? "_blank" : undefined}
                                                rel="noopener noreferrer"
                                                disabled={!canUseChat}
                                                className={
                                                    "btn w-full text-lg " +
                                                    (canUseChat ? "btn-accent-contrast" : "btn-neutral-20")
                                                }
                                            >
                                                {t("chat reception")}
                                                <i className="icon icon-chat ml-2 text-xl"></i>
                                            </a>
                                            {canUseChat ? (
                                                ""
                                            ) : (
                                                <p className={"text-xs p-1 pb-0 text-neutral-contrast-60"}>
                                                    * {t("to use chat")}
                                                </p>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : null}
                        <div className="mb-10 px-8">
                            <p className="border-b font-medium text-lg">{t("areas & activities")}</p>
                            <div className="flex items-stretch text-center">
                                <Link to="site/booking" className="flex-1 p-1 pr-4">
                                    <div className="flex items-center text-neutral-contrast h-full bg-neutral shadow rounded-lg p-4">
                                        <div className="notif-card w-full font-medium">
                                            {circleBookings ? <div className="circle"></div> : ""}
                                            <i className="block icon icon-booking text-3xl mb-2"></i>
                                            {t("my hotel booking")}
                                        </div>
                                    </div>
                                </Link>
                                <Link to="site/zones" className="flex-1 p-1 pr-2">
                                    <div className="flex items-center text-neutral-contrast h-full bg-neutral shadow rounded-lg p-4">
                                        <div className="w-full font-medium">
                                            <i className="block icon icon-grid text-3xl mb-2"></i>
                                            {t("areas & activities")}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </>
                )}
            </section>
            <Tabs />
        </section>
    );
};

export default HotelBasic;
