import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import dayjs from "./utils/dayjs";
import pkg from "../package.json";

const localesDir = "./locales/";
const texts = {};
for (let i = 0; i < pkg.languages.length; i++) {
    const iso = pkg.languages[i];
    texts[iso] = {};
    texts[iso]["interface"] = require(`${localesDir}${iso}/interface.json`);
    texts[iso]["country"] = require(`${localesDir}${iso}/country.json`);
    if (iso === "en") {
        // "en" is default language
        // Unique language list (native name)
        texts[iso]["language"] = require(`${localesDir}language.json`);
    }
}

export const isToday = (someDate) => {
    if (!(someDate instanceof Date)) {
        someDate = new Date(someDate);
    }
    const today = new Date();
    return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
    );
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: pkg.languages[0],
        supportedLngs: pkg.languages,
        debug: false,
        load: "languageOnly",
        fallbackNS: "interface",
        defaultNS: "interface",
        lowerCaseLng: true,
        cleanCode: true,
        resources: texts,
        detection: {
            lookupQuerystring: "lang",
            lookupCookie: "zapp_i18n",
            lookupLocalStorage: "zapp_i18n_lang",
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: function (value, format, lng) {
                switch (format) {
                    case "uppercase":
                        if (typeof value !== "string") {
                            value = "";
                        }
                        return value.toUpperCase();
                    case "lowercase":
                        if (typeof value !== "string") {
                            value = "";
                        }
                        return value.toLowerCase();
                    case "capitalize":
                        if (typeof value !== "string") {
                            value = "";
                        }
                        return value[0] ? value[0].toUpperCase() + value.substring(1) : "";
                    case "date":
                        if (typeof value === "string") {
                            value = new Date(value);
                        }
                        if (value instanceof Date) {
                            return dayjs(value).locale(lng).format("L");
                        }
                        return value;
                    case "country":
                        if (typeof value !== "string") {
                            return "";
                        }
                        // only works in chrome desktop (2020)
                        // use t({country:iso})
                        return new Intl.DisplayNames(lng, { type: "region" }).of(value.toUpperCase());
                    case "language":
                        if (typeof value !== "string") {
                            return "";
                        }
                        // only works in chrome desktop (2020)
                        // use t({language:iso})
                        const lang = new Intl.DisplayNames(lng, { type: "language" }).of(value.toUpperCase());
                        return lang[0] ? lang[0].toUpperCase() + lang.substring(1) : "";
                    default:
                        return value;
                }
            },
        },
        react: {
            useSuspense: true,
        },
    });

export default i18n;
