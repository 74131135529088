import React from "react";

export const ShopContext = React.createContext({
    shopToken: null,
    shop: null,
    pickupLocations: [],
    categories: null,
    categoryID: null,
    subCategoryID: null,
    productID: null,
    latestProduct: null,
    latestScrollY: null,
    cartItemID: null,
    cart: {
        shippingMethod: null,
        shippingMethodID: null,
        shippingLocation: null,
        shippingLocationID: null,
        shippingPrice: null,
        shippingPriceWithTax: null,
        deliveryType: null,
        deliveryTime: null,
        products: null,
        comments: null,
    },
    cartPriceSum: null,
    theme: null,
    currencyCode: null,
    pricesIncludeTax: null,
    allowScheduledDelivery: null,
    delayedDeliveryThreshold: null,
    deliverySchedule: null,
    showingCart: null,
    comments: null,
    maxProductsPerOrder: null,
    autoAcceptOrders: null,
    shipping: null,
    shippingMethods: null,
    searchHistory: null,
    customLocation: { lat: null, lng: null },
    geolocationTranslations: [],
    productUnavailable: null,
    setProductUnavailable: () => {},
    setCustomLocation: () => {},
    hideMap: () => {},
    setPickupOption: () => {},
    setShippingPrice: () => {},
    setComments: () => {},
    setShippingMethod: () => {},
    setDeliveryType: () => {},
    setDeliveryTime: () => {},
    setCategoryID: () => {},
    setSubCategoryID: () => {},
    setProductID: () => {},
    setSearchHistory: () => {},
    setCartItemID: () => {},
    addToCart: () => {},
    updateCartQuantity: () => {},
    hideCart: () => {},
    showCart: () => {},
    cleanCart: () => {},
    orderNow: () => {},
});

export const ShopProvider = ShopContext.Provider;
