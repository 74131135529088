import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSelectedHotel } from "actions/hotel";
import { useSendTrackScreenGeneralMobile } from "graphql/useUser";
import { useLocation } from "react-router-dom"; // Importación ajustada a la versión estándar
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSendTrackEventGeneralMobile } from "graphql/useUser";

const SendTrackScreenTrigger = () => {
    const [path, setPath] = useState(null);
    const [signInMethod, setSignInMethod] = useState(null);
    const [isSignUp, setIsSignUp] = useState(null);

    const project = useSelector(getSelectedHotel);
    const { selectedHotel, selectedLandings, selectedRooms } = useSelector((state) => state.session);
    const { sendTrackEventGeneralMobile } = useSendTrackEventGeneralMobile();

    //VARIABLES FOR LANDING
    const landingSelectedHistory = selectedLandings?.[selectedHotel]?.history || [];
    const screenSelected = landingSelectedHistory.slice(-1)[0] || null;
    const selectedRoom = selectedRooms?.[selectedHotel];

    const { sendTrackScreen } = useSendTrackScreenGeneralMobile({
        path: path,
        screenSelected: screenSelected,
        selectedRoom: selectedRoom,
    });

    const location = useLocation();

    useEffect(() => {
        setPath(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        if (location?.state?.current?.siginInMethod) {
            setSignInMethod(location?.state?.current?.siginInMethod);
        }

        if (location?.state === "ACCESS_BY_GUEST") {
            setSignInMethod(location?.state);
        }

        if (location?.state === "SIGN_UP") {
            setIsSignUp(true);
        }
    }, [location?.state]);

    useEffect(() => {
        if (signInMethod && signInMethod !== "ACCESS_BY_GUEST" && selectedHotel) {
            sendTrackEventGeneralMobile({ signInMethod: signInMethod }, "SIGN_IN");
            setSignInMethod(null);
        }

        if (signInMethod === "ACCESS_BY_GUEST" && selectedHotel) {
            sendTrackEventGeneralMobile(null, "ACCESS_BY_GUEST");

            setSignInMethod(null);
        }

        if (isSignUp && selectedHotel) {
            sendTrackEventGeneralMobile(null, "SIGN_UP");
            setIsSignUp(null);
        }
    }, [signInMethod, selectedHotel]);

    useEffect(() => {
        if (path && project) {
            sendTrackScreen();
        }
    }, [path, screenSelected]);

    return null;
};

export default SendTrackScreenTrigger;
