const httpResponse = (ok, data) => {
    const response = {
        ok: ok,
        status: ok ? 200 : 500,
        statusText: ok ? "Accepted" : "Server error",
        json: () => {
            return new Promise((resolve) => resolve(!ok ? null : data));
        },
    };
    console.log({ mockupResponse: { ...response, jsonReturn: ok ? data : null } });
    return response;
};

/*
Simulate options
=====================

To server error
----------------------
Email: panic@zafiro.tv

To login error
----------------------
Email: error@zafiro.tv
or Code: 666
or Room: 666

Previously registered user
------------------------
Email: loyalty@zafiro.tv
or family@zafiro.tv
or Google Login

Force verify
------------------------
Accepting commercial communications
+ loyalty@zafiro.tv
or guest@zafiro.tv
or family@zafiro.tv
or Google Login

Has Rooms
------------------------
Email: loyalty@zafiro.tv
or Email: guest@zafiro.tv
or Email: family@zafiro.tv
or Email: Google Login
or Room: 1234

Has Multiple rooms
------------------------
Login type is not PMS
and Email: family@zafiro.tv
or Email: Google Login

Pairing enabled
------------------------
Login type Email
+ Email
loyalty@zafiro.tv
or guest@zafiro.tv
or family@zafiro.tv
or Google Login
*/

const wifiLogin = (_url, body, resolve, _reject) => {
    const cfg = {
        error: body.Email === "panic@zafiro.tv",
        loginOK:
            body.LoginType == "open" ||
            (body.Email && body.Email != "error@zafiro.tv") ||
            (body.Code && body.Code != 666) ||
            (body.Room && body.Room != 666),
        loyaltyOK: body.RrssToken || body.Email === "loyalty@zafiro.tv" || body.Email === "family@zafiro.tv",
        verify: body.MarketingAccepted,
        withRooms:
            body.Email === "loyalty@zafiro.tv" ||
            body.Email === "guest@zafiro.tv" ||
            body.Email === "family@zafiro.tv" ||
            body.RrssToken ||
            body.Room == 1234,
        multipleRooms: body.Email === "family@zafiro.tv" || body.RrssToken,
        withPairing: body.LoginType === "email",
        redirectPWA: true,
    };
    if (body.RrssToken) {
        cfg.loginOK = true;
        cfg.verify = false;
    }
    if (cfg.error) {
        cfg.loginOK = false;
    }
    if ((!body.Email && !body.RrssToken) || !cfg.loginOK) {
        cfg.loyaltyOK = false;
    }
    if (!cfg.loginOK) {
        cfg.withRooms = false;
        cfg.verify = false;
        cfg.redirect = false;
    }
    if (body.LoginType === "pms" || (!body.Email && !body.RrssToken) || !cfg.withRooms) {
        cfg.multipleRooms = false;
    }
    if (!cfg.withRooms) {
        cfg.withPairing = false;
    }
    const http = httpResponse(!cfg.error, {
        success: cfg.loginOK,
        loyalty: cfg.loyaltyOK
            ? {
                  ref: "ref-loyalty-test",
                  email: body.RrssToken ? "juanedc@gmail.com" : body.Email,
              }
            : null,
        rrss: body.RrssToken
            ? {
                  googleAccessToken: "GGOOGLEACCESSTOKENTEST",
              }
            : null,
        email: cfg.loginOK
            ? {
                  verify: cfg.verify,
                  hash: "",
              }
            : null,
        room: {
            rooms: cfg.withRooms
                ? cfg.multipleRooms
                    ? [
                          {
                              name: "Room 101",
                              number: 101,
                              code: "xk276i",
                              cc: cfg.withPairing,
                              ccUrl: cfg.withPairing
                                  ? "https://0dev20.cast.zafiro.mobi/pairing/?tokenMobile=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYWlyaW5nQ29kZSI6IjNiOWxzNCJ9.S9KDlEQyKnM3HNPeMYmDjOKSz8vdJ9x4xeAdUuErrXU"
                                  : null,
                          },
                          {
                              name: "Room 103",
                              number: 103,
                              code: "9vtv4o",
                              cc: false,
                              ccUrl: null,
                          },
                          {
                              name: "Suite 102",
                              number: 102,
                              code: "868tw2",
                              cc: cfg.withPairing,
                              ccUrl: cfg.withPairing
                                  ? "https://0dev20.cast.zafiro.mobi/pairing/?tokenMobile=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYWlyaW5nQ29kZSI6IjNiOWxzNCJ9.S9KDlEQyKnM3HNPeMYmDjOKSz8vdJ9x4xeAdUuErrXU"
                                  : null,
                          },
                          {
                              name: "Suite 204",
                              number: 204,
                              code: "446t8q",
                              cc: cfg.withPairing,
                              ccUrl: cfg.withPairing
                                  ? "https://0dev20.cast.zafiro.mobi/pairing/?tokenMobile=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYWlyaW5nQ29kZSI6IjNiOWxzNCJ9.S9KDlEQyKnM3HNPeMYmDjOKSz8vdJ9x4xeAdUuErrXU"
                                  : null,
                          },
                      ]
                    : [
                          {
                              name: "Room 101",
                              number: 101,
                              code: "xk276i",
                              cc: cfg.withPairing,
                              ccUrl: "https://google.101",
                          },
                      ]
                : null,
        },
        redirect: cfg.loginOK
            ? {
                  pwa: cfg.redirectPWA,
                  url: cfg.redirectPWA ? "http://localhost:3000" : "https://www.wikipedia.org",
              }
            : null,
        login: cfg.loginOK
            ? {
                  url: "https://mk.wifi.zafiro.mobi/login",
                  urlData: {
                      username: cfg.verify ? "10min" : "24hs",
                      password: cfg.verify ? "pavalidar" : "interneeee",
                      dst: "",
                  },
              }
            : null,
    });
    resolve(http);
};

const wifiSignup = (url, body, resolve, reject) => {
    const cfg = {
        error: false,
        verify: true,
    };
    if (!body.Email) {
        cfg.error = true;
    }
    const http = httpResponse(!cfg.error, {
        email: body.RrssToken ? "juanedc@gmail.com" : body.Email,
        hash: "",
        auth: null,
        birthDate: null,
        chainId: 1,
        countryRef: null,
        emailVerified: false,
        gender: null,
        id: 1653,
        languageRef: null,
        lastAccess: "2023-02-02T12:57:49.479113+01:00",
        locked: false,
        marketingAccepted: true,
        name: "Lion",
        phone: null,
        photo: null,
        ref: "e1813699-0391-4da7-8867-a1fdb0881846",
        sessionValidDate: "2023-02-02T12:57:49.479113+01:00",
        signupApp: "portal-wifi.local.zafiro.link",
        signupDate: "2023-02-02T12:57:49.479113+01:00",
        signupWifi: null,
        surname: "León",
        termsAccepted: true,
    });
    resolve(http);
};

export const fetchTimeout = (url, params, timeout) => {
    return new Promise((resolve, reject) => {
        const method = params && params.method ? params.method.toLowerCase() : "GET";
        const body = params && params.body ? JSON.parse(params.body) : null || {};
        console.log({
            mockupRequest: {
                url,
                method,
                body,
            },
        });
        if (url.match(/\/pairing\/\?tokenMobile\=/)) {
            setTimeout(() => {
                resolve(
                    httpResponse(true, {
                        success: true,
                        error: null,
                        ccInfo: [{ name: "Chromecast HD", roomtv: 6449 }],
                    })
                );
            }, [1500]);
        } else if (url.match(/v0\/user/)) {
            setTimeout(() => {
                resolve(
                    httpResponse(true, {
                        success: true,
                        error: null,
                    })
                );
            }, [500]);
        } else if (url.match(/v0\/login/)) {
            setTimeout(() => {
                wifiLogin(url, body, resolve, reject);
            }, [1500]);
        } else if (url.match(/v0\/portal\/accesstypes/)) {
            setTimeout(() => {
                resolve(
                    httpResponse(true, {
                        Session: "HS_Testing_CDaMorte:cb4d9c1a-3695-4fa9-a09f-15a040c1055f",
                        ProjectRef: "project-0000-0000-0000-0119",
                        ChainRef: "chain-0000-0000-0000-0014",
                        AccessTypes: [
                            {
                                Name: "email",
                                RRSS: true,
                                AdditionalData: {
                                    Age: {
                                        Enabled: true,
                                        Mandatory: false,
                                    },
                                    Country: {
                                        Enabled: true,
                                        Mandatory: false,
                                    },
                                    Email: {
                                        Enabled: true,
                                        Mandatory: false,
                                    },
                                    Name: {
                                        Enabled: true,
                                        Mandatory: true,
                                    },
                                    Surname: {
                                        Enabled: true,
                                        Mandatory: true,
                                    },
                                },
                            },
                        ],
                    })
                );
            }, [1500]);
        } else if (url.match(/v0\/loyalty\/verify/)) {
            if (body && body.Code) {
                setTimeout(() => {
                    resolve(
                        httpResponse(true, {
                            response: {
                                ref: body.Ref,
                                code: body.Code,
                                valid: true,
                                updated: true,
                            },
                            login: {
                                url: "https://mk.wifi.zafiro.mobi/login",
                                urlData: {
                                    username: "24hs",
                                    password: "interneeee",
                                    dst: "",
                                },
                            },
                        })
                    );
                }, [2000]);
            } else {
                setTimeout(() => {
                    resolve(
                        httpResponse(true, {
                            hash: Array(40)
                                .fill("")
                                .map((v) => Math.random().toString(36).charAt(2))
                                .join(""),
                            ref: body.Ref,
                        })
                    );
                }, [500]);
            }
        } else if (url.match(/v0\/loyalty/)) {
            if (method === "put") {
                setTimeout(() => {
                    resolve(
                        httpResponse(true, {
                            chainId: 1,
                            id: 1646,
                            ref: body.Ref,
                            gender: null,
                            email: "patata@juanedc.com",
                            name: "Patata",
                            surname: "DC",
                            phone: null,
                            photo: null,
                            languageRef: null,
                            countryRef: null,
                            birthDate: null,
                            locked: false,
                            emailVerified: true,
                            termsAccepted: true,
                            marketingAccepted: body ? body.MarketingAccepted : null,
                            signupDate: "2023-02-02T10:13:23.721745+01:00",
                            signupApp: "portal-wifi.local.zafiro.link",
                            signupWifi: null,
                            sessionValidDate: "2023-02-02T10:13:23.721745+01:00",
                            lastAccess: "2023-02-02T10:13:23.721745+01:00",
                            auth: null,
                        })
                    );
                }, [800]);
            } else {
                setTimeout(() => {
                    wifiSignup(url, body, resolve, reject);
                }, [2000]);
            }
        } else {
            setTimeout(() => {
                resolve({
                    ok: false,
                    status: 404,
                    statusText: "Unknown endpoint " + url,
                    json: () => null,
                });
            }, [400]);
        }
    });
};
