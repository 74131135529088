import { cloneDeep } from "lodash";
import { getHotel } from "../../actions/hotel";

export const DATABASE_ROOMS = "DATABASE_ROOMS";

const CREATE = "CREATE";
const READ = "READ";
const UPDATE = "UPDATE";
const DELETE = "DELETE";
const SET_ROOMS = "SET_ROOMS";

export const initialState = {
    rooms: {},
    userRooms: {},
};

function currentState(state) {
    return state?.user;
}
const dispatch = (payload) => {
    return (dispatch) => {
        dispatch({
            type: DATABASE_ROOMS,
            payload: payload,
        });
    };
};

export function createRoom(params) {
    return dispatch({ type: CREATE, params });
}

export function readRoom(params) {
    return dispatch({ type: READ, params });
}

export function updateRoom(params) {
    return dispatch({ type: UPDATE, params });
}

export function deleteRoom(params) {
    return dispatch({ type: DELETE, params });
}

export function setRooms(params) {
    return dispatch({ type: SET_ROOMS, params });
}

export function getRoomsProjects(globalState) {
    return Object.keys(getAllRooms(globalState));
}

export function getAllRooms(globalState) {
    const state = currentState(globalState);

    const manual = getManualRooms(state);
    const auto = getAutomaticRooms(state);

    const full = {};
    for (let projectRef in manual) {
        if (auto.hasOwnProperty(projectRef)) {
            full[projectRef] = { ...manual[projectRef], ...auto[projectRef] };
        } else {
            full[projectRef] = manual[projectRef];
        }
    }
    for (let projectRef in auto) {
        if (!full.hasOwnProperty(projectRef)) {
            full[projectRef] = auto[projectRef];
        }
    }

    return full;
}

export function getProjectRooms(globalState) {
    const projectRef = getHotel(globalState);
    if (projectRef) {
        const allRooms = getAllRooms(globalState);
        if (allRooms) {
            return allRooms[projectRef];
        }
    }
    return null;
}

export function getRoom(globalState) {
    const projectRef = getHotel(globalState);
    if (projectRef) {
        const selectedRooms = globalState?.session?.selectedRooms;
        const selectedProjectRoom = selectedRooms ? parseInt(selectedRooms[projectRef]) : null;
        if (selectedProjectRoom) {
            const allRooms = getAllRooms(globalState);
            if (allRooms && allRooms[projectRef]) {
                return allRooms[projectRef][selectedProjectRoom];
            }
        }
    }
    return null;
}

export function getRoomNumber(globalState) {
    const room = getRoom(globalState);
    return room?.number;
}

const roomStruct = {
    number: null,
    code: null,
    pairingURL: null,
    token: null,
    guestID: null,
    pairingEnabled: null,
    wifiConnected: null,
    hasChromecast: null,
    added: null,
    salesToken: null
};

const Rooms = (state, payload) => {
    ensureState(state);
    const userRef = getCurrentUser(state);
    const type = payload?.type;
    if (type === CREATE) {
        const { projectRef, number, code, guestID, hasChromecast, wifiConnected, pairingEnabled, pairingURL, token, salesToken } =
            payload.params;
        ensureState(state, { projectRef });
        if (number) {
            state.rooms[projectRef][number] = {
                ...roomStruct,
                ...{
                    number: parseInt(number),
                    code,
                    guestID,
                    pairingURL,
                    token,
                    pairingEnabled,
                    wifiConnected,
                    hasChromecast,
                    salesToken,
                    added: new Date().getTime(),
                },
            };
        }
    } else if (type === SET_ROOMS) {
        if (userRef) {
            const data = payload.params;
            ensureState(state);
            const rooms = state.userRooms[userRef].rooms;
            if (data) {
                data.forEach((r) => {
                    const hotelRooms = rooms[r.projectRef];
                    const found = hotelRooms ? hotelRooms.find((e) => e && e.number === r.number) : null;
                    if (found) {
                        if (found.code === r.code && found.guestID === r.guestID) {
                            // Already exists
                            console.info(`Room ${r.number} already exists`);
                        } else {
                            // Already exists, but updated room code
                            found.code = r.code;
                            found.guestID = r.guestID;
                            console.info(`Room ${r.number} already exists but with different code or guest`);
                        }
                    } else {
                        // New room
                        if (!rooms[r.projectRef]) {
                            rooms[r.projectRef] = [];
                        }
                        rooms[r.projectRef].push({ ...roomStruct, ...r });
                        console.info(`Room ${r.number} added!`);
                    }
                });
            }

            Object.keys(rooms).forEach((projectRef) => {
                if (rooms[projectRef]) {
                    rooms[projectRef].forEach((r, index) => {
                        const found = data
                            ? data.find((e) => e && e.projectRef === projectRef && e.number === r.number)
                            : null;
                        if (!found) {
                            rooms[projectRef].splice(index, 1);
                            console.info(`Room ${r.number} removed!`);
                        }
                    });
                }
            });

            state.userRooms[userRef].rooms = rooms;
            state.userRooms[userRef].lastUpdate = new Date().getTime();
        }
    } else if (type === READ) {
        //REVIEW mover operaciones de lectura
    } else if (type === UPDATE) {
        const { projectRef, number, data } = payload.params;

        ensureState(state, { projectRef });

        // Update user rooms (automatic)
        if (userRef) {
            const found = getAutomaticRoom(state, projectRef, number);
            if (found) {
                updateRoomData(found, data);
                state.userRooms = cloneDeep(state.userRooms);
            }
        }

        // Update rooms (manual)
        const found = getManualRoom(state, projectRef, number);
        if (found) {
            updateRoomData(found, data);
            state.rooms = cloneDeep(state.rooms);
        }
    } else if (type === DELETE) {
        const { projectRef, number } = payload.params;
        ensureState(state, { projectRef });
        const found = getManualRoom(state, projectRef, number);
        if (found) {
            deleteManualRoom(state, projectRef, number);
        }
    } else {
        console.error("Unkwnown method " + type + " for rooms database");
    }
    return state;
};

const ensureState = (state, params) => {
    if (!state.rooms) {
        // Manual rooms
        state.rooms = { ...initialState.rooms };
    }
    if (!state.userRooms) {
        // Automatic rooms
        state.userRooms = { ...initialState.userRooms };
    }

    // Active user rooms
    const userRef = getCurrentUser(state);
    if (userRef && !state.userRooms[userRef]) {
        state.userRooms[userRef] = { rooms: {} };
    }
    if (userRef && !state.userRooms[userRef].rooms) {
        state.userRooms[userRef].rooms = {};
    }

    // Project rooms
    if (params?.projectRef) {
        const projectRef = params?.projectRef;
        if (!state.rooms[projectRef]) {
            state.rooms[projectRef] = {};
        }
        if (userRef && !state.userRooms[userRef].rooms[projectRef]) {
            state.userRooms[userRef].rooms[projectRef] = [];
        }
    }
};

function getCurrentUser(state) {
    return state?.current;
}

// cleanRoomProject delete keys of projects without rooms
const cleanRoomProjects = (projects) => {
    if (projects) {
        for (let ref in projects) {
            if (!projects[ref] || Object.keys(projects[ref]).length === 0) {
                delete projects[ref];
            }
        }
    }
};

function getManualRooms(state) {
    const rooms = state?.rooms;
    if (rooms) {
        cleanRoomProjects(rooms);
    }
    return rooms;
}

function getAutomaticRooms(state) {
    const userRef = getCurrentUser(state);
    const roomsArray = userRef && state?.userRooms ? state.userRooms[userRef]?.rooms : null;
    const rooms = {};
    if (roomsArray) {
        Object.entries(roomsArray)
            .map(([clave, valor]) => [
                clave,
                Array.isArray(valor)
                    ? valor.reduce((arr, value) => {
                          if (value) {
                              arr[value.number] = {
                                  number: value.number,
                                  code: value.code,
                                  guestID: value.guestID,
                                  automatic: true,
                                  salesToken: value.salesToken
                              };
                          }
                          return arr;
                      }, {})
                    : valor,
            ])
            .forEach(([projectRef, projectRooms]) => {
                rooms[projectRef] = {
                    ...rooms[projectRef],
                    ...projectRooms,
                };
            });
    }
    if (rooms) {
        cleanRoomProjects(rooms);
    }
    return rooms;
}

const getAutomaticRoom = (state, projectRef, number) => {
    if (!state || !projectRef || !number) {
        return null;
    }
    const userRef = getCurrentUser(state);
    const projectUserRooms = state.userRooms[userRef].rooms[projectRef];
    return projectUserRooms ? projectUserRooms.find((e) => e && e.number === number) : null;
};

const getManualRoom = (state, projectRef, number) => {
    if (!state || !projectRef || !number) {
        return null;
    }
    return state.rooms[projectRef][number];
};

const deleteManualRoom = (state, projectRef, number) => {
    const found = getManualRoom(state, projectRef, number);
    if (found) {
        delete state.rooms[projectRef][number];
        state.rooms = cloneDeep(state.rooms);
        return true;
    }
    return false;
};

const updateRoomData = (room, data) => {
    if (room) {
        Object.assign(room, data);
    }
    return room;
};

export default Rooms;
