import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { getAllRooms } from "../modules/rooms/store";
import { useMessages } from "../graphql/messages";

export const MessagesContext = React.createContext({
    messages: null,
    loading: null,
    error: null,
    hasUnread: () => null,
    messagesFor: () => null,
});

export const MessagesProvider = ({ children }) => {
    const rooms = useSelector(getAllRooms);

    const { query: loadMessages, called, loading, error, data: messages } = useMessages({ rooms, pollInterval: 60000 });

    useEffect(() => {
        if (!called && rooms && Object.keys(rooms).length > 0) {
            loadMessages();
        }
    }, [rooms]);

    useEffect(() => {
        if (error) {
            console.error(error);
        }
    }, [error]);

    const contextValue = useMemo(
        () => ({
            messages,
            loading,
            error,
            hasUnread: (projectRef, roomNumber) => {
                return messages?.some(
                    (m) =>
                        m &&
                        m.isRead !== true &&
                        (!projectRef || m.projectRef === projectRef) &&
                        (!roomNumber || Number(m.roomNumber) === Number(roomNumber))
                );
            },
            messagesFor: (projectRef, roomNumber) => {
                return messages?.filter(
                    (m) =>
                        (!projectRef || m.projectRef === projectRef) &&
                        (!roomNumber || Number(m.roomNumber) === Number(roomNumber))
                );
            }
        }),
        [messages, loading, error]
    );

    return <MessagesContext.Provider value={contextValue}>{children}</MessagesContext.Provider>;
};
