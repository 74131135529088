import { useState } from "react";
import handleGraphqlError from "./handleGraphqlError";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { useQuery } from "./utils";
import { GET_NOTIFICATIONS } from "./queries/notifications";
import { UPDATE_NOTIFICATION } from "./mutations/notifications";
import { getToken } from "../actions/user";

const defaultCache = {
    maxAge: 60000, // 1 minute
    fetchPolicy: "cache-first",
};

export const useNotifications = (params) => {
    const pollInterval = params?.pollInterval;
    const update = params?.update || false;

    const query = useQuery(GET_NOTIFICATIONS, {
        ...defaultCache,
        ...(update ? { fetchPolicy: "network-only" } : null),
        ...(pollInterval ? { maxAge: pollInterval - 1000, pollInterval } : null),
    });

    const data = query?.data?.getNotifications?.results;

    return {
        ...query,
        data,
    };
};

export const useUpdateNotification = (callbacks) => {
    const userToken = useSelector(getToken);
    const [notification, setNotification] = useState(null);
    const dispatch = useDispatch();
    const [updateNotification, { loading, error }] = useMutation(UPDATE_NOTIFICATION.query, {
        onCompleted: (data) => {
            if (data && data.updateNotification) {
                setNotification(data.updateNotification);
            }
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, null, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });

    return {
        updateNotification: (params) => updateNotification({ variables: params }),
        loading,
        error,
        notification: notification,
    };
};
