import React, { useState } from "react";
import Header from "components/Header";
import Tabs from "components/Tabs";
import { useTranslation } from "react-i18next";
import InformationStayCard from "./InformationStayCard";
import { UseStayBookingCode } from "graphql/stays";
import Loading from "components/Loading";

const InformationStay = () => {
    const { t } = useTranslation();
    const [dataStayBooking, setDataStayBooking] = useState(null);
    const [loading, setLoading] = useState(true);

    return (
        <section className="basic has-tabs has-top">
            {loading ? (
                <div className=" w-full h-full absolute top-0 left-0 bg-white z-30 ">
                    <Loading />
                </div>
            ) : null}
            <Header title={t("information-about-stay")} />
            <section className=" ">
                <InformationStayCard dataStayBooking={dataStayBooking} inInformationStay={true} />
            </section>
            <Tabs />
            <UseStayBookingCode setLoading={setLoading} setData={setDataStayBooking} />
        </section>
    );
};

export default InformationStay;
