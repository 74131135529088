import Messages from "../views/Messages";

export const messagesRoutes = (prefix = "") => {
    return [
        {
            path: prefix,
            exact: true,
            section: Messages,
        },
    ];
};
