import Sales from "../views/Sales";

export const shopRoutes = (prefix = "") => {
    return [
        {
            path: `${prefix}/:id?/:sub?`,
            section: Sales,
        },
    ];
};
