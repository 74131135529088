import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    getItemAllergen,
    getItemAttributeName,
    validateAviabilityRestaurant,
    getMenu,
    updateAmounts,
    validateAviability,
    calculateAmounts,
} from "../../../integrations/useCuiner";
import { Redirect } from "react-router";
import { calculateDecimals, normalizeToSearch } from "../../../utils/others";
import ErrorMessage from "../../../components/ErrorMessage";
import Loading from "../../../components/Loading";
import Tabs from "../../../components/Tabs";
import Header from "../../../components/Header";
import useForceUpdate from "../../../hooks/useForceUpdate";
import _ from "lodash";
import {
    setCuinerData,
    getCuinerData,
    getCuinerRestaurant,
    getCuinerMenu,
    getCuinerCart,
} from "../../../actions/cuiner";

const Menu = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const forceUpdate = useForceUpdate();
    const i18nLang = (i18n.language || "").match(/^[^-]+/)[0];
    const cuinerData = useSelector(getCuinerData);
    const selectedRestaurant = useSelector(getCuinerRestaurant);
    const selectedMenu = useSelector(getCuinerMenu);
    const cart = useSelector(getCuinerCart);
    const selectedCategoy = selectedMenu && selectedMenu.currentCategory ? selectedMenu.currentCategory : null;
    const selectedLanguage =
        selectedCategoy && selectedCategoy.selectedLanguage ? selectedCategoy.selectedLanguage : i18nLang;
    const selectedPriceList =
        selectedCategoy && selectedCategoy.selectedPriceList ? selectedCategoy.selectedPriceList : null;

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [menuItems, setMenuItems] = useState(null);
    const [menuItemsFiltered, setMenuItemsFiltered] = useState(null);
    const [redirectTo, setRedirectTo] = useState("");
    const [valSearch, setValSearch] = useState("");
    const [searching, setSearching] = useState(false);
    const [restaurantAvailability, setRestaurantAvailability] = useState(false);

    // UseEffect functions

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        setMenuItemsFiltered(menuItems);
    }, [menuItems]);

    useEffect(() => {
        setRestaurantAvailability(
            validateAviabilityRestaurant(
                selectedRestaurant && selectedRestaurant.calendar ? selectedRestaurant.calendar : []
            )
        );
    }, [selectedRestaurant]);

    //Utils function
    const refetch = async () => {
        setLoading(true);
        setError(null);
        setMenuItems(null);
        if (cuinerData && cuinerData.api && selectedMenu && selectedMenu.id && selectedCategoy && selectedCategoy.id) {
            let priceListIds = [];
            if (selectedMenu.priceLists && selectedMenu.priceLists.length > 0) {
                priceListIds = _.map(selectedMenu.priceLists, "id");
            }
            const menuResponse = await getMenu(cuinerData.api, {
                restaurantId: selectedRestaurant.id,
                menuId: selectedMenu.id,
                priceListIds: priceListIds,
            });
            if (menuResponse && menuResponse.data) {
                setMenuItems(
                    arrangeMenuItems(
                        menuResponse.data && menuResponse.data.items
                            ? convertObjectToList(menuResponse.data.items)
                            : null,
                        menuResponse.data && menuResponse.data.modifiers_groups
                            ? convertObjectToList(menuResponse.data.modifiers_groups)
                            : null
                    )
                );
            } else {
                setError(menuResponse.error);
            }
            setTimeout(() => {
                setLoading(false);
            }, 500);
        } else {
            setRedirectTo("/site");
        }
    };
    const convertObjectToList = (object) => {
        let items = [];
        let item = null;
        if (object != null) {
            Object.keys(object).forEach((key, index) => {
                item = object[key];
                items.push(item);
            });
        }
        return items;
    };
    const handleChangeSearch = (value) => {
        setValSearch(value);
        let menuItemsClone = [];
        if (value && value !== "") {
            if (menuItems && selectedLanguage) {
                menuItems.map((menuItem) => {
                    let addElement = false;
                    if (menuItem.cuinerData && menuItem.cuinerData.titles)
                        addElement = normalizeToSearch(menuItem.cuinerData.titles[selectedLanguage]).includes(
                            normalizeToSearch(value)
                        );
                    if (!addElement && menuItem.cuinerData && menuItem.cuinerData.descriptions) {
                        addElement = normalizeToSearch(menuItem.cuinerData.descriptions[selectedLanguage]).includes(
                            normalizeToSearch(value)
                        );
                    }
                    if (addElement) {
                        menuItemsClone.push(menuItem);
                    }
                });
            }
        } else {
            menuItemsClone = menuItems;
        }
        setMenuItemsFiltered(menuItemsClone);
    };
    const updateItemQuantity = (item, quantity) => {
        if (item) {
            let cuinerDataClone = { ...cuinerData };
            let selectedRestaurantClone = { ...selectedRestaurant };
            let cartClone = [...cart];
            let menuItemsClone = [...menuItems];
            let itemClone = { ...item };
            itemClone.quantity = quantity;
            itemClone = updateAmounts(updateAviabilityItem(itemClone));
            let index = _.findIndex(menuItemsClone, { id: item.id });
            menuItemsClone.splice(index, 1, itemClone);
            const exists = cartClone?.some((cart) => cart.id === itemClone.id);
            if (itemClone.quantity && itemClone.quantity > 0) {
                if (exists) {
                    index = _.findIndex(cartClone, { id: itemClone.id });
                    cartClone.splice(index, 1, itemClone);
                } else {
                    cartClone.push(itemClone);
                }
            } else {
                if (exists) {
                    _.remove(cartClone, { id: itemClone.id });
                }
            }
            selectedRestaurantClone.cart = cartClone;
            index = _.findIndex(cuinerDataClone.restaurants, { id: selectedRestaurantClone.id });
            cuinerDataClone.restaurants.splice(index, 1, selectedRestaurantClone);
            setMenuItems(menuItemsClone);
            dispatch(setCuinerData(cuinerDataClone));
            forceUpdate();
        }
    };
    const updateAviabilityItem = (item) => {
        if (item && selectedLanguage) {
            item.canPurchase = validateAviability(
                item.cuinerData.available,
                item.cuinerData.availabilities,
                {
                    all: item.cuinerData.modifiersGroups,
                    selecteds: item.modifierGroups,
                },
                t,
                selectedLanguage
            );
        }
        return item;
    };

    //organization functions
    const arrangeMenuItems = (itemsData, modifierGroupsData) => {
        const response = [];
        const items = [];
        const modifierGroups = modifierGroupsData && modifierGroupsData.length > 0 ? [...modifierGroupsData] : [];
        let item = null;
        let itemData = null;
        let dynamicObject = null;
        let add = true;
        if (itemsData && itemsData.length > 0) {
            if (modifierGroups && modifierGroups.length > 0) {
                let modifierGroup = null;
                for (var i = 0; i < modifierGroups.length; i++) {
                    modifierGroup = modifierGroups[i];
                    modifierGroup.showingItems = true;
                    modifierGroup.items = [];
                    if (modifierGroup.items_ids && modifierGroup.items_ids.length > 0) {
                        for (var j = 0; j < modifierGroup.items_ids.length; j++) {
                            dynamicObject = itemsData.filter((i) => i.id === modifierGroup.items_ids[j]);
                            if (dynamicObject && dynamicObject.length > 0) {
                                dynamicObject = arrangeItem(dynamicObject[0]);
                                if (dynamicObject) modifierGroup.items.push(dynamicObject);
                            }
                        }
                    }
                    modifierGroups[i] = modifierGroup;
                }
            }
            for (var i = 0; i < itemsData.length; i++) {
                itemData = itemsData[i];
                if (itemData) {
                    add = true;
                    item = arrangeItem(itemData);
                    if (selectedCategoy && selectedCategoy.id && selectedCategoy.id > 0) {
                        add =
                            itemData.categories &&
                            itemData.categories.filter((c) => c.categories_id === selectedCategoy.id).length > 0;
                    }
                    if (add) {
                        if (itemData.modifiers_groups_id && itemData.modifiers_groups_id.length > 0) {
                            for (var j = 0; j < itemData.modifiers_groups_id.length; j++) {
                                dynamicObject = modifierGroups.filter((m) => m.id === itemData.modifiers_groups_id[j]);
                                if (dynamicObject && dynamicObject.length > 0) {
                                    item.modifiersGroups.push(dynamicObject[0]);
                                }
                            }
                        }
                        items.push(item);
                    }
                }
            }
        }
        if (items && items.length > 0) {
            items
                .filter((i) => i.type !== "modifier")
                .forEach((item) => {
                    const cartItem =
                        cart && cart.filter((c) => c.id === item.id).length > 0
                            ? cart.filter((c) => c.id === item.id)[0]
                            : null;
                    response.push(
                        updateAmounts(
                            updateAviabilityItem({
                                id: item.id,
                                cuinerData: item,
                                modifierGroups: cartItem ? cartItem.modifierGroups : [],
                                quantity: cartItem ? cartItem.quantity : 0,
                                unitPrice:
                                    selectedPriceList &&
                                    item.priceBases &&
                                    item.priceBases.filter((u) => u.price_list_id === selectedPriceList.id).length > 0
                                        ? parseFloat(
                                              item.priceBases.filter((u) => u.price_list_id === selectedPriceList.id)[0]
                                                  .price
                                          )
                                        : 0.0,
                                taxPercent:
                                    selectedPriceList &&
                                    item.priceBases &&
                                    item.priceBases.filter((u) => u.price_list_id === selectedPriceList.id).length > 0
                                        ? parseFloat(
                                              item.priceBases.filter((u) => u.price_list_id === selectedPriceList.id)[0]
                                                  .tax_percent
                                          )
                                        : 0.0,
                                extraAmounts: cartItem ? cartItem.extraAmounts : [],
                                unitTax: 0.0,
                                subTotal: 0.0,
                                taxes: 0.0,
                                total: 0.0,
                                canPurchase: true,
                                selectedLanguage: selectedLanguage,
                                selectedPriceList: selectedPriceList,
                            })
                        )
                    );
                });
        }
        return response;
    };
    const arrangeItem = (itemData) => {
        const item = {
            id: itemData.id ? itemData.id : null,
            type: itemData.item_type ? itemData.item_type : null,
            available: itemData.avaiable && itemData.avaiable === "true" ? true : false,
            color: itemData.color ? itemData.color : null,
            imageUrl: itemData.image_url ? itemData.image_url : null,
            titles: itemData.title ? itemData.title : null,
            descriptions: itemData.description ? itemData.description : null,
            priceBases: itemData.price_base && itemData.price_base.length > 0 ? itemData.price_base : [],
            pricesWithModifiers:
                itemData.price_as_modifier && itemData.price_as_modifier.length > 0 ? itemData.price_as_modifier : [],
            availabilities: itemData.availability && itemData.availability.length > 0 ? itemData.availability : [],
            modifiersGroups: [],
            attributes: [],
            allergens: [],
        };
        if (itemData.attributes) {
            itemData.attributes.map((a) => getItemAttributeName(a, t));
        }
        if (itemData.allergens) {
            itemData.allergens.map((a) => getItemAllergen(a, t));
        }
        return item;
    };

    //Redirect function
    const goToItem = (item) => {
        let index = null;
        let cuinerDataClone = { ...cuinerData };
        let selectedRestaurantClone = { ...selectedRestaurant };
        let selectedMenuClone = { ...selectedMenu };
        selectedMenuClone.currentItem = item;
        index = _.findIndex(selectedRestaurantClone.menus, { id: selectedMenu.id });
        selectedRestaurantClone.menus.splice(index, 1, selectedMenuClone);
        index = _.findIndex(cuinerDataClone.restaurants, { id: selectedRestaurant.id });
        cuinerDataClone.restaurants.splice(index, 1, selectedRestaurantClone);
        dispatch(setCuinerData(cuinerDataClone));
        setRedirectTo("/pos/item");
    };
    const goToCart = () => {
        setRedirectTo("/pos/cart");
    };

    //Render functions
    const _renderMenuItems = () => {
        let response = [];
        if (selectedLanguage && selectedPriceList && menuItemsFiltered != null && menuItemsFiltered.length > 0) {
            menuItemsFiltered.map(async (item) => {
                const quantity = item?.quantity;
                const canPurchase = item?.canPurchase;
                const available = canPurchase?.available;
                const reqModifier = canPurchase?.requireModifier;
                const goFunction = available || reqModifier ? () => goToItem(item) : null;
                response.push(
                    <>
                        <li
                            className={`border-b bg-neutral p-2 ${goFunction ? "cursor-pointer" : ""}`}
                            onClick={(e) => {
                                if (goFunction) goFunction();
                            }}
                        >
                            <span className="inline-block">
                                {quantity > 0 &&
                                item.modifierGroups &&
                                item.modifierGroups instanceof Array &&
                                item.modifierGroups.length < 1 ? (
                                    <span className="bg-neutral-contrast items-center rounded text-sm font-normal p-1 text-neutral mr-2">
                                        x{quantity}
                                    </span>
                                ) : null}
                                {selectedLanguage &&
                                item.cuinerData &&
                                item.cuinerData.titles &&
                                item.cuinerData.titles[selectedLanguage]
                                    ? item.cuinerData.titles[selectedLanguage]
                                    : ""}
                            </span>
                            <div className="w-full py-3">
                                <div className="flex justify-between w-full">
                                    <div className="flex ">
                                        {item.cuinerData &&
                                        item.cuinerData.imageUrl !== null &&
                                        item.cuinerData.imageUrl !== "" ? (
                                            <img
                                                className="object-cover h-16 w-20 rounded inline-block mr-2"
                                                src={item.cuinerData.imageUrl}
                                            />
                                        ) : null}
                                        <div className="flex flex-col -mt-1 h-full">
                                            {selectedLanguage &&
                                            item.cuinerData &&
                                            item.cuinerData.descriptions &&
                                            item.cuinerData.descriptions[selectedLanguage] ? (
                                                <>
                                                    <span className="text-neutral-contrast-50 align-middle">
                                                        {item.cuinerData.descriptions[selectedLanguage]}
                                                    </span>
                                                    <br />
                                                </>
                                            ) : null}
                                            <span className="font-medium align-middle">{`${calculateDecimals(
                                                item.unitPrice + item.unitTax,
                                                2
                                            )}\u20AC`}</span>
                                        </div>
                                    </div>
                                    <div className="">{_renderMainOptions(item)}</div>
                                </div>
                                {item.cuinerData &&
                                item.cuinerData.attributes &&
                                item.cuinerData.attributes.length > 0 ? (
                                    <div className="w-full overflow-x-auto">
                                        {item.cuinerData.attributes.map((a) => (
                                            <span className="bg-neutral-contrast items-center rounded text-sm font-normal p-1 text-neutral mr-2">
                                                {a}
                                            </span>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                        </li>
                        {quantity > 0 &&
                        item.modifierGroups &&
                        item.modifierGroups instanceof Array &&
                        item.modifierGroups.length > 0 ? (
                            <div>{_renderModifiers(item, item.modifierGroups)}</div>
                        ) : null}
                    </>
                );
            });
        }
        response = <ul>{response}</ul>;
        return response;
    };

    const _renderModifiers = (item, modifiersGroups) => {
        return modifiersGroups.map((modifierG) =>
            modifierG.items.map((modifier, index) => {
                let extraAmountAdded =
                    item.extraAmounts &&
                    item.extraAmounts.filter((em) => em.itemModifierGroup.id === modifier.id).length > 0
                        ? item.extraAmounts.filter((em) => em.itemModifierGroup.id === modifier.id)
                        : null;
                extraAmountAdded =
                    extraAmountAdded && extraAmountAdded.length > 0
                        ? _.sumBy(
                              extraAmountAdded.filter((ea) => ea.operator === "+"),
                              "total"
                          )
                        : 0.0;
                const unitsAdded = _.sumBy(modifierG.items, "quantity");
                const isButtonPlusDisabled = unitsAdded >= modifierG.units_max;
                return (
                    <div
                        key={index}
                        style={{ minHeight: "108px", backgroundColor: "E8EBEB" }}
                        className="flex justify-between py-4 pl-3 pr-4 border"
                    >
                        <div>
                            <span className=" bg-neutral-contrast flex justify-center items-center h-5 w-5 text-neutral rounded-lg font-normal text-xs">
                                x{modifier.quantity}
                            </span>
                        </div>
                        <div className=" flex flex-col flex-1 space-y-1 ml-4 mr-2">
                            <span className=" font-normal text-sm text-neutral-contrast">
                                {selectedLanguage && modifierG && modifierG.title && modifierG.title[selectedLanguage]
                                    ? modifierG.title[selectedLanguage]
                                    : ""}
                            </span>
                            <span className=" font-normal text-sm text-neutral-contrast opacity-75">
                                {selectedLanguage &&
                                modifier.cuinerData &&
                                modifier.cuinerData.titles &&
                                modifier.cuinerData.titles[selectedLanguage]
                                    ? modifier.cuinerData.titles[selectedLanguage]
                                    : ""}
                            </span>
                            <span className=" font-medium text-neutral-contrast text-sm ">
                                {extraAmountAdded && extraAmountAdded > 0.0 ? `+ ${extraAmountAdded}\u20AC` : null}
                            </span>
                        </div>
                        {restaurantAvailability && (
                            <div className="flex flex-col space-y-2">
                                <button
                                    disabled={isButtonPlusDisabled}
                                    type="button"
                                    onClick={() =>
                                        updateModifierItemQuantity(
                                            item,
                                            modifier.quantity + 1,
                                            modifierG,
                                            modifier.cuinerData
                                        )
                                    }
                                    className=" w-8 h-8 border rounded icon icon-add text-xl"
                                ></button>
                                <button
                                    type="button"
                                    onClick={() =>
                                        updateModifierItemQuantity(
                                            item,
                                            modifier.quantity - 1,
                                            modifierG,
                                            modifier.cuinerData
                                        )
                                    }
                                    className=" w-8 h-8 border rounded icon icon-less text-xl"
                                ></button>
                            </div>
                        )}
                    </div>
                );
            })
        );
    };

    const updateModifierItemQuantity = (item, quantity, modifierGroupData, modifierItemData) => {
        let itemClone = { ...item };
        let modifierGroups = [...itemClone.modifierGroups];
        let modifierGroup = null;
        let modifierItem = null;
        let modifierAddedPreviously = false;
        let modifieGroupAddedPreviously = false;
        let pricesWithModifiers = null;
        let index = null;
        if (
            modifierGroupData &&
            modifierGroupData.id &&
            modifierGroupData.id > 0 &&
            modifierItemData &&
            modifierItemData.id &&
            modifierItemData.id > 0
        ) {
            modifierGroup = null;
            if (modifierGroups && modifierGroups.length > 0) {
                modifierGroup = modifierGroups.filter((m) => m.id === modifierGroupData.id);
                if (modifierGroup && modifierGroup.length > 0) {
                    modifieGroupAddedPreviously = true;
                    modifierGroup = modifierGroup[0];
                } else {
                    modifierGroup = null;
                }
            }
            if (!modifierGroup) {
                modifierGroup = { ...modifierGroupData };
                modifierGroup.items = [];
                delete modifierGroup.items_ids;
            }
            modifierItem = null;
            if (modifierGroup.items && modifierGroup.items.length > 0) {
                modifierItem = modifierGroup.items.filter((mi) => mi.id === modifierItemData.id);
                if (modifierItem && modifierItem.length > 0) {
                    modifierAddedPreviously = true;
                    modifierItem = modifierItem[0];
                } else {
                    modifierItem = null;
                }
            }
            if (!modifierItem) {
                pricesWithModifiers =
                    selectedPriceList &&
                    modifierItemData.pricesWithModifiers &&
                    modifierItemData.pricesWithModifiers.filter((u) => {
                        return (
                            u.modifiers_groups_id === modifierGroupData.id &&
                            u.price_info &&
                            u.price_info.price_list_id &&
                            u.price_info.price_list_id === selectedPriceList.id
                        );
                    }).length > 0
                        ? modifierItemData.pricesWithModifiers.filter((u) => {
                              return (
                                  u.modifiers_groups_id === modifierGroupData.id &&
                                  u.price_info &&
                                  u.price_info.price_list_id &&
                                  u.price_info.price_list_id === selectedPriceList.id
                              );
                          })[0]
                        : null;
                modifierItem = {
                    id: modifierItemData.id,
                    cuinerData: { ...modifierItemData },
                    quantity: 0,
                    unitPrice:
                        pricesWithModifiers &&
                        pricesWithModifiers.price_info &&
                        pricesWithModifiers.price_info.price &&
                        pricesWithModifiers.price_info.price > 0.0
                            ? parseFloat(pricesWithModifiers.price_info.price)
                            : 0.0,
                    taxPercent:
                        pricesWithModifiers &&
                        pricesWithModifiers.price_info &&
                        pricesWithModifiers.price_info.tax_percent &&
                        pricesWithModifiers.price_info.tax_percent > 0.0
                            ? parseFloat(pricesWithModifiers.price_info.tax_percent)
                            : 0.0,
                    unitTax: 0.0,
                    subTotal: 0.0,
                    taxes: 0.0,
                    total: 0.0,
                };
            }
            if (quantity >= 1) {
                modifierItem.quantity = quantity;
                modifierItem = calculateAmounts(modifierItem);
                if (modifierAddedPreviously) {
                    index = _.findIndex(modifierGroup.items, { id: modifierItem.id });
                    modifierGroup.items.splice(index, 1, modifierItem);
                } else {
                    modifierGroup.items.push(modifierItem);
                }
            } else {
                if (modifierAddedPreviously) {
                    _.remove(modifierGroup.items, (mi) => mi.id === modifierItem.id);
                }
            }
            if (modifierGroup.items && modifierGroup.items.length > 0) {
                if (modifieGroupAddedPreviously) {
                    index = _.findIndex(modifierGroups, { id: modifierGroup.id });
                    modifierGroups.splice(index, 1, modifierGroup);
                } else {
                    modifierGroups.push(modifierGroup);
                }
            } else {
                if (modifieGroupAddedPreviously) {
                    _.remove(modifierGroups, (m) => m.id === modifierGroup.id);
                }
            }
            itemClone.modifierGroups = modifierGroups;
            itemClone = updateAmounts(itemClone);
            itemClone = updateAviabilityItem(itemClone);

            const cuinerDataClone = { ...cuinerData };
            const selectedRestaurantClone = { ...selectedRestaurant };
            const menuItemsClone = [...menuItems];
            const cartClone = [...cart];
            const indexItem = _.findIndex(menuItemsClone, { id: item.id });
            menuItemsClone.splice(indexItem, 1, itemClone);
            const exists = cartClone?.some((cart) => cart.id === itemClone.id);
            if (exists) {
                const available = itemClone?.canPurchase?.available === true;
                index = _.findIndex(cartClone, { id: itemClone.id });
                if (available) {
                    cartClone.splice(index, 1, itemClone);
                } else {
                    cartClone.splice(index, 1);
                    itemClone.quantity = 0;
                }
            } else {
                cartClone.push(itemClone);
            }
            setMenuItems(menuItemsClone);
            selectedRestaurantClone.cart = cartClone;
            const indexRestaurant = _.findIndex(cuinerDataClone.restaurants, { id: selectedRestaurantClone.id });
            cuinerDataClone.restaurants.splice(indexRestaurant, 1, selectedRestaurantClone);
            dispatch(setCuinerData(cuinerDataClone));
        }
    };

    const _renderMainOptions = (item) => {
        const response = [];
        if (item) {
            if (
                item.canPurchase &&
                item.canPurchase.available &&
                item.modifierGroups &&
                item.modifierGroups instanceof Array &&
                item.modifierGroups.length < 1
            ) {
                if (item.quantity && item.quantity > 0) {
                    response.push(
                        <>
                            <button
                                disabled={restaurantAvailability === false}
                                type="submit"
                                className={`btn border w-10 text-xl block mb-3 font-normal ${
                                    restaurantAvailability ? "opacity-100" : "opacity-0"
                                }`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    updateItemQuantity(item, item.quantity + 1);
                                }}
                            >
                                <i className="icon icon-add text-xl"></i>
                            </button>
                            <button
                                disabled={restaurantAvailability === false}
                                type="submit"
                                className={`btn border w-10 text-xl block font-normal ${
                                    restaurantAvailability ? "opacity-100" : "opacity-0"
                                }`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    updateItemQuantity(item, item.quantity - 1);
                                }}
                            >
                                <i className="icon icon-less text-xl"></i>
                            </button>
                        </>
                    );
                } else {
                    response.push(
                        <button
                            disabled={restaurantAvailability === false}
                            type="submit"
                            className={`btn border w-10 h-24 text-xl font-normal ${
                                restaurantAvailability ? "opacity-100" : "opacity-0"
                            } `}
                            onClick={(e) => {
                                e.stopPropagation();
                                updateItemQuantity(item, 1);
                            }}
                        >
                            <i className="icon icon-add text-xl"></i>
                        </button>
                    );
                }
            } else {
                if (
                    item.canPurchase &&
                    item.modifierGroups &&
                    item.modifierGroups instanceof Array &&
                    (item.canPurchase.requireModifier || item.modifierGroups.length > 0)
                ) {
                    response.push(
                        <button
                            type="submit"
                            className={`btn border w-10 h-24 text-xl font-normal ${
                                restaurantAvailability ? "opacity-100" : "opacity-0"
                            } `}
                            onClick={(e) => {
                                e.stopPropagation();
                                goToItem(item);
                            }}
                        >
                            <i className="icon icon-add text-xl"></i>
                        </button>
                    );
                }
            }
        }
        return response;
    };

    const _renderButtonCart = () => {
        const response = [];
        if (cart && cart.length > 0) {
            let total = 0.0;
            cart.map((cartData) => {
                if (cartData && cartData.total) total = calculateDecimals(total + cartData.total, 2);
            });
            response.push(
                <div className="w-full flex items-center justify-center">
                    <div className="fixed w-80 bottom-0 mb-20">
                        <div
                            className="bg-neutral-contrast-90 rounded-sm font-medium text-neutral w-full text-center py-2 text-xl px-4 cursor-pointer"
                            onClick={goToCart}
                        >
                            <div className="flex justify-between w-full">
                                <span>{t("view basket (x)", { items: cart.length })}</span>
                                <span>{`${total}\u20AC`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return response;
    };

    //Redirect catch
    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    return (
        <>
            <section className="basic has-tabs has-top">
                {searching ? (
                    <Header back={false}>
                        <div className="flex justify-between w-full">
                            <div className="h-full pt-4 pr-3">
                                <i className="icon icon-search text-xl primary "></i>
                            </div>
                            <input
                                type="text"
                                className="search-top primary"
                                placeholder={t("search")}
                                onChange={(e) => {
                                    handleChangeSearch(e.target.value);
                                }}
                                value={valSearch}
                            />
                            <div
                                className="h-full pt-4"
                                onClick={() => {
                                    handleChangeSearch("");
                                    setSearching(false);
                                }}
                            >
                                <i className="icon icon-close text-xl cursor-pointer primary "></i>
                            </div>
                        </div>
                    </Header>
                ) : (
                    <Header
                        title={
                            selectedLanguage &&
                            selectedCategoy &&
                            selectedCategoy.titles &&
                            selectedCategoy.titles[selectedLanguage]
                                ? selectedCategoy.titles[selectedLanguage]
                                : ""
                        }
                    >
                        <div
                            className="h-full pt-5"
                            onClick={() => {
                                handleChangeSearch("");
                                setSearching(true);
                            }}
                        >
                            <i className="icon icon-search text-xl cursor-pointer primary "></i>
                        </div>
                    </Header>
                )}
                <section style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {loading ? (
                        <Loading />
                    ) : error ? (
                        <ErrorMessage type="server" error={error} />
                    ) : (
                        <>
                            <div className="w-full mb-5">{_renderMenuItems()}</div>
                            {_renderButtonCart()}
                        </>
                    )}
                </section>
                <Tabs hideKey={true} />
            </section>
        </>
    );
};

export default Menu;
