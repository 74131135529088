import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import handleGraphqlError from "./handleGraphqlError";
import { MARK_MESSAGES_AS_READ } from "./queries/messages";
import { getToken } from "../actions/config";
import { getToken as getUserToken } from "../actions/user";

export const useMarkMessagesAsRead = (callbacks) => {
    const userToken = useSelector(getUserToken);
    const appToken = useSelector(getToken);
    const dispatch = useDispatch();
    const [markMessagesAsRead, { loading, error, data }] = useMutation(MARK_MESSAGES_AS_READ.query, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        onCompleted: (data) => {
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });
    return { markMessagesAsRead, loading, error, data };
};
