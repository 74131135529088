import React, { useState, useRef, useEffect } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import Loading from "../components/Loading";
import { useSelector, useDispatch } from "react-redux";
import { useSendEmailVerificationCode, useVerifyEmailVerificationCode } from "../graphql/useUser";
import useInterval from "../hooks/useInterval";
import swal from "@sweetalert/with-react";
import { updateUserData, getUser } from "../actions/user";
import { toast } from "react-toastify";

const VerifyEmail = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const currentUser = useSelector(getUser);
    const lastVerifEmail = useSelector((state) => state.user.verificationEmail);
    const user = useSelector((state) =>
        currentUser && state.user && state.user.data ? state.user.data[currentUser] : null
    );
    const [redirectTo, setRedirectTo] = useState("");
    let initialCount = 60;
    if (lastVerifEmail && lastVerifEmail.sent) {
        const verificationAgo = Math.round((new Date().getTime() - lastVerifEmail.sent) / 1000);
        initialCount = 60 - verificationAgo;
        if (initialCount < 0) {
            initialCount = 0;
        }
    }
    const [count, setCount] = useState(initialCount);
    const [code, setCode] = useState("");

    const locationAddRoom = matchPath(location.pathname, {
        path: "/site/addroom",
    });
    const locationBooking = matchPath(location.pathname, {
        path: "/site/booking",
    });
    const locationZones = matchPath(location.pathname, {
        path: "/site/zones",
    });
    const locationStays = matchPath(location.pathname, {
        path: "/stays",
    });
    const locationPrecheckin = matchPath(location.pathname, {
        path: "/precheckin",
    });
    const locationCuiner = matchPath(location.pathname, {
        path: "/pos",
    });

    let afterSignup = false;
    let redirectAfterLogin = "/site";
    if (locationBooking) {
        redirectAfterLogin = "/site/booking";
    } else if (locationZones) {
        redirectAfterLogin = "/site/zones";
    } else if (locationStays) {
        redirectAfterLogin = "/stays";
    } else if (locationPrecheckin) {
        redirectAfterLogin = "/precheckin";
    } else if (locationAddRoom) {
        redirectAfterLogin = "/site/addroom";
    } else {
        afterSignup = true;
    }

    const textInput = useRef(null);

    useInterval(() => {
        if (count > 0) {
            setCount(count - 1);
        }
    }, 1000);

    const verificationError = () => {
        swal({
            title: t("error.title"),
            text: t("error.unknown") + "\n" + t("error.try again"),
            button: t("alert ok"),
        });
    };

    const verifyCodeError = () => {
        resetError();
        setCount(60);
        setCode("");
        textInput.current.value = "";
        textInput.current.focus();
    };

    const resetError = () => {
        swal({
            title: t("error.title"),
            text: t("error.unknown") + "\n" + t("error.unknownAction"),
            button: t("alert ok"),
        });
    };

    const {
        sendEmailVerificationCode,
        loading: sending,
        verification,
        setVerification,
    } = useSendEmailVerificationCode({
        onError: () => {
            verificationError();
        },
    });

    const { verifyEmailVerificationCode, loading: verifying } = useVerifyEmailVerificationCode({
        onCompleted: (data) => {
            if (data.verifyEmailVerificationCode) {
                setVerification({
                    ...verification,
                    valid: true,
                });
                const user = data.verifyEmailVerificationCode;
                if (user) {
                    dispatch(updateUserData(user));
                    toast(t("hello user verified", { name: user.name }) + "\n" + t("welcome message"));
                    setRedirectTo(redirectAfterLogin);
                } else {
                    verifyCodeError();
                }
            } else {
                verifyCodeError();
            }
        },
        onError: (error) => {
            verifyCodeError();
        },
    });

    useEffect(() => {
        if (!lastVerifEmail || !lastVerifEmail.sent) {
            sendEmailVerificationCode();
        }
    }, [lastVerifEmail, sendEmailVerificationCode]);

    const sendCode = () => {
        if (count === 0) {
            sendEmailVerificationCode();
            setCount(60);
            setCode("");
            textInput.current.value = "";
            textInput.current.focus();
        }
    };

    const verifyCode = (code) => {
        if (verification.hash) {
            verifyEmailVerificationCode({
                variables: {
                    hash: verification.hash,
                    code: code,
                },
            });
        } else {
            verifyCodeError();
        }
    };

    if (!user) {
        return <Redirect push to={{ pathname: "/site", state: afterSignup ? "SIGN_UP" : null }} />;
    }

    if (redirectTo) {
        return <Redirect push to={{ pathname: redirectTo, state: afterSignup ? "SIGN_UP" : null }} />;
    }

    const classNumber = "font-mono h-12 text-xl inline-block border-b border-neutral-contrast flex-1 mx-1 py-2 ";

    return (
        <section className="basic has-top">
            <Header
                title={
                    verification && verification.valid
                        ? t("change your pass")
                        : verification && verification.hash
                        ? t("enter code")
                        : t("verify account")
                }
                back={afterSignup ? false : undefined}
            />
            <section>
                {sending || verifying ? (
                    <Loading />
                ) : verification && verification.valid ? null : (
                    <>
                        <p className="text-center mb-5">{t("verify code sent", { email: user.email })}</p>
                        <p className="text-center mb-10">{t("enter verify code")}</p>

                        <div className="opacity-0 h-0 relative overflow-hidden">
                            <input
                                ref={textInput}
                                type="number"
                                max="999999"
                                onChange={(e) => {
                                    const value = e.target.value.trim().substring(0, 6);
                                    if (value.length === 6) {
                                        verifyCode(value);
                                    }
                                    setCode(value);
                                }}
                                autoFocus={true}
                                className="absolute"
                            />
                        </div>
                        <p
                            className="flex text-center mb-6"
                            onClick={(e) => {
                                textInput.current.focus();
                            }}
                            onDoubleClick={(e) => {
                                textInput.current.value = "";
                                setCode("");
                            }}
                        >
                            {[0, 1, 2, 3, 4, 5].map((index) => (
                                <span className={classNumber} key={index}>
                                    {code[index]}
                                </span>
                            ))}
                        </p>

                        <p className="text-neutral-contrast my-3">
                            <button
                                className={count > 0 ? "text-neutral-contrast-50" : "text-neutral-contrast"}
                                disabled={count > 0 ? true : false}
                                onClick={() => sendCode()}
                            >
                                {t("send code again")}
                            </button>
                            <span className="inline-block ml-4">
                                {count > 0 ? t("x seconds", { count: count }) : ""}
                            </span>
                        </p>

                        {afterSignup ? (
                            <div className="text-center absolute bottom-0 left-0 right-0 mb-10">
                                <Link
                                    id="test-verify"
                                    to={{ pathname: redirectAfterLogin, state: afterSignup ? "SIGN_UP" : null }}
                                    className="text-accent text-lg inline-block font-bold"
                                >
                                    {t("verify later")}
                                </Link>
                            </div>
                        ) : (
                            ""
                        )}
                    </>
                )}
            </section>
        </section>
    );
};

export default VerifyEmail;
