import {
    AUTHORIZE_MARKETING,
    PORTAL_CONFIG,
    PORTAL_SESSION_REFRESH,
    PORTAL_SESSION_LOAD,
    decodePortalSessionToken,
} from "../actions/portal";

const initialState = {
    config: {
        accessConditions: null,
        privacyPolicy: null,
        authGoogle: null,
        askMarketing: false,
    },
    layout: {
        desktop: null,
        mobile: null,
    },
    marketingAccepted: false,
    session: {
        internet: null,
        email: null,
        ref: null,
        signup: null,
        hash: null,
        verified: null,
        verifyNotif: false,
        google: null,
        marketing: null,
        room: null,
        rooms: null,
        ccDone: null,
        ccSkip: null,
        verify: null,
        mikrotik: null,
        redirect: null,
        sessionKey: null,
    },
};

const reducerPortal = function (state = initialState, action = {}) {
    const newState = { ...state };
    switch (action.type) {
        case PORTAL_SESSION_REFRESH:
            if (action.payload) {
                newState.session = { ...newState.session, ...action.payload };
            } else {
                newState.session = { ...initialState.session };
            }
            break;
        case PORTAL_SESSION_LOAD:
            if (action.payload) {
                const { token, hash } = action.payload;
                newState.session = { ...decodePortalSessionToken(token) };
                if (hash) newState.session.hash = hash;
            } else {
                newState.session = { ...initialState.session };
            }
            break;
        case PORTAL_CONFIG:
            if (action.payload) {
                const cfg = action.payload;
                newState.config = {
                    accessConditions: cfg ? cfg.accessConditions : null,
                    authGoogle: cfg && cfg.authGoogle ? cfg.authGoogle.web : null,
                    privacyPolicy: cfg ? cfg.privacyPolicy : null,
                    askMarketing: cfg ? cfg.askMarketing : false,
                    projectName: cfg ? cfg.projectName : null,
                };
                newState.layout = {
                    desktop: cfg ? cfg.desktop : null,
                    mobile: cfg ? cfg.mobile : null,
                };
            }
            break;
        case AUTHORIZE_MARKETING:
            newState.marketingAccepted = action.payload;
            break;
        default:
    }
    return newState;
};

export default reducerPortal;
