import Restaurants from "../../views/integrations/cuiner/Restaurants";
import Menus from "../../views/integrations/cuiner/Menus";
import Menu from "../../views/integrations/cuiner/Menu";
import Item from "../../views/integrations/cuiner/Item";
import Categories from "../../views/integrations/cuiner/Categories";
import Cart from "../../views/integrations/cuiner/Cart";
import Order from "../../views/integrations/cuiner/Order";
import { authRoutes } from "../auth";

export const cuinerRoutes = (prefix = "") => {
    return [
        {
            path: prefix,
            exact: true,
            section: Restaurants,
        },
        {
            path: prefix + "/menus",
            exact: true,
            section: Menus,
        },
        {
            path: prefix + "/categories",
            exact: true,
            section: Categories,
        },
        {
            path: prefix + "/categories/:selectedCategoy",
            exact: true,
            section: Categories,
        },
        {
            path: prefix + "/menu",
            exact: true,
            section: Menu,
        },
        {
            path: prefix + "/item/:prevLocation?",
            exact: true,
            section: Item,
        },
        {
            path: prefix + "/cart",
            exact: true,
            section: Cart,
        },
        ...authRoutes(prefix + "/cart"),
        {
            path: prefix + "/order/:orderRef",
            exact: true,
            section: Order,
        },
        ...authRoutes(prefix + "/order/:orderRef"),
    ];
};
