import Profile from "../views/Profile";
import ProfileConfig from "../views/ProfileConfig";
import ProfileEdit from "../views/ProfileEdit";
import ProfilePass from "../views/ProfilePass";
import ProfileDelete from "../views/DeleteAccount"
import { authRoutes } from "./auth";

export const profileRoutes = (prefix = "") => {
  return [
    {
      path: prefix,
      exact: true,
      section: Profile,
    },
    {
      path: prefix + "/config",
      exact: true,
      section: ProfileConfig,
    },
    {
      path: prefix + "/edit",
      exact: true,
      section: ProfileEdit,
    },
    {
      path: prefix + "/newpass",
      exact: true,
      section: ProfilePass,
    },
    {
      path: prefix + "/deleteAccount",
      exact: true,
      section: ProfileDelete,
    },
    ...authRoutes(prefix),
  ];
};
