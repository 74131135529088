import { UPDATE_HOTELS, UPDATE_VOD_STATUS } from "../actions/hotel";
import { UPDATE_APP_TOKEN, SET_IS_EMBEDDED } from "../actions/config";

const initialState = {
    auth: {
        token: null,
        lastUpdate: null,
    },
    hotels: {
        list: null,
        lastUpdate: null,
    },
    VODReady: {},
};

const reducerCache = function (state = initialState, action = {}) {
    const newState = { ...state };
    switch (action.type) {
        case UPDATE_VOD_STATUS:
            if (action.payload) {
                let newdata = {};
                Object.keys(action.payload).forEach(function (key) {
                    let value = action.payload[key];
                    newdata[key] = {
                        ready: value,
                        date: new Date().getTime(),
                    };
                });
                newState.VODReady = { ...newState.VODReady, ...newdata };
            }
            break;
        case UPDATE_HOTELS:
            if (!newState.hotels) newState.hotels = {};
            newState.hotels.list = action.payload;
            newState.hotels.lastUpdate = new Date().getTime();
            break;
        case UPDATE_APP_TOKEN:
            if (!newState.auth) newState.auth = {};
            newState.auth.token = action.payload;
            newState.auth.lastUpdate = new Date().getTime();
            break;
        case SET_IS_EMBEDDED:
            newState.isEmbedded = action.payload;
            break;
        default:
    }
    return newState;
};

export default reducerCache;
