// Funcionalidades de React
import React, { useEffect, useState } from "react";
// Internacionalización
import { useTranslation } from "react-i18next";
// Manejo del estado global de la aplicación
import { useDispatch, useSelector } from "react-redux";
// Manipulación de colores
import Color from "color";
// Componentes personalizados
import Loading from "../../Loading";
import { parseSize } from "../DesignUtils";
// Acciones del portal
import { getPortalSession, updatePortalSession } from "../../../actions/portal";
// Custom hooks
import useWidget from "../../../hooks/useWidget";
import usePortalData from "../../../hooks/usePortalData";
import useWifiService from "../../../hooks/useWifiService";

import { GoogleOAuthProvider } from "google-oauth-gsi";
import swal from "@sweetalert/with-react";

import Render from "zrender/render";
import { METHOD_SIGNIN, METHOD_SIGNUP, METHOD_LOADING } from "zrender/widgets/wifilogin";
import { handleShowTerms, handleShowPrivacy } from "components/DialogHandlers";

import isoCountries from "../../../utils/countries";

const render = new Render("WIFILOGIN");

const UseWidgetWifiLogin = ({ config, id, deviceType }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const session = useSelector(getPortalSession);
    const step = session.getStep();

    const { styles } = useWidget({ type: "WIFILOGIN", styles: config ? config.styles : null });

    const { session: portalSession, config: tokenConfig } = usePortalData();
    const accessConditions = portalSession && portalSession.config ? portalSession.config.accessConditions : null;
    const privacyPolicy = portalSession && portalSession.config ? portalSession.config.privacyPolicy : null;
    const askMarketing = portalSession && portalSession.config ? portalSession.config.askMarketing : false;

    const clientId =
        session && session.config && session.config.authGoogle && session.config.authGoogle
            ? session.config.authGoogle.clientID
            : window.appConfig && window.appConfig.authGoogle
            ? window.appConfig.authGoogle.clientID
            : null;

    const googleProvider = new GoogleOAuthProvider({
        clientId: clientId,
        onScriptLoadSuccess: () => {
            if (clientId) {
                render.updateConfig({ googleDisabled: false });
                setGoogleEnabled(true);
                render.update();
            }
        },
        onScriptLoadError: () => {
            if (clientId) {
                render.updateConfig({ googleDisabled: true });
                setGoogleEnabled(false);
                render.update();
            }
            console.error("Unable to connect with google");
        },
    });

    const login = googleProvider.useGoogleLogin({
        flow: "auth_code",
        ux_mode: "redirect",
        prompt: "none",
        redirect_uri: window.location.origin + "/portal",
        onError: (err) => {
            googleOnError(err);
        },
        onNonOAuthError: (err) => {
            googleOnError(err);
        },
    });

    const googleOnError = (e) => {
        console.error("googleOnError", e);
        if (typeof e === "object") {
            if (e.details) {
                render.updateConfig({ googleError: e.details });
            } else if (e.type) {
                render.updateConfig({ googleError: e.type + ": " + e.message });
            } else {
                render.updateConfig({ googleError: e.message || "unknown error" });
            }
        } else {
            render.updateConfig({ googleError: e });
        }
        render.update();
    };

    const signInTypes = [];

    if (tokenConfig && tokenConfig.AccessTypes) {
        tokenConfig.AccessTypes.map((item) => {
            const id = item.Name;
            if (id) {
                let name;
                switch (id) {
                    case "email":
                        name = t("email");
                        break;
                    case "pms":
                        name = t("room_and_surname");
                        break;
                    case "code":
                        name = t("passcode");
                        break;
                    case "open":
                        name = t("open_access");
                        break;
                    case "mac":
                        // ignore
                        return item;
                    default:
                        console.error("Unknown access type: " + id);
                        return item;
                }
                const data = item.AdditionalData || {};
                signInTypes.push({
                    ...{
                        id: id,
                        name: name,
                        signupFields: {
                            email: data.Email,
                            name: data.Name,
                            surname: data.Surname,
                            birth: data.Age,
                            country: data.Country,
                        },
                    },
                    ...(id === "email" ? { showGoogle: item.RRSS } : {}),
                    ...(id === "pms" || id === "code" ? { showEmail: data.Email } : {}),
                });
            }
            return item;
        });
    }

    signInTypes.sort((a, b) => {
        const order = ["email", "pms", "code", "open"];
        const ai = a ? order.indexOf(a.id) : -2;
        const bi = b ? order.indexOf(b.id) : -2;
        return ai === bi ? 0 : ai > bi ? 1 : -1;
    });

    const { signIn, signUp, loading } = useWifiService();

    const [signInType, setSignInType] = useState(signInTypes.length > 0 ? signInTypes[0] : null);
    const [showLoading, setShowLoading] = useState(false);
    const [googleEnabled, setGoogleEnabled] = useState(googleProvider.clientId ? true : false);

    const fields = signInType ? signInType.signupFields : null;
    const signupAvailable =
        fields &&
        ((fields.name && fields.name.Enabled) ||
            (fields.surname && fields.surname.Enabled) ||
            (fields.birth && fields.birth.Enabled) ||
            (fields.country && fields.country.Enabled) ||
            (fields.surname && fields.surname.Enabled));

    if (step === "signup" && fields && !signupAvailable) {
        dispatch(updatePortalSession({ skipSignup: true }));
    }

    const submitSignIn = (data) => {
        if (signInType && signInType.id && data.terms) {
            signIn({
                type: signInType.id,
                room: data.room ? data.room.trim() : null,
                surname: data.surname ? data.surname.trim() : null,
                email: data.email ? data.email.trim() : null,
                code: data.passcode,
                marketingAccepted: data.marketing,
            });
        }
    };

    const submitSignUp = (data) => {
        signUp({
            email: session.email ? session.email.trim() : null,
            name: data.name ? data.name.trim() : null,
            surname: data.surname ? data.surname.trim() : null,
            birth: data.birth,
            country: data.country,
            marketingAccepted: data.marketing,
        });
    };

    const signInsCount = signInTypes.length;

    useEffect(() => {
        if (session && session.google && !loading) {
            render.updateConfig({ googleError: null });
            render.update();
            signIn({
                type: signInType.id,
                google: session.google,
            });
            dispatch(updatePortalSession({ google: null }));
        }
    }, [session, signInType]);

    useEffect(() => {
        render.setStyle(styles);
        render.setDevice(deviceType);
        render.updateTexts({
            googleButton: t("signin google"),
            acceptTerms: t("accept terms", { terms: "{{terms}}" }),
            termsAndPrivacy: t("terms & policy", { terms: "{{terms}}", privacy: "{{privacy}}" }),
            loginWithEmail: t("or access with your email"),
            terms: t("terms & conditions"),
            privacy: t("privacy policy"),
            marketing: t("authorize marketing"),
            submit: t("connect"),
            types: {
                email: t("email"),
                pms: t("room_and_surname"),
                code: t("passcode"),
                open: t("open_access"),
            },
            labels: {
                room: t("room"),
                name: t("name"),
                surname: t("surname"),
                email: t("email"),
                passcode: t("passcode"),
                birth: t("birth"),
                country: t("country"),
            },
            placeholders: {
                room: t("example something", { something: "102" }),
                name: t("example something", { something: "John" }),
                surname: t("example something", { something: "Smith" }),
                email: t("example something", { something: "smith@gmail.com" }),
                passcode: t("example something", { something: "ayJ8kk" }),
                country: t("search country"),
            },
        });
        var formFields = { signup: {} };
        for (var i in signInTypes) {
            const t = signInTypes[i];
            formFields[t.id] = {};
            const isActive = signInType && signInType.id === t.id;
            for (var k in t.signupFields) {
                const f = t.signupFields[k] || {};
                if (t.id !== "email" && k === "email") {
                    formFields[t.id].email = { enabled: f.Enabled, required: f.Mandatory };
                } else if (isActive && !k.match(/email|room|passcode/) && (k !== "surname" || t.id !== "pms")) {
                    formFields.signup[k] = { enabled: f.Enabled, required: f.Mandatory };
                }
            }
        }

        render.updateConfig({
            static: false,
            mode: loading
                ? METHOD_LOADING
                : step === "login"
                ? METHOD_SIGNIN
                : step === "signup" && signupAvailable
                ? METHOD_SIGNUP
                : "",
            loginTypes: signInsCount > 0 ? signInTypes.map((item, index) => (item ? item.id : null)) : null,
            activeType: signInType ? signInType.id : null,
            form: { fields: formFields },
            google: signInType && signInType.showGoogle,
            googleDisabled: !googleEnabled,
            googleError: googleProvider.clientId ? null : "Unconfigured google login",
            askMarketing: askMarketing,
            countries: isoCountries
                .sort((a, b) => {
                    const aname = t("country:" + a);
                    const bname = t("country:" + b);
                    return aname.toLowerCase().localeCompare(bname.toLowerCase());
                })
                .map((iso) => {
                    return { value: iso, name: t("country:" + iso) };
                }),
            events: {
                loading: function (loading) {
                    setShowLoading(loading);
                },
                updateType: function (type) {
                    setSignInType(signInTypes.length > 0 ? signInTypes.filter((t) => t && t.id == type)[0] : null);
                },
                back: function () {
                    dispatch(
                        updatePortalSession({
                            email: null,
                            signup: null,
                            verify: null,
                            mikrotik: null,
                            redirect: null,
                        })
                    );
                },
                google: login,
                signin: function (values) {
                    submitSignIn(values);
                },
                signup: function (values) {
                    submitSignUp(values);
                },
                skipSignup: function () {},
                openTerms: () => {
                    handleShowTerms(accessConditions, swal);
                },
                openPrivacy: () => {
                    handleShowPrivacy(privacyPolicy, swal);
                },
            },
        });
        render.update();
    }, [styles, deviceType, signInTypes, askMarketing, loading]);

    useEffect(() => {
        document.getElementById(id).appendChild(render.run());
    }, [id]);

    const css = render.css();

    return (
        <>
            {loading || showLoading ? <Loading color={css ? css.form.terms.link.color : null} /> : null}
            <div id={id} className={"w-full h-full" + (loading || showLoading ? " hidden" : "")}></div>
        </>
    );
};

export const parseConfig = ({ styles: style, parentCfg, deviceType }) => {
    const size = (s) => parseSize(s, deviceType) + "px";

    if (style.bgColor && parentCfg && parentCfg.bgColor === style.bgColor) {
        style.bgColor = null;
    }

    const defaultSize = 1.2;

    const inputFgColor = style.fgColor;
    const inputBgColor = Color(style.bgColor).alpha(1).string();
    const inputBorderColor = Color(style.fgColor).alpha(0.2).string();
    const labelColor = Color(style.fgColor).alpha(0.7).string();
    const errorColor = "rgb(220,91,91)";

    const buttonDisabledFgColor = Color(style.items.button.fgColor).alpha(0.5).string();
    const buttonDisabledBgColor = Color(style.items.button.bgColor).saturate(-0.5).string();

    let css = {};
    css.global = {
        color: style.fgColor,
        backgroundColor: style.bgColor,
        borderRadius: style.radius + "rem",
        fontSize: size(defaultSize),
    };

    css.link = {
        color: style.status.active.fgColor,
    };

    css.button = {
        color: style.items.button.fgColor,
        backgroundColor: style.items.button.bgColor,
        borderRadius: style.items.button.radius + "rem",
        fontFamily: style.items.button.font ? style.items.button.font.name : "inherit",
        fontSize: size(style.items.button.size),
        padding: size(0.75) + " " + size(2.5),
    };

    css.buttonDisabled = {
        ...css.button,
        color: buttonDisabledFgColor,
        backgroundColor: buttonDisabledBgColor,
    };
    css.buttonGoogle = {
        fontSize: size(defaultSize * 1.1),
        color: "black",
        backgroundColor: "white",
        borderColor: "rgba(50, 50, 50, 0.2)",
        borderWidth: "1px",
        borderRadius: "0.5rem",
    };
    css.iconGoogle = {
        height: size(defaultSize),
    };

    css.tab = {
        borderColor: "transparent",
    };
    css.tabActive = {
        borderColor: style.status.active.fgColor,
        color: style.status.active.fgColor,
        fontWeight: 600,
    };

    css.label = {
        fontSize: size(defaultSize * 0.8),
        color: labelColor,
    };
    css.labelError = {
        ...css.label,
        color: errorColor,
    };

    css.input = {
        color: inputFgColor,
        backgroundColor: inputBgColor,
        fontSize: size(defaultSize),
        padding: ".5em 1em",
        height: "auto",
        borderColor: inputBorderColor,
        borderWidth: "1px",
        borderRadius: "0.3rem",
    };
    css.inputError = {
        ...css.input,
        borderColor: errorColor,
    };

    css.terms = {
        fontSize: size(defaultSize * 0.8),
    };

    css.check = {
        width: "1.2em",
        height: "1.2em",
        color: inputBgColor,
        backgroundColor: inputBgColor,
        borderColor: inputBorderColor,
    };
    css.checkOn = {
        ...css.check,
        color: style.status.active.fgColor,
        borderColor: style.status.active.fgColor,
    };

    return css;
};

export default UseWidgetWifiLogin;
