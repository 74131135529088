import {
    SET_SELECTED_HOTEL,
    SET_SELECTED_ROOM,
    SET_ENSURE_USER_CALLBACK,
    SET_SELECTED_LANDING,
} from "../actions/hotel";
import { SET_PUSH_TOKEN, SET_UNREAD_MESSAGES } from "../actions/notifications";
import { SET_SIGNUP_FORM_VALUES } from "../actions/config";
import { SET_GOOGLE_TOKEN, SET_VERIFIED_TOKEN } from "../actions/integrations";
import { SET_CUINER_DATA } from "../actions/cuiner";
import { SET_STAY_BOOKINGS, SET_STAY_BOOKINGS_GUESTS_ORDER } from "actions/stays";

const initialState = {
    selectedHotel: null,
    selectedRooms: {},
    selectedLandings: {},
    pushToken: null,
    googleToken: null,
    verifiedToken: null,
    ensureUserCallback: null,
    lastSignupFormValues: null,
    cuinerData: {},
    stayBookings: {},
    arrUnreadMessages: null,
};

const reducerSession = function (state = initialState, action = {}) {
    const newState = { ...state };
    switch (action.type) {
        case SET_PUSH_TOKEN:
            newState.pushToken = action.payload;
            break;
        case SET_GOOGLE_TOKEN:
            newState.googleToken = action.payload;
            break;
        case SET_VERIFIED_TOKEN:
            newState.verifiedToken = action.payload;
            break;
        case SET_SIGNUP_FORM_VALUES:
            newState.lastSignupFormValues = action.payload;
            break;
        case SET_SELECTED_HOTEL:
            newState.selectedHotel = action.payload;
            newState.ensureUserCallback = null;
            break;
        case SET_SELECTED_ROOM:
            if (newState.selectedHotel) {
                if (newState.selectedRooms) {
                    if (!action.payload) {
                        delete newState.selectedRooms[newState.selectedHotel];
                    } else {
                        newState.selectedRooms[newState.selectedHotel] = action.payload;
                    }
                }
            }
            newState.ensureUserCallback = null;
            break;
        case SET_SELECTED_LANDING:
            if (newState.selectedHotel) {
                if (!newState.selectedLandings) newState.selectedLandings = {};
                if (!action.payload) {
                    delete newState.selectedLandings[newState.selectedHotel];
                } else {
                    newState.selectedLandings[newState.selectedHotel] = action.payload;
                }
            }
            break;
        case SET_CUINER_DATA:
            if (newState.selectedHotel) {
                if (!newState.cuinerData) newState.cuinerData = {};
                if (!action.payload) {
                    delete newState.cuinerData[newState.selectedHotel];
                } else {
                    newState.cuinerData[newState.selectedHotel] = action.payload;
                }
            }
            break;
        case SET_ENSURE_USER_CALLBACK:
            newState.ensureUserCallback = action.payload;
            break;
        case SET_STAY_BOOKINGS:
            if (newState.selectedHotel) {
                if (!newState.stayBookings) {
                    newState.stayBookings = {};
                }
                if (!newState.stayBookings[newState.selectedHotel]) {
                    newState.stayBookings[newState.selectedHotel] = {};
                    newState.stayBookings[newState.selectedHotel][action.payload.user] = [];
                }
                if (!action.payload.values) {
                    delete newState.stayBookings[newState.selectedHotel][action.payload.user];
                } else {
                    newState.stayBookings[newState.selectedHotel][action.payload.user ?? "any"] = action.payload.values;
                }
            }

            break;
        case SET_STAY_BOOKINGS_GUESTS_ORDER:
            if (newState.selectedHotel) {
                if (!newState.stayBookingsGuestsOrder) {
                    newState.stayBookingsGuestsOrder = {};
                }
                if (action.payload.code && action?.payload?.arrGuestsOrder?.length > 0) {
                    newState.stayBookingsGuestsOrder[action.payload.code] = action.payload.arrGuestsOrder;
                }
            }

            break;

        case SET_UNREAD_MESSAGES:
            if (newState.selectedHotel) {
                if (action?.payload?.arrUnreadMessages?.length > 0) {
                    newState.arrUnreadMessages = action?.payload?.arrUnreadMessages;
                }
            }
            break;
        default:
    }
    return newState;
};

export default reducerSession;
