import React, { useContext, useEffect } from "react";
import { parseTextConfig, parseSize, parseImageConfig, configToCSS } from "../DesignUtils";
import useWidget from "../../../hooks/useWidget";
import { MessagesContext } from "contexts/messages";
import { ProjectContext } from "contexts/project";

const UseWidgetMessage = ({ config, id, deviceType }) => {
    const { project, room } = useContext(ProjectContext);
    const { hasUnread } = useContext(MessagesContext);
    const hasUnreadMessages = hasUnread(project?.ref, room?.number);

    const { cfg } = useWidget({
        data: config ? config.messages : null,
        styles: config ? config.styles : null,
        parentCfg: config ? config.parentCfg : null,
        parseConfig,
    });

    const size = (s) => parseSize(s, deviceType) + "px";

    useEffect(() => {
        const element = document.querySelector(`#${id}`);
        element.parentNode.style.backgroundColor = "inherit";
    }, [id]);

    const iconPos = cfg.icon.pos;
    const iconBefore = iconPos && iconPos.match(/^(left|top)$/);
    const iconVertical = iconPos && iconPos.match(/^(top|bottom)$/);
    const iconSize = cfg.icon.size || 1;
    const iconMargin = cfg.icon.margin || 0;

    const icon = (
        <>
            <div
                className="inline-block m-2"
                style={configToCSS(
                    {
                        size: iconSize,
                        fgColor: cfg.colors.icon,
                        positionMargin: { pos: iconPos, margin: iconMargin },
                    },
                    deviceType
                )}
            >
                <div className="relative icon icon-mail block">
                    {hasUnreadMessages ? (
                        <div
                            className="bg-error rounded-full absolute top-0 right-0 -mr-1 bg-red-100"
                            style={configToCSS({
                                width: size(iconSize / 2.5),
                                height: size(iconSize / 2.5),
                            })}
                        ></div>
                    ) : null}
                </div>
            </div>
        </>
    );

    const text = (
        <span
            style={configToCSS(
                {
                    ...cfg.title,
                    display: iconVertical ? "block" : "inline-block",
                },
                deviceType
            )}
        >
            {cfg.title.text}
        </span>
    );

    return (
        <div
            className={`flex w-full h-full bg-center bg-no-repeat p-2`}
            id={id}
            style={configToCSS(
                {
                    size: cfg.title.size,
                    font: cfg.title.font,
                    border: cfg.border,
                    align: cfg.icon.align,
                    vAlign: cfg.icon.vAlign,
                    fgColor: cfg.colors.text,
                    bgColor: cfg.colors.bg,
                    bgImage: cfg.bgImage,
                },
                deviceType
            )}
        >
            <div className="w-full ">
                <div className={`w-full align-center`}>
                    {icon && iconBefore ? icon : null}
                    {text}
                    {icon && !iconBefore ? icon : null}
                </div>
            </div>
        </div>
    );
};

const parseConfig = ({ data, styles, parentCfg, theme, lang }) => {
    let bgColor = styles.bgColor || theme.colors.defaultBackground;
    if (bgColor && parentCfg && parentCfg.bgColor === bgColor) {
        bgColor = null;
    }

    return {
        title: {
            text: parseTextConfig(data.title),
            font: (styles.font ? styles.font.name : null) || theme.fonts.paragraph.font.name,
            size: styles.textSize || theme.fonts.paragraph.size,
            bold: styles.bold,
            italic: styles.italic,
            underline: styles.underline,
        },
        icon: {
            size: styles.iconSize,
            pos: styles.iconPosition || "right",
            margin: styles.iconMargin,
            align: styles.alignment || "center",
            vAlign: styles.vAlignment || "center",
        },
        bgImage: {
            src: parseImageConfig(data.image, lang),
            adjust: data.adjust,
        },
        colors: {
            text: styles.fgColor || theme.colors.defaultForeground,
            bg: bgColor,
            icon: styles.fgActiveColor || theme.colors.activeForeground,
        },
        border: {
            color: styles.borderColor,
            size: styles.borderWidth,
            radius: styles.radius,
        },
    };
};

export default UseWidgetMessage;
