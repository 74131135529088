import Hotels from "../views/Hotels";
import HotelSearch from "../views/HotelSearch";
import Hotel from "../views/Hotel";
import Room from "../views/Room";
import Zones from "../views/Zones";
import Booking from "../views/Booking";
import AddRoom from "../views/AddRoom";
import ScanQR from "../views/ScanQR";
import { authRoutes } from "./auth";

export const hotelRoutes = (prefix = "") => {
    return [
        {
            path: prefix + "/list",
            exact: true,
            section: Hotels,
        },
        {
            path: prefix + "/list/search",
            exact: true,
            section: HotelSearch,
        },
        {
            path: prefix + "/addroom",
            exact: true,
            section: AddRoom,
        },
        {
            path: prefix + "/addroom/p/:project",
            exact: true,
            section: AddRoom,
        },
        {
            path: prefix + "/addroom/qr",
            exact: true,
            section: ScanQR,
        },
        ...authRoutes(prefix + "/addroom"),
        {
            path: prefix,
            exact: true,
            section: Hotel,
        },
        {
            path: prefix + "/room",
            exact: true,
            section: Room,
        },
        {
            path: prefix + "/room/:roomShopRef",
            exact: true,
            section: Room,
        },
        {
            path: prefix + "/zones",
            exact: true,
            section: Zones,
        },
        ...authRoutes(prefix + "/zones"),
        {
            path: prefix + "/booking",
            exact: true,
            section: Booking,
        },
        ...authRoutes(prefix + "/booking"),
        // Deprecated routes (need to be changed from render library in back)
        // http://localhost:3000/?room=101&code=wnl442#/site/addroom/p/project-0000-0000-0000-0001
        // http://localhost:3000/?room=102&code=prvdm8#/hotel/addroom/p/project-0000-0000-0000-0001
        {
            path: "/hotel/addroom/p/:project",
            exact: true,
            section: AddRoom,
        },
    ];
};
