export const SET_STAY_BOOKINGS = "SET_BOOKINGS";
export const SET_STAY_BOOKINGS_GUESTS_ORDER = "SET_STAY_BOOKINGS_GUEST_ORDER";
import { getHotel } from "./hotel";

export function setStaysData(value) {
    return setDispatch(SET_STAY_BOOKINGS, value);
}

export function setStaysBookingsGuestsOrder(value) {
    return setDispatch(SET_STAY_BOOKINGS_GUESTS_ORDER, value);
}

export function getStaysData(state) {
    const projectRef = getHotel(state);
    const user = state?.user?.current || null;
    const bookings = state?.session?.stayBookings || null;
    let data = null;
    if (bookings && user && bookings[projectRef]) {
        data = bookings[projectRef][user];
    } else if (bookings && bookings[projectRef] && bookings[projectRef].any) {
        data = bookings[projectRef].any;
    }
    return data;
}

export function getStaysBookingsGuestOrders(state) {
    return state?.session?.stayBookingsGuestsOrder;
}

function setDispatch(type, value) {
    return (dispatch) => {
        dispatch({
            type: type,
            payload: value,
        });
    };
}
