import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

const SelectInput = ({
    placeholder,
    options,
    name,
    onChange,
    isRequired,
    error,
    errorMsg,
    disabled,
    value,
    onClick,
}) => {
    const { t } = useTranslation();
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const firstRender = useRef(false);
    const [optionsSelected, setOptionsSelected] = useState(null);
    const optionsRef = useRef(null);

    const onCLickAction = (item) => {
        setOptionsSelected([item]);
        setIsOptionsOpen(false);
    };

    useEffect(() => {
        if (value && options?.length > 0 && !firstRender.current) {
            const currentOptionsSelected = options.filter((opt) => {
                return opt.id === value;
            });
            setOptionsSelected(currentOptionsSelected);
            firstRender.current = true;
        }
    }, [value, options]);

    useEffect(() => {
        if (onChange && optionsSelected?.length > 0) {
            onChange(optionsSelected);
        }
    }, [optionsSelected]);

    const onContainerClick = (e) => {
        if (optionsRef.current && !optionsRef.current.contains(e.target)) {
            setIsOptionsOpen(false);
        }
    };

    return (
        <div className=" w-full">
            <label className={` block text-sm mb-1  ${disabled ? " text-neutral-contrast-40" : ""}`}>
                {t(name)} {isRequired && !disabled ? " *" : ""}
            </label>
            <div
                onClick={() => {
                    if (!disabled) {
                        if (onClick) {
                            onClick();
                        } else {
                            setIsOptionsOpen((val) => !val);
                        }
                    }
                }}
                id={`select-input-open-button-options-${name}`}
                className={`select-style flex justify-between items-center ${
                    disabled || !optionsSelected || optionsSelected?.length <= 0 ? " text-neutral-contrast-30" : ""
                }  ${error ? " border border-red-100 " : " boder border-neutral-contrast-20"}`}
            >
                <div>{optionsSelected?.length > 0 ? optionsSelected[0]?.name : t(placeholder)}</div>{" "}
                <i className={`icon icon-down ${disabled ? "text-neutral-contrast-30" : "text-gray-800"} `}></i>
            </div>
            {isOptionsOpen ? (
                <div
                    id={name ? `select-input-container-options-${name}` : null}
                    onClick={onContainerClick}
                    className=" bg-neutral-contrast-80 fixed top-0 flex items-center justify-center left-0 h-screen w-full z-205 px-8 "
                >
                    <div
                        style={{ maxHeight: "80vh", overflowY: "scroll" }}
                        ref={optionsRef}
                        className=" w-full p-6 rounded bg-white"
                    >
                        {options.map((item, index) => {
                            return (
                                <div className=" flex items-center mb-2 ">
                                    <div
                                        className={` w-4 h-4 rounded-full ${
                                            optionsSelected?.some((opt) => opt.id === item.id)
                                                ? "border-4 bg-white border-neutral-contrast"
                                                : " border-8 border-gray-200"
                                        } mr-2`}
                                        onClick={() => {
                                            onCLickAction(item);
                                        }}
                                        id={name ? `select-option-radio${name}-${index}` : null}
                                    ></div>
                                    <span
                                        id={name ? `select-option-radio${name}-name-${index}` : null}
                                        onClick={() => {
                                            onCLickAction(item);
                                        }}
                                    >
                                        {item.name}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : null}
            {error && errorMsg ? <div className=" text-error float-right text-sm">{errorMsg}</div> : null}
        </div>
    );
};

export default SelectInput;
