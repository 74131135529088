import Stays from "../views/Stays";
import AddStay from "views/AddStay";
import { authRoutes } from "./auth";
import pkg from "../../package.json";
import InformationStay from "components/Stays/InformationStay";
import PreCheckin from "components/Stays/PreCheckin";
import FormGuestPrecheckin from "components/Stays/FormGuestPrecheckin";

export const staysRoutes = (prefix = "") => {
    if (!pkg.features.stays) {
        return [];
    }
    return [
        {
            path: prefix,
            exact: true,
            section: Stays,
        },
        {
            path: prefix + "/add",
            exact: true,
            section: AddStay,
        },
        {
            path: prefix + "/status=:status",
            exact: true,
            section: Stays,
        },
        {
            path: prefix + "/:id",
            exact: true,
            section: Stays,
        },
        {
            path: prefix + "/details/:code",
            exact: true,
            section: InformationStay,
        },
        {
            path: prefix + "/details/:code/precheckin",
            exact: true,
            section: PreCheckin,
        },
        {
            path: prefix + "/details/:code/precheckin/step/:step",
            exact: true,
            section: PreCheckin,
        },
        {
            path: prefix + "/details/:code/precheckin/:id/:number",
            exact: true,
            section: FormGuestPrecheckin,
        },
        ...authRoutes(prefix + "/precheckin"),
    ];
};
