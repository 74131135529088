import React from "react";
import { useTranslation } from "react-i18next";

const TextArea = ({ placeholder = "", disabled, name = "name", labelStyle, value, icon }) => {
    const { t } = useTranslation();

    const style = !labelStyle ? "block text-neutral-contrast-50 font-bold text-sm mb-3" : labelStyle;

    return (
        <div>
            <div className=" flex items-center">
                <label className={style} htmlFor={`text-input-${name}-input`}>
                    {t(name)}
                </label>
                {icon ? <i className={`icon ${icon} ml-3 text-2xl -mt-2`}></i> : null}
            </div>
            <textarea
                readOnly={disabled}
                placeholder={placeholder}
                value={value}
                cols={10}
                className=" h-32 resize-none py-2"
                id={`${name}-text-area`}
            ></textarea>
        </div>
    );
};

export default TextArea;
