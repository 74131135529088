export const UPDATE_HOTELS = "UPDATE_HOTELS";
export const SET_SELECTED_HOTEL = "SET_SELECTED_HOTEL";
export const SET_SELECTED_ROOM = "SET_SELECTED_ROOM";
export const SET_ENSURE_USER_CALLBACK = "SET_ENSURE_USER_CALLBACK";
export const SET_SELECTED_LANDING = "SET_SELECTED_LANDING";
export const UPDATE_SERVER_BOOKMARKS = "UPDATE_SERVER_BOOKMARKS";
export const UPDATE_VOD_STATUS = "UPDATE_VOD_STATUS";

export function updateHotels(value) {
    return setDispatch(UPDATE_HOTELS, value);
}

export function getHotels(state) {
    return state?.cache?.hotels?.list || null;
}

export function getHotel(state) {
    return state?.session?.selectedHotel || null;
}

export function updateVODStatus(value) {
    return setDispatch(UPDATE_VOD_STATUS, value);
}

export function setSelectedHotel(value) {
    return setDispatch(SET_SELECTED_HOTEL, value);
}

export function setSelectedRoom(value) {
    return setDispatch(SET_SELECTED_ROOM, value);
}

export function setEnsureUserCallback(value) {
    return setDispatch(SET_ENSURE_USER_CALLBACK, value);
}

export function setSelectedLanding(value) {
    return setDispatch(SET_SELECTED_LANDING, value);
}

export function updateServerBookmarks(data) {
    return setDispatch(UPDATE_SERVER_BOOKMARKS, data);
}

function setDispatch(type, value) {
    return (dispatch) => {
        dispatch({
            type: type,
            payload: value,
        });
    };
}

export function getSelectedHotel(state) {
    const selectedHotel = state && state.session ? state.session.selectedHotel : null;
    if (selectedHotel) {
        const projects = getHotels(state);
        if (projects) {
            const current = projects.filter((item) => item.ref === selectedHotel);
            if (current && current.length > 0) {
                return current[0];
            } else {
                console.warn("Selected project not found in this list of projects");
            }
        } else {
            console.warn("Unable to validate selected hotel. Not found projects");
        }
    }
    return selectedHotel ? { ref: selectedHotel } : null;
}
