import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUserData } from "../../../actions/user";
import { parseTextConfig, configToCSS } from "../DesignUtils";
import useWidget from "../../../hooks/useWidget";
import { getSelectedHotel } from "../../../actions/hotel";
import pkg from "../../../../package.json";
import { useSendTrackEventGeneralMobile } from "graphql/useUser";

const UseWidgetChatButton = ({ config, id, deviceType }) => {
    const { t } = useTranslation();
    const selectedHotel = useSelector(getSelectedHotel);
    const user = useSelector(getUserData);
    const showChat = pkg.features.chat && selectedHotel && selectedHotel.chatURL;
    const canUseChat = showChat && ((selectedHotel && selectedHotel.chatPublic) || (user && user.emailVerified));
    const { sendTrackEventGeneralMobile } = useSendTrackEventGeneralMobile();

    const { cfg } = useWidget({
        data: config ? config.chatbutton : null,
        styles: config ? config.styles : null,
        parentCfg: config ? config.parentCfg : null,
        parseConfig,
    });

    useEffect(() => {
        const element = document.querySelector(`#${id}`);
        element.parentNode.style.backgroundColor = "inherit";
    }, [id]);

    const iconBefore = cfg.icon.pos && cfg.icon.pos.match(/^(left|top)$/);
    const iconVertical = cfg.icon.pos && cfg.icon.pos.match(/^(top|bottom)$/);

    const icon = cfg.icon.enabled ? (
        <div
            className="icon icon-chat inline-block m-2"
            style={configToCSS(
                {
                    size: cfg.icon.size,
                    fgColor: (canUseChat ? cfg.colors : cfg.colors.disabled).icon,
                    positionMargin: { pos: cfg.icon.pos, margin: cfg.icon.margin },
                },
                deviceType
            )}
        ></div>
    ) : null;

    const text = (
        <span
            style={configToCSS(
                {
                    ...cfg.title,
                    display: iconVertical ? "block" : "inline-block",
                },
                deviceType
            )}
        >
            {cfg.title.text}
        </span>
    );

    return (
        <div
            className={`flex w-full h-full bg-center bg-no-repeat p-2`}
            id={id}
            style={configToCSS(
                {
                    size: cfg.title.size,
                    font: cfg.title.font,
                    border: { ...cfg.border, ...(canUseChat ? {} : cfg.border.disabled) },
                    align: cfg.icon.align,
                    vAlign: cfg.icon.vAlign,
                    fgColor: (canUseChat ? cfg.colors : cfg.colors.disabled).text,
                    bgColor: (canUseChat ? cfg.colors : cfg.colors.disabled).bg,
                },
                deviceType
            )}
        >
            <div className="w-full ">
                <a
                    id="action-widget-chat"
                    onClick={() => {
                        if (!canUseChat) {
                            if (selectedHotel && selectedHotel.chatURL) {
                                if (user && !user.emailVerified) {
                                    toast(t("must verify to chat"));
                                } else {
                                    toast(t("must login to chat"));
                                }
                            } else {
                                toast(t("No linked phone number"));
                            }
                        } else {
                            sendTrackEventGeneralMobile({ contactType: "CHAT_WIDGET" }, "CONTACT");
                        }
                    }}
                    href={canUseChat && selectedHotel ? selectedHotel.chatURL : undefined}
                    target={canUseChat ? "_blank" : undefined}
                    rel="noopener noreferrer"
                    className={`w-full`}
                    disabled={!canUseChat}
                >
                    <div className={`w-full align-center`}>
                        {icon && iconBefore ? icon : null}
                        {text}
                        {icon && !iconBefore ? icon : null}
                    </div>
                </a>
            </div>
        </div>
    );
};

const parseConfig = ({ data, styles, parentCfg, theme }) => {
    let bgColor = styles.bgColor || theme.colors.defaultBackground;
    if (bgColor && parentCfg && parentCfg.bgColor === bgColor) {
        bgColor = null;
    }
    return {
        title: {
            text: parseTextConfig(data.title),
            size: styles.textSize || 1.7,
            bold: styles.bold,
            italic: styles.italic,
            underline: styles.underline,
        },
        icon: {
            enabled: data.showIcon,
            size: styles.iconSize,
            pos: styles.iconPosition || "right",
            margin: styles.iconMargin,
            align: styles.alignment || "center",
            vAlign: styles.vAlignment || "center",
        },
        colors: {
            text: styles.fgColor || theme.colors.defaultForeground,
            bg: bgColor,
            icon: styles.iconColor || theme.colors.defaultForeground,
            disabled: {
                text: styles.disabledFgColor || "#76889b",
                bg: styles.disabledBgColor || "#d3dae1",
                icon: styles.disabledFgColor || "#76889b",
            },
        },
        border: {
            color: styles.borderColor,
            size: styles.borderWidth,
            disabled: {
                color: styles.disabledBorderColor,
                size: styles.disabledBorderWidth,
            },
            radius: styles.radius || 0.5,
        },
    };
};

export default UseWidgetChatButton;
