import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import ErrorPage from "../components/ErrorPage";
import { useConfiguredTerms, arePublicConditions } from "../utils/config";

const Terms = () => {
    const { t, i18n } = useTranslation();
    const i18nLang = (i18n.language || "").match(/^[^-]+/)[0];
    const html = useConfiguredTerms(i18nLang);
    if (!html) {
        return <ErrorPage type="config-terms" />;
    }

    if (arePublicConditions()) {
        return (
            <div className="p-5 text-center w-full">
                <h1 className="text-2xl font-bold">{t("terms & conditions")}</h1>
                <div className="text-left select-text" dangerouslySetInnerHTML={{ __html: html }}></div>
            </div>
        );
    }

    return (
        <section className="basic has-top">
            <Header title={t("terms & conditions")} />
            <section className="select-text" dangerouslySetInnerHTML={{ __html: html }}></section>
        </section>
    );
};

export default Terms;
