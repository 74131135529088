export function queryString(params) {
    if (!params || typeof params !== "object") {
        return "";
    }
    return Object.keys(params)
        .map((key) => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
        })
        .join("&");
}

export function checkValidURL(target, times, delay) {
    return new Promise((res, rej) => {
        // return a promise
        (function rec(i) {
            // recursive IIFE
            fetch(target, { mode: "no-cors" })
                .then((r) => {
                    // fetch the resourse
                    res(r); // resolve promise if success
                })
                .catch((err) => {
                    if (times === 0) {
                        // if number of tries reached
                        return rej(err); // don't try again
                    }
                    setTimeout(() => rec(--times), delay); // otherwise, wait and try
                }); // again until no more tries
        })(times);
    });
}
