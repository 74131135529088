import i18n from "i18n";
import { getNationalitiesOptions } from "utils/nationalities";

export const GUESTS_ROLES = {
    MAIN: "MAIN",
};

export const PRECHECKIN_STATUS = {
    PENDING_COMPLETION: { text: "pending-completion", style: "text-gray-500", value: "PENDING_COMPLETION" },
    PENDING_VALIDATION: { text: "pending-validation", style: "text-yellow-500", value: "PENDING_VALIDATION" },
    PENDING_MODIFICATION: { text: "pending-modification", style: "text-gray-600", value: "PENDING_MODIFICATION" },
    VALIDATED: { text: "validated", style: "text-green-500", value: "VALIDATED" },
    COMPLETED: { text: "completed", style: "text-green-500", value: "COMPLETED" },
};

//UTILS TO HANDLER FORM PRECHECKIN GUEST

export function extractEnabledFields(
    config,
    isAChildGuest,
    configChild,
    existingValues = {},
    numberOfRooms,
    customFields,
    childrenCustomFields
) {
    const effectiveConfig = isAChildGuest ? configChild : config;
    const enabledFields = {};

    Object.keys(effectiveConfig).forEach((key) => {
        let defaultValue = null;
        if (key === "gender" && effectiveConfig?.[key]?.enabled && !existingValues[key]) {
            defaultValue = "female";
        } else if (existingValues[key]) {
            defaultValue = existingValues[key].value;
        }

        enabledFields[key] = {
            value: defaultValue,
            required: effectiveConfig?.[key]?.required ? true : false,
            enabled: effectiveConfig?.[key]?.enabled ? true : false,
        };
    });

    if (!enabledFields.rooms) {
        const valueRoom = () => {
            if (existingValues.rooms) {
                return existingValues.rooms.value;
            } else if (numberOfRooms <= 1) {
                return "1";
            } else {
                return null;
            }
        };

        enabledFields["rooms"] = {
            value: valueRoom(),
            required: false,
            enabled: true,
        };
    }

    if (!isAChildGuest) {
        customFields.forEach((f) => {
            enabledFields["custom-field-" + f.ref] = {
                value: "",
                required: f.required ? true : false,
                enabled: f.enabled ? true : false,
            };
        });
    } else {
        childrenCustomFields.forEach((f) => {
            enabledFields["custom-field-" + f.ref] = {
                value: "",
                required: f.required ? true : false,
                enabled: f.enabled ? true : false,
            };
        });
    }

    return enabledFields;
}

export function filterValidFieldsWithValue(data, isAChildGuest, enabledFields) {
    const applicableFields = isAChildGuest ? enabledFields.childEnabledFields : enabledFields.adultEnabledFields;

    const validFieldsWithValue = {};
    for (const key in applicableFields) {
        if (applicableFields[key].enabled && data[key] && data[key].value !== null) {
            validFieldsWithValue[key] = data[key].value;
        }
    }

    return validFieldsWithValue;
}

export function parseValidFieldsToMutationVars(data) {
    const mappings = {
        name: { newKey: "name" },
        email: { newKey: "email" },
        gender: { newKey: "gender" },
        surname: { newKey: "surname" },
        phone: { newKey: "phoneNumber" },
        birthdate: { newKey: "birthDate" },
        documentType: { newKey: "documentType" },
        documentNumber: { newKey: "documentNumber" },
        nationality: {
            newKey: "countryRef",
        },
        rooms: {
            newKey: "stayBookingRoomIndex",
        },
        documentExpeditionDate: {
            newKey: "documentExtraInformation",
            transform: (value) => ({ expeditionDate: value }),
        },
        customFieldsInformation: [],
    };

    const validFieldsToMutation = {
        customFieldsInformation: [],
    };

    for (const key in data) {
        const field = data[key];
        const mapping = mappings[key];

        if (mapping) {
            const value = mapping.transform ? mapping.transform(field) : field;

            if (value !== undefined) {
                validFieldsToMutation[mapping.newKey] = value;
            }
        }

        if (key.includes("custom-field-")) {
            const refinedKey = key.replace("custom-field-", "");
            validFieldsToMutation.customFieldsInformation.push({ ref: refinedKey, value: data[key] });
        }
    }

    return validFieldsToMutation;
}

export function generateArrayOptions(size, t) {
    const result = [];
    for (let i = 1; i <= size; i++) {
        result.push({
            id: String(i),
            name: `${t("room")} ${i}`,
        });
    }
    return result;
}

export function getDocumentsOptions(arrOptions, t) {
    const arrDocuments = [];

    arrOptions?.forEach((opt) => {
        if (opt === "national_id") {
            arrDocuments.push({ id: "NID", name: t("national_id") });
        } else if (opt === "passport") {
            arrDocuments.push({ id: "PASSPORT", name: t("passport") });
        } else if (opt === "driving-license") {
            arrDocuments.push({ id: "DRIVER_LICENSE", name: t("driving-license") });
        } else if (opt === "residence-permit") {
            arrDocuments.push({ id: "RESIDENCE_PERMIT", name: t("residence-permit") });
        }
    });

    return arrDocuments;
}

export function calculateIsAChildGuest(ageGuest, maximumAgeOfChildren, requestDataFromChildren) {
    let isAChild = false;

    if (ageGuest !== null && maximumAgeOfChildren && requestDataFromChildren) {
        if (ageGuest < maximumAgeOfChildren) {
            isAChild = true;
        }
    }

    return isAChild;
}

export function updateDataFieldsWithGuestInfo(guest, dataFields, numberOfRooms) {
    const fieldMappings = {
        name: "name",
        email: "email",
        gender: "gender",
        surname: "surname",
        birthDate: "birthdate",
        countryRef: "nationality",
        phoneNumber: "phone",
        documentType: "documentType",
        documentNumber: "documentNumber",
        stayBookingRoomIndex: "rooms",
        "documentExtraInformation.expeditionDate": "documentExpeditionDate",
    };

    const copyDataFields = { ...dataFields };

    function getNestedProperty(obj, path) {
        return path.split(".").reduce((acc, part) => acc && acc[part], obj);
    }

    for (const [guestKey, dataFieldKey] of Object.entries(fieldMappings)) {
        let guestValue = getNestedProperty(guest, guestKey);
        if (dataFieldKey === "gender" && (guestValue === undefined || guestValue === "" || guestValue === null)) {
            guestValue = "female";
        }

        if (
            dataFieldKey === "rooms" &&
            numberOfRooms <= 1 &&
            (guestValue === undefined || guestValue === "" || guestValue === null)
        ) {
            guestValue = "1";
        }

        if (guestValue !== undefined && copyDataFields[dataFieldKey]) {
            copyDataFields[dataFieldKey].value = guestValue;
        }
    }

    if (guest?.customFieldsInformation?.length > 0) {
        guest?.customFieldsInformation.forEach((f) => {
            if (copyDataFields["custom-field-" + f.ref]) {
                copyDataFields["custom-field-" + f.ref].value = f.value;
            }
            // console.log()
        });
    }

    return copyDataFields;
}

export function calculateYearsSince(dateString) {
    if (!dateString) {
        return 0;
    }

    const givenDate = new Date(dateString);
    const today = new Date();

    let years = today.getFullYear() - givenDate.getFullYear();
    const months = today.getMonth() - givenDate.getMonth();
    const days = today.getDate() - givenDate.getDate();

    if (months < 0 || (months === 0 && days < 0)) {
        years--;
    }

    return years;
}

function adjustFields(preCheckInFields) {
    const fields = { ...preCheckInFields };
    Object.keys(fields).forEach((key) => {
        if (fields[key] === null) {
            fields[key] = {
                enabled: false,
                required: false,
            };
            if (key === "documentType") {
                fields[key].values = [];
            }
        }
    });
    return fields;
}

export function getInfoFromData(data, id) {
    // console.log(data);
    const lang = (i18n.language || "").match(/^[^-]+/)[0];

    const {
        preCheckInSettingsFields = {},
        preCheckInSettingsFieldsChildren = {},
        preCheckInSettings = {},
        getStayBookings = {},
        preCheckInSettingsCustomFields = [],
        preCheckInSettingsCustomFieldsChildren = [],
    } = data || {};

    function filterAndSelectLanguage(fields, lang) {
        return fields
            .filter((field) => field.enabled)
            .map((field) => {
                const nameForLang =
                    field?.names?.find((name) => name.lang === lang && name?.name?.trim() !== "") ||
                    field?.names?.find((name) => name?.name?.trim() !== "");
                return {
                    ...field,
                    name: nameForLang?.name || "",
                };
            })
            .sort((a, b) => a.order - b.order);
    }
    const adjustedFields = adjustFields(preCheckInSettingsFields);

    const { maximumAgeOfChildren = 0, requestDataFromChildren } = preCheckInSettings;

    const { documentType = {} } = adjustedFields;

    const { values: documentTypeOptions = [] } = documentType;

    const { numberOfRooms = 0, numberOfGuests, guests, state } = getStayBookings;

    const guestIsFull = numberOfGuests <= guests?.length;

    const onModificationAvailable = state !== PRECHECKIN_STATUS.COMPLETED.value;

    let guest = null;

    if (id) {
        guest = guests?.filter((guest) => guest.id === Number(id))?.[0];
    }

    return {
        preCheckInSettingsFieldsChildren,
        preCheckInSettingsFields: adjustedFields,
        onModificationAvailable,
        requestDataFromChildren,
        maximumAgeOfChildren,
        documentTypeOptions,
        numberOfGuests,
        numberOfRooms,
        guestIsFull,
        guest,
        preCheckInSettingsCustomFields: filterAndSelectLanguage(preCheckInSettingsCustomFields, lang),
        preCheckInSettingsCustomFieldsChildren: filterAndSelectLanguage(preCheckInSettingsCustomFieldsChildren, lang),
    };
}

export const INPUTS_FORM_GENERATOR = ({ numberOfRooms, t, isoCountries, documentTypeOptions }) => {
    return [
        { type: "text", key: "name" },
        { type: "text", key: "surname" },
        {
            type: "select",
            key: "rooms",
            name: "select-room",
            placeholder: "select-room",
            options: generateArrayOptions(numberOfRooms, t),
        },
        { type: "date", key: "birthdate" },
        {
            type: "select",
            key: "nationality",
            options: getNationalitiesOptions(),
            placeholder: "select-nationality",
        },
        {
            type: "select",
            key: "documentType",
            options: getDocumentsOptions(documentTypeOptions, t),
            placeholder: "select-document-type",
            name: "document-type",
        },
        {
            type: "text",
            key: "documentNumber",
            placeholder: "no-of-document",
            name: "document-number",
        },
        { type: "date", key: "documentExpeditionDate", name: "date-of-issue" },
        {
            type: "selector",
            key: "gender",
            initialOptionSelected: "female",
            options: [
                { id: "female", name: "female" },
                { id: "male", name: "male" },
            ],
        },
        { type: "tel", key: "phone", placeholder: "phone-number" },
        { type: "text", key: "email" },
    ];
};

export function resizeArrayGuestsOrder(originalArray, newSize) {
    if (newSize > originalArray.length) {
        for (let i = originalArray.length; i < newSize; i++) {
            originalArray.push({ id: null, index: i });
        }
    } else if (newSize < originalArray.length) {
        let nonNullIds = originalArray.filter((item) => item.id !== null);

        if (nonNullIds.length > newSize) {
            nonNullIds = nonNullIds.slice(0, newSize);
        }

        while (nonNullIds.length < newSize) {
            nonNullIds.push({ id: null, index: nonNullIds.length });
        }

        nonNullIds.forEach((item, index) => (item.index = index));
        originalArray = nonNullIds;
    }

    return originalArray;
}
export function updateIdGuestArrayGuestsOrder(arrayGuestsOrder, guests) {
    const updateArrayGuestsOrder = arrayGuestsOrder.map((item) => ({ ...item }));

    guests.forEach((guest, index) => {
        if (index < updateArrayGuestsOrder.length && !updateArrayGuestsOrder.some((item) => item.id === guest.id)) {
            updateArrayGuestsOrder[index].id = guest.id;
        }
    });

    return updateArrayGuestsOrder;
}

export function generateArrayGuestsOrder(size) {
    const result = [];
    for (let i = 0; i < size; i++) {
        result.push({ id: null, index: i });
    }
    return result;
}

export function getRecordByIndexFromCurrentStaysBookingGuestsOrder(array, index) {
    const record = array?.find((record) => record.index === index);

    return record || null;
}

export function setIdAtIndexStaysBookingGuestsOrder(array, index, newId) {
    const newArray = [...array];

    if (index >= 0 && index < newArray.length && (typeof newId === "string" || typeof newId === "number")) {
        newArray[index].id = newId;
    } else {
        return array;
    }

    return newArray;
}
