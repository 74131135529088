import React, { useEffect } from "react";

import { parseTextConfig, parseImageConfig, configToCSS } from "../DesignUtils";
import useWidget from "../../../hooks/useWidget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UseWidgetButton = ({ config, id, deviceType }) => {
    const { cfg } = useWidget({
        data: config ? config.button : null,
        styles: config ? config.styles : null,
        parentCfg: config ? config.parentCfg : null,
        parseConfig,
    });

    useEffect(() => {
        const element = document.querySelector(`#${id}`);
        element.parentNode.style.backgroundColor = "inherit";
    }, [id]);

    const iconBefore = cfg.icon.pos && cfg.icon.pos.match(/^(left|top)$/);
    const iconVertical = cfg.icon.pos && cfg.icon.pos.match(/^(top|bottom)$/);

    const icon =
        cfg.icon.enabled && cfg.icon.id ? (
            <div
                className="icon inline-block m-2"
                style={configToCSS(
                    {
                        size: cfg.icon.size,
                        fgColor: cfg.colors.icon,
                        positionMargin: { pos: cfg.icon.pos, margin: cfg.icon.margin },
                    },
                    deviceType
                )}
            >
                <FontAwesomeIcon icon={[cfg.icon.lib, cfg.icon.id]} />
            </div>
        ) : null;

    const text = (
        <span
            style={configToCSS(
                {
                    ...cfg.title,
                    display: iconVertical ? "block" : "inline-block",
                },
                deviceType
            )}
        >
            {cfg.title.text}
        </span>
    );

    return (
        <div
            className={`flex w-full h-full bg-center bg-no-repeat p-2`}
            id={id}
            style={configToCSS(
                {
                    size: cfg.title.size,
                    font: cfg.title.font,
                    border: cfg.border,
                    align: cfg.icon.align,
                    vAlign: cfg.icon.vAlign,
                    fgColor: cfg.colors.text,
                    bgColor: cfg.colors.bg,
                    bgImage: cfg.bgImage,
                },
                deviceType
            )}
        >
            <div className="w-full ">
                <div className={`w-full align-center`}>
                    {icon && iconBefore ? icon : null}
                    {text}
                    {icon && !iconBefore ? icon : null}
                </div>
            </div>
        </div>
    );
};

const parseConfig = ({ data, styles, parentCfg, theme, lang }) => {
    let bgColor = styles.bgColor || theme.colors.defaultBackground;
    if(bgColor && (parentCfg && parentCfg.bgColor === bgColor))bgColor = null;
    return {
        title: {
            text: parseTextConfig(data.title),
            font: (styles.font ? styles.font.name : null) || theme.fonts.paragraph.font.name,
            size: styles.textSize || theme.fonts.paragraph.size,
            bold: styles.bold,
            italic: styles.italic,
            underline: styles.underline,
        },
        icon: {
            enabled: data.showIcon,
            lib: data.lib || "fa",
            id: data.icon,
            size: styles.iconSize,
            pos: styles.iconPosition || "right",
            margin: styles.iconMargin,
            align: styles.alignment || "center",
            vAlign: styles.vAlignment || "center",
        },
        bgImage: {
            src: parseImageConfig(data.image, lang),
            adjust: data.adjust,
        },
        colors: {
            text: styles.fgColor || theme.colors.defaultForeground,
            bg: bgColor,
            icon: styles.iconColor || theme.colors.defaultForeground,
        },
        border: {
            color: styles.borderColor,
            size: styles.borderWidth,
            radius: styles.radius,
        },
    };
};

export default UseWidgetButton;
