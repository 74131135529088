import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Header from "../components/Header";
import Tabs from "../components/Tabs";
import { useDispatch } from "react-redux";
import { setSelectedHotel } from "../actions/hotel";
import { useHotels } from "../graphql/useHotels";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";

const HotelSearch = (params) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { loading, error, hotels } = useHotels({ autoload: true });
    const [redirectTo, setRedirectTo] = useState("");
    const [search, setSearch] = useState(params.search || "");

    const goToHotel = (hotel) => {
        dispatch(setSelectedHotel(hotel.ref));
        setRedirectTo("/site");
    };

    let hotelsFiltered = null;

    if (hotels && typeof search === "string" && search !== "") {
        const lowerSearch = search.toLowerCase();
        hotelsFiltered = hotels.filter((item) => {
            var keys = ["name", "location"];
            return keys.some((key) => {
                if (typeof item[key] === "string") {
                    return item[key].toLowerCase().includes(lowerSearch);
                }
                return false;
            });
        });
    }

    useEffect(() => {
        dispatch(setSelectedHotel(null));
    }, []);

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    return (
        <section className="basic has-tabs has-top">
            {!hotels && error ? (
                <ErrorMessage type="server" error={error} />
            ) : loading ? (
                <Loading />
            ) : (
                <>
                    <Header>
                        <input
                            type="text"
                            className="search-top primary"
                            placeholder={t("search hotel")}
                            autoFocus={true}
                            onChange={(e) => {
                                if (typeof e.target.value === "string") {
                                    setSearch(e.target.value);
                                }
                            }}
                        />
                    </Header>
                    <section>
                        <ul id="result-list">
                            {hotelsFiltered !== null
                                ? hotelsFiltered
                                      .sort((a, b) => {
                                          if (a.bookmark === true && b.bookmark !== true) {
                                              return -1;
                                          } else if (b.bookmark === true && a.bookmark !== true) {
                                              return 1;
                                          }
                                          return 0;
                                      })
                                      .map((hotel, index) => {
                                          return (
                                              <li
                                                  key={index}
                                                  onClick={() => goToHotel(hotel)}
                                                  className="border-b text-left"
                                              >
                                                  <button className="flex justify-between w-full py-3">
                                                      <span>
                                                          <div className="text-left">{hotel.name}</div>
                                                          {hotel.location !== null && hotel.location !== "" ? (
                                                              <div className="text-sm text-left">
                                                                  <i className="inline-block align-text-top icon icon-location text-lg"></i>{" "}
                                                                  {hotel.location}
                                                              </div>
                                                          ) : null}
                                                      </span>
                                                      <span>
                                                          {hotel.bookmark === true ? (
                                                              <i className="icon icon-bookmark text-3xl"></i>
                                                          ) : null}
                                                      </span>
                                                  </button>
                                              </li>
                                          );
                                      })
                                : null}
                        </ul>
                    </section>
                </>
            )}
            <Tabs />
        </section>
    );
};

export default HotelSearch;
