import React, { useContext, useEffect, useState } from "react";
import { useMarkMessagesAsRead } from "../graphql/useMessages";

import Loading from "./Loading";
import Message from "./Message";
import { MESSAGE_ALERT_TYPES } from "../utils/messages";
import { MessagesContext } from "../contexts/messages";
import { useDispatch, useSelector } from "react-redux";
import { setAllUnreadMessages } from "actions/notifications";
import { useSendTrackEventGeneralMobile } from "graphql/useUser";

const Messages = () => {
    const { messages: allMessages, loading } = useContext(MessagesContext);

    const dispatch = useDispatch();

    const { arrUnreadMessages } = useSelector((state) => state.session);
    const [messages, setMessages] = useState(null);
    const [unreadMessages, setUnreadMessages] = useState(null);
    const [prepareRead, setPrepareRead] = useState({ confirm: false, messages: [] });
    const [indexShowing, setIndexShowing] = useState(0);
    const { sendTrackEventGeneralMobile } = useSendTrackEventGeneralMobile();

    const { markMessagesAsRead, error: setReadError } = useMarkMessagesAsRead();

    const message = messages?.length > 0 ? messages?.[indexShowing] : null;

    const readMessage = (confirm) => {
        if (message && prepareRead?.messages?.filter((m) => m.id === message.id).length === 0) {
            setPrepareRead({
                ...prepareRead,
                ...(confirm ? { confirm: true } : null),
                messages: [
                    ...(prepareRead ? prepareRead.messages : null),
                    {
                        id: message.id,
                        projectRef: message.projectRef,
                    },
                ],
            });
        }
    };

    useEffect(() => {
        setMessages(allMessages?.filter((m) => MESSAGE_ALERT_TYPES.includes(m?.priority) && !m?.isRead));
        setUnreadMessages(allMessages?.filter((m) => !m?.isRead));
    }, [allMessages]);

    useEffect(() => {
        if (unreadMessages?.length > 0) {
            const updatedMessages = processUnreadMessages(
                arrUnreadMessages || [],
                unreadMessages,
                sendTrackEventGeneralMobile
            );
            dispatch(setAllUnreadMessages({ arrUnreadMessages: updatedMessages }));
        }
    }, [unreadMessages]);

    useEffect(() => {
        if (prepareRead?.confirm) {
            const msgProjects = prepareRead?.messages?.reduce((a, b) => {
                if (!a[b.projectRef]) {
                    a[b.projectRef] = [];
                }
                a[b.projectRef].push(b.id);
                return a;
            }, {});
            Object.entries(msgProjects).forEach(([projectRef, messageIDs]) => {
                if (messageIDs && messageIDs.length > 0) {
                    markMessagesAsRead({
                        variables: {
                            projectRef,
                            messageIDs,
                        },
                    });
                }
            });
            const allIDs = Object.values(msgProjects).reduce((a, b) => a.concat(b), []);
            setMessages([
                ...(messages || []).map((m) =>
                    allIDs.includes(m.id)
                        ? {
                              ...m,
                              isRead: true,
                          }
                        : m
                ),
            ]);
            setPrepareRead({ confirm: false, messages: [] });
        }
    }, [prepareRead?.confirm]);

    useEffect(() => {
        if (setReadError) {
            console.error(setReadError);
        }
    }, [setReadError]);

    return (
        <>
            {loading ? <Loading /> : null}
            {message ? (
                <Message
                    message={message}
                    onClose={() => {
                        setMessages(null);
                        setIndexShowing(0);
                        readMessage(true);
                    }}
                    pagination={
                        messages.length > 1
                            ? {
                                  current: indexShowing + 1,
                                  total: messages.length,
                                  prev: () => {
                                      setIndexShowing(indexShowing - 1);
                                  },
                                  next: () => {
                                      readMessage(false);
                                      setIndexShowing(indexShowing + 1);
                                  },
                              }
                            : null
                    }
                />
            ) : null}
        </>
    );
};

export default Messages;

const processUnreadMessages = (currentMessages, newMessages, sendTrackEventGeneralMobile) => {
    const getMappedNewMessages = (newMessages) => {
        return newMessages.map((m) => ({
            id: m.id,
            tracked: false,
            priority: m.priority,
        }));
    };

    const updateTrackedMessages = (messages) => {
        return messages.map((m) => {
            if (!m.tracked) {
                sendTrackEventGeneralMobile({ receiveMessageType: m.priority }, "RECEIVE_MESSAGE");
            }
            return { ...m, tracked: true };
        });
    };

    const existingMessageIds = new Set(currentMessages.map((m) => m.id));
    const newFilteredMessages = newMessages.filter((m) => !existingMessageIds.has(m.id));
    const mappedNewMessages = getMappedNewMessages(newFilteredMessages);
    let combinedMessages = currentMessages.concat(mappedNewMessages);
    return updateTrackedMessages(combinedMessages);
};
