import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHotels } from "../graphql/useHotels";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import Webview from "../components/Webview";
import { queryString } from "../utils/url";
import { setSelectedHotel, getHotel } from "../actions/hotel";
import { GET } from "../utils/get";
import usePostMessage from "../hooks/usePostMessage";
import useZonesMessage from "../hooks/useZonesMessage";
import { getReservationsUrl } from "../actions/config";
import { getUser } from "../actions/user";

const Booking = () => {
    const dispatch = useDispatch();
    const selectedHotel = useSelector(getHotel);
    const currentUser = useSelector(getUser);
    const user = useSelector((state) =>
        currentUser && state.user && state.user.data ? state.user.data[currentUser] : null
    );
    const {
        loading,
        error,
        hotels: hotel,
    } = useHotels({
        ref: selectedHotel,
        autoload: true,
    });
    const get = GET(useLocation());
    if (get.h) {
        const hotelRef = atob(get.h);
        if (hotelRef && selectedHotel !== hotelRef) {
            dispatch(setSelectedHotel(hotelRef));
        }
    }
    const zoneRef = get.z ? atob(get.z) : undefined;
    const initialIframePath = "/";

    const { incoming, setIncoming, handleIcomingMessage } = usePostMessage();

    const { parseMessage, iframePath, redirectTo } = useZonesMessage(initialIframePath, setIncoming);

    useEffect(() => {
        parseMessage("booking", incoming);

        window.addEventListener("message", handleIcomingMessage);
        return () => {
            window.removeEventListener("message", handleIcomingMessage);
        };
    }, [handleIcomingMessage, parseMessage, incoming]);

    if (!selectedHotel || !hotel) {
        return <Redirect push to="/site/list" />;
    }

    if (!user) {
        return <Redirect to="booking/signin" />;
    } else if (!user.emailVerified) {
        return <Redirect to="booking/verify" />;
    }

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    let url = getReservationsUrl() + iframePath;
    const urlParams = {};
    if (hotel) {
        urlParams.projectRef = hotel.ref;
        if (hotel.timeZone) urlParams.timeZone = hotel.timeZone;
    }
    if (zoneRef) {
        urlParams.zoneRef = zoneRef;
        urlParams.page = "detail";
    }
    if (get.status) {
        urlParams.status = get.status;
    }
    if (url) {
        url += (url.match(/\?/) ? "&" : "?") + queryString(urlParams);
    }

    return (
        <>
            {!hotel && error ? (
                <ErrorMessage type="server" />
            ) : loading ? (
                <Loading />
            ) : (
                <section className="basic">
                    <div className="max-w-lg mx-auto">
                        <Webview
                            title={hotel ? hotel.name : ""}
                            url={url}
                            addParams={["lang", "token", "logged", "appType"]}
                        />
                    </div>
                </section>
            )}
        </>
    );
};

export default Booking;
