import React, { useEffect, useRef } from "react";
import Render from "zrender/render";
import { useTranslation } from "react-i18next";
import { getDasUrl } from "../actions/config";
import { getDeviceWidth } from "./Design/DesignUtils";

const Widget = ({ id, type, device, deviceWidth, config, style, texts, lang, noUseFullHeight}) => {
    const currentDevice = device || "mobile";
    const useDeviceWidth = !deviceWidth;

    const renderRef = useRef(new Render(type));
    const render = renderRef.current;
    const { t: tr } = useTranslation();

    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const widgetRef = useRef(null);

    const className = `relative ${type === "TOPBAR" ? "" : `overflow-hidden w-full ${!noUseFullHeight ? "h-full" :""}`}`;

    const printWidget = () => {
        const node = widgetRef.current;
        const widget = render.run();
        if (node) {
            while (node.firstChild) {
                node.removeChild(node.firstChild);
            }
            if (widget) {
                node.appendChild(widget);
            }
        }
    };

    useEffect(() => {
        render.DAS = getDasUrl;
        render.setDevice(currentDevice, useDeviceWidth ? getDeviceWidth() : deviceWidth);
        render.updateConfig({ static: false, lang: "es", ...config });
        render.setStyle(style);
        render.updateTexts(texts);
        printWidget();
    }, [type, currentDevice, deviceWidth, config, style, texts]);

    useEffect(() => {
        const updateDevice = () => {
            render.setDevice(currentDevice, getDeviceWidth());
            printWidget();
        };
        if (!useDeviceWidth) {
            window.removeEventListener("resize", updateDevice);
        } else {
            window.addEventListener("resize", updateDevice);
        }
        return () => {
            window.removeEventListener("resize", updateDevice);
        };
    }, [useDeviceWidth]);

    return <div ref={widgetRef} id={id} className={className}></div>;
};

export default Widget;
